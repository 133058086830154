// source: ads.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var discovery_pb = require('./discovery_pb.js');
goog.object.extend(proto, discovery_pb);
goog.exportSymbol('proto.marketplace.Ad', null, global);
goog.exportSymbol('proto.marketplace.AdByIDRequest', null, global);
goog.exportSymbol('proto.marketplace.AdCurrentVersion', null, global);
goog.exportSymbol('proto.marketplace.AdCurrentVersionAdParamsFilter', null, global);
goog.exportSymbol('proto.marketplace.AdCurrentVersionAdParamsFilterResponse', null, global);
goog.exportSymbol('proto.marketplace.AdCurrentVersionFilter', null, global);
goog.exportSymbol('proto.marketplace.AdCurrentVersionFilter.Level', null, global);
goog.exportSymbol('proto.marketplace.AdCurrentVersionFilter.State', null, global);
goog.exportSymbol('proto.marketplace.AdCurrentVersionFilterResponse', null, global);
goog.exportSymbol('proto.marketplace.AdCurrentVersionResponse', null, global);
goog.exportSymbol('proto.marketplace.AdParam', null, global);
goog.exportSymbol('proto.marketplace.AdParamValue', null, global);
goog.exportSymbol('proto.marketplace.AdResponse', null, global);
goog.exportSymbol('proto.marketplace.AdViewRequest', null, global);
goog.exportSymbol('proto.marketplace.AnalyticsResponse', null, global);
goog.exportSymbol('proto.marketplace.CreateGoldAdResponse', null, global);
goog.exportSymbol('proto.marketplace.Delegation', null, global);
goog.exportSymbol('proto.marketplace.DelegationResponse', null, global);
goog.exportSymbol('proto.marketplace.DelegationsByGovernorateRequest', null, global);
goog.exportSymbol('proto.marketplace.DeleteGoldenResponse', null, global);
goog.exportSymbol('proto.marketplace.DeleteUserDataRequest', null, global);
goog.exportSymbol('proto.marketplace.DeleteUserDataResponse', null, global);
goog.exportSymbol('proto.marketplace.FavoriteRequest', null, global);
goog.exportSymbol('proto.marketplace.Geolocation', null, global);
goog.exportSymbol('proto.marketplace.GetAdsByUserRequest', null, global);
goog.exportSymbol('proto.marketplace.GetAdsByUserRequest.SortMechanism', null, global);
goog.exportSymbol('proto.marketplace.GetPaymentResponse', null, global);
goog.exportSymbol('proto.marketplace.GoldAd', null, global);
goog.exportSymbol('proto.marketplace.GoldAdId', null, global);
goog.exportSymbol('proto.marketplace.GoldAdIdResponse', null, global);
goog.exportSymbol('proto.marketplace.Governorate', null, global);
goog.exportSymbol('proto.marketplace.GovernorateResponse', null, global);
goog.exportSymbol('proto.marketplace.IndexedParamValue', null, global);
goog.exportSymbol('proto.marketplace.IndexedParamValue.ValueCase', null, global);
goog.exportSymbol('proto.marketplace.Leads', null, global);
goog.exportSymbol('proto.marketplace.LeadsCount', null, global);
goog.exportSymbol('proto.marketplace.Location', null, global);
goog.exportSymbol('proto.marketplace.LocationByGovernorateAndDelegation', null, global);
goog.exportSymbol('proto.marketplace.LocationResponse', null, global);
goog.exportSymbol('proto.marketplace.MeiliSearchRequest', null, global);
goog.exportSymbol('proto.marketplace.MeiliSearchResponse', null, global);
goog.exportSymbol('proto.marketplace.NbAdsByUserRequest', null, global);
goog.exportSymbol('proto.marketplace.NbAdsByUserResponse', null, global);
goog.exportSymbol('proto.marketplace.NearPointRequest', null, global);
goog.exportSymbol('proto.marketplace.NearPointResponse', null, global);
goog.exportSymbol('proto.marketplace.NewAdRequest', null, global);
goog.exportSymbol('proto.marketplace.NewAdRequest.ProductType', null, global);
goog.exportSymbol('proto.marketplace.NewDelegationRequest', null, global);
goog.exportSymbol('proto.marketplace.NewGovernorateRequest', null, global);
goog.exportSymbol('proto.marketplace.NewLocationRequest', null, global);
goog.exportSymbol('proto.marketplace.OfflineAdsForUserRequest', null, global);
goog.exportSymbol('proto.marketplace.OfflineAdsForUserRequest.SortMechanism', null, global);
goog.exportSymbol('proto.marketplace.ParamRange', null, global);
goog.exportSymbol('proto.marketplace.Payment', null, global);
goog.exportSymbol('proto.marketplace.ReportAdByUserRequest', null, global);
goog.exportSymbol('proto.marketplace.ReportAdByUserResponse', null, global);
goog.exportSymbol('proto.marketplace.ResponseView', null, global);
goog.exportSymbol('proto.marketplace.StateInAdByIDRequest', null, global);
goog.exportSymbol('proto.marketplace.TotalAdsCountResponse', null, global);
goog.exportSymbol('proto.marketplace.TransactionStatus', null, global);
goog.exportSymbol('proto.marketplace.TransactionStatus.TransactionStatus', null, global);
goog.exportSymbol('proto.marketplace.TransactionStatusRequest', null, global);
goog.exportSymbol('proto.marketplace.TransactionStatusRequest.TransactionStatus', null, global);
goog.exportSymbol('proto.marketplace.TransactionStatusResponse', null, global);
goog.exportSymbol('proto.marketplace.Type', null, global);
goog.exportSymbol('proto.marketplace.ValidateAdparamsRequest', null, global);
goog.exportSymbol('proto.marketplace.ValidateAdparamsResponse', null, global);
goog.exportSymbol('proto.marketplace.Version', null, global);
goog.exportSymbol('proto.marketplace.Version.Level', null, global);
goog.exportSymbol('proto.marketplace.Version.ProductType', null, global);
goog.exportSymbol('proto.marketplace.Version.State', null, global);
goog.exportSymbol('proto.marketplace.Version.VersionChangeType', null, global);
goog.exportSymbol('proto.marketplace.VersionFilterResponse', null, global);
goog.exportSymbol('proto.marketplace.VersionFilterResponse.Level', null, global);
goog.exportSymbol('proto.marketplace.VersionFilterResponse.ProductType', null, global);
goog.exportSymbol('proto.marketplace.VersionFilterResponse.State', null, global);
goog.exportSymbol('proto.marketplace.VersionFilterResponse.VersionChangeType', null, global);
goog.exportSymbol('proto.marketplace.VersionInAdByIDRequest', null, global);
goog.exportSymbol('proto.marketplace.ViewResponse', null, global);
goog.exportSymbol('proto.marketplace.Voucher', null, global);
goog.exportSymbol('proto.marketplace.VoucherLog', null, global);
goog.exportSymbol('proto.marketplace.VoucherRequest', null, global);
goog.exportSymbol('proto.marketplace.VoucherResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.AdParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketplace.AdParam.repeatedFields_, null);
};
goog.inherits(proto.marketplace.AdParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.AdParam.displayName = 'proto.marketplace.AdParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.ParamRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.ParamRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.ParamRange.displayName = 'proto.marketplace.ParamRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.Ad = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketplace.Ad.repeatedFields_, null);
};
goog.inherits(proto.marketplace.Ad, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.Ad.displayName = 'proto.marketplace.Ad';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.Version = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketplace.Version.repeatedFields_, null);
};
goog.inherits(proto.marketplace.Version, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.Version.displayName = 'proto.marketplace.Version';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.VersionFilterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketplace.VersionFilterResponse.repeatedFields_, null);
};
goog.inherits(proto.marketplace.VersionFilterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.VersionFilterResponse.displayName = 'proto.marketplace.VersionFilterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.IndexedParamValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.marketplace.IndexedParamValue.oneofGroups_);
};
goog.inherits(proto.marketplace.IndexedParamValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.IndexedParamValue.displayName = 'proto.marketplace.IndexedParamValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.AdParamValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.AdParamValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.AdParamValue.displayName = 'proto.marketplace.AdParamValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.Geolocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.Geolocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.Geolocation.displayName = 'proto.marketplace.Geolocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.Location.displayName = 'proto.marketplace.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.Governorate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.Governorate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.Governorate.displayName = 'proto.marketplace.Governorate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.Delegation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.Delegation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.Delegation.displayName = 'proto.marketplace.Delegation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.AdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.AdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.AdResponse.displayName = 'proto.marketplace.AdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.AdCurrentVersionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.AdCurrentVersionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.AdCurrentVersionResponse.displayName = 'proto.marketplace.AdCurrentVersionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.TotalAdsCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.TotalAdsCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.TotalAdsCountResponse.displayName = 'proto.marketplace.TotalAdsCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.AdCurrentVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.AdCurrentVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.AdCurrentVersion.displayName = 'proto.marketplace.AdCurrentVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.AdCurrentVersionAdParamsFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.AdCurrentVersionAdParamsFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.AdCurrentVersionAdParamsFilter.displayName = 'proto.marketplace.AdCurrentVersionAdParamsFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.AdCurrentVersionFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketplace.AdCurrentVersionFilter.repeatedFields_, null);
};
goog.inherits(proto.marketplace.AdCurrentVersionFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.AdCurrentVersionFilter.displayName = 'proto.marketplace.AdCurrentVersionFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.AdCurrentVersionFilterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.AdCurrentVersionFilterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.AdCurrentVersionFilterResponse.displayName = 'proto.marketplace.AdCurrentVersionFilterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.AdCurrentVersionAdParamsFilterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.AdCurrentVersionAdParamsFilterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.AdCurrentVersionAdParamsFilterResponse.displayName = 'proto.marketplace.AdCurrentVersionAdParamsFilterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.AdByIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.AdByIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.AdByIDRequest.displayName = 'proto.marketplace.AdByIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.GoldAd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.GoldAd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.GoldAd.displayName = 'proto.marketplace.GoldAd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.GoldAdId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.GoldAdId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.GoldAdId.displayName = 'proto.marketplace.GoldAdId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.GoldAdIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.GoldAdIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.GoldAdIdResponse.displayName = 'proto.marketplace.GoldAdIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.CreateGoldAdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.CreateGoldAdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.CreateGoldAdResponse.displayName = 'proto.marketplace.CreateGoldAdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.StateInAdByIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.StateInAdByIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.StateInAdByIDRequest.displayName = 'proto.marketplace.StateInAdByIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.VersionInAdByIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.VersionInAdByIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.VersionInAdByIDRequest.displayName = 'proto.marketplace.VersionInAdByIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.NewAdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketplace.NewAdRequest.repeatedFields_, null);
};
goog.inherits(proto.marketplace.NewAdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.NewAdRequest.displayName = 'proto.marketplace.NewAdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.LocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.LocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.LocationResponse.displayName = 'proto.marketplace.LocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.NewLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.NewLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.NewLocationRequest.displayName = 'proto.marketplace.NewLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.NewGovernorateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.NewGovernorateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.NewGovernorateRequest.displayName = 'proto.marketplace.NewGovernorateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.NewDelegationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.NewDelegationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.NewDelegationRequest.displayName = 'proto.marketplace.NewDelegationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.GovernorateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.GovernorateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.GovernorateResponse.displayName = 'proto.marketplace.GovernorateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.DelegationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.DelegationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.DelegationResponse.displayName = 'proto.marketplace.DelegationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.NearPointRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.NearPointRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.NearPointRequest.displayName = 'proto.marketplace.NearPointRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.LocationByGovernorateAndDelegation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.LocationByGovernorateAndDelegation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.LocationByGovernorateAndDelegation.displayName = 'proto.marketplace.LocationByGovernorateAndDelegation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.NearPointResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.NearPointResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.NearPointResponse.displayName = 'proto.marketplace.NearPointResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.DelegationsByGovernorateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.DelegationsByGovernorateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.DelegationsByGovernorateRequest.displayName = 'proto.marketplace.DelegationsByGovernorateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.FavoriteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.FavoriteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.FavoriteRequest.displayName = 'proto.marketplace.FavoriteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.Payment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.Payment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.Payment.displayName = 'proto.marketplace.Payment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.GetPaymentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.GetPaymentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.GetPaymentResponse.displayName = 'proto.marketplace.GetPaymentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.NbAdsByUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.NbAdsByUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.NbAdsByUserRequest.displayName = 'proto.marketplace.NbAdsByUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.NbAdsByUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.NbAdsByUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.NbAdsByUserResponse.displayName = 'proto.marketplace.NbAdsByUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.OfflineAdsForUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.OfflineAdsForUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.OfflineAdsForUserRequest.displayName = 'proto.marketplace.OfflineAdsForUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.GetAdsByUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.GetAdsByUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.GetAdsByUserRequest.displayName = 'proto.marketplace.GetAdsByUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.ReportAdByUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.ReportAdByUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.ReportAdByUserRequest.displayName = 'proto.marketplace.ReportAdByUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.ReportAdByUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.ReportAdByUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.ReportAdByUserResponse.displayName = 'proto.marketplace.ReportAdByUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.TransactionStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.TransactionStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.TransactionStatusRequest.displayName = 'proto.marketplace.TransactionStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.TransactionStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.TransactionStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.TransactionStatusResponse.displayName = 'proto.marketplace.TransactionStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.TransactionStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.TransactionStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.TransactionStatus.displayName = 'proto.marketplace.TransactionStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.MeiliSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.MeiliSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.MeiliSearchRequest.displayName = 'proto.marketplace.MeiliSearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.MeiliSearchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketplace.MeiliSearchResponse.repeatedFields_, null);
};
goog.inherits(proto.marketplace.MeiliSearchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.MeiliSearchResponse.displayName = 'proto.marketplace.MeiliSearchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.AdViewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.AdViewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.AdViewRequest.displayName = 'proto.marketplace.AdViewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.ViewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.ViewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.ViewResponse.displayName = 'proto.marketplace.ViewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.Leads = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.Leads, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.Leads.displayName = 'proto.marketplace.Leads';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.ResponseView = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketplace.ResponseView.repeatedFields_, null);
};
goog.inherits(proto.marketplace.ResponseView, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.ResponseView.displayName = 'proto.marketplace.ResponseView';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.VoucherRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.VoucherRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.VoucherRequest.displayName = 'proto.marketplace.VoucherRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.VoucherResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.VoucherResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.VoucherResponse.displayName = 'proto.marketplace.VoucherResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.Voucher = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.Voucher, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.Voucher.displayName = 'proto.marketplace.Voucher';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.VoucherLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.VoucherLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.VoucherLog.displayName = 'proto.marketplace.VoucherLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.ValidateAdparamsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketplace.ValidateAdparamsRequest.repeatedFields_, null);
};
goog.inherits(proto.marketplace.ValidateAdparamsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.ValidateAdparamsRequest.displayName = 'proto.marketplace.ValidateAdparamsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.ValidateAdparamsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.ValidateAdparamsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.ValidateAdparamsResponse.displayName = 'proto.marketplace.ValidateAdparamsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.DeleteUserDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.DeleteUserDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.DeleteUserDataRequest.displayName = 'proto.marketplace.DeleteUserDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.DeleteUserDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.DeleteUserDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.DeleteUserDataResponse.displayName = 'proto.marketplace.DeleteUserDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.DeleteGoldenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.DeleteGoldenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.DeleteGoldenResponse.displayName = 'proto.marketplace.DeleteGoldenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.LeadsCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.LeadsCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.LeadsCount.displayName = 'proto.marketplace.LeadsCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.AnalyticsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.AnalyticsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.AnalyticsResponse.displayName = 'proto.marketplace.AnalyticsResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketplace.AdParam.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.AdParam.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.AdParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.AdParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    possiblevaluesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    rangevalues: (f = msg.getRangevalues()) && proto.marketplace.ParamRange.toObject(includeInstance, f),
    dependantname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    single: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.AdParam}
 */
proto.marketplace.AdParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.AdParam;
  return proto.marketplace.AdParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.AdParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.AdParam}
 */
proto.marketplace.AdParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addPossiblevalues(value);
      break;
    case 5:
      var value = new proto.marketplace.ParamRange;
      reader.readMessage(value,proto.marketplace.ParamRange.deserializeBinaryFromReader);
      msg.setRangevalues(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDependantname(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSingle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.AdParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.AdParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.AdParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPossiblevaluesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getRangevalues();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.marketplace.ParamRange.serializeBinaryToWriter
    );
  }
  f = message.getDependantname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSingle();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.marketplace.AdParam.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdParam} returns this
 */
proto.marketplace.AdParam.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.marketplace.AdParam.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdParam} returns this
 */
proto.marketplace.AdParam.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.marketplace.AdParam.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdParam} returns this
 */
proto.marketplace.AdParam.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string possibleValues = 4;
 * @return {!Array<string>}
 */
proto.marketplace.AdParam.prototype.getPossiblevaluesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.marketplace.AdParam} returns this
 */
proto.marketplace.AdParam.prototype.setPossiblevaluesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.marketplace.AdParam} returns this
 */
proto.marketplace.AdParam.prototype.addPossiblevalues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.AdParam} returns this
 */
proto.marketplace.AdParam.prototype.clearPossiblevaluesList = function() {
  return this.setPossiblevaluesList([]);
};


/**
 * optional ParamRange rangeValues = 5;
 * @return {?proto.marketplace.ParamRange}
 */
proto.marketplace.AdParam.prototype.getRangevalues = function() {
  return /** @type{?proto.marketplace.ParamRange} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.ParamRange, 5));
};


/**
 * @param {?proto.marketplace.ParamRange|undefined} value
 * @return {!proto.marketplace.AdParam} returns this
*/
proto.marketplace.AdParam.prototype.setRangevalues = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.AdParam} returns this
 */
proto.marketplace.AdParam.prototype.clearRangevalues = function() {
  return this.setRangevalues(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.AdParam.prototype.hasRangevalues = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string dependantName = 7;
 * @return {string}
 */
proto.marketplace.AdParam.prototype.getDependantname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdParam} returns this
 */
proto.marketplace.AdParam.prototype.setDependantname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool single = 8;
 * @return {boolean}
 */
proto.marketplace.AdParam.prototype.getSingle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marketplace.AdParam} returns this
 */
proto.marketplace.AdParam.prototype.setSingle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.ParamRange.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.ParamRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.ParamRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ParamRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    min: jspb.Message.getFieldWithDefault(msg, 1, 0),
    max: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.ParamRange}
 */
proto.marketplace.ParamRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.ParamRange;
  return proto.marketplace.ParamRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.ParamRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.ParamRange}
 */
proto.marketplace.ParamRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.ParamRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.ParamRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.ParamRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ParamRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMin();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMax();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 min = 1;
 * @return {number}
 */
proto.marketplace.ParamRange.prototype.getMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.ParamRange} returns this
 */
proto.marketplace.ParamRange.prototype.setMin = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 max = 2;
 * @return {number}
 */
proto.marketplace.ParamRange.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.ParamRange} returns this
 */
proto.marketplace.ParamRange.prototype.setMax = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketplace.Ad.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.Ad.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.Ad.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.Ad} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Ad.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subcategoryid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    creationtimestamp: (f = msg.getCreationtimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    currentversion: (f = msg.getCurrentversion()) && proto.marketplace.Version.toObject(includeInstance, f),
    versionsList: jspb.Message.toObjectList(msg.getVersionsList(),
    proto.marketplace.Version.toObject, includeInstance),
    sync: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.Ad}
 */
proto.marketplace.Ad.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.Ad;
  return proto.marketplace.Ad.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.Ad} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.Ad}
 */
proto.marketplace.Ad.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubcategoryid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationtimestamp(value);
      break;
    case 4:
      var value = new proto.marketplace.Version;
      reader.readMessage(value,proto.marketplace.Version.deserializeBinaryFromReader);
      msg.setCurrentversion(value);
      break;
    case 5:
      var value = new proto.marketplace.Version;
      reader.readMessage(value,proto.marketplace.Version.deserializeBinaryFromReader);
      msg.addVersions(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSync(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.Ad.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.Ad.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.Ad} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Ad.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubcategoryid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreationtimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCurrentversion();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.marketplace.Version.serializeBinaryToWriter
    );
  }
  f = message.getVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.marketplace.Version.serializeBinaryToWriter
    );
  }
  f = message.getSync();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.marketplace.Ad.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Ad} returns this
 */
proto.marketplace.Ad.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subCategoryId = 2;
 * @return {string}
 */
proto.marketplace.Ad.prototype.getSubcategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Ad} returns this
 */
proto.marketplace.Ad.prototype.setSubcategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp creationTimestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marketplace.Ad.prototype.getCreationtimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marketplace.Ad} returns this
*/
proto.marketplace.Ad.prototype.setCreationtimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.Ad} returns this
 */
proto.marketplace.Ad.prototype.clearCreationtimestamp = function() {
  return this.setCreationtimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.Ad.prototype.hasCreationtimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Version currentVersion = 4;
 * @return {?proto.marketplace.Version}
 */
proto.marketplace.Ad.prototype.getCurrentversion = function() {
  return /** @type{?proto.marketplace.Version} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.Version, 4));
};


/**
 * @param {?proto.marketplace.Version|undefined} value
 * @return {!proto.marketplace.Ad} returns this
*/
proto.marketplace.Ad.prototype.setCurrentversion = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.Ad} returns this
 */
proto.marketplace.Ad.prototype.clearCurrentversion = function() {
  return this.setCurrentversion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.Ad.prototype.hasCurrentversion = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Version versions = 5;
 * @return {!Array<!proto.marketplace.Version>}
 */
proto.marketplace.Ad.prototype.getVersionsList = function() {
  return /** @type{!Array<!proto.marketplace.Version>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.marketplace.Version, 5));
};


/**
 * @param {!Array<!proto.marketplace.Version>} value
 * @return {!proto.marketplace.Ad} returns this
*/
proto.marketplace.Ad.prototype.setVersionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.marketplace.Version=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marketplace.Version}
 */
proto.marketplace.Ad.prototype.addVersions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.marketplace.Version, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.Ad} returns this
 */
proto.marketplace.Ad.prototype.clearVersionsList = function() {
  return this.setVersionsList([]);
};


/**
 * optional bool sync = 6;
 * @return {boolean}
 */
proto.marketplace.Ad.prototype.getSync = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marketplace.Ad} returns this
 */
proto.marketplace.Ad.prototype.setSync = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketplace.Version.repeatedFields_ = [4,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.Version.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.Version.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.Version} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Version.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imagesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    versionchangetype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    state: jspb.Message.getFieldWithDefault(msg, 6, 0),
    ishighlighted: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    level: jspb.Message.getFieldWithDefault(msg, 8, 0),
    location: (f = msg.getLocation()) && proto.marketplace.Location.toObject(includeInstance, f),
    score: jspb.Message.getFieldWithDefault(msg, 10, 0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    indexedparamvaluesMap: (f = msg.getIndexedparamvaluesMap()) ? f.toObject(includeInstance, proto.marketplace.IndexedParamValue.toObject) : [],
    adparamvaluesList: jspb.Message.toObjectList(msg.getAdparamvaluesList(),
    proto.marketplace.AdParamValue.toObject, includeInstance),
    timestampsortscore: (f = msg.getTimestampsortscore()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    userphonenumber: jspb.Message.getFieldWithDefault(msg, 15, ""),
    shopavatar: jspb.Message.getFieldWithDefault(msg, 16, ""),
    producttype: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.Version}
 */
proto.marketplace.Version.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.Version;
  return proto.marketplace.Version.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.Version} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.Version}
 */
proto.marketplace.Version.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addImages(value);
      break;
    case 5:
      var value = /** @type {!proto.marketplace.Version.VersionChangeType} */ (reader.readEnum());
      msg.setVersionchangetype(value);
      break;
    case 6:
      var value = /** @type {!proto.marketplace.Version.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIshighlighted(value);
      break;
    case 8:
      var value = /** @type {!proto.marketplace.Version.Level} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    case 9:
      var value = new proto.marketplace.Location;
      reader.readMessage(value,proto.marketplace.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScore(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 12:
      var value = msg.getIndexedparamvaluesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.marketplace.IndexedParamValue.deserializeBinaryFromReader, "", new proto.marketplace.IndexedParamValue());
         });
      break;
    case 13:
      var value = new proto.marketplace.AdParamValue;
      reader.readMessage(value,proto.marketplace.AdParamValue.deserializeBinaryFromReader);
      msg.addAdparamvalues(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestampsortscore(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserphonenumber(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopavatar(value);
      break;
    case 17:
      var value = /** @type {!proto.marketplace.Version.ProductType} */ (reader.readEnum());
      msg.setProducttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.Version.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.Version.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.Version} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Version.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getVersionchangetype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getIshighlighted();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.marketplace.Location.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getIndexedparamvaluesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(12, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.marketplace.IndexedParamValue.serializeBinaryToWriter);
  }
  f = message.getAdparamvaluesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.marketplace.AdParamValue.serializeBinaryToWriter
    );
  }
  f = message.getTimestampsortscore();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserphonenumber();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getShopavatar();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getProducttype();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.marketplace.Version.ProductType = {
  DEFAULT: 0,
  NEW: 1
};

/**
 * @enum {number}
 */
proto.marketplace.Version.VersionChangeType = {
  CREATE: 0,
  SELL: 1,
  BOOST: 2,
  STATE_UPDATE: 3,
  DATA_UPDATE: 4,
  ACTIVATE_NORMAL: 5,
  HIGHLIGHT: 6,
  EXPIRE: 7,
  FEATURE: 8
};

/**
 * @enum {number}
 */
proto.marketplace.Version.State = {
  PENDING_MODERATION: 0,
  REJECTED: 1,
  ACTIVE: 2,
  EXPIRED: 3,
  DELETED: 4,
  SOLD: 5,
  MANUAL_PENDING_MODERATION: 6
};

/**
 * @enum {number}
 */
proto.marketplace.Version.Level = {
  STANDARD: 0,
  FEATURED: 1
};

/**
 * optional google.protobuf.Timestamp timestamp = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marketplace.Version.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marketplace.Version} returns this
*/
proto.marketplace.Version.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.Version} returns this
 */
proto.marketplace.Version.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.Version.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.marketplace.Version.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Version} returns this
 */
proto.marketplace.Version.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.marketplace.Version.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Version} returns this
 */
proto.marketplace.Version.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string images = 4;
 * @return {!Array<string>}
 */
proto.marketplace.Version.prototype.getImagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.marketplace.Version} returns this
 */
proto.marketplace.Version.prototype.setImagesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.marketplace.Version} returns this
 */
proto.marketplace.Version.prototype.addImages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.Version} returns this
 */
proto.marketplace.Version.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional VersionChangeType versionChangeType = 5;
 * @return {!proto.marketplace.Version.VersionChangeType}
 */
proto.marketplace.Version.prototype.getVersionchangetype = function() {
  return /** @type {!proto.marketplace.Version.VersionChangeType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.marketplace.Version.VersionChangeType} value
 * @return {!proto.marketplace.Version} returns this
 */
proto.marketplace.Version.prototype.setVersionchangetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional State state = 6;
 * @return {!proto.marketplace.Version.State}
 */
proto.marketplace.Version.prototype.getState = function() {
  return /** @type {!proto.marketplace.Version.State} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.marketplace.Version.State} value
 * @return {!proto.marketplace.Version} returns this
 */
proto.marketplace.Version.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool isHighlighted = 7;
 * @return {boolean}
 */
proto.marketplace.Version.prototype.getIshighlighted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marketplace.Version} returns this
 */
proto.marketplace.Version.prototype.setIshighlighted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional Level level = 8;
 * @return {!proto.marketplace.Version.Level}
 */
proto.marketplace.Version.prototype.getLevel = function() {
  return /** @type {!proto.marketplace.Version.Level} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.marketplace.Version.Level} value
 * @return {!proto.marketplace.Version} returns this
 */
proto.marketplace.Version.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional Location location = 9;
 * @return {?proto.marketplace.Location}
 */
proto.marketplace.Version.prototype.getLocation = function() {
  return /** @type{?proto.marketplace.Location} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.Location, 9));
};


/**
 * @param {?proto.marketplace.Location|undefined} value
 * @return {!proto.marketplace.Version} returns this
*/
proto.marketplace.Version.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.Version} returns this
 */
proto.marketplace.Version.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.Version.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int64 score = 10;
 * @return {number}
 */
proto.marketplace.Version.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.Version} returns this
 */
proto.marketplace.Version.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional double price = 11;
 * @return {number}
 */
proto.marketplace.Version.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.Version} returns this
 */
proto.marketplace.Version.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * map<string, IndexedParamValue> indexedParamValues = 12;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.marketplace.IndexedParamValue>}
 */
proto.marketplace.Version.prototype.getIndexedparamvaluesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.marketplace.IndexedParamValue>} */ (
      jspb.Message.getMapField(this, 12, opt_noLazyCreate,
      proto.marketplace.IndexedParamValue));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.marketplace.Version} returns this
 */
proto.marketplace.Version.prototype.clearIndexedparamvaluesMap = function() {
  this.getIndexedparamvaluesMap().clear();
  return this;};


/**
 * repeated AdParamValue adParamValues = 13;
 * @return {!Array<!proto.marketplace.AdParamValue>}
 */
proto.marketplace.Version.prototype.getAdparamvaluesList = function() {
  return /** @type{!Array<!proto.marketplace.AdParamValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.marketplace.AdParamValue, 13));
};


/**
 * @param {!Array<!proto.marketplace.AdParamValue>} value
 * @return {!proto.marketplace.Version} returns this
*/
proto.marketplace.Version.prototype.setAdparamvaluesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.marketplace.AdParamValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marketplace.AdParamValue}
 */
proto.marketplace.Version.prototype.addAdparamvalues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.marketplace.AdParamValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.Version} returns this
 */
proto.marketplace.Version.prototype.clearAdparamvaluesList = function() {
  return this.setAdparamvaluesList([]);
};


/**
 * optional google.protobuf.Timestamp timestampSortScore = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marketplace.Version.prototype.getTimestampsortscore = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marketplace.Version} returns this
*/
proto.marketplace.Version.prototype.setTimestampsortscore = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.Version} returns this
 */
proto.marketplace.Version.prototype.clearTimestampsortscore = function() {
  return this.setTimestampsortscore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.Version.prototype.hasTimestampsortscore = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string userPhoneNumber = 15;
 * @return {string}
 */
proto.marketplace.Version.prototype.getUserphonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Version} returns this
 */
proto.marketplace.Version.prototype.setUserphonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string shopAvatar = 16;
 * @return {string}
 */
proto.marketplace.Version.prototype.getShopavatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Version} returns this
 */
proto.marketplace.Version.prototype.setShopavatar = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional ProductType productType = 17;
 * @return {!proto.marketplace.Version.ProductType}
 */
proto.marketplace.Version.prototype.getProducttype = function() {
  return /** @type {!proto.marketplace.Version.ProductType} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.marketplace.Version.ProductType} value
 * @return {!proto.marketplace.Version} returns this
 */
proto.marketplace.Version.prototype.setProducttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketplace.VersionFilterResponse.repeatedFields_ = [4,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.VersionFilterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.VersionFilterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.VersionFilterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.VersionFilterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imagesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    versionchangetype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    state: jspb.Message.getFieldWithDefault(msg, 6, 0),
    ishighlighted: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    level: jspb.Message.getFieldWithDefault(msg, 8, 0),
    location: (f = msg.getLocation()) && proto.marketplace.Location.toObject(includeInstance, f),
    score: jspb.Message.getFieldWithDefault(msg, 10, 0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    adparamvaluesList: jspb.Message.toObjectList(msg.getAdparamvaluesList(),
    proto.marketplace.AdParamValue.toObject, includeInstance),
    producttype: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.VersionFilterResponse}
 */
proto.marketplace.VersionFilterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.VersionFilterResponse;
  return proto.marketplace.VersionFilterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.VersionFilterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.VersionFilterResponse}
 */
proto.marketplace.VersionFilterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addImages(value);
      break;
    case 5:
      var value = /** @type {!proto.marketplace.VersionFilterResponse.VersionChangeType} */ (reader.readEnum());
      msg.setVersionchangetype(value);
      break;
    case 6:
      var value = /** @type {!proto.marketplace.VersionFilterResponse.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIshighlighted(value);
      break;
    case 8:
      var value = /** @type {!proto.marketplace.VersionFilterResponse.Level} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    case 9:
      var value = new proto.marketplace.Location;
      reader.readMessage(value,proto.marketplace.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScore(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 12:
      var value = new proto.marketplace.AdParamValue;
      reader.readMessage(value,proto.marketplace.AdParamValue.deserializeBinaryFromReader);
      msg.addAdparamvalues(value);
      break;
    case 13:
      var value = /** @type {!proto.marketplace.VersionFilterResponse.ProductType} */ (reader.readEnum());
      msg.setProducttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.VersionFilterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.VersionFilterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.VersionFilterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.VersionFilterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getVersionchangetype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getIshighlighted();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.marketplace.Location.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getAdparamvaluesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.marketplace.AdParamValue.serializeBinaryToWriter
    );
  }
  f = message.getProducttype();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.marketplace.VersionFilterResponse.VersionChangeType = {
  CREATE: 0,
  SELL: 1,
  BOOST: 2,
  STATE_UPDATE: 3,
  DATA_UPDATE: 4,
  ACTIVATE_NORMAL: 5,
  HIGHLIGHT: 6,
  EXPIRE: 7
};

/**
 * @enum {number}
 */
proto.marketplace.VersionFilterResponse.State = {
  PENDING_MODERATION: 0,
  REJECTED: 1,
  ACTIVE: 2,
  EXPIRED: 3,
  DELETED: 4,
  SOLD: 5,
  MANUAL_PENDING_MODERATION: 6
};

/**
 * @enum {number}
 */
proto.marketplace.VersionFilterResponse.Level = {
  STANDARD: 0,
  FEATURED: 1
};

/**
 * @enum {number}
 */
proto.marketplace.VersionFilterResponse.ProductType = {
  DEFAULT: 0,
  NEW: 1
};

/**
 * optional google.protobuf.Timestamp timestamp = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marketplace.VersionFilterResponse.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marketplace.VersionFilterResponse} returns this
*/
proto.marketplace.VersionFilterResponse.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.VersionFilterResponse} returns this
 */
proto.marketplace.VersionFilterResponse.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.VersionFilterResponse.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.marketplace.VersionFilterResponse.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.VersionFilterResponse} returns this
 */
proto.marketplace.VersionFilterResponse.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.marketplace.VersionFilterResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.VersionFilterResponse} returns this
 */
proto.marketplace.VersionFilterResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string images = 4;
 * @return {!Array<string>}
 */
proto.marketplace.VersionFilterResponse.prototype.getImagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.marketplace.VersionFilterResponse} returns this
 */
proto.marketplace.VersionFilterResponse.prototype.setImagesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.marketplace.VersionFilterResponse} returns this
 */
proto.marketplace.VersionFilterResponse.prototype.addImages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.VersionFilterResponse} returns this
 */
proto.marketplace.VersionFilterResponse.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional VersionChangeType versionChangeType = 5;
 * @return {!proto.marketplace.VersionFilterResponse.VersionChangeType}
 */
proto.marketplace.VersionFilterResponse.prototype.getVersionchangetype = function() {
  return /** @type {!proto.marketplace.VersionFilterResponse.VersionChangeType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.marketplace.VersionFilterResponse.VersionChangeType} value
 * @return {!proto.marketplace.VersionFilterResponse} returns this
 */
proto.marketplace.VersionFilterResponse.prototype.setVersionchangetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional State state = 6;
 * @return {!proto.marketplace.VersionFilterResponse.State}
 */
proto.marketplace.VersionFilterResponse.prototype.getState = function() {
  return /** @type {!proto.marketplace.VersionFilterResponse.State} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.marketplace.VersionFilterResponse.State} value
 * @return {!proto.marketplace.VersionFilterResponse} returns this
 */
proto.marketplace.VersionFilterResponse.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool isHighlighted = 7;
 * @return {boolean}
 */
proto.marketplace.VersionFilterResponse.prototype.getIshighlighted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marketplace.VersionFilterResponse} returns this
 */
proto.marketplace.VersionFilterResponse.prototype.setIshighlighted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional Level level = 8;
 * @return {!proto.marketplace.VersionFilterResponse.Level}
 */
proto.marketplace.VersionFilterResponse.prototype.getLevel = function() {
  return /** @type {!proto.marketplace.VersionFilterResponse.Level} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.marketplace.VersionFilterResponse.Level} value
 * @return {!proto.marketplace.VersionFilterResponse} returns this
 */
proto.marketplace.VersionFilterResponse.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional Location location = 9;
 * @return {?proto.marketplace.Location}
 */
proto.marketplace.VersionFilterResponse.prototype.getLocation = function() {
  return /** @type{?proto.marketplace.Location} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.Location, 9));
};


/**
 * @param {?proto.marketplace.Location|undefined} value
 * @return {!proto.marketplace.VersionFilterResponse} returns this
*/
proto.marketplace.VersionFilterResponse.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.VersionFilterResponse} returns this
 */
proto.marketplace.VersionFilterResponse.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.VersionFilterResponse.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int64 score = 10;
 * @return {number}
 */
proto.marketplace.VersionFilterResponse.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.VersionFilterResponse} returns this
 */
proto.marketplace.VersionFilterResponse.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional double price = 11;
 * @return {number}
 */
proto.marketplace.VersionFilterResponse.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.VersionFilterResponse} returns this
 */
proto.marketplace.VersionFilterResponse.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * repeated AdParamValue adParamValues = 12;
 * @return {!Array<!proto.marketplace.AdParamValue>}
 */
proto.marketplace.VersionFilterResponse.prototype.getAdparamvaluesList = function() {
  return /** @type{!Array<!proto.marketplace.AdParamValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.marketplace.AdParamValue, 12));
};


/**
 * @param {!Array<!proto.marketplace.AdParamValue>} value
 * @return {!proto.marketplace.VersionFilterResponse} returns this
*/
proto.marketplace.VersionFilterResponse.prototype.setAdparamvaluesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.marketplace.AdParamValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marketplace.AdParamValue}
 */
proto.marketplace.VersionFilterResponse.prototype.addAdparamvalues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.marketplace.AdParamValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.VersionFilterResponse} returns this
 */
proto.marketplace.VersionFilterResponse.prototype.clearAdparamvaluesList = function() {
  return this.setAdparamvaluesList([]);
};


/**
 * optional ProductType productType = 13;
 * @return {!proto.marketplace.VersionFilterResponse.ProductType}
 */
proto.marketplace.VersionFilterResponse.prototype.getProducttype = function() {
  return /** @type {!proto.marketplace.VersionFilterResponse.ProductType} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.marketplace.VersionFilterResponse.ProductType} value
 * @return {!proto.marketplace.VersionFilterResponse} returns this
 */
proto.marketplace.VersionFilterResponse.prototype.setProducttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.marketplace.IndexedParamValue.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.marketplace.IndexedParamValue.ValueCase = {
  VALUE_NOT_SET: 0,
  STRVALUE: 1,
  NUMBERVALUE: 2
};

/**
 * @return {proto.marketplace.IndexedParamValue.ValueCase}
 */
proto.marketplace.IndexedParamValue.prototype.getValueCase = function() {
  return /** @type {proto.marketplace.IndexedParamValue.ValueCase} */(jspb.Message.computeOneofCase(this, proto.marketplace.IndexedParamValue.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.IndexedParamValue.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.IndexedParamValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.IndexedParamValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.IndexedParamValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    strvalue: jspb.Message.getFieldWithDefault(msg, 1, ""),
    numbervalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.IndexedParamValue}
 */
proto.marketplace.IndexedParamValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.IndexedParamValue;
  return proto.marketplace.IndexedParamValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.IndexedParamValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.IndexedParamValue}
 */
proto.marketplace.IndexedParamValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStrvalue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNumbervalue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.IndexedParamValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.IndexedParamValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.IndexedParamValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.IndexedParamValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string strValue = 1;
 * @return {string}
 */
proto.marketplace.IndexedParamValue.prototype.getStrvalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.IndexedParamValue} returns this
 */
proto.marketplace.IndexedParamValue.prototype.setStrvalue = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.marketplace.IndexedParamValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.marketplace.IndexedParamValue} returns this
 */
proto.marketplace.IndexedParamValue.prototype.clearStrvalue = function() {
  return jspb.Message.setOneofField(this, 1, proto.marketplace.IndexedParamValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.IndexedParamValue.prototype.hasStrvalue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double numberValue = 2;
 * @return {number}
 */
proto.marketplace.IndexedParamValue.prototype.getNumbervalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.IndexedParamValue} returns this
 */
proto.marketplace.IndexedParamValue.prototype.setNumbervalue = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.marketplace.IndexedParamValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.marketplace.IndexedParamValue} returns this
 */
proto.marketplace.IndexedParamValue.prototype.clearNumbervalue = function() {
  return jspb.Message.setOneofField(this, 2, proto.marketplace.IndexedParamValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.IndexedParamValue.prototype.hasNumbervalue = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.AdParamValue.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.AdParamValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.AdParamValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdParamValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    param: (f = msg.getParam()) && proto.marketplace.AdParam.toObject(includeInstance, f),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.AdParamValue}
 */
proto.marketplace.AdParamValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.AdParamValue;
  return proto.marketplace.AdParamValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.AdParamValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.AdParamValue}
 */
proto.marketplace.AdParamValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.marketplace.AdParam;
      reader.readMessage(value,proto.marketplace.AdParam.deserializeBinaryFromReader);
      msg.setParam(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.AdParamValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.AdParamValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.AdParamValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdParamValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParam();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.marketplace.AdParam.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional AdParam param = 1;
 * @return {?proto.marketplace.AdParam}
 */
proto.marketplace.AdParamValue.prototype.getParam = function() {
  return /** @type{?proto.marketplace.AdParam} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.AdParam, 1));
};


/**
 * @param {?proto.marketplace.AdParam|undefined} value
 * @return {!proto.marketplace.AdParamValue} returns this
*/
proto.marketplace.AdParamValue.prototype.setParam = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.AdParamValue} returns this
 */
proto.marketplace.AdParamValue.prototype.clearParam = function() {
  return this.setParam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.AdParamValue.prototype.hasParam = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.marketplace.AdParamValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdParamValue} returns this
 */
proto.marketplace.AdParamValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.Geolocation.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.Geolocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.Geolocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Geolocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.Geolocation}
 */
proto.marketplace.Geolocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.Geolocation;
  return proto.marketplace.Geolocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.Geolocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.Geolocation}
 */
proto.marketplace.Geolocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.Geolocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.Geolocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.Geolocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Geolocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double latitude = 1;
 * @return {number}
 */
proto.marketplace.Geolocation.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.Geolocation} returns this
 */
proto.marketplace.Geolocation.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double longitude = 2;
 * @return {number}
 */
proto.marketplace.Geolocation.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.Geolocation} returns this
 */
proto.marketplace.Geolocation.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    governorate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    delegation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    coordinates: (f = msg.getCoordinates()) && proto.marketplace.Geolocation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.Location}
 */
proto.marketplace.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.Location;
  return proto.marketplace.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.Location}
 */
proto.marketplace.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGovernorate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelegation(value);
      break;
    case 3:
      var value = new proto.marketplace.Geolocation;
      reader.readMessage(value,proto.marketplace.Geolocation.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGovernorate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDelegation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.marketplace.Geolocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string governorate = 1;
 * @return {string}
 */
proto.marketplace.Location.prototype.getGovernorate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Location} returns this
 */
proto.marketplace.Location.prototype.setGovernorate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string delegation = 2;
 * @return {string}
 */
proto.marketplace.Location.prototype.getDelegation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Location} returns this
 */
proto.marketplace.Location.prototype.setDelegation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Geolocation coordinates = 3;
 * @return {?proto.marketplace.Geolocation}
 */
proto.marketplace.Location.prototype.getCoordinates = function() {
  return /** @type{?proto.marketplace.Geolocation} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.Geolocation, 3));
};


/**
 * @param {?proto.marketplace.Geolocation|undefined} value
 * @return {!proto.marketplace.Location} returns this
*/
proto.marketplace.Location.prototype.setCoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.Location} returns this
 */
proto.marketplace.Location.prototype.clearCoordinates = function() {
  return this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.Location.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.Governorate.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.Governorate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.Governorate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Governorate.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.Governorate}
 */
proto.marketplace.Governorate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.Governorate;
  return proto.marketplace.Governorate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.Governorate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.Governorate}
 */
proto.marketplace.Governorate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.Governorate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.Governorate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.Governorate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Governorate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.marketplace.Governorate.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Governorate} returns this
 */
proto.marketplace.Governorate.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.Delegation.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.Delegation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.Delegation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Delegation.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    governoratexid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.Delegation}
 */
proto.marketplace.Delegation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.Delegation;
  return proto.marketplace.Delegation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.Delegation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.Delegation}
 */
proto.marketplace.Delegation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGovernoratexid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.Delegation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.Delegation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.Delegation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Delegation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGovernoratexid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.marketplace.Delegation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Delegation} returns this
 */
proto.marketplace.Delegation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string governorateXID = 2;
 * @return {string}
 */
proto.marketplace.Delegation.prototype.getGovernoratexid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Delegation} returns this
 */
proto.marketplace.Delegation.prototype.setGovernoratexid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.AdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.AdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.AdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ad: (f = msg.getAd()) && proto.marketplace.Ad.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.AdResponse}
 */
proto.marketplace.AdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.AdResponse;
  return proto.marketplace.AdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.AdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.AdResponse}
 */
proto.marketplace.AdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.marketplace.Ad;
      reader.readMessage(value,proto.marketplace.Ad.deserializeBinaryFromReader);
      msg.setAd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.AdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.AdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.AdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.marketplace.Ad.serializeBinaryToWriter
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.AdResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdResponse} returns this
 */
proto.marketplace.AdResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Ad ad = 2;
 * @return {?proto.marketplace.Ad}
 */
proto.marketplace.AdResponse.prototype.getAd = function() {
  return /** @type{?proto.marketplace.Ad} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.Ad, 2));
};


/**
 * @param {?proto.marketplace.Ad|undefined} value
 * @return {!proto.marketplace.AdResponse} returns this
*/
proto.marketplace.AdResponse.prototype.setAd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.AdResponse} returns this
 */
proto.marketplace.AdResponse.prototype.clearAd = function() {
  return this.setAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.AdResponse.prototype.hasAd = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.AdCurrentVersionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.AdCurrentVersionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.AdCurrentVersionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdCurrentVersionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ad: (f = msg.getAd()) && proto.marketplace.AdCurrentVersion.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.AdCurrentVersionResponse}
 */
proto.marketplace.AdCurrentVersionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.AdCurrentVersionResponse;
  return proto.marketplace.AdCurrentVersionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.AdCurrentVersionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.AdCurrentVersionResponse}
 */
proto.marketplace.AdCurrentVersionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.marketplace.AdCurrentVersion;
      reader.readMessage(value,proto.marketplace.AdCurrentVersion.deserializeBinaryFromReader);
      msg.setAd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.AdCurrentVersionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.AdCurrentVersionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.AdCurrentVersionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdCurrentVersionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.marketplace.AdCurrentVersion.serializeBinaryToWriter
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.AdCurrentVersionResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdCurrentVersionResponse} returns this
 */
proto.marketplace.AdCurrentVersionResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AdCurrentVersion ad = 2;
 * @return {?proto.marketplace.AdCurrentVersion}
 */
proto.marketplace.AdCurrentVersionResponse.prototype.getAd = function() {
  return /** @type{?proto.marketplace.AdCurrentVersion} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.AdCurrentVersion, 2));
};


/**
 * @param {?proto.marketplace.AdCurrentVersion|undefined} value
 * @return {!proto.marketplace.AdCurrentVersionResponse} returns this
*/
proto.marketplace.AdCurrentVersionResponse.prototype.setAd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.AdCurrentVersionResponse} returns this
 */
proto.marketplace.AdCurrentVersionResponse.prototype.clearAd = function() {
  return this.setAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.AdCurrentVersionResponse.prototype.hasAd = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.TotalAdsCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.TotalAdsCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.TotalAdsCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.TotalAdsCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.TotalAdsCountResponse}
 */
proto.marketplace.TotalAdsCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.TotalAdsCountResponse;
  return proto.marketplace.TotalAdsCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.TotalAdsCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.TotalAdsCountResponse}
 */
proto.marketplace.TotalAdsCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.TotalAdsCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.TotalAdsCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.TotalAdsCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.TotalAdsCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 count = 1;
 * @return {number}
 */
proto.marketplace.TotalAdsCountResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.TotalAdsCountResponse} returns this
 */
proto.marketplace.TotalAdsCountResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.AdCurrentVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.AdCurrentVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.AdCurrentVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdCurrentVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subcategoryid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currentversion: (f = msg.getCurrentversion()) && proto.marketplace.Version.toObject(includeInstance, f),
    creationtimestamp: (f = msg.getCreationtimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    adid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sync: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.AdCurrentVersion}
 */
proto.marketplace.AdCurrentVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.AdCurrentVersion;
  return proto.marketplace.AdCurrentVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.AdCurrentVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.AdCurrentVersion}
 */
proto.marketplace.AdCurrentVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubcategoryid(value);
      break;
    case 3:
      var value = new proto.marketplace.Version;
      reader.readMessage(value,proto.marketplace.Version.deserializeBinaryFromReader);
      msg.setCurrentversion(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationtimestamp(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdid(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSync(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.AdCurrentVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.AdCurrentVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.AdCurrentVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdCurrentVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubcategoryid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrentversion();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.marketplace.Version.serializeBinaryToWriter
    );
  }
  f = message.getCreationtimestamp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAdid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSync();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.marketplace.AdCurrentVersion.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdCurrentVersion} returns this
 */
proto.marketplace.AdCurrentVersion.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subCategoryId = 2;
 * @return {string}
 */
proto.marketplace.AdCurrentVersion.prototype.getSubcategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdCurrentVersion} returns this
 */
proto.marketplace.AdCurrentVersion.prototype.setSubcategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Version currentVersion = 3;
 * @return {?proto.marketplace.Version}
 */
proto.marketplace.AdCurrentVersion.prototype.getCurrentversion = function() {
  return /** @type{?proto.marketplace.Version} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.Version, 3));
};


/**
 * @param {?proto.marketplace.Version|undefined} value
 * @return {!proto.marketplace.AdCurrentVersion} returns this
*/
proto.marketplace.AdCurrentVersion.prototype.setCurrentversion = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.AdCurrentVersion} returns this
 */
proto.marketplace.AdCurrentVersion.prototype.clearCurrentversion = function() {
  return this.setCurrentversion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.AdCurrentVersion.prototype.hasCurrentversion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp creationTimestamp = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marketplace.AdCurrentVersion.prototype.getCreationtimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marketplace.AdCurrentVersion} returns this
*/
proto.marketplace.AdCurrentVersion.prototype.setCreationtimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.AdCurrentVersion} returns this
 */
proto.marketplace.AdCurrentVersion.prototype.clearCreationtimestamp = function() {
  return this.setCreationtimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.AdCurrentVersion.prototype.hasCreationtimestamp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string ADID = 5;
 * @return {string}
 */
proto.marketplace.AdCurrentVersion.prototype.getAdid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdCurrentVersion} returns this
 */
proto.marketplace.AdCurrentVersion.prototype.setAdid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool sync = 6;
 * @return {boolean}
 */
proto.marketplace.AdCurrentVersion.prototype.getSync = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marketplace.AdCurrentVersion} returns this
 */
proto.marketplace.AdCurrentVersion.prototype.setSync = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.AdCurrentVersionAdParamsFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.AdCurrentVersionAdParamsFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.AdCurrentVersionAdParamsFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdCurrentVersionAdParamsFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subcategoryid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currentversion: (f = msg.getCurrentversion()) && proto.marketplace.VersionFilterResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.AdCurrentVersionAdParamsFilter}
 */
proto.marketplace.AdCurrentVersionAdParamsFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.AdCurrentVersionAdParamsFilter;
  return proto.marketplace.AdCurrentVersionAdParamsFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.AdCurrentVersionAdParamsFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.AdCurrentVersionAdParamsFilter}
 */
proto.marketplace.AdCurrentVersionAdParamsFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubcategoryid(value);
      break;
    case 3:
      var value = new proto.marketplace.VersionFilterResponse;
      reader.readMessage(value,proto.marketplace.VersionFilterResponse.deserializeBinaryFromReader);
      msg.setCurrentversion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.AdCurrentVersionAdParamsFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.AdCurrentVersionAdParamsFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.AdCurrentVersionAdParamsFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdCurrentVersionAdParamsFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubcategoryid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrentversion();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.marketplace.VersionFilterResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.marketplace.AdCurrentVersionAdParamsFilter.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdCurrentVersionAdParamsFilter} returns this
 */
proto.marketplace.AdCurrentVersionAdParamsFilter.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subCategoryId = 2;
 * @return {string}
 */
proto.marketplace.AdCurrentVersionAdParamsFilter.prototype.getSubcategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdCurrentVersionAdParamsFilter} returns this
 */
proto.marketplace.AdCurrentVersionAdParamsFilter.prototype.setSubcategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional VersionFilterResponse currentVersion = 3;
 * @return {?proto.marketplace.VersionFilterResponse}
 */
proto.marketplace.AdCurrentVersionAdParamsFilter.prototype.getCurrentversion = function() {
  return /** @type{?proto.marketplace.VersionFilterResponse} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.VersionFilterResponse, 3));
};


/**
 * @param {?proto.marketplace.VersionFilterResponse|undefined} value
 * @return {!proto.marketplace.AdCurrentVersionAdParamsFilter} returns this
*/
proto.marketplace.AdCurrentVersionAdParamsFilter.prototype.setCurrentversion = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.AdCurrentVersionAdParamsFilter} returns this
 */
proto.marketplace.AdCurrentVersionAdParamsFilter.prototype.clearCurrentversion = function() {
  return this.setCurrentversion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.AdCurrentVersionAdParamsFilter.prototype.hasCurrentversion = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketplace.AdCurrentVersionFilter.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.AdCurrentVersionFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.AdCurrentVersionFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.AdCurrentVersionFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdCurrentVersionFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 4, 0),
    paramsstringMap: (f = msg.getParamsstringMap()) ? f.toObject(includeInstance, undefined) : [],
    subcategoryid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    location: (f = msg.getLocation()) && proto.marketplace.Location.toObject(includeInstance, f),
    imagesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    ishighlighted: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    state: jspb.Message.getFieldWithDefault(msg, 10, 0),
    level: jspb.Message.getFieldWithDefault(msg, 11, 0),
    score: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.AdCurrentVersionFilter}
 */
proto.marketplace.AdCurrentVersionFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.AdCurrentVersionFilter;
  return proto.marketplace.AdCurrentVersionFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.AdCurrentVersionFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.AdCurrentVersionFilter}
 */
proto.marketplace.AdCurrentVersionFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 5:
      var value = msg.getParamsstringMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubcategoryid(value);
      break;
    case 7:
      var value = new proto.marketplace.Location;
      reader.readMessage(value,proto.marketplace.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addImages(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIshighlighted(value);
      break;
    case 10:
      var value = /** @type {!proto.marketplace.AdCurrentVersionFilter.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 11:
      var value = /** @type {!proto.marketplace.AdCurrentVersionFilter.Level} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.AdCurrentVersionFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.AdCurrentVersionFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.AdCurrentVersionFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdCurrentVersionFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getParamsstringMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getSubcategoryid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.marketplace.Location.serializeBinaryToWriter
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getIshighlighted();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.marketplace.AdCurrentVersionFilter.State = {
  PENDING_MODERATION: 0,
  REJECTED: 1,
  ACTIVE: 2,
  EXPIRED: 3,
  DELETED: 4,
  SOLD: 5,
  MANUAL_PENDING_MODERATION: 6
};

/**
 * @enum {number}
 */
proto.marketplace.AdCurrentVersionFilter.Level = {
  STANDARD: 0,
  FEATURED: 1
};

/**
 * optional string title = 1;
 * @return {string}
 */
proto.marketplace.AdCurrentVersionFilter.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdCurrentVersionFilter} returns this
 */
proto.marketplace.AdCurrentVersionFilter.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float price = 2;
 * @return {number}
 */
proto.marketplace.AdCurrentVersionFilter.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.AdCurrentVersionFilter} returns this
 */
proto.marketplace.AdCurrentVersionFilter.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.marketplace.AdCurrentVersionFilter.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdCurrentVersionFilter} returns this
 */
proto.marketplace.AdCurrentVersionFilter.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 timestamp = 4;
 * @return {number}
 */
proto.marketplace.AdCurrentVersionFilter.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.AdCurrentVersionFilter} returns this
 */
proto.marketplace.AdCurrentVersionFilter.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * map<string, string> paramsString = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.marketplace.AdCurrentVersionFilter.prototype.getParamsstringMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.marketplace.AdCurrentVersionFilter} returns this
 */
proto.marketplace.AdCurrentVersionFilter.prototype.clearParamsstringMap = function() {
  this.getParamsstringMap().clear();
  return this;};


/**
 * optional string subCategoryId = 6;
 * @return {string}
 */
proto.marketplace.AdCurrentVersionFilter.prototype.getSubcategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdCurrentVersionFilter} returns this
 */
proto.marketplace.AdCurrentVersionFilter.prototype.setSubcategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Location location = 7;
 * @return {?proto.marketplace.Location}
 */
proto.marketplace.AdCurrentVersionFilter.prototype.getLocation = function() {
  return /** @type{?proto.marketplace.Location} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.Location, 7));
};


/**
 * @param {?proto.marketplace.Location|undefined} value
 * @return {!proto.marketplace.AdCurrentVersionFilter} returns this
*/
proto.marketplace.AdCurrentVersionFilter.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.AdCurrentVersionFilter} returns this
 */
proto.marketplace.AdCurrentVersionFilter.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.AdCurrentVersionFilter.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated string images = 8;
 * @return {!Array<string>}
 */
proto.marketplace.AdCurrentVersionFilter.prototype.getImagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.marketplace.AdCurrentVersionFilter} returns this
 */
proto.marketplace.AdCurrentVersionFilter.prototype.setImagesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.marketplace.AdCurrentVersionFilter} returns this
 */
proto.marketplace.AdCurrentVersionFilter.prototype.addImages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.AdCurrentVersionFilter} returns this
 */
proto.marketplace.AdCurrentVersionFilter.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional bool isHighlighted = 9;
 * @return {boolean}
 */
proto.marketplace.AdCurrentVersionFilter.prototype.getIshighlighted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marketplace.AdCurrentVersionFilter} returns this
 */
proto.marketplace.AdCurrentVersionFilter.prototype.setIshighlighted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional State state = 10;
 * @return {!proto.marketplace.AdCurrentVersionFilter.State}
 */
proto.marketplace.AdCurrentVersionFilter.prototype.getState = function() {
  return /** @type {!proto.marketplace.AdCurrentVersionFilter.State} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.marketplace.AdCurrentVersionFilter.State} value
 * @return {!proto.marketplace.AdCurrentVersionFilter} returns this
 */
proto.marketplace.AdCurrentVersionFilter.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional Level level = 11;
 * @return {!proto.marketplace.AdCurrentVersionFilter.Level}
 */
proto.marketplace.AdCurrentVersionFilter.prototype.getLevel = function() {
  return /** @type {!proto.marketplace.AdCurrentVersionFilter.Level} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.marketplace.AdCurrentVersionFilter.Level} value
 * @return {!proto.marketplace.AdCurrentVersionFilter} returns this
 */
proto.marketplace.AdCurrentVersionFilter.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional int64 score = 12;
 * @return {number}
 */
proto.marketplace.AdCurrentVersionFilter.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.AdCurrentVersionFilter} returns this
 */
proto.marketplace.AdCurrentVersionFilter.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.AdCurrentVersionFilterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.AdCurrentVersionFilterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.AdCurrentVersionFilterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdCurrentVersionFilterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ad: (f = msg.getAd()) && proto.marketplace.AdCurrentVersionFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.AdCurrentVersionFilterResponse}
 */
proto.marketplace.AdCurrentVersionFilterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.AdCurrentVersionFilterResponse;
  return proto.marketplace.AdCurrentVersionFilterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.AdCurrentVersionFilterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.AdCurrentVersionFilterResponse}
 */
proto.marketplace.AdCurrentVersionFilterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.marketplace.AdCurrentVersionFilter;
      reader.readMessage(value,proto.marketplace.AdCurrentVersionFilter.deserializeBinaryFromReader);
      msg.setAd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.AdCurrentVersionFilterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.AdCurrentVersionFilterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.AdCurrentVersionFilterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdCurrentVersionFilterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.marketplace.AdCurrentVersionFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.AdCurrentVersionFilterResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdCurrentVersionFilterResponse} returns this
 */
proto.marketplace.AdCurrentVersionFilterResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AdCurrentVersionFilter ad = 2;
 * @return {?proto.marketplace.AdCurrentVersionFilter}
 */
proto.marketplace.AdCurrentVersionFilterResponse.prototype.getAd = function() {
  return /** @type{?proto.marketplace.AdCurrentVersionFilter} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.AdCurrentVersionFilter, 2));
};


/**
 * @param {?proto.marketplace.AdCurrentVersionFilter|undefined} value
 * @return {!proto.marketplace.AdCurrentVersionFilterResponse} returns this
*/
proto.marketplace.AdCurrentVersionFilterResponse.prototype.setAd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.AdCurrentVersionFilterResponse} returns this
 */
proto.marketplace.AdCurrentVersionFilterResponse.prototype.clearAd = function() {
  return this.setAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.AdCurrentVersionFilterResponse.prototype.hasAd = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.AdCurrentVersionAdParamsFilterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.AdCurrentVersionAdParamsFilterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.AdCurrentVersionAdParamsFilterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdCurrentVersionAdParamsFilterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ad: (f = msg.getAd()) && proto.marketplace.AdCurrentVersionAdParamsFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.AdCurrentVersionAdParamsFilterResponse}
 */
proto.marketplace.AdCurrentVersionAdParamsFilterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.AdCurrentVersionAdParamsFilterResponse;
  return proto.marketplace.AdCurrentVersionAdParamsFilterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.AdCurrentVersionAdParamsFilterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.AdCurrentVersionAdParamsFilterResponse}
 */
proto.marketplace.AdCurrentVersionAdParamsFilterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.marketplace.AdCurrentVersionAdParamsFilter;
      reader.readMessage(value,proto.marketplace.AdCurrentVersionAdParamsFilter.deserializeBinaryFromReader);
      msg.setAd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.AdCurrentVersionAdParamsFilterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.AdCurrentVersionAdParamsFilterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.AdCurrentVersionAdParamsFilterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdCurrentVersionAdParamsFilterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.marketplace.AdCurrentVersionAdParamsFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.AdCurrentVersionAdParamsFilterResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdCurrentVersionAdParamsFilterResponse} returns this
 */
proto.marketplace.AdCurrentVersionAdParamsFilterResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AdCurrentVersionAdParamsFilter ad = 2;
 * @return {?proto.marketplace.AdCurrentVersionAdParamsFilter}
 */
proto.marketplace.AdCurrentVersionAdParamsFilterResponse.prototype.getAd = function() {
  return /** @type{?proto.marketplace.AdCurrentVersionAdParamsFilter} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.AdCurrentVersionAdParamsFilter, 2));
};


/**
 * @param {?proto.marketplace.AdCurrentVersionAdParamsFilter|undefined} value
 * @return {!proto.marketplace.AdCurrentVersionAdParamsFilterResponse} returns this
*/
proto.marketplace.AdCurrentVersionAdParamsFilterResponse.prototype.setAd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.AdCurrentVersionAdParamsFilterResponse} returns this
 */
proto.marketplace.AdCurrentVersionAdParamsFilterResponse.prototype.clearAd = function() {
  return this.setAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.AdCurrentVersionAdParamsFilterResponse.prototype.hasAd = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.AdByIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.AdByIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.AdByIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdByIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.AdByIDRequest}
 */
proto.marketplace.AdByIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.AdByIDRequest;
  return proto.marketplace.AdByIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.AdByIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.AdByIDRequest}
 */
proto.marketplace.AdByIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.AdByIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.AdByIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.AdByIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdByIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.AdByIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdByIDRequest} returns this
 */
proto.marketplace.AdByIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.GoldAd.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.GoldAd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.GoldAd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GoldAd.toObject = function(includeInstance, msg) {
  var f, obj = {
    adid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creationtimestamp: (f = msg.getCreationtimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    link: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.GoldAd}
 */
proto.marketplace.GoldAd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.GoldAd;
  return proto.marketplace.GoldAd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.GoldAd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.GoldAd}
 */
proto.marketplace.GoldAd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationtimestamp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.GoldAd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.GoldAd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.GoldAd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GoldAd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreationtimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string adid = 1;
 * @return {string}
 */
proto.marketplace.GoldAd.prototype.getAdid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GoldAd} returns this
 */
proto.marketplace.GoldAd.prototype.setAdid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp creationTimestamp = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marketplace.GoldAd.prototype.getCreationtimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marketplace.GoldAd} returns this
*/
proto.marketplace.GoldAd.prototype.setCreationtimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.GoldAd} returns this
 */
proto.marketplace.GoldAd.prototype.clearCreationtimestamp = function() {
  return this.setCreationtimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.GoldAd.prototype.hasCreationtimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string link = 3;
 * @return {string}
 */
proto.marketplace.GoldAd.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GoldAd} returns this
 */
proto.marketplace.GoldAd.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.GoldAdId.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.GoldAdId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.GoldAdId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GoldAdId.toObject = function(includeInstance, msg) {
  var f, obj = {
    adid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    link: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.GoldAdId}
 */
proto.marketplace.GoldAdId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.GoldAdId;
  return proto.marketplace.GoldAdId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.GoldAdId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.GoldAdId}
 */
proto.marketplace.GoldAdId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.GoldAdId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.GoldAdId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.GoldAdId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GoldAdId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string adid = 1;
 * @return {string}
 */
proto.marketplace.GoldAdId.prototype.getAdid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GoldAdId} returns this
 */
proto.marketplace.GoldAdId.prototype.setAdid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string link = 2;
 * @return {string}
 */
proto.marketplace.GoldAdId.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GoldAdId} returns this
 */
proto.marketplace.GoldAdId.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.GoldAdIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.GoldAdIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.GoldAdIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GoldAdIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ad: (f = msg.getAd()) && proto.marketplace.AdResponse.toObject(includeInstance, f),
    link: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.GoldAdIdResponse}
 */
proto.marketplace.GoldAdIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.GoldAdIdResponse;
  return proto.marketplace.GoldAdIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.GoldAdIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.GoldAdIdResponse}
 */
proto.marketplace.GoldAdIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.marketplace.AdResponse;
      reader.readMessage(value,proto.marketplace.AdResponse.deserializeBinaryFromReader);
      msg.setAd(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.GoldAdIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.GoldAdIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.GoldAdIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GoldAdIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.marketplace.AdResponse.serializeBinaryToWriter
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.GoldAdIdResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GoldAdIdResponse} returns this
 */
proto.marketplace.GoldAdIdResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AdResponse ad = 2;
 * @return {?proto.marketplace.AdResponse}
 */
proto.marketplace.GoldAdIdResponse.prototype.getAd = function() {
  return /** @type{?proto.marketplace.AdResponse} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.AdResponse, 2));
};


/**
 * @param {?proto.marketplace.AdResponse|undefined} value
 * @return {!proto.marketplace.GoldAdIdResponse} returns this
*/
proto.marketplace.GoldAdIdResponse.prototype.setAd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.GoldAdIdResponse} returns this
 */
proto.marketplace.GoldAdIdResponse.prototype.clearAd = function() {
  return this.setAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.GoldAdIdResponse.prototype.hasAd = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string link = 3;
 * @return {string}
 */
proto.marketplace.GoldAdIdResponse.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GoldAdIdResponse} returns this
 */
proto.marketplace.GoldAdIdResponse.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.CreateGoldAdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.CreateGoldAdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.CreateGoldAdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.CreateGoldAdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.CreateGoldAdResponse}
 */
proto.marketplace.CreateGoldAdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.CreateGoldAdResponse;
  return proto.marketplace.CreateGoldAdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.CreateGoldAdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.CreateGoldAdResponse}
 */
proto.marketplace.CreateGoldAdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.CreateGoldAdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.CreateGoldAdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.CreateGoldAdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.CreateGoldAdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.CreateGoldAdResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.CreateGoldAdResponse} returns this
 */
proto.marketplace.CreateGoldAdResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.StateInAdByIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.StateInAdByIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.StateInAdByIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.StateInAdByIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newstate: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.StateInAdByIDRequest}
 */
proto.marketplace.StateInAdByIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.StateInAdByIDRequest;
  return proto.marketplace.StateInAdByIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.StateInAdByIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.StateInAdByIDRequest}
 */
proto.marketplace.StateInAdByIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.marketplace.Version.State} */ (reader.readEnum());
      msg.setNewstate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.StateInAdByIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.StateInAdByIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.StateInAdByIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.StateInAdByIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewstate();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.StateInAdByIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.StateInAdByIDRequest} returns this
 */
proto.marketplace.StateInAdByIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Version.State newState = 2;
 * @return {!proto.marketplace.Version.State}
 */
proto.marketplace.StateInAdByIDRequest.prototype.getNewstate = function() {
  return /** @type {!proto.marketplace.Version.State} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.marketplace.Version.State} value
 * @return {!proto.marketplace.StateInAdByIDRequest} returns this
 */
proto.marketplace.StateInAdByIDRequest.prototype.setNewstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.VersionInAdByIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.VersionInAdByIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.VersionInAdByIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.VersionInAdByIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newversion: (f = msg.getNewversion()) && proto.marketplace.Version.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.VersionInAdByIDRequest}
 */
proto.marketplace.VersionInAdByIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.VersionInAdByIDRequest;
  return proto.marketplace.VersionInAdByIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.VersionInAdByIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.VersionInAdByIDRequest}
 */
proto.marketplace.VersionInAdByIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.marketplace.Version;
      reader.readMessage(value,proto.marketplace.Version.deserializeBinaryFromReader);
      msg.setNewversion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.VersionInAdByIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.VersionInAdByIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.VersionInAdByIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.VersionInAdByIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewversion();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.marketplace.Version.serializeBinaryToWriter
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.VersionInAdByIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.VersionInAdByIDRequest} returns this
 */
proto.marketplace.VersionInAdByIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Version newVersion = 2;
 * @return {?proto.marketplace.Version}
 */
proto.marketplace.VersionInAdByIDRequest.prototype.getNewversion = function() {
  return /** @type{?proto.marketplace.Version} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.Version, 2));
};


/**
 * @param {?proto.marketplace.Version|undefined} value
 * @return {!proto.marketplace.VersionInAdByIDRequest} returns this
*/
proto.marketplace.VersionInAdByIDRequest.prototype.setNewversion = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.VersionInAdByIDRequest} returns this
 */
proto.marketplace.VersionInAdByIDRequest.prototype.clearNewversion = function() {
  return this.setNewversion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.VersionInAdByIDRequest.prototype.hasNewversion = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketplace.NewAdRequest.repeatedFields_ = [5,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.NewAdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.NewAdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.NewAdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NewAdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subcategoryid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imagesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    location: (f = msg.getLocation()) && proto.marketplace.Location.toObject(includeInstance, f),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    adparamvaluesList: jspb.Message.toObjectList(msg.getAdparamvaluesList(),
    proto.marketplace.AdParamValue.toObject, includeInstance),
    userphonenumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
    shopavatar: jspb.Message.getFieldWithDefault(msg, 10, ""),
    producttype: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.NewAdRequest}
 */
proto.marketplace.NewAdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.NewAdRequest;
  return proto.marketplace.NewAdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.NewAdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.NewAdRequest}
 */
proto.marketplace.NewAdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubcategoryid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addImages(value);
      break;
    case 6:
      var value = new proto.marketplace.Location;
      reader.readMessage(value,proto.marketplace.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 8:
      var value = new proto.marketplace.AdParamValue;
      reader.readMessage(value,proto.marketplace.AdParamValue.deserializeBinaryFromReader);
      msg.addAdparamvalues(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserphonenumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopavatar(value);
      break;
    case 11:
      var value = /** @type {!proto.marketplace.NewAdRequest.ProductType} */ (reader.readEnum());
      msg.setProducttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.NewAdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.NewAdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.NewAdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NewAdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubcategoryid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.marketplace.Location.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getAdparamvaluesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.marketplace.AdParamValue.serializeBinaryToWriter
    );
  }
  f = message.getUserphonenumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getShopavatar();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getProducttype();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.marketplace.NewAdRequest.ProductType = {
  DEFAULT: 0,
  NEW: 1
};

/**
 * optional string userID = 1;
 * @return {string}
 */
proto.marketplace.NewAdRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewAdRequest} returns this
 */
proto.marketplace.NewAdRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.marketplace.NewAdRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewAdRequest} returns this
 */
proto.marketplace.NewAdRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subCategoryId = 3;
 * @return {string}
 */
proto.marketplace.NewAdRequest.prototype.getSubcategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewAdRequest} returns this
 */
proto.marketplace.NewAdRequest.prototype.setSubcategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.marketplace.NewAdRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewAdRequest} returns this
 */
proto.marketplace.NewAdRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string images = 5;
 * @return {!Array<string>}
 */
proto.marketplace.NewAdRequest.prototype.getImagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.marketplace.NewAdRequest} returns this
 */
proto.marketplace.NewAdRequest.prototype.setImagesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.marketplace.NewAdRequest} returns this
 */
proto.marketplace.NewAdRequest.prototype.addImages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.NewAdRequest} returns this
 */
proto.marketplace.NewAdRequest.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional Location location = 6;
 * @return {?proto.marketplace.Location}
 */
proto.marketplace.NewAdRequest.prototype.getLocation = function() {
  return /** @type{?proto.marketplace.Location} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.Location, 6));
};


/**
 * @param {?proto.marketplace.Location|undefined} value
 * @return {!proto.marketplace.NewAdRequest} returns this
*/
proto.marketplace.NewAdRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.NewAdRequest} returns this
 */
proto.marketplace.NewAdRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.NewAdRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional double price = 7;
 * @return {number}
 */
proto.marketplace.NewAdRequest.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.NewAdRequest} returns this
 */
proto.marketplace.NewAdRequest.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * repeated AdParamValue adParamValues = 8;
 * @return {!Array<!proto.marketplace.AdParamValue>}
 */
proto.marketplace.NewAdRequest.prototype.getAdparamvaluesList = function() {
  return /** @type{!Array<!proto.marketplace.AdParamValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.marketplace.AdParamValue, 8));
};


/**
 * @param {!Array<!proto.marketplace.AdParamValue>} value
 * @return {!proto.marketplace.NewAdRequest} returns this
*/
proto.marketplace.NewAdRequest.prototype.setAdparamvaluesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.marketplace.AdParamValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marketplace.AdParamValue}
 */
proto.marketplace.NewAdRequest.prototype.addAdparamvalues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.marketplace.AdParamValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.NewAdRequest} returns this
 */
proto.marketplace.NewAdRequest.prototype.clearAdparamvaluesList = function() {
  return this.setAdparamvaluesList([]);
};


/**
 * optional string userPhoneNumber = 9;
 * @return {string}
 */
proto.marketplace.NewAdRequest.prototype.getUserphonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewAdRequest} returns this
 */
proto.marketplace.NewAdRequest.prototype.setUserphonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string shopAvatar = 10;
 * @return {string}
 */
proto.marketplace.NewAdRequest.prototype.getShopavatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewAdRequest} returns this
 */
proto.marketplace.NewAdRequest.prototype.setShopavatar = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional ProductType productType = 11;
 * @return {!proto.marketplace.NewAdRequest.ProductType}
 */
proto.marketplace.NewAdRequest.prototype.getProducttype = function() {
  return /** @type {!proto.marketplace.NewAdRequest.ProductType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.marketplace.NewAdRequest.ProductType} value
 * @return {!proto.marketplace.NewAdRequest} returns this
 */
proto.marketplace.NewAdRequest.prototype.setProducttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.LocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.LocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.LocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.LocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    location: (f = msg.getLocation()) && proto.marketplace.Location.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.LocationResponse}
 */
proto.marketplace.LocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.LocationResponse;
  return proto.marketplace.LocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.LocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.LocationResponse}
 */
proto.marketplace.LocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.marketplace.Location;
      reader.readMessage(value,proto.marketplace.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.LocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.LocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.LocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.LocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.marketplace.Location.serializeBinaryToWriter
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.LocationResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.LocationResponse} returns this
 */
proto.marketplace.LocationResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Location location = 2;
 * @return {?proto.marketplace.Location}
 */
proto.marketplace.LocationResponse.prototype.getLocation = function() {
  return /** @type{?proto.marketplace.Location} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.Location, 2));
};


/**
 * @param {?proto.marketplace.Location|undefined} value
 * @return {!proto.marketplace.LocationResponse} returns this
*/
proto.marketplace.LocationResponse.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.LocationResponse} returns this
 */
proto.marketplace.LocationResponse.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.LocationResponse.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.NewLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.NewLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.NewLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NewLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    governorate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    delegation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    coordinates: (f = msg.getCoordinates()) && proto.marketplace.Geolocation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.NewLocationRequest}
 */
proto.marketplace.NewLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.NewLocationRequest;
  return proto.marketplace.NewLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.NewLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.NewLocationRequest}
 */
proto.marketplace.NewLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGovernorate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelegation(value);
      break;
    case 3:
      var value = new proto.marketplace.Geolocation;
      reader.readMessage(value,proto.marketplace.Geolocation.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.NewLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.NewLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.NewLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NewLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGovernorate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDelegation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.marketplace.Geolocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string governorate = 1;
 * @return {string}
 */
proto.marketplace.NewLocationRequest.prototype.getGovernorate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewLocationRequest} returns this
 */
proto.marketplace.NewLocationRequest.prototype.setGovernorate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string delegation = 2;
 * @return {string}
 */
proto.marketplace.NewLocationRequest.prototype.getDelegation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewLocationRequest} returns this
 */
proto.marketplace.NewLocationRequest.prototype.setDelegation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Geolocation coordinates = 3;
 * @return {?proto.marketplace.Geolocation}
 */
proto.marketplace.NewLocationRequest.prototype.getCoordinates = function() {
  return /** @type{?proto.marketplace.Geolocation} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.Geolocation, 3));
};


/**
 * @param {?proto.marketplace.Geolocation|undefined} value
 * @return {!proto.marketplace.NewLocationRequest} returns this
*/
proto.marketplace.NewLocationRequest.prototype.setCoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.NewLocationRequest} returns this
 */
proto.marketplace.NewLocationRequest.prototype.clearCoordinates = function() {
  return this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.NewLocationRequest.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.NewGovernorateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.NewGovernorateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.NewGovernorateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NewGovernorateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.NewGovernorateRequest}
 */
proto.marketplace.NewGovernorateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.NewGovernorateRequest;
  return proto.marketplace.NewGovernorateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.NewGovernorateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.NewGovernorateRequest}
 */
proto.marketplace.NewGovernorateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.NewGovernorateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.NewGovernorateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.NewGovernorateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NewGovernorateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.marketplace.NewGovernorateRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewGovernorateRequest} returns this
 */
proto.marketplace.NewGovernorateRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.NewDelegationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.NewDelegationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.NewDelegationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NewDelegationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    governoratexid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.NewDelegationRequest}
 */
proto.marketplace.NewDelegationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.NewDelegationRequest;
  return proto.marketplace.NewDelegationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.NewDelegationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.NewDelegationRequest}
 */
proto.marketplace.NewDelegationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGovernoratexid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.NewDelegationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.NewDelegationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.NewDelegationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NewDelegationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGovernoratexid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.marketplace.NewDelegationRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewDelegationRequest} returns this
 */
proto.marketplace.NewDelegationRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string governorateXID = 2;
 * @return {string}
 */
proto.marketplace.NewDelegationRequest.prototype.getGovernoratexid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewDelegationRequest} returns this
 */
proto.marketplace.NewDelegationRequest.prototype.setGovernoratexid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.GovernorateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.GovernorateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.GovernorateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GovernorateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    governorate: (f = msg.getGovernorate()) && proto.marketplace.Governorate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.GovernorateResponse}
 */
proto.marketplace.GovernorateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.GovernorateResponse;
  return proto.marketplace.GovernorateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.GovernorateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.GovernorateResponse}
 */
proto.marketplace.GovernorateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.marketplace.Governorate;
      reader.readMessage(value,proto.marketplace.Governorate.deserializeBinaryFromReader);
      msg.setGovernorate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.GovernorateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.GovernorateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.GovernorateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GovernorateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGovernorate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.marketplace.Governorate.serializeBinaryToWriter
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.GovernorateResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GovernorateResponse} returns this
 */
proto.marketplace.GovernorateResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Governorate Governorate = 2;
 * @return {?proto.marketplace.Governorate}
 */
proto.marketplace.GovernorateResponse.prototype.getGovernorate = function() {
  return /** @type{?proto.marketplace.Governorate} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.Governorate, 2));
};


/**
 * @param {?proto.marketplace.Governorate|undefined} value
 * @return {!proto.marketplace.GovernorateResponse} returns this
*/
proto.marketplace.GovernorateResponse.prototype.setGovernorate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.GovernorateResponse} returns this
 */
proto.marketplace.GovernorateResponse.prototype.clearGovernorate = function() {
  return this.setGovernorate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.GovernorateResponse.prototype.hasGovernorate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.DelegationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.DelegationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.DelegationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.DelegationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    delegation: (f = msg.getDelegation()) && proto.marketplace.Delegation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.DelegationResponse}
 */
proto.marketplace.DelegationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.DelegationResponse;
  return proto.marketplace.DelegationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.DelegationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.DelegationResponse}
 */
proto.marketplace.DelegationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.marketplace.Delegation;
      reader.readMessage(value,proto.marketplace.Delegation.deserializeBinaryFromReader);
      msg.setDelegation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.DelegationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.DelegationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.DelegationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.DelegationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDelegation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.marketplace.Delegation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.DelegationResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.DelegationResponse} returns this
 */
proto.marketplace.DelegationResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Delegation delegation = 2;
 * @return {?proto.marketplace.Delegation}
 */
proto.marketplace.DelegationResponse.prototype.getDelegation = function() {
  return /** @type{?proto.marketplace.Delegation} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.Delegation, 2));
};


/**
 * @param {?proto.marketplace.Delegation|undefined} value
 * @return {!proto.marketplace.DelegationResponse} returns this
*/
proto.marketplace.DelegationResponse.prototype.setDelegation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.DelegationResponse} returns this
 */
proto.marketplace.DelegationResponse.prototype.clearDelegation = function() {
  return this.setDelegation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.DelegationResponse.prototype.hasDelegation = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.NearPointRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.NearPointRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.NearPointRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NearPointRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    point: (f = msg.getPoint()) && proto.marketplace.Geolocation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.NearPointRequest}
 */
proto.marketplace.NearPointRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.NearPointRequest;
  return proto.marketplace.NearPointRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.NearPointRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.NearPointRequest}
 */
proto.marketplace.NearPointRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.marketplace.Geolocation;
      reader.readMessage(value,proto.marketplace.Geolocation.deserializeBinaryFromReader);
      msg.setPoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.NearPointRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.NearPointRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.NearPointRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NearPointRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.marketplace.Geolocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional Geolocation point = 1;
 * @return {?proto.marketplace.Geolocation}
 */
proto.marketplace.NearPointRequest.prototype.getPoint = function() {
  return /** @type{?proto.marketplace.Geolocation} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.Geolocation, 1));
};


/**
 * @param {?proto.marketplace.Geolocation|undefined} value
 * @return {!proto.marketplace.NearPointRequest} returns this
*/
proto.marketplace.NearPointRequest.prototype.setPoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.NearPointRequest} returns this
 */
proto.marketplace.NearPointRequest.prototype.clearPoint = function() {
  return this.setPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.NearPointRequest.prototype.hasPoint = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.LocationByGovernorateAndDelegation.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.LocationByGovernorateAndDelegation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.LocationByGovernorateAndDelegation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.LocationByGovernorateAndDelegation.toObject = function(includeInstance, msg) {
  var f, obj = {
    governoratename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    delegationname: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.LocationByGovernorateAndDelegation}
 */
proto.marketplace.LocationByGovernorateAndDelegation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.LocationByGovernorateAndDelegation;
  return proto.marketplace.LocationByGovernorateAndDelegation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.LocationByGovernorateAndDelegation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.LocationByGovernorateAndDelegation}
 */
proto.marketplace.LocationByGovernorateAndDelegation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGovernoratename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelegationname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.LocationByGovernorateAndDelegation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.LocationByGovernorateAndDelegation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.LocationByGovernorateAndDelegation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.LocationByGovernorateAndDelegation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGovernoratename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDelegationname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string governorateName = 1;
 * @return {string}
 */
proto.marketplace.LocationByGovernorateAndDelegation.prototype.getGovernoratename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.LocationByGovernorateAndDelegation} returns this
 */
proto.marketplace.LocationByGovernorateAndDelegation.prototype.setGovernoratename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string delegationName = 2;
 * @return {string}
 */
proto.marketplace.LocationByGovernorateAndDelegation.prototype.getDelegationname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.LocationByGovernorateAndDelegation} returns this
 */
proto.marketplace.LocationByGovernorateAndDelegation.prototype.setDelegationname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.NearPointResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.NearPointResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.NearPointResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NearPointResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    point: (f = msg.getPoint()) && proto.marketplace.Geolocation.toObject(includeInstance, f),
    governorate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    delegation: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.NearPointResponse}
 */
proto.marketplace.NearPointResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.NearPointResponse;
  return proto.marketplace.NearPointResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.NearPointResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.NearPointResponse}
 */
proto.marketplace.NearPointResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.marketplace.Geolocation;
      reader.readMessage(value,proto.marketplace.Geolocation.deserializeBinaryFromReader);
      msg.setPoint(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGovernorate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelegation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.NearPointResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.NearPointResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.NearPointResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NearPointResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.marketplace.Geolocation.serializeBinaryToWriter
    );
  }
  f = message.getGovernorate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDelegation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional Geolocation point = 1;
 * @return {?proto.marketplace.Geolocation}
 */
proto.marketplace.NearPointResponse.prototype.getPoint = function() {
  return /** @type{?proto.marketplace.Geolocation} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.Geolocation, 1));
};


/**
 * @param {?proto.marketplace.Geolocation|undefined} value
 * @return {!proto.marketplace.NearPointResponse} returns this
*/
proto.marketplace.NearPointResponse.prototype.setPoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.NearPointResponse} returns this
 */
proto.marketplace.NearPointResponse.prototype.clearPoint = function() {
  return this.setPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.NearPointResponse.prototype.hasPoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string governorate = 2;
 * @return {string}
 */
proto.marketplace.NearPointResponse.prototype.getGovernorate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NearPointResponse} returns this
 */
proto.marketplace.NearPointResponse.prototype.setGovernorate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string delegation = 3;
 * @return {string}
 */
proto.marketplace.NearPointResponse.prototype.getDelegation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NearPointResponse} returns this
 */
proto.marketplace.NearPointResponse.prototype.setDelegation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.DelegationsByGovernorateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.DelegationsByGovernorateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.DelegationsByGovernorateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.DelegationsByGovernorateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    governoratexid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.DelegationsByGovernorateRequest}
 */
proto.marketplace.DelegationsByGovernorateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.DelegationsByGovernorateRequest;
  return proto.marketplace.DelegationsByGovernorateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.DelegationsByGovernorateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.DelegationsByGovernorateRequest}
 */
proto.marketplace.DelegationsByGovernorateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGovernoratexid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.DelegationsByGovernorateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.DelegationsByGovernorateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.DelegationsByGovernorateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.DelegationsByGovernorateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGovernoratexid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string governorateXID = 2;
 * @return {string}
 */
proto.marketplace.DelegationsByGovernorateRequest.prototype.getGovernoratexid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.DelegationsByGovernorateRequest} returns this
 */
proto.marketplace.DelegationsByGovernorateRequest.prototype.setGovernoratexid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.FavoriteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.FavoriteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.FavoriteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.FavoriteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    kcid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.FavoriteRequest}
 */
proto.marketplace.FavoriteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.FavoriteRequest;
  return proto.marketplace.FavoriteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.FavoriteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.FavoriteRequest}
 */
proto.marketplace.FavoriteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKcid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.FavoriteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.FavoriteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.FavoriteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.FavoriteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKcid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.FavoriteRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.FavoriteRequest} returns this
 */
proto.marketplace.FavoriteRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string kcId = 2;
 * @return {string}
 */
proto.marketplace.FavoriteRequest.prototype.getKcid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.FavoriteRequest} returns this
 */
proto.marketplace.FavoriteRequest.prototype.setKcid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.Payment.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.Payment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.Payment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Payment.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymenturl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    numsite: jspb.Message.getFieldWithDefault(msg, 5, ""),
    passwordval: jspb.Message.getFieldWithDefault(msg, 6, ""),
    custaddress: jspb.Message.getFieldWithDefault(msg, 7, ""),
    language: jspb.Message.getFieldWithDefault(msg, 8, ""),
    amountsecond: jspb.Message.getFieldWithDefault(msg, 9, ""),
    tauxconversion: jspb.Message.getFieldWithDefault(msg, 10, ""),
    terminal: jspb.Message.getFieldWithDefault(msg, 11, ""),
    batchnumber: jspb.Message.getFieldWithDefault(msg, 12, ""),
    merchantreference: jspb.Message.getFieldWithDefault(msg, 13, ""),
    reccunum: jspb.Message.getFieldWithDefault(msg, 14, ""),
    reccuexpirydate: jspb.Message.getFieldWithDefault(msg, 15, ""),
    reccufrecuency: jspb.Message.getFieldWithDefault(msg, 16, ""),
    cryptopassword: jspb.Message.getFieldWithDefault(msg, 17, ""),
    payementtype: jspb.Message.getFieldWithDefault(msg, 18, ""),
    vad: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.Payment}
 */
proto.marketplace.Payment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.Payment;
  return proto.marketplace.Payment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.Payment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.Payment}
 */
proto.marketplace.Payment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymenturl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumsite(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPasswordval(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustaddress(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmountsecond(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTauxconversion(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminal(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchnumber(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerchantreference(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setReccunum(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setReccuexpirydate(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setReccufrecuency(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCryptopassword(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayementtype(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setVad(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.Payment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.Payment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.Payment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Payment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymenturl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNumsite();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPasswordval();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCustaddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAmountsecond();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTauxconversion();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTerminal();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getBatchnumber();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMerchantreference();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getReccunum();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getReccuexpirydate();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getReccufrecuency();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCryptopassword();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPayementtype();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getVad();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional string operationName = 1;
 * @return {string}
 */
proto.marketplace.Payment.prototype.getOperationname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Payment} returns this
 */
proto.marketplace.Payment.prototype.setOperationname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string paymentUrl = 2;
 * @return {string}
 */
proto.marketplace.Payment.prototype.getPaymenturl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Payment} returns this
 */
proto.marketplace.Payment.prototype.setPaymenturl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.marketplace.Payment.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Payment} returns this
 */
proto.marketplace.Payment.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string numSite = 5;
 * @return {string}
 */
proto.marketplace.Payment.prototype.getNumsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Payment} returns this
 */
proto.marketplace.Payment.prototype.setNumsite = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string passwordVal = 6;
 * @return {string}
 */
proto.marketplace.Payment.prototype.getPasswordval = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Payment} returns this
 */
proto.marketplace.Payment.prototype.setPasswordval = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string CustAddress = 7;
 * @return {string}
 */
proto.marketplace.Payment.prototype.getCustaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Payment} returns this
 */
proto.marketplace.Payment.prototype.setCustaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string language = 8;
 * @return {string}
 */
proto.marketplace.Payment.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Payment} returns this
 */
proto.marketplace.Payment.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string amountSecond = 9;
 * @return {string}
 */
proto.marketplace.Payment.prototype.getAmountsecond = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Payment} returns this
 */
proto.marketplace.Payment.prototype.setAmountsecond = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string tauxConversion = 10;
 * @return {string}
 */
proto.marketplace.Payment.prototype.getTauxconversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Payment} returns this
 */
proto.marketplace.Payment.prototype.setTauxconversion = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string terminal = 11;
 * @return {string}
 */
proto.marketplace.Payment.prototype.getTerminal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Payment} returns this
 */
proto.marketplace.Payment.prototype.setTerminal = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string batchNumber = 12;
 * @return {string}
 */
proto.marketplace.Payment.prototype.getBatchnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Payment} returns this
 */
proto.marketplace.Payment.prototype.setBatchnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string merchantReference = 13;
 * @return {string}
 */
proto.marketplace.Payment.prototype.getMerchantreference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Payment} returns this
 */
proto.marketplace.Payment.prototype.setMerchantreference = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string reccuNum = 14;
 * @return {string}
 */
proto.marketplace.Payment.prototype.getReccunum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Payment} returns this
 */
proto.marketplace.Payment.prototype.setReccunum = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string reccuExpiryDate = 15;
 * @return {string}
 */
proto.marketplace.Payment.prototype.getReccuexpirydate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Payment} returns this
 */
proto.marketplace.Payment.prototype.setReccuexpirydate = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string reccuFrecuency = 16;
 * @return {string}
 */
proto.marketplace.Payment.prototype.getReccufrecuency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Payment} returns this
 */
proto.marketplace.Payment.prototype.setReccufrecuency = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string cryptoPassword = 17;
 * @return {string}
 */
proto.marketplace.Payment.prototype.getCryptopassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Payment} returns this
 */
proto.marketplace.Payment.prototype.setCryptopassword = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string payementType = 18;
 * @return {string}
 */
proto.marketplace.Payment.prototype.getPayementtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Payment} returns this
 */
proto.marketplace.Payment.prototype.setPayementtype = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string vad = 19;
 * @return {string}
 */
proto.marketplace.Payment.prototype.getVad = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Payment} returns this
 */
proto.marketplace.Payment.prototype.setVad = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.GetPaymentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.GetPaymentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.GetPaymentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GetPaymentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    payment: (f = msg.getPayment()) && proto.marketplace.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.GetPaymentResponse}
 */
proto.marketplace.GetPaymentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.GetPaymentResponse;
  return proto.marketplace.GetPaymentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.GetPaymentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.GetPaymentResponse}
 */
proto.marketplace.GetPaymentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.marketplace.Payment;
      reader.readMessage(value,proto.marketplace.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.GetPaymentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.GetPaymentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.GetPaymentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GetPaymentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.marketplace.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.GetPaymentResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GetPaymentResponse} returns this
 */
proto.marketplace.GetPaymentResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Payment payment = 2;
 * @return {?proto.marketplace.Payment}
 */
proto.marketplace.GetPaymentResponse.prototype.getPayment = function() {
  return /** @type{?proto.marketplace.Payment} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.Payment, 2));
};


/**
 * @param {?proto.marketplace.Payment|undefined} value
 * @return {!proto.marketplace.GetPaymentResponse} returns this
*/
proto.marketplace.GetPaymentResponse.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.GetPaymentResponse} returns this
 */
proto.marketplace.GetPaymentResponse.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.GetPaymentResponse.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.NbAdsByUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.NbAdsByUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.NbAdsByUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NbAdsByUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subcategoryid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.NbAdsByUserRequest}
 */
proto.marketplace.NbAdsByUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.NbAdsByUserRequest;
  return proto.marketplace.NbAdsByUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.NbAdsByUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.NbAdsByUserRequest}
 */
proto.marketplace.NbAdsByUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubcategoryid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.NbAdsByUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.NbAdsByUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.NbAdsByUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NbAdsByUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubcategoryid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.marketplace.NbAdsByUserRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NbAdsByUserRequest} returns this
 */
proto.marketplace.NbAdsByUserRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subCategoryId = 2;
 * @return {string}
 */
proto.marketplace.NbAdsByUserRequest.prototype.getSubcategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NbAdsByUserRequest} returns this
 */
proto.marketplace.NbAdsByUserRequest.prototype.setSubcategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.NbAdsByUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.NbAdsByUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.NbAdsByUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NbAdsByUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    nbadsbyuser: jspb.Message.getFieldWithDefault(msg, 1, 0),
    defaultlimit: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.NbAdsByUserResponse}
 */
proto.marketplace.NbAdsByUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.NbAdsByUserResponse;
  return proto.marketplace.NbAdsByUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.NbAdsByUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.NbAdsByUserResponse}
 */
proto.marketplace.NbAdsByUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNbadsbyuser(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDefaultlimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.NbAdsByUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.NbAdsByUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.NbAdsByUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NbAdsByUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNbadsbyuser();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDefaultlimit();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional int64 nbAdsByUser = 1;
 * @return {number}
 */
proto.marketplace.NbAdsByUserResponse.prototype.getNbadsbyuser = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.NbAdsByUserResponse} returns this
 */
proto.marketplace.NbAdsByUserResponse.prototype.setNbadsbyuser = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float defaultLimit = 2;
 * @return {number}
 */
proto.marketplace.NbAdsByUserResponse.prototype.getDefaultlimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.NbAdsByUserResponse} returns this
 */
proto.marketplace.NbAdsByUserResponse.prototype.setDefaultlimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.OfflineAdsForUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.OfflineAdsForUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.OfflineAdsForUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.OfflineAdsForUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paginationparams: (f = msg.getPaginationparams()) && discovery_pb.PaginationParams.toObject(includeInstance, f),
    filterparamsMap: (f = msg.getFilterparamsMap()) ? f.toObject(includeInstance, undefined) : [],
    sortmechanism: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.OfflineAdsForUserRequest}
 */
proto.marketplace.OfflineAdsForUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.OfflineAdsForUserRequest;
  return proto.marketplace.OfflineAdsForUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.OfflineAdsForUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.OfflineAdsForUserRequest}
 */
proto.marketplace.OfflineAdsForUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = new discovery_pb.PaginationParams;
      reader.readMessage(value,discovery_pb.PaginationParams.deserializeBinaryFromReader);
      msg.setPaginationparams(value);
      break;
    case 3:
      var value = msg.getFilterparamsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 4:
      var value = /** @type {!proto.marketplace.OfflineAdsForUserRequest.SortMechanism} */ (reader.readEnum());
      msg.setSortmechanism(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.OfflineAdsForUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.OfflineAdsForUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.OfflineAdsForUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.OfflineAdsForUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaginationparams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      discovery_pb.PaginationParams.serializeBinaryToWriter
    );
  }
  f = message.getFilterparamsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getSortmechanism();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.marketplace.OfflineAdsForUserRequest.SortMechanism = {
  TIMESTAMP: 0,
  RELEVANCE: 1,
  ASCPRICE: 2,
  DESCPRICE: 3
};

/**
 * optional string userID = 1;
 * @return {string}
 */
proto.marketplace.OfflineAdsForUserRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.OfflineAdsForUserRequest} returns this
 */
proto.marketplace.OfflineAdsForUserRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PaginationParams paginationParams = 2;
 * @return {?proto.marketplace.PaginationParams}
 */
proto.marketplace.OfflineAdsForUserRequest.prototype.getPaginationparams = function() {
  return /** @type{?proto.marketplace.PaginationParams} */ (
    jspb.Message.getWrapperField(this, discovery_pb.PaginationParams, 2));
};


/**
 * @param {?proto.marketplace.PaginationParams|undefined} value
 * @return {!proto.marketplace.OfflineAdsForUserRequest} returns this
*/
proto.marketplace.OfflineAdsForUserRequest.prototype.setPaginationparams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.OfflineAdsForUserRequest} returns this
 */
proto.marketplace.OfflineAdsForUserRequest.prototype.clearPaginationparams = function() {
  return this.setPaginationparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.OfflineAdsForUserRequest.prototype.hasPaginationparams = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * map<string, string> filterParams = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.marketplace.OfflineAdsForUserRequest.prototype.getFilterparamsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.marketplace.OfflineAdsForUserRequest} returns this
 */
proto.marketplace.OfflineAdsForUserRequest.prototype.clearFilterparamsMap = function() {
  this.getFilterparamsMap().clear();
  return this;};


/**
 * optional SortMechanism sortMechanism = 4;
 * @return {!proto.marketplace.OfflineAdsForUserRequest.SortMechanism}
 */
proto.marketplace.OfflineAdsForUserRequest.prototype.getSortmechanism = function() {
  return /** @type {!proto.marketplace.OfflineAdsForUserRequest.SortMechanism} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.marketplace.OfflineAdsForUserRequest.SortMechanism} value
 * @return {!proto.marketplace.OfflineAdsForUserRequest} returns this
 */
proto.marketplace.OfflineAdsForUserRequest.prototype.setSortmechanism = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.GetAdsByUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.GetAdsByUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.GetAdsByUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GetAdsByUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paginationparams: (f = msg.getPaginationparams()) && discovery_pb.PaginationParams.toObject(includeInstance, f),
    filterparamsMap: (f = msg.getFilterparamsMap()) ? f.toObject(includeInstance, undefined) : [],
    sortmechanism: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.GetAdsByUserRequest}
 */
proto.marketplace.GetAdsByUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.GetAdsByUserRequest;
  return proto.marketplace.GetAdsByUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.GetAdsByUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.GetAdsByUserRequest}
 */
proto.marketplace.GetAdsByUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = new discovery_pb.PaginationParams;
      reader.readMessage(value,discovery_pb.PaginationParams.deserializeBinaryFromReader);
      msg.setPaginationparams(value);
      break;
    case 3:
      var value = msg.getFilterparamsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 4:
      var value = /** @type {!proto.marketplace.GetAdsByUserRequest.SortMechanism} */ (reader.readEnum());
      msg.setSortmechanism(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.GetAdsByUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.GetAdsByUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.GetAdsByUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GetAdsByUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaginationparams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      discovery_pb.PaginationParams.serializeBinaryToWriter
    );
  }
  f = message.getFilterparamsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getSortmechanism();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.marketplace.GetAdsByUserRequest.SortMechanism = {
  TIMESTAMP: 0,
  RELEVANCE: 1,
  ASCPRICE: 2,
  DESCPRICE: 3
};

/**
 * optional string userID = 1;
 * @return {string}
 */
proto.marketplace.GetAdsByUserRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GetAdsByUserRequest} returns this
 */
proto.marketplace.GetAdsByUserRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PaginationParams paginationParams = 2;
 * @return {?proto.marketplace.PaginationParams}
 */
proto.marketplace.GetAdsByUserRequest.prototype.getPaginationparams = function() {
  return /** @type{?proto.marketplace.PaginationParams} */ (
    jspb.Message.getWrapperField(this, discovery_pb.PaginationParams, 2));
};


/**
 * @param {?proto.marketplace.PaginationParams|undefined} value
 * @return {!proto.marketplace.GetAdsByUserRequest} returns this
*/
proto.marketplace.GetAdsByUserRequest.prototype.setPaginationparams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.GetAdsByUserRequest} returns this
 */
proto.marketplace.GetAdsByUserRequest.prototype.clearPaginationparams = function() {
  return this.setPaginationparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.GetAdsByUserRequest.prototype.hasPaginationparams = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * map<string, string> filterParams = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.marketplace.GetAdsByUserRequest.prototype.getFilterparamsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.marketplace.GetAdsByUserRequest} returns this
 */
proto.marketplace.GetAdsByUserRequest.prototype.clearFilterparamsMap = function() {
  this.getFilterparamsMap().clear();
  return this;};


/**
 * optional SortMechanism sortMechanism = 4;
 * @return {!proto.marketplace.GetAdsByUserRequest.SortMechanism}
 */
proto.marketplace.GetAdsByUserRequest.prototype.getSortmechanism = function() {
  return /** @type {!proto.marketplace.GetAdsByUserRequest.SortMechanism} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.marketplace.GetAdsByUserRequest.SortMechanism} value
 * @return {!proto.marketplace.GetAdsByUserRequest} returns this
 */
proto.marketplace.GetAdsByUserRequest.prototype.setSortmechanism = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.ReportAdByUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.ReportAdByUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.ReportAdByUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ReportAdByUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reporttype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reportcomment: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    adtitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    owneraduserphonenumber: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.ReportAdByUserRequest}
 */
proto.marketplace.ReportAdByUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.ReportAdByUserRequest;
  return proto.marketplace.ReportAdByUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.ReportAdByUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.ReportAdByUserRequest}
 */
proto.marketplace.ReportAdByUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReporttype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportcomment(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdtitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwneraduserphonenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.ReportAdByUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.ReportAdByUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.ReportAdByUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ReportAdByUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReporttype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReportcomment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdtitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOwneraduserphonenumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string reportType = 1;
 * @return {string}
 */
proto.marketplace.ReportAdByUserRequest.prototype.getReporttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ReportAdByUserRequest} returns this
 */
proto.marketplace.ReportAdByUserRequest.prototype.setReporttype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reportComment = 2;
 * @return {string}
 */
proto.marketplace.ReportAdByUserRequest.prototype.getReportcomment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ReportAdByUserRequest} returns this
 */
proto.marketplace.ReportAdByUserRequest.prototype.setReportcomment = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string adID = 3;
 * @return {string}
 */
proto.marketplace.ReportAdByUserRequest.prototype.getAdid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ReportAdByUserRequest} returns this
 */
proto.marketplace.ReportAdByUserRequest.prototype.setAdid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string adTitle = 4;
 * @return {string}
 */
proto.marketplace.ReportAdByUserRequest.prototype.getAdtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ReportAdByUserRequest} returns this
 */
proto.marketplace.ReportAdByUserRequest.prototype.setAdtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ownerAdUserPhoneNumber = 5;
 * @return {string}
 */
proto.marketplace.ReportAdByUserRequest.prototype.getOwneraduserphonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ReportAdByUserRequest} returns this
 */
proto.marketplace.ReportAdByUserRequest.prototype.setOwneraduserphonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.ReportAdByUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.ReportAdByUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.ReportAdByUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ReportAdByUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.ReportAdByUserResponse}
 */
proto.marketplace.ReportAdByUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.ReportAdByUserResponse;
  return proto.marketplace.ReportAdByUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.ReportAdByUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.ReportAdByUserResponse}
 */
proto.marketplace.ReportAdByUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.ReportAdByUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.ReportAdByUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.ReportAdByUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ReportAdByUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.marketplace.ReportAdByUserResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ReportAdByUserResponse} returns this
 */
proto.marketplace.ReportAdByUserResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.TransactionStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.TransactionStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.TransactionStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.TransactionStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.TransactionStatusRequest}
 */
proto.marketplace.TransactionStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.TransactionStatusRequest;
  return proto.marketplace.TransactionStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.TransactionStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.TransactionStatusRequest}
 */
proto.marketplace.TransactionStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {!proto.marketplace.TransactionStatusRequest.TransactionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.TransactionStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.TransactionStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.TransactionStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.TransactionStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.marketplace.TransactionStatusRequest.TransactionStatus = {
  VIREMENT: 0,
  SUR_PLACE: 1,
  EN_LIGNE: 2
};

/**
 * optional string userId = 1;
 * @return {string}
 */
proto.marketplace.TransactionStatusRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.TransactionStatusRequest} returns this
 */
proto.marketplace.TransactionStatusRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TransactionStatus status = 2;
 * @return {!proto.marketplace.TransactionStatusRequest.TransactionStatus}
 */
proto.marketplace.TransactionStatusRequest.prototype.getStatus = function() {
  return /** @type {!proto.marketplace.TransactionStatusRequest.TransactionStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.marketplace.TransactionStatusRequest.TransactionStatus} value
 * @return {!proto.marketplace.TransactionStatusRequest} returns this
 */
proto.marketplace.TransactionStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.TransactionStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.TransactionStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.TransactionStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.TransactionStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.TransactionStatusResponse}
 */
proto.marketplace.TransactionStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.TransactionStatusResponse;
  return proto.marketplace.TransactionStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.TransactionStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.TransactionStatusResponse}
 */
proto.marketplace.TransactionStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.TransactionStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.TransactionStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.TransactionStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.TransactionStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.TransactionStatusResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.TransactionStatusResponse} returns this
 */
proto.marketplace.TransactionStatusResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.TransactionStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.TransactionStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.TransactionStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.TransactionStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    creationtimestamp: (f = msg.getCreationtimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.TransactionStatus}
 */
proto.marketplace.TransactionStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.TransactionStatus;
  return proto.marketplace.TransactionStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.TransactionStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.TransactionStatus}
 */
proto.marketplace.TransactionStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {!proto.marketplace.TransactionStatus.TransactionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationtimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.TransactionStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.TransactionStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.TransactionStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.TransactionStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCreationtimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.marketplace.TransactionStatus.TransactionStatus = {
  VIREMENT: 0,
  SUR_PLACE: 1,
  EN_LIGNE: 2
};

/**
 * optional string userId = 1;
 * @return {string}
 */
proto.marketplace.TransactionStatus.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.TransactionStatus} returns this
 */
proto.marketplace.TransactionStatus.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TransactionStatus status = 2;
 * @return {!proto.marketplace.TransactionStatus.TransactionStatus}
 */
proto.marketplace.TransactionStatus.prototype.getStatus = function() {
  return /** @type {!proto.marketplace.TransactionStatus.TransactionStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.marketplace.TransactionStatus.TransactionStatus} value
 * @return {!proto.marketplace.TransactionStatus} returns this
 */
proto.marketplace.TransactionStatus.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp CreationTimestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marketplace.TransactionStatus.prototype.getCreationtimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marketplace.TransactionStatus} returns this
*/
proto.marketplace.TransactionStatus.prototype.setCreationtimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.TransactionStatus} returns this
 */
proto.marketplace.TransactionStatus.prototype.clearCreationtimestamp = function() {
  return this.setCreationtimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.TransactionStatus.prototype.hasCreationtimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.MeiliSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.MeiliSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.MeiliSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.MeiliSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchquery: jspb.Message.getFieldWithDefault(msg, 1, ""),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    matches: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.MeiliSearchRequest}
 */
proto.marketplace.MeiliSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.MeiliSearchRequest;
  return proto.marketplace.MeiliSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.MeiliSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.MeiliSearchRequest}
 */
proto.marketplace.MeiliSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchquery(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMatches(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.MeiliSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.MeiliSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.MeiliSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.MeiliSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchquery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMatches();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string SearchQuery = 1;
 * @return {string}
 */
proto.marketplace.MeiliSearchRequest.prototype.getSearchquery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.MeiliSearchRequest} returns this
 */
proto.marketplace.MeiliSearchRequest.prototype.setSearchquery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 Offset = 2;
 * @return {number}
 */
proto.marketplace.MeiliSearchRequest.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.MeiliSearchRequest} returns this
 */
proto.marketplace.MeiliSearchRequest.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 Limit = 3;
 * @return {number}
 */
proto.marketplace.MeiliSearchRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.MeiliSearchRequest} returns this
 */
proto.marketplace.MeiliSearchRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool Matches = 4;
 * @return {boolean}
 */
proto.marketplace.MeiliSearchRequest.prototype.getMatches = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marketplace.MeiliSearchRequest} returns this
 */
proto.marketplace.MeiliSearchRequest.prototype.setMatches = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketplace.MeiliSearchResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.MeiliSearchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.MeiliSearchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.MeiliSearchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.MeiliSearchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    elementsList: jspb.Message.toObjectList(msg.getElementsList(),
    proto.marketplace.AdCurrentVersion.toObject, includeInstance),
    countsearch: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.MeiliSearchResponse}
 */
proto.marketplace.MeiliSearchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.MeiliSearchResponse;
  return proto.marketplace.MeiliSearchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.MeiliSearchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.MeiliSearchResponse}
 */
proto.marketplace.MeiliSearchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.marketplace.AdCurrentVersion;
      reader.readMessage(value,proto.marketplace.AdCurrentVersion.deserializeBinaryFromReader);
      msg.addElements(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCountsearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.MeiliSearchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.MeiliSearchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.MeiliSearchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.MeiliSearchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.marketplace.AdCurrentVersion.serializeBinaryToWriter
    );
  }
  f = message.getCountsearch();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated AdCurrentVersion elements = 1;
 * @return {!Array<!proto.marketplace.AdCurrentVersion>}
 */
proto.marketplace.MeiliSearchResponse.prototype.getElementsList = function() {
  return /** @type{!Array<!proto.marketplace.AdCurrentVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.marketplace.AdCurrentVersion, 1));
};


/**
 * @param {!Array<!proto.marketplace.AdCurrentVersion>} value
 * @return {!proto.marketplace.MeiliSearchResponse} returns this
*/
proto.marketplace.MeiliSearchResponse.prototype.setElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.marketplace.AdCurrentVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marketplace.AdCurrentVersion}
 */
proto.marketplace.MeiliSearchResponse.prototype.addElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.marketplace.AdCurrentVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.MeiliSearchResponse} returns this
 */
proto.marketplace.MeiliSearchResponse.prototype.clearElementsList = function() {
  return this.setElementsList([]);
};


/**
 * optional int64 CountSearch = 2;
 * @return {number}
 */
proto.marketplace.MeiliSearchResponse.prototype.getCountsearch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.MeiliSearchResponse} returns this
 */
proto.marketplace.MeiliSearchResponse.prototype.setCountsearch = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.AdViewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.AdViewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.AdViewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdViewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    local: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    useragent: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    timezone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.AdViewRequest}
 */
proto.marketplace.AdViewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.AdViewRequest;
  return proto.marketplace.AdViewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.AdViewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.AdViewRequest}
 */
proto.marketplace.AdViewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocal(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUseragent(value);
      break;
    case 5:
      var value = /** @type {!proto.marketplace.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.AdViewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.AdViewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.AdViewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AdViewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocal();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUseragent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string SessionId = 1;
 * @return {string}
 */
proto.marketplace.AdViewRequest.prototype.getSessionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdViewRequest} returns this
 */
proto.marketplace.AdViewRequest.prototype.setSessionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string local = 2;
 * @return {string}
 */
proto.marketplace.AdViewRequest.prototype.getLocal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdViewRequest} returns this
 */
proto.marketplace.AdViewRequest.prototype.setLocal = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string AdId = 3;
 * @return {string}
 */
proto.marketplace.AdViewRequest.prototype.getAdid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdViewRequest} returns this
 */
proto.marketplace.AdViewRequest.prototype.setAdid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string UserAgent = 4;
 * @return {string}
 */
proto.marketplace.AdViewRequest.prototype.getUseragent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdViewRequest} returns this
 */
proto.marketplace.AdViewRequest.prototype.setUseragent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Type type = 5;
 * @return {!proto.marketplace.Type}
 */
proto.marketplace.AdViewRequest.prototype.getType = function() {
  return /** @type {!proto.marketplace.Type} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.marketplace.Type} value
 * @return {!proto.marketplace.AdViewRequest} returns this
 */
proto.marketplace.AdViewRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string TimeZone = 6;
 * @return {string}
 */
proto.marketplace.AdViewRequest.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.AdViewRequest} returns this
 */
proto.marketplace.AdViewRequest.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp Date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marketplace.AdViewRequest.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marketplace.AdViewRequest} returns this
*/
proto.marketplace.AdViewRequest.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.AdViewRequest} returns this
 */
proto.marketplace.AdViewRequest.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.AdViewRequest.prototype.hasDate = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.ViewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.ViewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.ViewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ViewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    local: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    useragent: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    timezone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.ViewResponse}
 */
proto.marketplace.ViewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.ViewResponse;
  return proto.marketplace.ViewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.ViewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.ViewResponse}
 */
proto.marketplace.ViewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocal(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUseragent(value);
      break;
    case 5:
      var value = /** @type {!proto.marketplace.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.ViewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.ViewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.ViewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ViewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocal();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUseragent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string SessionId = 1;
 * @return {string}
 */
proto.marketplace.ViewResponse.prototype.getSessionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ViewResponse} returns this
 */
proto.marketplace.ViewResponse.prototype.setSessionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string local = 2;
 * @return {string}
 */
proto.marketplace.ViewResponse.prototype.getLocal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ViewResponse} returns this
 */
proto.marketplace.ViewResponse.prototype.setLocal = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string AdId = 3;
 * @return {string}
 */
proto.marketplace.ViewResponse.prototype.getAdid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ViewResponse} returns this
 */
proto.marketplace.ViewResponse.prototype.setAdid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string UserAgent = 4;
 * @return {string}
 */
proto.marketplace.ViewResponse.prototype.getUseragent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ViewResponse} returns this
 */
proto.marketplace.ViewResponse.prototype.setUseragent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Type type = 5;
 * @return {!proto.marketplace.Type}
 */
proto.marketplace.ViewResponse.prototype.getType = function() {
  return /** @type {!proto.marketplace.Type} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.marketplace.Type} value
 * @return {!proto.marketplace.ViewResponse} returns this
 */
proto.marketplace.ViewResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string TimeZone = 6;
 * @return {string}
 */
proto.marketplace.ViewResponse.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ViewResponse} returns this
 */
proto.marketplace.ViewResponse.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp Date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marketplace.ViewResponse.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marketplace.ViewResponse} returns this
*/
proto.marketplace.ViewResponse.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.ViewResponse} returns this
 */
proto.marketplace.ViewResponse.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.ViewResponse.prototype.hasDate = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.Leads.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.Leads.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.Leads} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Leads.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.Leads}
 */
proto.marketplace.Leads.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.Leads;
  return proto.marketplace.Leads.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.Leads} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.Leads}
 */
proto.marketplace.Leads.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.marketplace.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.Leads.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.Leads.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.Leads} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Leads.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional Type type = 1;
 * @return {!proto.marketplace.Type}
 */
proto.marketplace.Leads.prototype.getType = function() {
  return /** @type {!proto.marketplace.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.marketplace.Type} value
 * @return {!proto.marketplace.Leads} returns this
 */
proto.marketplace.Leads.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 Count = 2;
 * @return {number}
 */
proto.marketplace.Leads.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.Leads} returns this
 */
proto.marketplace.Leads.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketplace.ResponseView.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.ResponseView.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.ResponseView.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.ResponseView} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ResponseView.toObject = function(includeInstance, msg) {
  var f, obj = {
    leadsList: jspb.Message.toObjectList(msg.getLeadsList(),
    proto.marketplace.Leads.toObject, includeInstance),
    viewsList: jspb.Message.toObjectList(msg.getViewsList(),
    proto.marketplace.ViewResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.ResponseView}
 */
proto.marketplace.ResponseView.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.ResponseView;
  return proto.marketplace.ResponseView.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.ResponseView} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.ResponseView}
 */
proto.marketplace.ResponseView.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.marketplace.Leads;
      reader.readMessage(value,proto.marketplace.Leads.deserializeBinaryFromReader);
      msg.addLeads(value);
      break;
    case 2:
      var value = new proto.marketplace.ViewResponse;
      reader.readMessage(value,proto.marketplace.ViewResponse.deserializeBinaryFromReader);
      msg.addViews(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.ResponseView.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.ResponseView.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.ResponseView} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ResponseView.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLeadsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.marketplace.Leads.serializeBinaryToWriter
    );
  }
  f = message.getViewsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.marketplace.ViewResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Leads Leads = 1;
 * @return {!Array<!proto.marketplace.Leads>}
 */
proto.marketplace.ResponseView.prototype.getLeadsList = function() {
  return /** @type{!Array<!proto.marketplace.Leads>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.marketplace.Leads, 1));
};


/**
 * @param {!Array<!proto.marketplace.Leads>} value
 * @return {!proto.marketplace.ResponseView} returns this
*/
proto.marketplace.ResponseView.prototype.setLeadsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.marketplace.Leads=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marketplace.Leads}
 */
proto.marketplace.ResponseView.prototype.addLeads = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.marketplace.Leads, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.ResponseView} returns this
 */
proto.marketplace.ResponseView.prototype.clearLeadsList = function() {
  return this.setLeadsList([]);
};


/**
 * repeated ViewResponse Views = 2;
 * @return {!Array<!proto.marketplace.ViewResponse>}
 */
proto.marketplace.ResponseView.prototype.getViewsList = function() {
  return /** @type{!Array<!proto.marketplace.ViewResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.marketplace.ViewResponse, 2));
};


/**
 * @param {!Array<!proto.marketplace.ViewResponse>} value
 * @return {!proto.marketplace.ResponseView} returns this
*/
proto.marketplace.ResponseView.prototype.setViewsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.marketplace.ViewResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marketplace.ViewResponse}
 */
proto.marketplace.ResponseView.prototype.addViews = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.marketplace.ViewResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.ResponseView} returns this
 */
proto.marketplace.ResponseView.prototype.clearViewsList = function() {
  return this.setViewsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.VoucherRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.VoucherRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.VoucherRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.VoucherRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.VoucherRequest}
 */
proto.marketplace.VoucherRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.VoucherRequest;
  return proto.marketplace.VoucherRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.VoucherRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.VoucherRequest}
 */
proto.marketplace.VoucherRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.VoucherRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.VoucherRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.VoucherRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.VoucherRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Token = 2;
 * @return {string}
 */
proto.marketplace.VoucherRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.VoucherRequest} returns this
 */
proto.marketplace.VoucherRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.VoucherResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.VoucherResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.VoucherResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.VoucherResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    picture: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.VoucherResponse}
 */
proto.marketplace.VoucherResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.VoucherResponse;
  return proto.marketplace.VoucherResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.VoucherResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.VoucherResponse}
 */
proto.marketplace.VoucherResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPicture(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.VoucherResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.VoucherResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.VoucherResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.VoucherResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPicture();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional string Picture = 1;
 * @return {string}
 */
proto.marketplace.VoucherResponse.prototype.getPicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.VoucherResponse} returns this
 */
proto.marketplace.VoucherResponse.prototype.setPicture = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float Amount = 2;
 * @return {number}
 */
proto.marketplace.VoucherResponse.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.VoucherResponse} returns this
 */
proto.marketplace.VoucherResponse.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.Voucher.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.Voucher.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.Voucher} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Voucher.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    secret: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    picture: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startat: jspb.Message.getFieldWithDefault(msg, 5, 0),
    endsat: jspb.Message.getFieldWithDefault(msg, 6, 0),
    partner: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.Voucher}
 */
proto.marketplace.Voucher.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.Voucher;
  return proto.marketplace.Voucher.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.Voucher} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.Voucher}
 */
proto.marketplace.Voucher.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecret(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPicture(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartat(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndsat(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.Voucher.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.Voucher.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.Voucher} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Voucher.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSecret();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getPicture();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartat();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getEndsat();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPartner();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.Voucher.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Voucher} returns this
 */
proto.marketplace.Voucher.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secret = 2;
 * @return {string}
 */
proto.marketplace.Voucher.prototype.getSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Voucher} returns this
 */
proto.marketplace.Voucher.prototype.setSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float amount = 3;
 * @return {number}
 */
proto.marketplace.Voucher.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.Voucher} returns this
 */
proto.marketplace.Voucher.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string picture = 4;
 * @return {string}
 */
proto.marketplace.Voucher.prototype.getPicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Voucher} returns this
 */
proto.marketplace.Voucher.prototype.setPicture = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 startAt = 5;
 * @return {number}
 */
proto.marketplace.Voucher.prototype.getStartat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.Voucher} returns this
 */
proto.marketplace.Voucher.prototype.setStartat = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 endsAt = 6;
 * @return {number}
 */
proto.marketplace.Voucher.prototype.getEndsat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.Voucher} returns this
 */
proto.marketplace.Voucher.prototype.setEndsat = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string partner = 7;
 * @return {string}
 */
proto.marketplace.Voucher.prototype.getPartner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Voucher} returns this
 */
proto.marketplace.Voucher.prototype.setPartner = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.VoucherLog.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.VoucherLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.VoucherLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.VoucherLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    voucherId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    uuid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.VoucherLog}
 */
proto.marketplace.VoucherLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.VoucherLog;
  return proto.marketplace.VoucherLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.VoucherLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.VoucherLog}
 */
proto.marketplace.VoucherLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVoucherId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAmount(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.VoucherLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.VoucherLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.VoucherLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.VoucherLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVoucherId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string voucher_id = 1;
 * @return {string}
 */
proto.marketplace.VoucherLog.prototype.getVoucherId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.VoucherLog} returns this
 */
proto.marketplace.VoucherLog.prototype.setVoucherId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.marketplace.VoucherLog.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.VoucherLog} returns this
 */
proto.marketplace.VoucherLog.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string uuid = 4;
 * @return {string}
 */
proto.marketplace.VoucherLog.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.VoucherLog} returns this
 */
proto.marketplace.VoucherLog.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional float amount = 5;
 * @return {number}
 */
proto.marketplace.VoucherLog.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.VoucherLog} returns this
 */
proto.marketplace.VoucherLog.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marketplace.VoucherLog.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marketplace.VoucherLog} returns this
*/
proto.marketplace.VoucherLog.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.VoucherLog} returns this
 */
proto.marketplace.VoucherLog.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.VoucherLog.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketplace.ValidateAdparamsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.ValidateAdparamsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.ValidateAdparamsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.ValidateAdparamsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ValidateAdparamsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adparamvaluesList: jspb.Message.toObjectList(msg.getAdparamvaluesList(),
    proto.marketplace.AdParamValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.ValidateAdparamsRequest}
 */
proto.marketplace.ValidateAdparamsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.ValidateAdparamsRequest;
  return proto.marketplace.ValidateAdparamsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.ValidateAdparamsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.ValidateAdparamsRequest}
 */
proto.marketplace.ValidateAdparamsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 2:
      var value = new proto.marketplace.AdParamValue;
      reader.readMessage(value,proto.marketplace.AdParamValue.deserializeBinaryFromReader);
      msg.addAdparamvalues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.ValidateAdparamsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.ValidateAdparamsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.ValidateAdparamsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ValidateAdparamsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdparamvaluesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.marketplace.AdParamValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string category_id = 1;
 * @return {string}
 */
proto.marketplace.ValidateAdparamsRequest.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ValidateAdparamsRequest} returns this
 */
proto.marketplace.ValidateAdparamsRequest.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated AdParamValue adParamValues = 2;
 * @return {!Array<!proto.marketplace.AdParamValue>}
 */
proto.marketplace.ValidateAdparamsRequest.prototype.getAdparamvaluesList = function() {
  return /** @type{!Array<!proto.marketplace.AdParamValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.marketplace.AdParamValue, 2));
};


/**
 * @param {!Array<!proto.marketplace.AdParamValue>} value
 * @return {!proto.marketplace.ValidateAdparamsRequest} returns this
*/
proto.marketplace.ValidateAdparamsRequest.prototype.setAdparamvaluesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.marketplace.AdParamValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marketplace.AdParamValue}
 */
proto.marketplace.ValidateAdparamsRequest.prototype.addAdparamvalues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.marketplace.AdParamValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.ValidateAdparamsRequest} returns this
 */
proto.marketplace.ValidateAdparamsRequest.prototype.clearAdparamvaluesList = function() {
  return this.setAdparamvaluesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.ValidateAdparamsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.ValidateAdparamsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.ValidateAdparamsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ValidateAdparamsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    adparamsvalid: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.ValidateAdparamsResponse}
 */
proto.marketplace.ValidateAdparamsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.ValidateAdparamsResponse;
  return proto.marketplace.ValidateAdparamsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.ValidateAdparamsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.ValidateAdparamsResponse}
 */
proto.marketplace.ValidateAdparamsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdparamsvalid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.ValidateAdparamsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.ValidateAdparamsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.ValidateAdparamsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ValidateAdparamsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdparamsvalid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool adparamsValid = 1;
 * @return {boolean}
 */
proto.marketplace.ValidateAdparamsResponse.prototype.getAdparamsvalid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marketplace.ValidateAdparamsResponse} returns this
 */
proto.marketplace.ValidateAdparamsResponse.prototype.setAdparamsvalid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.DeleteUserDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.DeleteUserDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.DeleteUserDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.DeleteUserDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deleteorenable: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.DeleteUserDataRequest}
 */
proto.marketplace.DeleteUserDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.DeleteUserDataRequest;
  return proto.marketplace.DeleteUserDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.DeleteUserDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.DeleteUserDataRequest}
 */
proto.marketplace.DeleteUserDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeleteorenable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.DeleteUserDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.DeleteUserDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.DeleteUserDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.DeleteUserDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleteorenable();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string DeleteOrEnable = 1;
 * @return {string}
 */
proto.marketplace.DeleteUserDataRequest.prototype.getDeleteorenable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.DeleteUserDataRequest} returns this
 */
proto.marketplace.DeleteUserDataRequest.prototype.setDeleteorenable = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.DeleteUserDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.DeleteUserDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.DeleteUserDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.DeleteUserDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.DeleteUserDataResponse}
 */
proto.marketplace.DeleteUserDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.DeleteUserDataResponse;
  return proto.marketplace.DeleteUserDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.DeleteUserDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.DeleteUserDataResponse}
 */
proto.marketplace.DeleteUserDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.DeleteUserDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.DeleteUserDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.DeleteUserDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.DeleteUserDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.marketplace.DeleteUserDataResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.DeleteUserDataResponse} returns this
 */
proto.marketplace.DeleteUserDataResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.DeleteGoldenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.DeleteGoldenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.DeleteGoldenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.DeleteGoldenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    countdeleted: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.DeleteGoldenResponse}
 */
proto.marketplace.DeleteGoldenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.DeleteGoldenResponse;
  return proto.marketplace.DeleteGoldenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.DeleteGoldenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.DeleteGoldenResponse}
 */
proto.marketplace.DeleteGoldenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCountdeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.DeleteGoldenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.DeleteGoldenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.DeleteGoldenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.DeleteGoldenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountdeleted();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.marketplace.DeleteGoldenResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.DeleteGoldenResponse} returns this
 */
proto.marketplace.DeleteGoldenResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 countDeleted = 2;
 * @return {number}
 */
proto.marketplace.DeleteGoldenResponse.prototype.getCountdeleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.DeleteGoldenResponse} returns this
 */
proto.marketplace.DeleteGoldenResponse.prototype.setCountdeleted = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.LeadsCount.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.LeadsCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.LeadsCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.LeadsCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatleads: jspb.Message.getFieldWithDefault(msg, 1, 0),
    whatsappleads: jspb.Message.getFieldWithDefault(msg, 2, 0),
    showphoneleads: jspb.Message.getFieldWithDefault(msg, 3, 0),
    contactformleads: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.LeadsCount}
 */
proto.marketplace.LeadsCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.LeadsCount;
  return proto.marketplace.LeadsCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.LeadsCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.LeadsCount}
 */
proto.marketplace.LeadsCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChatleads(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWhatsappleads(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setShowphoneleads(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContactformleads(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.LeadsCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.LeadsCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.LeadsCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.LeadsCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatleads();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWhatsappleads();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getShowphoneleads();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getContactformleads();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 chatLeads = 1;
 * @return {number}
 */
proto.marketplace.LeadsCount.prototype.getChatleads = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.LeadsCount} returns this
 */
proto.marketplace.LeadsCount.prototype.setChatleads = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 whatsAppLeads = 2;
 * @return {number}
 */
proto.marketplace.LeadsCount.prototype.getWhatsappleads = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.LeadsCount} returns this
 */
proto.marketplace.LeadsCount.prototype.setWhatsappleads = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 showPhoneLeads = 3;
 * @return {number}
 */
proto.marketplace.LeadsCount.prototype.getShowphoneleads = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.LeadsCount} returns this
 */
proto.marketplace.LeadsCount.prototype.setShowphoneleads = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 ContactFormLeads = 4;
 * @return {number}
 */
proto.marketplace.LeadsCount.prototype.getContactformleads = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.LeadsCount} returns this
 */
proto.marketplace.LeadsCount.prototype.setContactformleads = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.AnalyticsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.AnalyticsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.AnalyticsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AnalyticsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalclicks: jspb.Message.getFieldWithDefault(msg, 1, 0),
    leads: (f = msg.getLeads()) && proto.marketplace.LeadsCount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.AnalyticsResponse}
 */
proto.marketplace.AnalyticsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.AnalyticsResponse;
  return proto.marketplace.AnalyticsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.AnalyticsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.AnalyticsResponse}
 */
proto.marketplace.AnalyticsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalclicks(value);
      break;
    case 2:
      var value = new proto.marketplace.LeadsCount;
      reader.readMessage(value,proto.marketplace.LeadsCount.deserializeBinaryFromReader);
      msg.setLeads(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.AnalyticsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.AnalyticsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.AnalyticsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.AnalyticsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalclicks();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLeads();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.marketplace.LeadsCount.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 totalClicks = 1;
 * @return {number}
 */
proto.marketplace.AnalyticsResponse.prototype.getTotalclicks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.AnalyticsResponse} returns this
 */
proto.marketplace.AnalyticsResponse.prototype.setTotalclicks = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional LeadsCount Leads = 2;
 * @return {?proto.marketplace.LeadsCount}
 */
proto.marketplace.AnalyticsResponse.prototype.getLeads = function() {
  return /** @type{?proto.marketplace.LeadsCount} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.LeadsCount, 2));
};


/**
 * @param {?proto.marketplace.LeadsCount|undefined} value
 * @return {!proto.marketplace.AnalyticsResponse} returns this
*/
proto.marketplace.AnalyticsResponse.prototype.setLeads = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.AnalyticsResponse} returns this
 */
proto.marketplace.AnalyticsResponse.prototype.clearLeads = function() {
  return this.setLeads(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.AnalyticsResponse.prototype.hasLeads = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.marketplace.Type = {
  AD_VIEW: 0,
  AD_CHAT_LEAD: 1,
  AD_SHOW_NUMBER_LEAD: 2,
  AD_WHATS_APP_LEAD: 3
};

goog.object.extend(exports, proto.marketplace);
