import { AdCurrentVersionResponse, AdResponse } from '@/api/gen-protos/ads_pb';
import { CategoryResponse } from '@/api/gen-protos/categories_pb';
import { User, UserResponse } from '@/api/gen-protos/users_pb';
import { GetUserTransactionsBalanceResponse } from '@/api/gen-protos/wallets_pb';
import { categories } from '@/lib/helpers';

import {
  IAdDetailItem,
  ICategory,
  ICategoryParamList,
  IListingItem,
  IParams,
  IPublisher,
  Itransaction,
  IUserData,
} from './types';

const modifyImageUrl = (imageUrl: string): string => {
  const modifiedUrl = imageUrl.replace(
    'https://storage.googleapis.com/tayara-migration-yams-pro',
    'https://cdn.tayara.tn'
  );
  return modifiedUrl;
};
/**
 *map Ad response data to IListingItem
 * @param AdCurrentVersionResponse AdCurrentVersionResponse
 * @returns IListingItem
 */
export const mapAdCurrentVersionResponseToIListingItem = (
  adRes: AdResponse | AdCurrentVersionResponse,
  user?: User.AsObject,
  isGold?: boolean
): IListingItem => {
  const ad = adRes?.getAd();
  const subCategory = categories.find(
    (category) => category.id === ad?.getSubcategoryid()
  );

  const shopAvatar = ad?.getCurrentversion()?.getShopavatar();
  const publisher: IPublisher = {
    avatar: shopAvatar ?? '',
    id: ad?.getUserid() ?? '',
    isShop: !!shopAvatar,
    name: user?.currentstate?.fullname,
  };
  const listingItem: IListingItem = {
    title: ad?.getCurrentversion()?.getTitle() ?? '',
    description: ad?.getCurrentversion()?.getDescription() ?? '',
    phone: ad?.getCurrentversion()?.getUserphonenumber() ?? '',
    id: adRes?.getId() ?? '',
    price: ad?.getCurrentversion()?.getPrice() ?? 0,
    images: ad?.getCurrentversion()?.getImagesList() ?? [],
    location: {
      coordinates: {
        latitude: ad
          ?.getCurrentversion()
          ?.getLocation()
          ?.getCoordinates()
          ?.getLatitude(),
        longitude: ad
          ?.getCurrentversion()
          ?.getLocation()
          ?.getCoordinates()
          ?.getLongitude(),
      },
      delegation: ad?.getCurrentversion()?.getLocation()?.getDelegation()!,
      governorate: ad?.getCurrentversion()?.getLocation()?.getGovernorate(),
    },
    metadata: {
      state: ad?.getCurrentversion()?.getState() || 1,
      // 0) PENDING_MOD,
      // 1) REJECTED,
      // 2) ACTIVE,
      // 3) EXPIRED,
      // 4) DELETED,
      // 5) SOLD,
      // 6) PENDING_MANUAL_MOD,
      // Not yet defined)PENDING_INSERTION,
      category: categories.find((g) => g.id === subCategory?.parentcategoryxid)
        ?.name,
      publishedOn: ad?.getCurrentversion()?.getTimestampsortscore()?.toDate(),
      publisher,
      subCategory: subCategory?.id,
      isFeatured: ad?.getCurrentversion()?.getLevel() === 1,
      isGold,
      meiliSynced: ad?.getSync(),
    },
  };

  return listingItem;
};

/**
 *map category response data to ICategoryParam
 * @param CategoryResponse categoryResponse
 * @returns [] ICategoryParam
 */
export const mapCategoryResponseToCategoryInterface = (
  categoryResponse: CategoryResponse
): ICategoryParamList | undefined => {
  const categoryParam: ICategoryParamList | undefined = categoryResponse
    .getCategory()
    ?.getAdparamsList()
    .map((param) => ({
      dependantname: param.getDependantname(),
      label: param.getLabel(),
      name: param.getName(),
      possiblevaluesList: param.getPossiblevaluesList(),
      single: param.getSingle(),
      type: param.getType(),
    }));

  return categoryParam;
};

/**
 *map user response data to IUserData
 * @param userResponse UserResponse
 * @returns [] IUserData
 */
export const mapUserResponseToIUserData = (
  userResponse: UserResponse.AsObject
): IUserData =>
  userResponse
    ? {
        avatar: modifyImageUrl(userResponse.user?.currentstate?.avatar || ''),
        address: userResponse.user?.currentstate?.address,
        email: userResponse.user?.currentstate?.email,
        fullname: userResponse.user?.currentstate?.fullname,
        isShop: userResponse.user?.usertype === 1,
        enabled: userResponse.user?.enabled,
        kcid: userResponse.user?.kcid,
        favoritesList: userResponse.user?.favoritesList,
        phonenumber: userResponse.user?.currentstate?.phonenumber,
        tokenbalance: userResponse.user?.currentstate?.tokenbalance,
        url: userResponse.user?.currentstate?.url,
        id: userResponse.id,
        shopBackgroundIMage: modifyImageUrl(
          userResponse.user?.shopbackgroundimage || ''
        ),

        description: userResponse.user?.currentstate?.description,

        categoryId: userResponse.user?.currentstate?.categoryid,
        blacklist: userResponse.user?.blacklistList,
      }
    : {};

/**
 *map transaction response data to ITransaction
 * @param GetUserTransactionsBalanceResponse GetUserTransactionsBalanceResponse
 * @returns [] ITransaction
 */
export const mapGetUserTransactionsBalanceResponseToItransaction = (
  getUserTransactionsBalanceResponse: GetUserTransactionsBalanceResponse
): Itransaction[] =>
  getUserTransactionsBalanceResponse
    .getTransactionsList()
    .map((transaction) => ({
      amount: transaction.getAmount(),
      createdTime: transaction.getCreatedTime(),
      creatorAccountId: transaction.getCreatorAccountId(),
      description: transaction.getDescription(),
      isVirement: ["'planche@caravel'", 'planche'].includes(
        transaction.getCreatorAccountId()
      ),
    }));

/**
 *map Ad response data to IListingItem
 * @param AdCurrentVersionResponse AdCurrentVersionResponse
 * @returns IAdDetailItem
 */
export const mapAdResponseToDetails = (
  adResponse: AdResponse
): IAdDetailItem => {
  const subCategory = categories.find(
    (category) => category.id === adResponse?.getAd()?.getSubcategoryid()
  );
  const iPublisher: IPublisher = {
    avatar: modifyImageUrl(
      adResponse?.getAd()?.getCurrentversion()?.getShopavatar() ?? ''
    ),
    id: adResponse?.getAd()?.getUserid() ?? '',
  };

  const adDetails: IAdDetailItem = {
    title: adResponse?.getAd()?.getCurrentversion()?.getTitle() ?? '',
    description:
      adResponse?.getAd()?.getCurrentversion()?.getDescription() ?? '',
    phone: adResponse?.getAd()?.getCurrentversion()?.getUserphonenumber() ?? '',
    id: adResponse?.getId() ?? '',
    price: adResponse?.getAd()?.getCurrentversion()?.getPrice() ?? 0,
    images:
      adResponse
        ?.getAd()
        ?.getCurrentversion()
        ?.getImagesList()
        .map(modifyImageUrl) ?? [],
    location: {
      coordinates: {
        latitude: adResponse
          ?.getAd()
          ?.getCurrentversion()
          ?.getLocation()
          ?.getCoordinates()
          ?.getLatitude(),
        longitude: adResponse
          ?.getAd()
          ?.getCurrentversion()
          ?.getLocation()
          ?.getCoordinates()
          ?.getLongitude(),
      },
      delegation: adResponse
        ?.getAd()
        ?.getCurrentversion()
        ?.getLocation()
        ?.getDelegation(),
      governorate: adResponse
        ?.getAd()
        ?.getCurrentversion()
        ?.getLocation()
        ?.getGovernorate(),
    },
    category:
      categories.find((g) => g.id === subCategory?.parentcategoryxid)?.name ||
      '',
    subCategoryId: subCategory?.id || '',
    publishedOn: adResponse
      ?.getAd()
      ?.getCurrentversion()
      ?.getTimestampsortscore()
      ?.toDate(),
    publisher: iPublisher,
    adParams: adResponse
      ?.getAd()
      ?.getCurrentversion()
      ?.getAdparamvaluesList()
      .map((adParamValue) => ({
        label: adParamValue.getParam()?.getName(),
        value: adParamValue.getValue(),
      })),
    sold: adResponse?.getAd()?.getCurrentversion()?.getState() === 5,
    deleted: adResponse?.getAd()?.getCurrentversion()?.getState() === 4,
    state: adResponse?.getAd()?.getCurrentversion()?.getState() || 2,
  };

  return adDetails;
};

/**
 *map CategoryResponse data to IParams
 * @param CategoryResponse CategoryResponse
 * @returns IAdDetailItem
 */
export const mapCategoryResponseToIParams = (
  categoryResponse: CategoryResponse
): IParams[] | undefined =>
  categoryResponse
    ?.getCategory()
    ?.getAdparamsList()
    .map((param) => ({
      disabled: !!param.getDependantname(),
      min: param.getRangevalues()?.getMin(),
      max: param.getRangevalues()?.getMax(),
      dependant: param.getDependantname(),
      name: param.getName(),
      type: param.getType(),
      possiblesValues: param.getPossiblevaluesList(),
      singleSelect: param.getSingle() || false,
      fixedPossiblesValues: param.getPossiblevaluesList(),
    }));

/**
 *map CategoryResponse data to IParams
 * @param AdCurrentVersionResponse AdCurrentVersionResponse
 * @returns ICategory
 */
export const mapCategoryResponseToICategory = (
  categoryResponse: CategoryResponse
): ICategory => ({
  boost: categoryResponse.getCategory()?.getProductpriceMap().get('boost'),
  boost24: categoryResponse.getCategory()?.getProductpriceMap().get('boost24'),
  boost7: categoryResponse.getCategory()?.getProductpriceMap().get('boost7'),
  featured: categoryResponse
    .getCategory()
    ?.getProductpriceMap()
    .get('featured'),
  golden: categoryResponse.getCategory()?.getProductpriceMap().get('golden'),
  name: categoryResponse.getCategory()?.getName(),
});
