import { ProductTypeEnum } from '@/modules/core/types';

export interface GovernorateHelper {
  id: string;
  name: string;
}

export interface DelegationHelper {
  id: string;
  name: string;
  governoratexid: string;
}

export interface CategoryHelper {
  id: string;
  name: string;
  link: string;
  parentcategoryxid: string;
}

const governorates: GovernorateHelper[] = [
  {
    id: '60c4e5bc9676f6559c251f06',
    name: 'Sousse',
  },
  {
    id: '60c4e5bc9676f6559c251f09',
    name: 'Bizerte',
  },
  {
    id: '60c4e5bd9676f6559c251f0c',
    name: 'Béja',
  },
  {
    id: '60c4e5be9676f6559c251f13',
    name: 'Gafsa',
  },
  {
    id: '60c4e5be9676f6559c251f18',
    name: 'Le Kef',
  },
  {
    id: '60c4e5bf9676f6559c251f1b',
    name: 'La Manouba',
  },
  {
    id: '60c4e5bf9676f6559c251f1e',
    name: 'Médenine',
  },
  {
    id: '60c4e5c09676f6559c251f21',
    name: 'Nabeul',
  },
  {
    id: '60c4e5c09676f6559c251f24',
    name: 'Siliana',
  },
  {
    id: '60c4e5c19676f6559c251f2b',
    name: 'Jendouba',
  },
  {
    id: '60c4e5c19676f6559c251f2e',
    name: 'Kairouan',
  },
  {
    id: '60c4e5c29676f6559c251f35',
    name: 'Kasserine',
  },
  {
    id: '60c4e5c39676f6559c251f3a',
    name: 'Mahdia',
  },
  {
    id: '60c4e5c49676f6559c251f3f',
    name: 'Monastir',
  },
  {
    id: '60c4e5c49676f6559c251f42',
    name: 'Sfax',
  },
  {
    id: '60c4e5c69676f6559c251f4d',
    name: 'Tataouine',
  },
  {
    id: '60c4e5c69676f6559c251f50',
    name: 'Zaghouan',
  },
  {
    id: '60c4e5c89676f6559c251f5d',
    name: 'Ariana',
  },
  {
    id: '60c4e5c99676f6559c251f62',
    name: 'Ben Arous',
  },
  {
    id: '60c4e5c99676f6559c251f65',
    name: 'Gabès',
  },
  {
    id: '60c4e5ce9676f6559c251f82',
    name: 'Kébili',
  },
  {
    id: '60c4e5d19676f6559c251f97',
    name: 'Tozeur',
  },
  {
    id: '60c4e5d19676f6559c251f9a',
    name: 'Tunis',
  },
  {
    id: '60c4e5dc9676f6559c251fe3',
    name: 'Sidi Bouzid',
  },
];

const delegations: DelegationHelper[] = [
  {
    id: '60c4e5bc9676f6559c251f07',
    name: 'Autres Villes',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e5bc9676f6559c251f0a',
    name: 'Autres Villes',
    governoratexid: '60c4e5bc9676f6559c251f09',
  },
  {
    id: '60c4e5bd9676f6559c251f0d',
    name: 'Amdoun',
    governoratexid: '60c4e5bd9676f6559c251f0c',
  },
  {
    id: '60c4e5bd9676f6559c251f0f',
    name: 'Mateur',
    governoratexid: '60c4e5bc9676f6559c251f09',
  },
  {
    id: '60c4e5bd9676f6559c251f11',
    name: 'El Ksar',
    governoratexid: '60c4e5bd9676f6559c251f0c',
  },
  {
    id: '60c4e5be9676f6559c251f14',
    name: 'Gafsa Nord',
    governoratexid: '60c4e5be9676f6559c251f13',
  },
  {
    id: '60c4e5be9676f6559c251f16',
    name: 'Gafsa Sud',
    governoratexid: '60c4e5be9676f6559c251f13',
  },
  {
    id: '60c4e5bf9676f6559c251f19',
    name: 'Nebeur',
    governoratexid: '60c4e5be9676f6559c251f18',
  },
  {
    id: '60c4e5bf9676f6559c251f1c',
    name: 'Douar Hicher',
    governoratexid: '60c4e5bf9676f6559c251f1b',
  },
  {
    id: '60c4e5bf9676f6559c251f1f',
    name: 'Ben Gardane',
    governoratexid: '60c4e5bf9676f6559c251f1e',
  },
  {
    id: '60c4e5c09676f6559c251f22',
    name: 'Bou Argoub',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e5c09676f6559c251f25',
    name: 'Bou Arada',
    governoratexid: '60c4e5c09676f6559c251f24',
  },
  {
    id: '60c4e5c09676f6559c251f27',
    name: 'Kesra',
    governoratexid: '60c4e5c09676f6559c251f24',
  },
  {
    id: '60c4e5c19676f6559c251f29',
    name: 'Thibar',
    governoratexid: '60c4e5bd9676f6559c251f0c',
  },
  {
    id: '60c4e5c19676f6559c251f2c',
    name: 'Ghardimaou',
    governoratexid: '60c4e5c19676f6559c251f2b',
  },
  {
    id: '60c4e5c19676f6559c251f2f',
    name: 'Chebika',
    governoratexid: '60c4e5c19676f6559c251f2e',
  },
  {
    id: '60c4e5c29676f6559c251f31',
    name: 'Hajeb El Ayoun',
    governoratexid: '60c4e5c19676f6559c251f2e',
  },
  {
    id: '60c4e5c29676f6559c251f33',
    name: 'Kairouan Nord',
    governoratexid: '60c4e5c19676f6559c251f2e',
  },
  {
    id: '60c4e5c39676f6559c251f36',
    name: 'Sbiba',
    governoratexid: '60c4e5c29676f6559c251f35',
  },
  {
    id: '60c4e5c39676f6559c251f38',
    name: 'Sakiet Sidi Youssef',
    governoratexid: '60c4e5be9676f6559c251f18',
  },
  {
    id: '60c4e5c39676f6559c251f3b',
    name: 'Melloulèche',
    governoratexid: '60c4e5c39676f6559c251f3a',
  },
  {
    id: '60c4e5c49676f6559c251f3d',
    name: 'Djerba Ajim',
    governoratexid: '60c4e5bf9676f6559c251f1e',
  },
  {
    id: '60c4e5c49676f6559c251f40',
    name: 'Zéramdine',
    governoratexid: '60c4e5c49676f6559c251f3f',
  },
  {
    id: '60c4e5c49676f6559c251f43',
    name: 'Ghraiba',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e5c59676f6559c251f45',
    name: 'Sakiet Ezzit',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e5c59676f6559c251f47',
    name: 'Gaâfour',
    governoratexid: '60c4e5c09676f6559c251f24',
  },
  {
    id: '60c4e5c59676f6559c251f49',
    name: 'Siliana Sud',
    governoratexid: '60c4e5c09676f6559c251f24',
  },
  {
    id: '60c4e5c69676f6559c251f4b',
    name: 'Zaouit Ksibat Thrayett',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e5c69676f6559c251f4e',
    name: 'Dehiba',
    governoratexid: '60c4e5c69676f6559c251f4d',
  },
  {
    id: '60c4e5c69676f6559c251f51',
    name: 'Ez Zeriba',
    governoratexid: '60c4e5c69676f6559c251f50',
  },
  {
    id: '60c4e5c79676f6559c251f53',
    name: 'Autres Villes',
    governoratexid: '60c4e5bd9676f6559c251f0c',
  },
  {
    id: '60c4e5c79676f6559c251f55',
    name: 'Autres Villes',
    governoratexid: '60c4e5be9676f6559c251f13',
  },
  {
    id: '60c4e5c89676f6559c251f5b',
    name: 'Autres Villes',
    governoratexid: '60c4e5c69676f6559c251f4d',
  },
  {
    id: '60c4e5c89676f6559c251f5e',
    name: 'Sidi Thabet',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '60c4e5c99676f6559c251f60',
    name: 'Béja Sud',
    governoratexid: '60c4e5bd9676f6559c251f0c',
  },
  {
    id: '60c4e5c99676f6559c251f63',
    name: 'Ezzahra',
    governoratexid: '60c4e5c99676f6559c251f62',
  },
  {
    id: '60c4e5c99676f6559c251f66',
    name: 'Ghanouch',
    governoratexid: '60c4e5c99676f6559c251f65',
  },
  {
    id: '60c4e5ca9676f6559c251f68',
    name: 'Es Sers',
    governoratexid: '60c4e5be9676f6559c251f18',
  },
  {
    id: '60c4e5ca9676f6559c251f6a',
    name: 'Ksour Essef',
    governoratexid: '60c4e5c39676f6559c251f3a',
  },
  {
    id: '60c4e5ca9676f6559c251f6c',
    name: 'Sidi Alouane',
    governoratexid: '60c4e5c39676f6559c251f3a',
  },
  {
    id: '60c4e5cb9676f6559c251f6e',
    name: 'Oued Ellil',
    governoratexid: '60c4e5bf9676f6559c251f1b',
  },
  {
    id: '60c4e5cb9676f6559c251f70',
    name: 'Grombalia',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e5cb9676f6559c251f72',
    name: 'Hammamet Nord',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '6748440f3d75428653023794',
    name: 'Yasmine Hammamet',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e5cc9676f6559c251f74',
    name: 'Mahrès',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e5cc9676f6559c251f76',
    name: 'Bargou',
    governoratexid: '60c4e5c09676f6559c251f24',
  },
  {
    id: '60c4e5cc9676f6559c251f78',
    name: 'Akouda',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e5cc9676f6559c251f7a',
    name: 'Kondar',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e5cd9676f6559c251f7c',
    name: 'Smâr',
    governoratexid: '60c4e5c69676f6559c251f4d',
  },
  {
    id: '60c4e5cd9676f6559c251f7e',
    name: 'Tataouine Sud',
    governoratexid: '60c4e5c69676f6559c251f4d',
  },
  {
    id: '60c4e5ce9676f6559c251f83',
    name: 'Autres Villes',
    governoratexid: '60c4e5ce9676f6559c251f82',
  },
  {
    id: '60c4e5ce9676f6559c251f85',
    name: 'Boumhel',
    governoratexid: '60c4e5c99676f6559c251f62',
  },
  {
    id: '60c4e5ce9676f6559c251f87',
    name: 'Hammam Lif',
    governoratexid: '60c4e5c99676f6559c251f62',
  },
  {
    id: '60c4e5cf9676f6559c251f89',
    name: 'Gabès Ouest',
    governoratexid: '60c4e5c99676f6559c251f65',
  },
  {
    id: '60c4e5cf9676f6559c251f8b',
    name: 'Balta Bou Aouane',
    governoratexid: '60c4e5c19676f6559c251f2b',
  },
  {
    id: '60c4e5cf9676f6559c251f8d',
    name: 'Ouled Chamekh',
    governoratexid: '60c4e5c39676f6559c251f3a',
  },
  {
    id: '60c4e5d09676f6559c251f8f',
    name: 'Ksar Hellal',
    governoratexid: '60c4e5c49676f6559c251f3f',
  },
  {
    id: '60c4e5d09676f6559c251f91',
    name: 'Sahline',
    governoratexid: '60c4e5c49676f6559c251f3f',
  },
  {
    id: '60c4e5d09676f6559c251f93',
    name: 'Soliman',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e5d09676f6559c251f95',
    name: 'Rouhia',
    governoratexid: '60c4e5c09676f6559c251f24',
  },
  {
    id: '60c4e5d19676f6559c251f98',
    name: 'Degache',
    governoratexid: '60c4e5d19676f6559c251f97',
  },
  {
    id: '60c4e5d19676f6559c251f9b',
    name: 'El Omrane',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e5d29676f6559c251f9d',
    name: 'Sidi Daoud',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e5d29676f6559c251f9f',
    name: 'Saouaf',
    governoratexid: '60c4e5c69676f6559c251f50',
  },
  {
    id: '60c4e5d29676f6559c251fa3',
    name: 'Ben Arous',
    governoratexid: '60c4e5c99676f6559c251f62',
  },
  {
    id: '60c4e5d39676f6559c251fa7',
    name: 'Goubellat',
    governoratexid: '60c4e5bd9676f6559c251f0c',
  },
  {
    id: '60c4e5d39676f6559c251fa9',
    name: 'Medjez El Bab',
    governoratexid: '60c4e5bd9676f6559c251f0c',
  },
  {
    id: '60c4e5d49676f6559c251fab',
    name: 'Medina Jedida',
    governoratexid: '60c4e5c99676f6559c251f62',
  },
  {
    id: '60c4e5d49676f6559c251fad',
    name: 'Utique',
    governoratexid: '60c4e5bc9676f6559c251f09',
  },
  {
    id: '60c4e5d49676f6559c251faf',
    name: 'Mdhila',
    governoratexid: '60c4e5be9676f6559c251f13',
  },
  {
    id: '60c4e5d59676f6559c251fb1',
    name: 'Ain Draham',
    governoratexid: '60c4e5c19676f6559c251f2b',
  },
  {
    id: '60c4e5d59676f6559c251fb3',
    name: 'Echrarda',
    governoratexid: '60c4e5c19676f6559c251f2e',
  },
  {
    id: '60c4e5d59676f6559c251fb5',
    name: 'Djedeliane',
    governoratexid: '60c4e5c29676f6559c251f35',
  },
  {
    id: '60c4e5d59676f6559c251fb7',
    name: 'Foussana',
    governoratexid: '60c4e5c29676f6559c251f35',
  },
  {
    id: '60c4e5d69676f6559c251fb9',
    name: 'Kébili Nord',
    governoratexid: '60c4e5ce9676f6559c251f82',
  },
  {
    id: '60c4e5d69676f6559c251fbb',
    name: 'Dahmani',
    governoratexid: '60c4e5be9676f6559c251f18',
  },
  {
    id: '60c4e5d69676f6559c251fbd',
    name: 'Kélibia',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e5d79676f6559c251fbf',
    name: 'Menzel Bouzelfa',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e5d79676f6559c251fc1',
    name: 'El Aroussa',
    governoratexid: '60c4e5c09676f6559c251f24',
  },
  {
    id: '60c4e5d79676f6559c251fc3',
    name: 'Hraïria',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e5d89676f6559c251fc5',
    name: 'Sidi El Béchir',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e5d89676f6559c251fc7',
    name: 'Sidi Bou Said',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e5d89676f6559c251fc9',
    name: 'Ghazela',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '60c4e5d89676f6559c251fcb',
    name: 'Béja Nord',
    governoratexid: '60c4e5bd9676f6559c251f0c',
  },
  {
    id: '60c4e5d99676f6559c251fcd',
    name: 'Bizerte Nord',
    governoratexid: '60c4e5bc9676f6559c251f09',
  },
  {
    id: '60c4e5d99676f6559c251fcf',
    name: 'Menzel Jemil',
    governoratexid: '60c4e5bc9676f6559c251f09',
  },
  {
    id: '60c4e5d99676f6559c251fd1',
    name: 'Menzel El Habib',
    governoratexid: '60c4e5bf9676f6559c251f1b',
  },
  {
    id: '60c4e5da9676f6559c251fd3',
    name: 'Bou Salem',
    governoratexid: '60c4e5c19676f6559c251f2b',
  },
  {
    id: '60c4e5db9676f6559c251fd7',
    name: 'Hidra',
    governoratexid: '60c4e5c29676f6559c251f35',
  },
  {
    id: '60c4e5db9676f6559c251fd9',
    name: 'Médenine Nord',
    governoratexid: '60c4e5bf9676f6559c251f1e',
  },
  {
    id: '66e0525bcabba9838c7bf20f',
    name: 'Boughrara',
    governoratexid: '60c4e5bf9676f6559c251f1e',
  },
  {
    id: '60c4e5db9676f6559c251fdb',
    name: 'Bekalta',
    governoratexid: '60c4e5c49676f6559c251f3f',
  },
  {
    id: '60c4e5db9676f6559c251fdd',
    name: 'Ksibet El Médiouni',
    governoratexid: '60c4e5c49676f6559c251f3f',
  },

  {
    id: '60c4e5dc9676f6559c251fe1',
    name: 'Route Tunis',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e5dc9676f6559c251fe4',
    name: 'Regueb',
    governoratexid: '60c4e5dc9676f6559c251fe3',
  },
  {
    id: '60c4e5dd9676f6559c251fe6',
    name: 'Kalaâ Sghira',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e5dd9676f6559c251fe8',
    name: 'M Saken',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e5dd9676f6559c251fea',
    name: 'Sidi Bou Ali',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e5de9676f6559c251fec',
    name: 'Carthage',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e5de9676f6559c251fee',
    name: 'Sidi Hassine',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e5de9676f6559c251ff0',
    name: 'Centre Urbain Nord',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e5df9676f6559c251ff4',
    name: 'Autres Villes',
    governoratexid: '60c4e5c49676f6559c251f3f',
  },
  {
    id: '60c4e5df9676f6559c251ff6',
    name: 'Métlaoui',
    governoratexid: '60c4e5be9676f6559c251f13',
  },
  {
    id: '60c4e5df9676f6559c251ff8',
    name: 'Hassi Ferid',
    governoratexid: '60c4e5c29676f6559c251f35',
  },
  {
    id: '60c4e5e09676f6559c251ffa',
    name: 'Faouar',
    governoratexid: '60c4e5ce9676f6559c251f82',
  },
  {
    id: '60c4e5e09676f6559c251ffc',
    name: 'Kalâat Khasbah',
    governoratexid: '60c4e5be9676f6559c251f18',
  },
  {
    id: '60c4e5e09676f6559c251ffe',
    name: 'Djedeida',
    governoratexid: '60c4e5bf9676f6559c251f1b',
  },
  {
    id: '60c4e5e19676f6559c252000',
    name: 'El Battan',
    governoratexid: '60c4e5bf9676f6559c251f1b',
  },
  {
    id: '60c4e5e19676f6559c252002',
    name: 'Manouba Ville',
    governoratexid: '60c4e5bf9676f6559c251f1b',
  },
  {
    id: '60c4e5e19676f6559c252004',
    name: 'El Amra',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e5e29676f6559c252006',
    name: 'Sfax Médina',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e5e29676f6559c252008',
    name: 'Souk Jedid',
    governoratexid: '60c4e5dc9676f6559c251fe3',
  },
  {
    id: '60c4e5e29676f6559c25200a',
    name: 'Sousse Médina',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e5e39676f6559c25200c',
    name: 'Sousse Riadh',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e5e39676f6559c25200e',
    name: 'Hazoua',
    governoratexid: '60c4e5d19676f6559c251f97',
  },
  {
    id: '60c4e5e39676f6559c252010',
    name: 'Ezzouhour',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e5e39676f6559c252012',
    name: 'Le Kram',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e5e49676f6559c252018',
    name: 'Autres Villes',
    governoratexid: '60c4e5c69676f6559c251f50',
  },
  {
    id: '60c4e5e59676f6559c25201c',
    name: 'Borj Louzir',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '60c4e5e59676f6559c25201e',
    name: 'Zarzouna',
    governoratexid: '60c4e5bc9676f6559c251f09',
  },
  {
    id: '60c4e5e69676f6559c252020',
    name: 'El Guettar',
    governoratexid: '60c4e5be9676f6559c251f13',
  },
  {
    id: '60c4e5e69676f6559c252022',
    name: 'Sened',
    governoratexid: '60c4e5be9676f6559c251f13',
  },
  {
    id: '60c4e5e69676f6559c252024',
    name: 'Tabarka',
    governoratexid: '60c4e5c19676f6559c251f2b',
  },
  {
    id: '60c4e5e69676f6559c252026',
    name: 'El Ouslatia',
    governoratexid: '60c4e5c19676f6559c251f2e',
  },
  {
    id: '60c4e5e79676f6559c252028',
    name: 'Kairouan Sud',
    governoratexid: '60c4e5c19676f6559c251f2e',
  },
  {
    id: '60c4e5e79676f6559c25202a',
    name: 'Kasserine Nord',
    governoratexid: '60c4e5c29676f6559c251f35',
  },
  {
    id: '60c4e5e79676f6559c25202c',
    name: 'Sbeïtla',
    governoratexid: '60c4e5c29676f6559c251f35',
  },
  {
    id: '60c4e5e89676f6559c25202e',
    name: 'Chebba',
    governoratexid: '60c4e5c39676f6559c251f3a',
  },
  {
    id: '60c4e5e89676f6559c252030',
    name: 'El Jem',
    governoratexid: '60c4e5c39676f6559c251f3a',
  },
  {
    id: '60c4e5e89676f6559c252032',
    name: 'Essouassi',
    governoratexid: '60c4e5c39676f6559c251f3a',
  },
  {
    id: '60c4e5e99676f6559c252034',
    name: 'Sidi Makhloulf',
    governoratexid: '60c4e5bf9676f6559c251f1e',
  },
  {
    id: '60c4e5ea9676f6559c252036',
    name: 'Monastir',
    governoratexid: '60c4e5c49676f6559c251f3f',
  },
  {
    id: '60c4e5ea9676f6559c252038',
    name: 'Ouerdanine',
    governoratexid: '60c4e5c49676f6559c251f3f',
  },
  {
    id: '60c4e5eb9676f6559c25203a',
    name: 'El Haouaria',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e5eb9676f6559c25203c',
    name: 'El Mida',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e5ec9676f6559c25203e',
    name: 'Meknassy',
    governoratexid: '60c4e5dc9676f6559c251fe3',
  },
  {
    id: '60c4e5ec9676f6559c252040',
    name: 'Siliana Nord',
    governoratexid: '60c4e5c09676f6559c251f24',
  },
  {
    id: '60c4e5ec9676f6559c252042',
    name: 'Tozeur',
    governoratexid: '60c4e5d19676f6559c251f97',
  },
  {
    id: '60c4e5ec9676f6559c252044',
    name: 'Djebel Jelloud',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e5ed9676f6559c252046',
    name: 'El Omrane Supérieur',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e5ed9676f6559c252048',
    name: 'Cité Olympique',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e5ed9676f6559c25204a',
    name: 'Médenine',
    governoratexid: '60c4e5bf9676f6559c251f1e',
  },
  {
    id: '60c4e5ee9676f6559c25204c',
    name: 'Raoued',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '60c4e5ee9676f6559c25204e',
    name: 'Hammam Chott',
    governoratexid: '60c4e5c99676f6559c251f62',
  },
  {
    id: '60c4e5ee9676f6559c252050',
    name: 'Ghar El Melh',
    governoratexid: '60c4e5bc9676f6559c251f09',
  },
  {
    id: '60c4e5ef9676f6559c252052',
    name: 'Gabès Médina',
    governoratexid: '60c4e5c99676f6559c251f65',
  },
  {
    id: '60c4e5ef9676f6559c252054',
    name: 'Jendouba Nord',
    governoratexid: '60c4e5c19676f6559c251f2b',
  },
  {
    id: '60c4e5ef9676f6559c252056',
    name: 'El Alâa',
    governoratexid: '60c4e5c19676f6559c251f2e',
  },
  {
    id: '60c4e5ef9676f6559c252058',
    name: 'Hebira',
    governoratexid: '60c4e5c39676f6559c251f3a',
  },
  {
    id: '60c4e5f09676f6559c25205a',
    name: 'Tebourba',
    governoratexid: '60c4e5bf9676f6559c251f1b',
  },
  {
    id: '60c4e5f09676f6559c25205c',
    name: 'Jemmal',
    governoratexid: '60c4e5c49676f6559c251f3f',
  },
  {
    id: '60c4e5f09676f6559c25205e',
    name: 'Moknine',
    governoratexid: '60c4e5c49676f6559c251f3f',
  },
  {
    id: '60c4e5f19676f6559c252060',
    name: 'Sakiet Eddaïer',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e5f19676f6559c252062',
    name: 'Sfax Ville',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e5f19676f6559c252064',
    name: 'Route Menzel Chaker',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e5f29676f6559c252066',
    name: 'Sidi Bouzid Ouest',
    governoratexid: '60c4e5dc9676f6559c251fe3',
  },
  {
    id: '60c4e5f29676f6559c252068',
    name: 'Bouficha',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e5f29676f6559c25206a',
    name: 'Hammam Sousse',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '65afa3746efb1b7aa55c96cd',
    name: 'Sousse corniche',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e5f29676f6559c25206c',
    name: 'Bir Lahmar',
    governoratexid: '60c4e5c69676f6559c251f4d',
  },
  {
    id: '60c4e5f39676f6559c25206e',
    name: 'Le Bardo',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e5f39676f6559c252070',
    name: 'Séjoumi',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e5f39676f6559c252072',
    name: 'Centre Ville Lafayette',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e5f49676f6559c252074',
    name: 'Bir Mchergua',
    governoratexid: '60c4e5c69676f6559c251f50',
  },
  {
    id: '60c4e5f49676f6559c252076',
    name: 'Autres Villes',
    governoratexid: '60c4e5d19676f6559c251f97',
  },
  {
    id: '60c4e5f59676f6559c25207a',
    name: 'La Soukra',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '60c4e5f59676f6559c25207c',
    name: 'Testour',
    governoratexid: '60c4e5bd9676f6559c251f0c',
  },
  {
    id: '60c4e5f59676f6559c25207e',
    name: 'Mohamedia',
    governoratexid: '60c4e5c99676f6559c251f62',
  },
  {
    id: '60c4e5f59676f6559c252080',
    name: 'Mareth',
    governoratexid: '60c4e5c99676f6559c251f65',
  },
  {
    id: '60c4e5f69676f6559c252082',
    name: 'Redeyef',
    governoratexid: '60c4e5be9676f6559c251f13',
  },
  {
    id: '60c4e5f69676f6559c252084',
    name: 'Fernana',
    governoratexid: '60c4e5c19676f6559c251f2b',
  },
  {
    id: '60c4e5f69676f6559c252086',
    name: 'Haffouz',
    governoratexid: '60c4e5c19676f6559c251f2e',
  },
  {
    id: '60c4e5f79676f6559c252088',
    name: 'Sbikha',
    governoratexid: '60c4e5c19676f6559c251f2e',
  },
  {
    id: '60c4e5f79676f6559c25208a',
    name: 'El Ksour',
    governoratexid: '60c4e5be9676f6559c251f18',
  },
  {
    id: '60c4e5f79676f6559c25208c',
    name: 'Zarzis',
    governoratexid: '60c4e5bf9676f6559c251f1e',
  },
  {
    id: '60c4e5f89676f6559c25208e',
    name: 'Bembla',
    governoratexid: '60c4e5c49676f6559c251f3f',
  },
  {
    id: '60c4e5f89676f6559c252090',
    name: 'Béni Khalled',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e5f89676f6559c252092',
    name: 'Menzel Temime',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e5f99676f6559c252094',
    name: 'Skhira',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e5f99676f6559c252096',
    name: 'Ouled Haffouz',
    governoratexid: '60c4e5dc9676f6559c251fe3',
  },
  {
    id: '60c4e5f99676f6559c252098',
    name: 'Ettahrir',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e5f99676f6559c25209a',
    name: 'La Goulette',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e5fa9676f6559c25209c',
    name: 'La Marsa',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e5fa9676f6559c25209e',
    name: 'En Nadhour',
    governoratexid: '60c4e5c69676f6559c251f50',
  },
  {
    id: '60c4e5fb9676f6559c2520a2',
    name: 'Autres Villes',
    governoratexid: '60c4e5c99676f6559c251f65',
  },
  {
    id: '60c4e5fb9676f6559c2520a4',
    name: 'Autres Villes',
    governoratexid: '60c4e5c39676f6559c251f3a',
  },
  {
    id: '60c4e5fb9676f6559c2520a6',
    name: 'Autres Villes',
    governoratexid: '60c4e5c29676f6559c251f35',
  },
  {
    id: '60c4e5fb9676f6559c2520a8',
    name: 'Autres Villes',
    governoratexid: '60c4e5bf9676f6559c251f1e',
  },
  {
    id: '60c4e5fc9676f6559c2520aa',
    name: 'Ariana Ville',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '60c4e5fc9676f6559c2520ac',
    name: 'Mégrine',
    governoratexid: '60c4e5c99676f6559c251f62',
  },
  {
    id: '60c4e5fc9676f6559c2520ae',
    name: 'Mornag',
    governoratexid: '60c4e5c99676f6559c251f62',
  },
  {
    id: '60c4e5fd9676f6559c2520b0',
    name: 'Djoumime',
    governoratexid: '60c4e5bc9676f6559c251f09',
  },
  {
    id: '60c4e5fd9676f6559c2520b2',
    name: 'Sejenane',
    governoratexid: '60c4e5bc9676f6559c251f09',
  },
  {
    id: '60c4e5fe9676f6559c2520b4',
    name: 'Métouia',
    governoratexid: '60c4e5c99676f6559c251f65',
  },
  {
    id: '60c4e5fe9676f6559c2520b6',
    name: 'Fériana',
    governoratexid: '60c4e5c29676f6559c251f35',
  },
  {
    id: '60c4e5ff9676f6559c2520b8',
    name: 'Douz Nord',
    governoratexid: '60c4e5ce9676f6559c251f82',
  },
  {
    id: '60c4e5ff9676f6559c2520ba',
    name: 'Bou Merdès',
    governoratexid: '60c4e5c39676f6559c251f3a',
  },
  {
    id: '60c4e5ff9676f6559c2520bc',
    name: 'Denden',
    governoratexid: '60c4e5bf9676f6559c251f1b',
  },
  {
    id: '60c4e6009676f6559c2520be',
    name: 'Beni Khedech',
    governoratexid: '60c4e5bf9676f6559c251f1e',
  },
  {
    id: '60c4e6009676f6559c2520c0',
    name: 'Téboulba',
    governoratexid: '60c4e5c49676f6559c251f3f',
  },
  {
    id: '60c4e6009676f6559c2520c2',
    name: 'Route El Afrane',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e6019676f6559c2520c4',
    name: 'Cebbala Ouled Asker',
    governoratexid: '60c4e5dc9676f6559c251fe3',
  },
  {
    id: '60c4e6019676f6559c2520c6',
    name: 'Sidi Ali Ben Aoun',
    governoratexid: '60c4e5dc9676f6559c251fe3',
  },
  {
    id: '60c4e6019676f6559c2520c8',
    name: 'El Krib',
    governoratexid: '60c4e5c09676f6559c251f24',
  },
  {
    id: '60c4e6019676f6559c2520ca',
    name: 'Enfidha',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e6029676f6559c2520cc',
    name: 'Nefta',
    governoratexid: '60c4e5d19676f6559c251f97',
  },
  {
    id: '60c4e6029676f6559c2520ce',
    name: 'Tameghza',
    governoratexid: '60c4e5d19676f6559c251f97',
  },
  {
    id: '60c4e6029676f6559c2520d0',
    name: 'Cité El Khadra',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e6039676f6559c2520d2',
    name: 'Autres Villes',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e6039676f6559c2520d4',
    name: 'Siliana',
    governoratexid: '60c4e5c09676f6559c251f24',
  },
  {
    id: '60c4e6039676f6559c2520d6',
    name: 'Tataouine',
    governoratexid: '60c4e5c69676f6559c251f4d',
  },
  {
    id: '60c4e6049676f6559c2520d8',
    name: 'Autres Villes',
    governoratexid: '60c4e5bf9676f6559c251f1b',
  },
  {
    id: '60c4e6049676f6559c2520da',
    name: 'Manar',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e6049676f6559c2520dc',
    name: 'Nefza',
    governoratexid: '60c4e5bd9676f6559c251f0c',
  },
  {
    id: '60c4e6059676f6559c2520de',
    name: 'Tinja',
    governoratexid: '60c4e5bc9676f6559c251f09',
  },
  {
    id: '60c4e6059676f6559c2520e0',
    name: 'Oued Meliz',
    governoratexid: '60c4e5c19676f6559c251f2b',
  },
  {
    id: '60c4e6059676f6559c2520e2',
    name: 'Nasrallah',
    governoratexid: '60c4e5c19676f6559c251f2e',
  },
  {
    id: '60c4e6059676f6559c2520e4',
    name: 'El Ayoun',
    governoratexid: '60c4e5c29676f6559c251f35',
  },
  {
    id: '60c4e6069676f6559c2520e6',
    name: 'Majel Bel Abbès',
    governoratexid: '60c4e5c29676f6559c251f35',
  },
  {
    id: '60c4e6069676f6559c2520e8',
    name: 'Thala',
    governoratexid: '60c4e5c29676f6559c251f35',
  },
  {
    id: '60c4e6069676f6559c2520ea',
    name: 'Souk Lahad',
    governoratexid: '60c4e5ce9676f6559c251f82',
  },
  {
    id: '60c4e6079676f6559c2520ec',
    name: 'Djerissa',
    governoratexid: '60c4e5be9676f6559c251f18',
  },
  {
    id: '60c4e6079676f6559c2520ee',
    name: 'Kef Ouest',
    governoratexid: '60c4e5be9676f6559c251f18',
  },
  {
    id: '60c4e6079676f6559c2520f0',
    name: 'Chorbane',
    governoratexid: '60c4e5c39676f6559c251f3a',
  },
  {
    id: '60c4e6089676f6559c2520f2',
    name: 'Djerba Houmt Souk',
    governoratexid: '60c4e5bf9676f6559c251f1e',
  },
  {
    id: '60c4e6089676f6559c2520f4',
    name: 'Djerba Midoun',
    governoratexid: '60c4e5bf9676f6559c251f1e',
  },
  {
    id: '60c4e6089676f6559c2520f6',
    name: 'Médenine Sud',
    governoratexid: '60c4e5bf9676f6559c251f1e',
  },
  {
    id: '60c4e6099676f6559c2520f8',
    name: 'Dar Châabane El Fehri',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e6099676f6559c2520fa',
    name: 'Menzel Bouzaiane',
    governoratexid: '60c4e5dc9676f6559c251fe3',
  },
  {
    id: '60c4e6099676f6559c2520fc',
    name: 'Ghomrassen',
    governoratexid: '60c4e5c69676f6559c251f4d',
  },
  {
    id: '60c4e6099676f6559c2520fe',
    name: 'Agba',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e60a9676f6559c252100',
    name: 'Mutuelleville',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e60a9676f6559c252102',
    name: 'Autres Villes',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e60a9676f6559c252104',
    name: 'Tunis',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e60b9676f6559c252106',
    name: 'Tozeur',
    governoratexid: '60c4e5d19676f6559c251f97',
  },
  {
    id: '60c4e60b9676f6559c252108',
    name: 'Kébili',
    governoratexid: '60c4e5ce9676f6559c251f82',
  },
  {
    id: '60c4e60b9676f6559c25210a',
    name: 'Autres Villes',
    governoratexid: '60c4e5c09676f6559c251f24',
  },
  {
    id: '60c4e60c9676f6559c25210c',
    name: 'Autres Villes',
    governoratexid: '60c4e5c99676f6559c251f62',
  },
  {
    id: '60c4e60c9676f6559c25210e',
    name: 'Jardins El Menzah',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '60c4e60c9676f6559c252110',
    name: 'Radès',
    governoratexid: '60c4e5c99676f6559c251f62',
  },
  {
    id: '60c4e60c9676f6559c252112',
    name: 'Menzel Bourguiba',
    governoratexid: '60c4e5bc9676f6559c251f09',
  },
  {
    id: '60c4e60d9676f6559c252114',
    name: 'Gabès Sud',
    governoratexid: '60c4e5c99676f6559c251f65',
  },
  {
    id: '60c4e60d9676f6559c252116',
    name: 'Matmata',
    governoratexid: '60c4e5c99676f6559c251f65',
  },
  {
    id: '60c4e60d9676f6559c252118',
    name: 'Nouvelle Matmata',
    governoratexid: '60c4e5c99676f6559c251f65',
  },
  {
    id: '60c4e60e9676f6559c25211a',
    name: 'Belkhir',
    governoratexid: '60c4e5be9676f6559c251f13',
  },
  {
    id: '60c4e60e9676f6559c25211c',
    name: 'Kasserine Sud',
    governoratexid: '60c4e5c29676f6559c251f35',
  },
  {
    id: '60c4e60e9676f6559c25211e',
    name: 'Kalâat Snan',
    governoratexid: '60c4e5be9676f6559c251f18',
  },
  {
    id: '60c4e60f9676f6559c252120',
    name: 'Tajerouine',
    governoratexid: '60c4e5be9676f6559c251f18',
  },
  {
    id: '60c4e60f9676f6559c252122',
    name: 'Béni Khiar',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e60f9676f6559c252124',
    name: 'Hammamet',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e6109676f6559c252126',
    name: 'Korba',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e6109676f6559c252128',
    name: 'Takelsa',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e6109676f6559c25212a',
    name: 'Menzel Chaker',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e6109676f6559c25212c',
    name: 'Route Mehdia',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e6119676f6559c25212e',
    name: 'Sidi Bouzid Est',
    governoratexid: '60c4e5dc9676f6559c251fe3',
  },
  {
    id: '60c4e6119676f6559c252130',
    name: 'Hergla',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e6119676f6559c252132',
    name: 'El Kabaria',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e6129676f6559c252134',
    name: 'Médina',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e6129676f6559c252136',
    name: 'L Aouina',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e6129676f6559c252138',
    name: 'El Fahs',
    governoratexid: '60c4e5c69676f6559c251f50',
  },
  {
    id: '60c4e6139676f6559c25213a',
    name: 'Autres Villes',
    governoratexid: '60c4e5be9676f6559c251f18',
  },
  {
    id: '60c4e6139676f6559c25213c',
    name: 'Autres Villes',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e6139676f6559c25213e',
    name: 'Autres Villes',
    governoratexid: '60c4e5c19676f6559c251f2e',
  },
  {
    id: '60c4e6149676f6559c252140',
    name: 'Chotrana',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '60c4e6149676f6559c252142',
    name: 'El Mourouj',
    governoratexid: '60c4e5c99676f6559c251f62',
  },
  {
    id: '60c4e6149676f6559c252144',
    name: 'Bizerte Sud',
    governoratexid: '60c4e5bc9676f6559c251f09',
  },
  {
    id: '60c4e6149676f6559c252146',
    name: 'Ras Jebel',
    governoratexid: '60c4e5bc9676f6559c251f09',
  },
  {
    id: '60c4e6159676f6559c252148',
    name: 'Douz Sud',
    governoratexid: '60c4e5ce9676f6559c251f82',
  },
  {
    id: '60c4e6159676f6559c25214a',
    name: 'Kef Est',
    governoratexid: '60c4e5be9676f6559c251f18',
  },
  {
    id: '60c4e6159676f6559c25214c',
    name: 'Nabeul',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e6169676f6559c25214e',
    name: 'Jilma',
    governoratexid: '60c4e5dc9676f6559c251fe3',
  },
  {
    id: '60c4e6169676f6559c252150',
    name: 'Sidi Bou Rouis',
    governoratexid: '60c4e5c09676f6559c251f24',
  },
  {
    id: '60c4e6169676f6559c252152',
    name: 'Kalaâ Kebira',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e6169676f6559c252154',
    name: 'Sidi El Héni',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e6179676f6559c252156',
    name: 'Sousse Jawhara',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e6179676f6559c252158',
    name: 'Remada',
    governoratexid: '60c4e5c69676f6559c251f4d',
  },
  {
    id: '60c4e6179676f6559c25215a',
    name: 'Tataouine Nord',
    governoratexid: '60c4e5c69676f6559c251f4d',
  },
  {
    id: '60c4e6189676f6559c25215c',
    name: 'Gabès',
    governoratexid: '60c4e5c99676f6559c251f65',
  },
  {
    id: '60c4e6189676f6559c25215e',
    name: 'Bizerte',
    governoratexid: '60c4e5bc9676f6559c251f09',
  },
  {
    id: '60c4e6189676f6559c252160',
    name: 'Ettadhamen',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '60c4e6199676f6559c252162',
    name: 'Mnihla',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '60c4e6199676f6559c252164',
    name: 'Ennasr',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '60c4e6199676f6559c252166',
    name: 'Menzah',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '60c4e6199676f6559c252168',
    name: 'El Hamma',
    governoratexid: '60c4e5c99676f6559c251f65',
  },
  {
    id: '60c4e61a9676f6559c25216a',
    name: 'Bouhajla',
    governoratexid: '60c4e5c19676f6559c251f2e',
  },
  {
    id: '60c4e61a9676f6559c25216c',
    name: 'Ezzouhour',
    governoratexid: '60c4e5c29676f6559c251f35',
  },
  {
    id: '60c4e61a9676f6559c25216e',
    name: 'Mornaguia',
    governoratexid: '60c4e5bf9676f6559c251f1b',
  },
  {
    id: '60c4e61b9676f6559c252170',
    name: 'Sayada Lamta Bou Hajar',
    governoratexid: '60c4e5c49676f6559c251f3f',
  },
  {
    id: '60c4e61b9676f6559c252172',
    name: 'Hammam Ghezèze',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e61b9676f6559c252174',
    name: 'Hammamet Centre',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e61c9676f6559c252176',
    name: 'Mrezga',
    governoratexid: '60c4e5c09676f6559c251f21',
  },
  {
    id: '60c4e61c9676f6559c252178',
    name: 'Agareb',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e61c9676f6559c25217a',
    name: 'Jebiniana',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e61d9676f6559c25217c',
    name: 'El Hencha',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e61d9676f6559c25217e',
    name: 'Kerkennah',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e61d9676f6559c252180',
    name: 'Route Soukra',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e61e9676f6559c252182',
    name: 'Bir El Hafey',
    governoratexid: '60c4e5dc9676f6559c251fe3',
  },
  {
    id: '60c4e61e9676f6559c252184',
    name: 'Mezzouna',
    governoratexid: '60c4e5dc9676f6559c251fe3',
  },
  {
    id: '60c4e61e9676f6559c252186',
    name: 'Sousse Sidi Abdelhamid',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e61e9676f6559c252188',
    name: 'Autres Villes',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '60c4e61f9676f6559c25218a',
    name: 'Mahdia',
    governoratexid: '60c4e5c39676f6559c251f3a',
  },
  {
    id: '60c4e61f9676f6559c25218c',
    name: 'Sousse',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e61f9676f6559c25218e',
    name: 'Autres Villes',
    governoratexid: '60c4e5c19676f6559c251f2b',
  },
  {
    id: '60c4e6209676f6559c252190',
    name: 'Monastir',
    governoratexid: '60c4e5c49676f6559c251f3f',
  },
  {
    id: '60c4e6209676f6559c252192',
    name: 'La Manouba',
    governoratexid: '60c4e5bf9676f6559c251f1b',
  },
  {
    id: '60c4e6209676f6559c252194',
    name: 'Autres Villes',
    governoratexid: '60c4e5dc9676f6559c251fe3',
  },
  {
    id: '60c4e6219676f6559c252196',
    name: 'Sidi Bouzid',
    governoratexid: '60c4e5dc9676f6559c251fe3',
  },
  {
    id: '60c4e6219676f6559c252198',
    name: 'Téboursouk',
    governoratexid: '60c4e5bd9676f6559c251f0c',
  },
  {
    id: '60c4e6219676f6559c25219a',
    name: 'Fouchana',
    governoratexid: '60c4e5c99676f6559c251f62',
  },
  {
    id: '60c4e6229676f6559c25219c',
    name: 'Ghezala',
    governoratexid: '60c4e5bc9676f6559c251f09',
  },
  {
    id: '60c4e6229676f6559c25219e',
    name: 'Oum El Araies',
    governoratexid: '60c4e5be9676f6559c251f13',
  },
  {
    id: '60c4e6229676f6559c2521a0',
    name: 'Sidi Aïch',
    governoratexid: '60c4e5be9676f6559c251f13',
  },
  {
    id: '60c4e6229676f6559c2521a2',
    name: 'Kébili Sud',
    governoratexid: '60c4e5ce9676f6559c251f82',
  },
  {
    id: '60c4e6239676f6559c2521a4',
    name: 'Borj El Amri',
    governoratexid: '60c4e5bf9676f6559c251f1b',
  },
  {
    id: '60c4e6239676f6559c2521a6',
    name: 'Beni Hassen',
    governoratexid: '60c4e5c49676f6559c251f3f',
  },
  {
    id: '60c4e6239676f6559c2521a8',
    name: 'Bir Ali Ben Khalifa',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e6249676f6559c2521aa',
    name: 'Thyna',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '60c4e6249676f6559c2521ac',
    name: 'Makthar',
    governoratexid: '60c4e5c09676f6559c251f24',
  },
  {
    id: '60c4e6249676f6559c2521ae',
    name: 'Sahloul',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e6259676f6559c2521b0',
    name: 'Kantaoui',
    governoratexid: '60c4e5bc9676f6559c251f06',
  },
  {
    id: '60c4e6259676f6559c2521b2',
    name: 'El Ouardia',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '61484a17b32baeccce7ada23',
    name: 'Route de GABES',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '61484a59b32baeccce7ada24',
    name: 'Route MHARZA',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '61484a7fb32baeccce7ada25',
    name: 'Route SOKRA',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '61484aaeb32baeccce7ada26',
    name: "Route de l'aéroport",
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '61484ad0b32baeccce7ada27',
    name: 'Route MANZEL CHAKER',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '61484ae8b32baeccce7ada28',
    name: 'Route El Ain',
    governoratexid: '60c4e5c49676f6559c251f42',
  },

  {
    id: '61484b22b32baeccce7ada2a',
    name: 'Route GREMDA',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '61484b38b32baeccce7ada2b',
    name: 'Route TANIOUR',
    governoratexid: '60c4e5c49676f6559c251f42',
  },

  {
    id: '61484b6db32baeccce7ada2d',
    name: 'ROUTE SALTANIA',
    governoratexid: '60c4e5c49676f6559c251f42',
  },

  {
    id: '6151de3a048256a6dd30f332',
    name: 'Lac 1',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '6151de55048256a6dd30f333',
    name: 'Lac 2',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '6151de75048256a6dd30f334',
    name: 'Jardins De Carthage',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '6151dea3048256a6dd30f335',
    name: 'Ain Zaghouen',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '62026f08c0081860777eab75',
    governoratexid: '60c4e5c99676f6559c251f62',
    name: 'Borj Cedria',
  },
  {
    id: '6214be5d6449e649f3fdd903',
    governoratexid: '60c4e5c69676f6559c251f50',
    name: 'Zaghouen',
  },
  {
    id: '6214c07f73ad4abe9ae3a999',
    governoratexid: '60c4e5bc9676f6559c251f06',
    name: 'Khzema',
  },
  {
    id: '6214c0a873ad4abe9ae3a99a',
    governoratexid: '60c4e5bc9676f6559c251f06',
    name: 'Chatt mariem',
  },

  {
    id: '62864bfcf8f60ad809eec515',
    name: 'Montplaisir',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },

  {
    id: '632495a3a29d9423d5bc055d',
    name: 'El Ksar',
    governoratexid: '60c4e5be9676f6559c251f13',
  },
  {
    id: '632495a3a29d9423d5bc055e',
    name: 'Cité Ennasr 1',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc055f',
    name: 'Cité Ennasr 2',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc0560',
    name: 'El Menzah 5',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc0561',
    name: 'El Menzah 6',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc0562',
    name: 'El Menzah 7',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc0563',
    name: 'El Menzah 8',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc0564',
    name: 'Riadh Andalous',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc0565',
    name: 'Nouvelle Ariana',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc0566',
    name: 'Ariana Essoughra',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc0567',
    name: 'Dar Fadhal',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc0568',
    name: 'Charguia 1',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc0569',
    name: 'Charguia 2',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc056a',
    name: 'Cite Ennkhilet',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc056b',
    name: 'Chotrana 1',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc056c',
    name: 'Chotrana 2',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc056d',
    name: 'Chotrana 3',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc056e',
    name: 'Cité Hedi Nouira',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc056f',
    name: 'Les Jardins El Menzah 1',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc0570',
    name: 'Les Jardins El Menzah 2',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc0571',
    name: 'Kalâat Andalous',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '632495a3a29d9423d5bc0572',
    name: 'Ain Zaghouan Nord',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '632495a3a29d9423d5bc0573',
    name: 'Ain Zaghouan Sud',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '632495a3a29d9423d5bc0574',
    name: 'Bab Souika',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '632495a3a29d9423d5bc0575',
    name: 'Bellevue',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '632495a3a29d9423d5bc0576',
    name: 'El Manar 1',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '632495a3a29d9423d5bc0577',
    name: 'El Manar 2',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '632495a3a29d9423d5bc0578',
    name: 'El Menzah 1',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '632495a3a29d9423d5bc0579',
    name: 'El Menzah 4',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '632495a3a29d9423d5bc057a',
    name: 'El Menzah 9',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '632495a3a29d9423d5bc057b',
    name: 'Gammarth',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '632495a3a29d9423d5bc057c',
    name: 'Khaznadar',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '632495a3a29d9423d5bc057d',
    name: 'Kheireddine Pacha',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '632495a3a29d9423d5bc057e',
    name: 'Ksar Said',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '632495a3a29d9423d5bc057f',
    name: 'Monfleury',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '632495a3a29d9423d5bc0580',
    name: 'Tunis Belvedere',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '6634bfaece27dc685a44a901',
    name: 'Cité jardin',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '6634bff2ce27dc685a44a904',
    name: 'Alain Savary',
    governoratexid: '60c4e5d19676f6559c251f9a',
  },
  {
    id: '632495a3a29d9423d5bc0581',
    name: 'El Mourouj 1',
    governoratexid: '60c4e5c99676f6559c251f62',
  },
  {
    id: '632495a3a29d9423d5bc0582',
    name: 'El Mourouj 3',
    governoratexid: '60c4e5c99676f6559c251f62',
  },
  {
    id: '632495a3a29d9423d5bc0583',
    name: 'El Mourouj 4',
    governoratexid: '60c4e5c99676f6559c251f62',
  },
  {
    id: '632495a3a29d9423d5bc0584',
    name: 'El Mourouj 5',
    governoratexid: '60c4e5c99676f6559c251f62',
  },
  {
    id: '632495a3a29d9423d5bc0585',
    name: 'El Mourouj 6',
    governoratexid: '60c4e5c99676f6559c251f62',
  },
  {
    id: '632495a3a29d9423d5bc0586',
    name: 'Sidi Rezig',
    governoratexid: '60c4e5c99676f6559c251f62',
  },
  {
    id: '632495a3a29d9423d5bc0587',
    name: 'El Alia',
    governoratexid: '60c4e5bc9676f6559c251f09',
  },
  {
    id: '641450d62b249514377b6096',
    name: 'Gafsa',
    governoratexid: '60c4e5be9676f6559c251f13',
  },
  {
    id: '641450d62b249514377b6097',
    name: 'Le Kef',
    governoratexid: '60c4e5be9676f6559c251f18',
  },
  {
    id: '641450d62b249514377b6098',
    name: 'Béja',
    governoratexid: '60c4e5bd9676f6559c251f0c',
  },
  {
    id: '641450d62b249514377b6099',
    name: 'Kasserine',
    governoratexid: '60c4e5c29676f6559c251f35',
  },
  {
    id: '641450d62b249514377b609a',
    name: 'Jendouba',
    governoratexid: '60c4e5c19676f6559c251f2b',
  },
  {
    id: '641450d62b249514377b609b',
    name: 'Ariana',
    governoratexid: '60c4e5c89676f6559c251f5d',
  },
  {
    id: '641450d62b249514377b609c',
    name: 'Sfax',
    governoratexid: '60c4e5c49676f6559c251f42',
  },
  {
    id: '641450d62b249514377b609d',
    name: 'Zaghouan',
    governoratexid: '60c4e5c69676f6559c251f50',
  },
  {
    id: '641450d62b249514377b609e',
    name: 'Kairouan',
    governoratexid: '60c4e5c19676f6559c251f2e',
  },
];

const categories: CategoryHelper[] = [
  // {
  //   id: '0',
  //   name: 'Le plus récent',
  //   parentcategoryxid: '',
  //   link: 'most-recent',
  // },
  {
    id: '60be84bc50ab95b45b08a094',
    name: 'Véhicules',
    link: 'vehicles',

    parentcategoryxid: '',
  },
  {
    id: '60be84c350ab95b45b08a0bf',
    name: 'Image & Son',
    parentcategoryxid: '60be84bc50ab95b45b08a097',
    link: 'audiovisual',
  },
  {
    id: '60be84be50ab95b45b08a0a5',
    name: 'Motos',
    parentcategoryxid: '60be84bc50ab95b45b08a094',
    link: 'motorcycles',
  },
  {
    id: '60be84be50ab95b45b08a0a3',
    name: 'Colocations',
    parentcategoryxid: '60be84bc50ab95b45b08a093',
    link: 'rent-sharing',
  },
  {
    id: '60be84c250ab95b45b08a0b6',
    name: 'Equipements pour enfant et bébé',
    parentcategoryxid: '60be84bc50ab95b45b08a099',
    link: 'kids',
  },
  {
    id: '60be84be50ab95b45b08a0a4',
    name: 'Voitures',
    parentcategoryxid: '60be84bc50ab95b45b08a094',
    link: 'cars',
  },
  {
    id: '66853d8c6d2202022943acf7',
    name: 'équipement garage',
    parentcategoryxid: '60be84bc50ab95b45b08a094',
    link: 'garage',
  },
  {
    id: '60be84bd50ab95b45b08a09d',
    name: 'Maisons et Villas',
    parentcategoryxid: '60be84bc50ab95b45b08a093',
    link: 'houses-and-villas',
  },
  {
    id: '60be84c350ab95b45b08a0c0',
    name: 'Ordinateurs',
    parentcategoryxid: '60be84bc50ab95b45b08a097',
    link: 'laptops',
  },
  {
    id: '66feac577e4f00ba289709da',
    name: 'Montres connectées',
    parentcategoryxid: '60be84bc50ab95b45b08a097',
    link: 'smart-watch',
  },
  {
    id: '60be84bc50ab95b45b08a095',
    name: 'Pour la Maison et Jardin',
    parentcategoryxid: '',
    link: 'home-and-garden',
  },
  {
    id: '60be84c150ab95b45b08a0b0',
    name: 'Jardins et Outils de bricolage',
    parentcategoryxid: '60be84bc50ab95b45b08a095',
    link: 'garden-and-diy-tools',
  },
  {
    id: '60be84be50ab95b45b08a0a0',
    name: 'Magasins, Commerces et Locaux industriels',
    parentcategoryxid: '60be84bc50ab95b45b08a093',
    link: 'commercial-and-industrial-premises',
  },
  {
    id: '60be84bc50ab95b45b08a098',
    name: 'Emploi et Services',
    parentcategoryxid: '',
    link: 'jobs-and-services',
  },
  {
    id: '670f8c9f17e67fce1f7aa810',
    name: 'Trottinette électrique',
    parentcategoryxid: '60be84bc50ab95b45b08a096',
    link: 'trottinette-electrique',
  },
  {
    id: '60be84c250ab95b45b08a0b8',
    name: 'Sports et Loisirs',
    parentcategoryxid: '60be84bc50ab95b45b08a096',
    link: 'sports-and-hobbies',
  },
  {
    id: '60be84c350ab95b45b08a0bc',
    name: 'Arts et Collections',
    parentcategoryxid: '60be84bc50ab95b45b08a096',
    link: 'art-and-collections',
  },
  {
    id: '60be84c150ab95b45b08a0b3',
    name: 'Montres et Bijoux',
    parentcategoryxid: '60be84bc50ab95b45b08a099',
    link: 'jewlry-and-watches',
  },
  {
    id: '60be84c550ab95b45b08a0cb',
    name: 'Matériels Professionnels',
    parentcategoryxid: '60be84bd50ab95b45b08a09a',
    link: 'professional-equipment',
  },
  {
    id: '60be84c250ab95b45b08a0b9',
    name: 'Animaux',
    parentcategoryxid: '60be84bc50ab95b45b08a096',
    link: 'pets',
  },
  {
    id: '60be84bd50ab95b45b08a09e',
    name: 'Locations de vacances',
    parentcategoryxid: '60be84bc50ab95b45b08a093',
    link: 'vacation-rentals',
  },
  {
    id: '60be84c250ab95b45b08a0ba',
    name: 'Films, Livres, Magazines',
    parentcategoryxid: '60be84bc50ab95b45b08a096',
    link: 'films-books-magazines',
  },
  {
    id: '60be84c250ab95b45b08a0b7',
    name: 'Produits de beauté',
    parentcategoryxid: '60be84bc50ab95b45b08a099',
    link: 'beaty-and-care',
  },
  {
    id: '60be84c550ab95b45b08a0c7',
    name: "Demandes d'emploi",
    parentcategoryxid: '60be84bc50ab95b45b08a098',
    link: 'job-requests',
  },
  {
    id: '60be84bc50ab95b45b08a093',
    name: 'Immobilier',
    parentcategoryxid: '',
    link: 'real-estate',
  },
  {
    id: '66c34239d16d7f3b0c2ec56e',
    name: 'ImmoNeuf',
    parentcategoryxid: '',
    link: 'ImmoNeuf',
  },
  {
    id: '66c34325d16d7f3b0c2ec570',
    name: 'Immobilier Neuf',
    parentcategoryxid: '66c34239d16d7f3b0c2ec56e',
    link: 'immobilier-neuf',
  },
  {
    id: '60be84c450ab95b45b08a0c3',
    name: 'Appareils photo et Caméras',
    parentcategoryxid: '60be84bc50ab95b45b08a097',
    link: 'photography-and-videography',
  },
  {
    id: '60be84c450ab95b45b08a0c5',
    name: 'Télévisions',
    parentcategoryxid: '60be84bc50ab95b45b08a097',
    link: 'tv',
  },
  {
    id: '60be84c550ab95b45b08a0c9',
    name: 'Cours et Formations',
    parentcategoryxid: '60be84bc50ab95b45b08a098',
    link: 'courses-and-trainings',
  },
  {
    id: '60be84bf50ab95b45b08a0a8',
    name: 'Vélos',
    parentcategoryxid: '60be84bc50ab95b45b08a096',
    link: 'bicycles',
  },
  {
    id: '60be84c350ab95b45b08a0bd',
    name: 'Instruments de musique',
    parentcategoryxid: '60be84bc50ab95b45b08a096',
    link: 'music-and-instruments',
  },
  {
    id: '60be84c150ab95b45b08a0b1',
    name: 'Vêtements',
    parentcategoryxid: '60be84bc50ab95b45b08a099',
    link: 'clothing',
  },
  {
    id: '60be84bf50ab95b45b08a0a9',
    name: 'Remorques et Caravanes',
    parentcategoryxid: '60be84bc50ab95b45b08a094',
    link: 'trailers-and-caravans',
  },
  {
    id: '60be84c350ab95b45b08a0bb',
    name: 'Voyages et Billetteries',
    parentcategoryxid: '60be84bc50ab95b45b08a096',
    link: 'travel-and-tickets',
  },
  {
    id: '60be84bd50ab95b45b08a09c',
    name: 'Appartements',
    parentcategoryxid: '60be84bc50ab95b45b08a093',
    link: 'appartments',
  },
  {
    id: '60be84be50ab95b45b08a09f',
    name: 'Bureaux et Plateaux',
    parentcategoryxid: '60be84bc50ab95b45b08a093',
    link: 'workspaces',
  },
  {
    id: '60be84c450ab95b45b08a0c6',
    name: "Offres d'emploi",
    parentcategoryxid: '60be84bc50ab95b45b08a098',
    link: 'job-offers',
  },
  {
    id: '60be84c550ab95b45b08a0ca',
    name: 'Business et Affaires commerciales',
    parentcategoryxid: '60be84bd50ab95b45b08a09a',
    link: 'business-and-commerce',
  },
  {
    id: '60be84bf50ab95b45b08a0aa',
    name: 'Engins Agricoles',
    parentcategoryxid: '60be84bc50ab95b45b08a094',
    link: 'agricultural-machinery',
  },
  {
    id: '60be84c050ab95b45b08a0ad',
    name: 'Autres Véhicules',
    parentcategoryxid: '60be84bc50ab95b45b08a094',
    link: 'other',
  },
  {
    id: '60be84c250ab95b45b08a0b4',
    name: 'Sacs et Accessoires',
    parentcategoryxid: '60be84bc50ab95b45b08a099',
    link: 'bags-and-accessories',
  },
  {
    id: '60be84bd50ab95b45b08a09a',
    name: 'Entreprises',
    parentcategoryxid: '',
    link: 'Entreprises',
  },
  {
    id: '60be84c550ab95b45b08a0cc',
    name: 'Stocks et Vente en gros',
    parentcategoryxid: '60be84bd50ab95b45b08a09a',
    link: 'stock-and-wholesale',
  },
  {
    id: '60be84c350ab95b45b08a0be',
    name: 'Téléphones',
    parentcategoryxid: '60be84bc50ab95b45b08a097',
    link: 'phones',
  },
  {
    id: '60be84c450ab95b45b08a0c1',
    name: 'Accessoires informatiques et Gadgets',
    parentcategoryxid: '60be84bc50ab95b45b08a097',
    link: 'tech-accessories-and-gadgets',
  },
  {
    id: '60be84bc50ab95b45b08a099',
    name: 'Habillement et Bien Etre',
    parentcategoryxid: '',
    link: 'clothing-and-well-being',
  },
  {
    id: '60be84c550ab95b45b08a0c8',
    name: 'Services',
    parentcategoryxid: '60be84bc50ab95b45b08a098',
    link: 'services',
  },
  {
    id: '60be84bf50ab95b45b08a0ab',
    name: 'Engins BTP',
    parentcategoryxid: '60be84bc50ab95b45b08a094',
    link: 'construction-machinery',
  },
  {
    id: '60be84be50ab95b45b08a0a2',
    name: 'Autres Immobiliers',
    parentcategoryxid: '60be84bc50ab95b45b08a093',
    link: 'other-real-estate',
  },
  {
    id: '60be84bc50ab95b45b08a097',
    name: 'Informatique et Multimedias',
    link: 'tech',
    parentcategoryxid: '',
  },
  {
    id: '60be84bf50ab95b45b08a0a7',
    name: 'Bateaux',
    parentcategoryxid: '60be84bc50ab95b45b08a094',
    link: 'boats',
  },
  {
    id: '60be84c450ab95b45b08a0c2',
    name: 'Jeux vidéo et Consoles',
    parentcategoryxid: '60be84bc50ab95b45b08a097',
    link: 'video-games-and-consoles',
  },
  {
    id: '60be84c450ab95b45b08a0c4',
    name: 'Tablettes',
    parentcategoryxid: '60be84bc50ab95b45b08a097',
    link: 'tablets',
  },
  {
    id: '60be84c050ab95b45b08a0ae',
    name: 'Electroménagers et Vaisselles',
    parentcategoryxid: '60be84bc50ab95b45b08a095',
    link: 'household-appliances',
  },
  {
    id: '60be84bc50ab95b45b08a096',
    name: 'Loisirs et Divertissement',
    link: 'leisure-and-entertainment',
    parentcategoryxid: '',
  },
  {
    id: '60be84c150ab95b45b08a0b2',
    name: 'Chaussures',
    parentcategoryxid: '60be84bc50ab95b45b08a099',
    link: 'shoes',
  },
  {
    id: '60be84bf50ab95b45b08a0a6',
    name: 'Pièces et Accessoires pour véhicules',
    parentcategoryxid: '60be84bc50ab95b45b08a094',
    link: 'vehicle-parts-and-accessories',
  },
  {
    id: '60be84c050ab95b45b08a0ac',
    name: 'Camions',
    link: 'trucks',
    parentcategoryxid: '60be84bc50ab95b45b08a094',
  },
  {
    id: '60be84be50ab95b45b08a0a1',
    name: 'Terrains et Fermes',
    parentcategoryxid: '60be84bc50ab95b45b08a093',
    link: 'land-and-farms',
  },
  {
    id: '60be84c050ab95b45b08a0af',
    name: 'Meubles et Décoration',
    parentcategoryxid: '60be84bc50ab95b45b08a095',
    link: 'furniture-and-decoration',
  },
  {
    id: '60be84c250ab95b45b08a0b5',
    name: 'Vêtements pour enfant et bébé',
    parentcategoryxid: '60be84bc50ab95b45b08a099',
    link: 'kids-clothing',
  },
  {
    id: '62a1bf06b71b9dd099a1d1c2',
    name: 'Vacances',
    link: 'vacation',
    parentcategoryxid: '',
  },
  {
    id: '62a32fe3cf8d891166d64bab',
    name: 'Vacances ',
    link: 'vacation',
    parentcategoryxid: '62a1bf06b71b9dd099a1d1c2',
  },
  {
    id: '60be84bd50ab95b45b08a09b',
    name: 'Autres',
    parentcategoryxid: '',
    link: 'other',
  },
  {
    id: '60be84c550ab95b45b08a0cd',
    name: 'Autres ',
    parentcategoryxid: '60be84bd50ab95b45b08a09b',
    link: 'other',
  },
];

export const getSubCategoryById = (id: string | undefined) =>
  categories.find((cat) => cat.id === id) || { name: '' };

export interface CategoriesGroup {
  [key: string]: CategoryHelper[];
}

// Return all parent categories
export const getAllParentCategories = () =>
  categories.filter((cat) => !cat.parentcategoryxid);

// Groups categories by parent id
export const getAllSubCategoriesAndGroupByParentId = () =>
  categories.reduce<CategoriesGroup>((acc, curr) => {
    const tmp = acc;
    tmp[curr.parentcategoryxid] = [
      ...(tmp[curr.parentcategoryxid] || []),
      curr,
    ];
    return tmp;
  }, {});

export const extractCategoryFromHelper = (categoryName: string) => {
  const findedParams = categories.find(
    (category) => category.name === categoryName
  );
  return findedParams;
};

export const getDelegationId = (delegationName: string): string | null => {
  const res = delegations.find(
    (delegation) => delegation.name === delegationName
  );

  if (res === undefined) return null;
  return res.id;
};

export const getProductTypeId = (productType: string): ProductTypeEnum => {
  switch (productType) {
    case 'Occasion':
      return ProductTypeEnum.OCCASION;

    case 'Neuf':
      return ProductTypeEnum.NEW;

    default:
      return ProductTypeEnum.OCCASION;
  }
};

export const getProductTypeName = (productTypeId: number): string => {
  switch (productTypeId) {
    case ProductTypeEnum.OCCASION:
      return 'Occasion';

    case ProductTypeEnum.NEW:
      return 'Neuf';

    default:
      return 'Occasion';
  }
};

export const getGovernorateId = (governorateName: string): string | null => {
  const res = governorates.find(
    (governorate) => governorate.name === governorateName
  );
  if (res === undefined) return null;
  return res.id;
};

export const getDelegationsOfGovernorate = (governorateId: string) => {
  const res = delegations
    .filter((delegation) => delegation.governoratexid === governorateId)
    .map((delegation) => ({ id: delegation.id, name: delegation.name }));
  return res;
};

export const computeAdsNearestPriceRange = (
  min: number,
  max: number = min
): string => {
  const mid = Math.round((max + min) / 2);
  const l = mid.toString().length;
  return l >= 1 ? `${10 ** (l - 1)}-${10 ** l}` : `0-1`;
};

export { categories, governorates, delegations };
