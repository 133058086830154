// package: marketplace
// file: marketplace.proto

var marketplace_pb = require("./marketplace_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var discovery_pb = require("./discovery_pb");
var users_pb = require("./users_pb");
var ads_pb = require("./ads_pb");
var categories_pb = require("./categories_pb");
var wallets_pb = require("./wallets_pb");
var scheduled_pb = require("./scheduled_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var MarketPlace = (function () {
  function MarketPlace() {}
  MarketPlace.serviceName = "marketplace.MarketPlace";
  return MarketPlace;
}());

MarketPlace.CreateAd = {
  methodName: "CreateAd",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.NewAdRequest,
  responseType: ads_pb.AdResponse
};

MarketPlace.GetAd = {
  methodName: "GetAd",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.AdByIDRequest,
  responseType: ads_pb.AdResponse
};

MarketPlace.RepublishAd = {
  methodName: "RepublishAd",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.AdByIDRequest,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.UpdateAd = {
  methodName: "UpdateAd",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.VersionInAdByIDRequest,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.ListAdsCurrentVersion = {
  methodName: "ListAdsCurrentVersion",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: discovery_pb.FilterAndDatesSearch,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.GetViewPerAd = {
  methodName: "GetViewPerAd",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.AdByIDRequest,
  responseType: ads_pb.ResponseView
};

MarketPlace.ViewAd = {
  methodName: "ViewAd",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.AdViewRequest,
  responseType: ads_pb.ViewResponse
};

MarketPlace.GetUserAnalytics = {
  methodName: "GetUserAnalytics",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: users_pb.UserByIDRequest,
  responseType: ads_pb.AnalyticsResponse
};

MarketPlace.UpdateAdData = {
  methodName: "UpdateAdData",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.VersionInAdByIDRequest,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.DeleteAd = {
  methodName: "DeleteAd",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.AdByIDRequest,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.MarkAdAsFeatured = {
  methodName: "MarkAdAsFeatured",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.AdByIDRequest,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.MoveAdToState = {
  methodName: "MoveAdToState",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.StateInAdByIDRequest,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.GetAdCurrentVersion = {
  methodName: "GetAdCurrentVersion",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.AdByIDRequest,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.BoostAd = {
  methodName: "BoostAd",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.AdByIDRequest,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.SoldAd = {
  methodName: "SoldAd",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.AdByIDRequest,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.MarkAdAsHighlited = {
  methodName: "MarkAdAsHighlited",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.AdByIDRequest,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.CreateGoldenAd = {
  methodName: "CreateGoldenAd",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.GoldAdId,
  responseType: ads_pb.CreateGoldAdResponse
};

MarketPlace.GetGoldenAd = {
  methodName: "GetGoldenAd",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: ads_pb.GoldAdIdResponse
};

MarketPlace.GetNumberAdsByUser = {
  methodName: "GetNumberAdsByUser",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.NbAdsByUserRequest,
  responseType: ads_pb.NbAdsByUserResponse
};

MarketPlace.GetOfflineAdsForUser = {
  methodName: "GetOfflineAdsForUser",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: ads_pb.OfflineAdsForUserRequest,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.GetOfflineAdsForUserCount = {
  methodName: "GetOfflineAdsForUserCount",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.OfflineAdsForUserRequest,
  responseType: ads_pb.TotalAdsCountResponse
};

MarketPlace.GetFeaturedAds = {
  methodName: "GetFeaturedAds",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: discovery_pb.FilterAndDatesSearch,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.GetAdsByUser = {
  methodName: "GetAdsByUser",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: ads_pb.GetAdsByUserRequest,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.GetAdsByUserCount = {
  methodName: "GetAdsByUserCount",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.GetAdsByUserRequest,
  responseType: ads_pb.TotalAdsCountResponse
};

MarketPlace.ReportAdByUser = {
  methodName: "ReportAdByUser",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.ReportAdByUserRequest,
  responseType: ads_pb.ReportAdByUserResponse
};

MarketPlace.ListFavorites = {
  methodName: "ListFavorites",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: users_pb.UserByIDRequest,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.ToggleFavorite = {
  methodName: "ToggleFavorite",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.FavoriteRequest,
  responseType: users_pb.UserResponse
};

MarketPlace.CreateCategory = {
  methodName: "CreateCategory",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: categories_pb.NewCategoryRequest,
  responseType: categories_pb.CategoryResponse
};

MarketPlace.ListCategories = {
  methodName: "ListCategories",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: categories_pb.CategoryResponse
};

MarketPlace.UpdateCategory = {
  methodName: "UpdateCategory",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: categories_pb.NewCategoryInCategoryByID,
  responseType: categories_pb.CategoryResponse
};

MarketPlace.GetCategory = {
  methodName: "GetCategory",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: categories_pb.CategoryByIDRequest,
  responseType: categories_pb.CategoryResponse
};

MarketPlace.GetCategoryBySubCategoryID = {
  methodName: "GetCategoryBySubCategoryID",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: categories_pb.CategoryByIDRequest,
  responseType: categories_pb.CategoryResponse
};

MarketPlace.GetSubCategories = {
  methodName: "GetSubCategories",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: categories_pb.CategoryByIDRequest,
  responseType: categories_pb.CategoryResponse
};

MarketPlace.ListAllSubCategories = {
  methodName: "ListAllSubCategories",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: categories_pb.CategoryResponse
};

MarketPlace.CreateUser = {
  methodName: "CreateUser",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: users_pb.NewUserRequest,
  responseType: users_pb.UserResponse
};

MarketPlace.UpdateUser = {
  methodName: "UpdateUser",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: users_pb.NewUserByIDRequest,
  responseType: users_pb.UserResponse
};

MarketPlace.GetUser = {
  methodName: "GetUser",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: users_pb.UserByIDRequest,
  responseType: users_pb.UserResponse
};

MarketPlace.GetUserByPhoneNumber = {
  methodName: "GetUserByPhoneNumber",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: users_pb.UserByPhoneNumberRequest,
  responseType: users_pb.UserResponse
};

MarketPlace.ListUsers = {
  methodName: "ListUsers",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: discovery_pb.FilterAndDatesSearch,
  responseType: users_pb.UserResponse
};

MarketPlace.GetUserKeys = {
  methodName: "GetUserKeys",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: users_pb.GetUserKeysRequest,
  responseType: users_pb.GetUserKeysResponse
};

MarketPlace.ListUsersCount = {
  methodName: "ListUsersCount",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: discovery_pb.FilterAndDatesSearch,
  responseType: users_pb.TotalUserCountResponse
};

MarketPlace.CreateGovernorate = {
  methodName: "CreateGovernorate",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.NewGovernorateRequest,
  responseType: ads_pb.GovernorateResponse
};

MarketPlace.CreateDelegation = {
  methodName: "CreateDelegation",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.NewDelegationRequest,
  responseType: ads_pb.DelegationResponse
};

MarketPlace.GetDelegationList = {
  methodName: "GetDelegationList",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: ads_pb.DelegationsByGovernorateRequest,
  responseType: ads_pb.DelegationResponse
};

MarketPlace.GetGovernorateList = {
  methodName: "GetGovernorateList",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: ads_pb.GovernorateResponse
};

MarketPlace.CreateDefaultLocation = {
  methodName: "CreateDefaultLocation",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.NewLocationRequest,
  responseType: ads_pb.LocationResponse
};

MarketPlace.ListDefaultLocation = {
  methodName: "ListDefaultLocation",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: ads_pb.LocationResponse
};

MarketPlace.GetDefaultLocationByGovernorateAndDelegation = {
  methodName: "GetDefaultLocationByGovernorateAndDelegation",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.LocationByGovernorateAndDelegation,
  responseType: ads_pb.LocationResponse
};

MarketPlace.GetNearestPointToCentral = {
  methodName: "GetNearestPointToCentral",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.NearPointRequest,
  responseType: ads_pb.LocationResponse
};

MarketPlace.GetAllDelegationList = {
  methodName: "GetAllDelegationList",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: ads_pb.DelegationResponse
};

MarketPlace.DiscoverFacetsForCategory = {
  methodName: "DiscoverFacetsForCategory",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: discovery_pb.CategoryRequest,
  responseType: discovery_pb.FacetedSearchResponse
};

MarketPlace.SearchAdsByText = {
  methodName: "SearchAdsByText",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: discovery_pb.SearchAdsByTitleRequest,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.SearchAdsByTextCount = {
  methodName: "SearchAdsByTextCount",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: discovery_pb.SearchAdsByTitleRequest,
  responseType: ads_pb.TotalAdsCountResponse
};

MarketPlace.AutocompleteSearchAdsByText = {
  methodName: "AutocompleteSearchAdsByText",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: discovery_pb.SearchAdsByTitleRequest,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.FilterAdsWithAdParams = {
  methodName: "FilterAdsWithAdParams",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: discovery_pb.FilterAndDatesSearch,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.GetTotalAdsCount = {
  methodName: "GetTotalAdsCount",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: discovery_pb.FilterAndDatesSearch,
  responseType: ads_pb.TotalAdsCountResponse
};

MarketPlace.SearchUsersByText = {
  methodName: "SearchUsersByText",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: discovery_pb.SearchUsersByTitleRequest,
  responseType: users_pb.UserResponse
};

MarketPlace.SearchShopsByText = {
  methodName: "SearchShopsByText",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: discovery_pb.SearchUsersByTitleRequest,
  responseType: users_pb.UserResponse
};

MarketPlace.SearchShopsByTextCount = {
  methodName: "SearchShopsByTextCount",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: discovery_pb.SearchUsersByTitleRequest,
  responseType: users_pb.TotalUserCountResponse
};

MarketPlace.GetShopsByCategoryId = {
  methodName: "GetShopsByCategoryId",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: categories_pb.CategoryByIDRequest,
  responseType: users_pb.UserResponse
};

MarketPlace.GetCountAdsByShopAndCategory = {
  methodName: "GetCountAdsByShopAndCategory",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: users_pb.UserByIDRequest,
  responseType: users_pb.ShopCountAdResponse
};

MarketPlace.HelloPayments = {
  methodName: "HelloPayments",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: wallets_pb.KeyResponse
};

MarketPlace.WalletsCreateAccount = {
  methodName: "WalletsCreateAccount",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: users_pb.WalletsCreateAccountRequest,
  responseType: google_protobuf_empty_pb.Empty
};

MarketPlace.GetAccountTransactions = {
  methodName: "GetAccountTransactions",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: users_pb.GetAccountTransactionsRequest,
  responseType: wallets_pb.AccountAssetTransferTransactionsResponse
};

MarketPlace.GetUserBalance = {
  methodName: "GetUserBalance",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: users_pb.GetAccountTransactionsRequest,
  responseType: users_pb.GetUserBalanceResponse
};

MarketPlace.BuyTokens = {
  methodName: "BuyTokens",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: users_pb.BuyTokensRequest,
  responseType: users_pb.BuyTokensResponse
};

MarketPlace.ConsumeTokensByUser = {
  methodName: "ConsumeTokensByUser",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: users_pb.ConsumeTokensByUserRequest,
  responseType: users_pb.ConsumeTokensByUserResponse
};

MarketPlace.ConsumeTokensByAdmin = {
  methodName: "ConsumeTokensByAdmin",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: users_pb.ConsumeTokensByUserRequest,
  responseType: users_pb.ConsumeTokensByUserResponse
};

MarketPlace.GetWalletsAccount = {
  methodName: "GetWalletsAccount",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: users_pb.GetWalletsAccountRequest,
  responseType: users_pb.GetWalletsAccountResponse
};

MarketPlace.GetUserBalanceAndTransactions = {
  methodName: "GetUserBalanceAndTransactions",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: users_pb.GetAccountTransactionsRequest,
  responseType: wallets_pb.GetUserTransactionsBalanceResponse
};

MarketPlace.CreateTokensPacks = {
  methodName: "CreateTokensPacks",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: wallets_pb.NewTokensPacksRequest,
  responseType: wallets_pb.TokensPacksResponse
};

MarketPlace.GetListTokensPacks = {
  methodName: "GetListTokensPacks",
  service: MarketPlace,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: wallets_pb.TokensPacksResponse
};

MarketPlace.GetPaymentInformation = {
  methodName: "GetPaymentInformation",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: ads_pb.GetPaymentResponse
};

MarketPlace.WalletsCreateAccountWithKeys = {
  methodName: "WalletsCreateAccountWithKeys",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: users_pb.WalletsCreateAccountWithKeysRequest,
  responseType: google_protobuf_empty_pb.Empty
};

MarketPlace.getTotalAdPremieum = {
  methodName: "getTotalAdPremieum",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: ads_pb.TotalAdsCountResponse
};

MarketPlace.getTotalAdGold = {
  methodName: "getTotalAdGold",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: ads_pb.TotalAdsCountResponse
};

MarketPlace.AutoBoostAd = {
  methodName: "AutoBoostAd",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: scheduled_pb.AutoBoostAdByIDRequest,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.BoostAdWithoutTokens = {
  methodName: "BoostAdWithoutTokens",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.AdByIDRequest,
  responseType: ads_pb.AdCurrentVersionResponse
};

MarketPlace.AddAutomaticBoost = {
  methodName: "AddAutomaticBoost",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: scheduled_pb.AutoBoostAdByIDRequest,
  responseType: scheduled_pb.AddAutomaticBoostResponse
};

MarketPlace.AddTransactionStatus = {
  methodName: "AddTransactionStatus",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: ads_pb.TransactionStatusRequest,
  responseType: ads_pb.TransactionStatusResponse
};

MarketPlace.PersistDeviceConfig = {
  methodName: "PersistDeviceConfig",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: users_pb.SendUserDeviceConfigRequest,
  responseType: users_pb.SendUserDeviceConfigResponse
};

MarketPlace.AddTokensByAdmin = {
  methodName: "AddTokensByAdmin",
  service: MarketPlace,
  requestStream: false,
  responseStream: false,
  requestType: users_pb.TransferTokensByAdminRequest,
  responseType: users_pb.TransferTokensResponse
};

exports.MarketPlace = MarketPlace;

function MarketPlaceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

MarketPlaceClient.prototype.createAd = function createAd(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.CreateAd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getAd = function getAd(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetAd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.republishAd = function republishAd(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.RepublishAd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.updateAd = function updateAd(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.UpdateAd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.listAdsCurrentVersion = function listAdsCurrentVersion(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.ListAdsCurrentVersion, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getViewPerAd = function getViewPerAd(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetViewPerAd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.viewAd = function viewAd(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.ViewAd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getUserAnalytics = function getUserAnalytics(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetUserAnalytics, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.updateAdData = function updateAdData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.UpdateAdData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.deleteAd = function deleteAd(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.DeleteAd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.markAdAsFeatured = function markAdAsFeatured(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.MarkAdAsFeatured, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.moveAdToState = function moveAdToState(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.MoveAdToState, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getAdCurrentVersion = function getAdCurrentVersion(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetAdCurrentVersion, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.boostAd = function boostAd(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.BoostAd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.soldAd = function soldAd(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.SoldAd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.markAdAsHighlited = function markAdAsHighlited(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.MarkAdAsHighlited, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.createGoldenAd = function createGoldenAd(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.CreateGoldenAd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getGoldenAd = function getGoldenAd(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.GetGoldenAd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getNumberAdsByUser = function getNumberAdsByUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetNumberAdsByUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getOfflineAdsForUser = function getOfflineAdsForUser(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.GetOfflineAdsForUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getOfflineAdsForUserCount = function getOfflineAdsForUserCount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetOfflineAdsForUserCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getFeaturedAds = function getFeaturedAds(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.GetFeaturedAds, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getAdsByUser = function getAdsByUser(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.GetAdsByUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getAdsByUserCount = function getAdsByUserCount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetAdsByUserCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.reportAdByUser = function reportAdByUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.ReportAdByUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.listFavorites = function listFavorites(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.ListFavorites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.toggleFavorite = function toggleFavorite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.ToggleFavorite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.createCategory = function createCategory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.CreateCategory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.listCategories = function listCategories(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.ListCategories, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.updateCategory = function updateCategory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.UpdateCategory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getCategory = function getCategory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetCategory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getCategoryBySubCategoryID = function getCategoryBySubCategoryID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetCategoryBySubCategoryID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getSubCategories = function getSubCategories(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.GetSubCategories, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.listAllSubCategories = function listAllSubCategories(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.ListAllSubCategories, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.createUser = function createUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.CreateUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.updateUser = function updateUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.UpdateUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getUser = function getUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getUserByPhoneNumber = function getUserByPhoneNumber(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetUserByPhoneNumber, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.listUsers = function listUsers(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.ListUsers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getUserKeys = function getUserKeys(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetUserKeys, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.listUsersCount = function listUsersCount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.ListUsersCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.createGovernorate = function createGovernorate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.CreateGovernorate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.createDelegation = function createDelegation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.CreateDelegation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getDelegationList = function getDelegationList(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.GetDelegationList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getGovernorateList = function getGovernorateList(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.GetGovernorateList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.createDefaultLocation = function createDefaultLocation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.CreateDefaultLocation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.listDefaultLocation = function listDefaultLocation(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.ListDefaultLocation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getDefaultLocationByGovernorateAndDelegation = function getDefaultLocationByGovernorateAndDelegation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetDefaultLocationByGovernorateAndDelegation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getNearestPointToCentral = function getNearestPointToCentral(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetNearestPointToCentral, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getAllDelegationList = function getAllDelegationList(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.GetAllDelegationList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.discoverFacetsForCategory = function discoverFacetsForCategory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.DiscoverFacetsForCategory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.searchAdsByText = function searchAdsByText(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.SearchAdsByText, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.searchAdsByTextCount = function searchAdsByTextCount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.SearchAdsByTextCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.autocompleteSearchAdsByText = function autocompleteSearchAdsByText(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.AutocompleteSearchAdsByText, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.filterAdsWithAdParams = function filterAdsWithAdParams(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.FilterAdsWithAdParams, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getTotalAdsCount = function getTotalAdsCount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetTotalAdsCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.searchUsersByText = function searchUsersByText(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.SearchUsersByText, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.searchShopsByText = function searchShopsByText(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.SearchShopsByText, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.searchShopsByTextCount = function searchShopsByTextCount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.SearchShopsByTextCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getShopsByCategoryId = function getShopsByCategoryId(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.GetShopsByCategoryId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getCountAdsByShopAndCategory = function getCountAdsByShopAndCategory(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetCountAdsByShopAndCategory, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.helloPayments = function helloPayments(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.HelloPayments, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.walletsCreateAccount = function walletsCreateAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.WalletsCreateAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getAccountTransactions = function getAccountTransactions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetAccountTransactions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getUserBalance = function getUserBalance(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetUserBalance, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.buyTokens = function buyTokens(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.BuyTokens, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.consumeTokensByUser = function consumeTokensByUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.ConsumeTokensByUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.consumeTokensByAdmin = function consumeTokensByAdmin(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.ConsumeTokensByAdmin, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getWalletsAccount = function getWalletsAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetWalletsAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getUserBalanceAndTransactions = function getUserBalanceAndTransactions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetUserBalanceAndTransactions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.createTokensPacks = function createTokensPacks(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.CreateTokensPacks, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getListTokensPacks = function getListTokensPacks(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketPlace.GetListTokensPacks, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getPaymentInformation = function getPaymentInformation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.GetPaymentInformation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.walletsCreateAccountWithKeys = function walletsCreateAccountWithKeys(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.WalletsCreateAccountWithKeys, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getTotalAdPremieum = function getTotalAdPremieum(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.getTotalAdPremieum, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.getTotalAdGold = function getTotalAdGold(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.getTotalAdGold, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.autoBoostAd = function autoBoostAd(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.AutoBoostAd, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.boostAdWithoutTokens = function boostAdWithoutTokens(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.BoostAdWithoutTokens, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.addAutomaticBoost = function addAutomaticBoost(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.AddAutomaticBoost, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.addTransactionStatus = function addTransactionStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.AddTransactionStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.persistDeviceConfig = function persistDeviceConfig(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.PersistDeviceConfig, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketPlaceClient.prototype.addTokensByAdmin = function addTokensByAdmin(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketPlace.AddTokensByAdmin, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.MarketPlaceClient = MarketPlaceClient;

