// source: categories.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var ads_pb = require('./ads_pb.js');
goog.object.extend(proto, ads_pb);
goog.exportSymbol('proto.marketplace.Category', null, global);
goog.exportSymbol('proto.marketplace.CategoryByIDRequest', null, global);
goog.exportSymbol('proto.marketplace.CategoryResponse', null, global);
goog.exportSymbol('proto.marketplace.NewCategoryInCategoryByID', null, global);
goog.exportSymbol('proto.marketplace.NewCategoryRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.CategoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.CategoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.CategoryResponse.displayName = 'proto.marketplace.CategoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.NewCategoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.NewCategoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.NewCategoryRequest.displayName = 'proto.marketplace.NewCategoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.NewCategoryInCategoryByID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.NewCategoryInCategoryByID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.NewCategoryInCategoryByID.displayName = 'proto.marketplace.NewCategoryInCategoryByID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.CategoryByIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.CategoryByIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.CategoryByIDRequest.displayName = 'proto.marketplace.CategoryByIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.Category = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketplace.Category.repeatedFields_, null);
};
goog.inherits(proto.marketplace.Category, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.Category.displayName = 'proto.marketplace.Category';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.CategoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.CategoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.CategoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.CategoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    category: (f = msg.getCategory()) && proto.marketplace.Category.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.CategoryResponse}
 */
proto.marketplace.CategoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.CategoryResponse;
  return proto.marketplace.CategoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.CategoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.CategoryResponse}
 */
proto.marketplace.CategoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.marketplace.Category;
      reader.readMessage(value,proto.marketplace.Category.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.CategoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.CategoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.CategoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.CategoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.marketplace.Category.serializeBinaryToWriter
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.CategoryResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.CategoryResponse} returns this
 */
proto.marketplace.CategoryResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Category category = 2;
 * @return {?proto.marketplace.Category}
 */
proto.marketplace.CategoryResponse.prototype.getCategory = function() {
  return /** @type{?proto.marketplace.Category} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.Category, 2));
};


/**
 * @param {?proto.marketplace.Category|undefined} value
 * @return {!proto.marketplace.CategoryResponse} returns this
*/
proto.marketplace.CategoryResponse.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.CategoryResponse} returns this
 */
proto.marketplace.CategoryResponse.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.CategoryResponse.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.NewCategoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.NewCategoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.NewCategoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NewCategoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: (f = msg.getCategory()) && proto.marketplace.Category.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.NewCategoryRequest}
 */
proto.marketplace.NewCategoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.NewCategoryRequest;
  return proto.marketplace.NewCategoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.NewCategoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.NewCategoryRequest}
 */
proto.marketplace.NewCategoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.marketplace.Category;
      reader.readMessage(value,proto.marketplace.Category.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.NewCategoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.NewCategoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.NewCategoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NewCategoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.marketplace.Category.serializeBinaryToWriter
    );
  }
};


/**
 * optional Category category = 1;
 * @return {?proto.marketplace.Category}
 */
proto.marketplace.NewCategoryRequest.prototype.getCategory = function() {
  return /** @type{?proto.marketplace.Category} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.Category, 1));
};


/**
 * @param {?proto.marketplace.Category|undefined} value
 * @return {!proto.marketplace.NewCategoryRequest} returns this
*/
proto.marketplace.NewCategoryRequest.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.NewCategoryRequest} returns this
 */
proto.marketplace.NewCategoryRequest.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.NewCategoryRequest.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.NewCategoryInCategoryByID.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.NewCategoryInCategoryByID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.NewCategoryInCategoryByID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NewCategoryInCategoryByID.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newcategory: (f = msg.getNewcategory()) && proto.marketplace.Category.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.NewCategoryInCategoryByID}
 */
proto.marketplace.NewCategoryInCategoryByID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.NewCategoryInCategoryByID;
  return proto.marketplace.NewCategoryInCategoryByID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.NewCategoryInCategoryByID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.NewCategoryInCategoryByID}
 */
proto.marketplace.NewCategoryInCategoryByID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.marketplace.Category;
      reader.readMessage(value,proto.marketplace.Category.deserializeBinaryFromReader);
      msg.setNewcategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.NewCategoryInCategoryByID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.NewCategoryInCategoryByID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.NewCategoryInCategoryByID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NewCategoryInCategoryByID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewcategory();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.marketplace.Category.serializeBinaryToWriter
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.NewCategoryInCategoryByID.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewCategoryInCategoryByID} returns this
 */
proto.marketplace.NewCategoryInCategoryByID.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Category newCategory = 2;
 * @return {?proto.marketplace.Category}
 */
proto.marketplace.NewCategoryInCategoryByID.prototype.getNewcategory = function() {
  return /** @type{?proto.marketplace.Category} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.Category, 2));
};


/**
 * @param {?proto.marketplace.Category|undefined} value
 * @return {!proto.marketplace.NewCategoryInCategoryByID} returns this
*/
proto.marketplace.NewCategoryInCategoryByID.prototype.setNewcategory = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.NewCategoryInCategoryByID} returns this
 */
proto.marketplace.NewCategoryInCategoryByID.prototype.clearNewcategory = function() {
  return this.setNewcategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.NewCategoryInCategoryByID.prototype.hasNewcategory = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.CategoryByIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.CategoryByIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.CategoryByIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.CategoryByIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.CategoryByIDRequest}
 */
proto.marketplace.CategoryByIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.CategoryByIDRequest;
  return proto.marketplace.CategoryByIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.CategoryByIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.CategoryByIDRequest}
 */
proto.marketplace.CategoryByIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.CategoryByIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.CategoryByIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.CategoryByIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.CategoryByIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.CategoryByIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.CategoryByIDRequest} returns this
 */
proto.marketplace.CategoryByIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketplace.Category.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.Category.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.Category.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.Category} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Category.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    parentcategoryxid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    iconlink: jspb.Message.getFieldWithDefault(msg, 3, ""),
    adparamsList: jspb.Message.toObjectList(msg.getAdparamsList(),
    ads_pb.AdParam.toObject, includeInstance),
    defaultlimitsMap: (f = msg.getDefaultlimitsMap()) ? f.toObject(includeInstance, undefined) : [],
    productpriceMap: (f = msg.getProductpriceMap()) ? f.toObject(includeInstance, undefined) : [],
    sortedorder: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.Category}
 */
proto.marketplace.Category.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.Category;
  return proto.marketplace.Category.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.Category} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.Category}
 */
proto.marketplace.Category.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentcategoryxid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconlink(value);
      break;
    case 4:
      var value = new ads_pb.AdParam;
      reader.readMessage(value,ads_pb.AdParam.deserializeBinaryFromReader);
      msg.addAdparams(value);
      break;
    case 5:
      var value = msg.getDefaultlimitsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readFloat, null, "", 0.0);
         });
      break;
    case 6:
      var value = msg.getProductpriceMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readFloat, null, "", 0.0);
         });
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSortedorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.Category.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.Category.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.Category} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Category.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParentcategoryxid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIconlink();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdparamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      ads_pb.AdParam.serializeBinaryToWriter
    );
  }
  f = message.getDefaultlimitsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeFloat);
  }
  f = message.getProductpriceMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeFloat);
  }
  f = message.getSortedorder();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.marketplace.Category.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Category} returns this
 */
proto.marketplace.Category.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string parentCategoryXId = 2;
 * @return {string}
 */
proto.marketplace.Category.prototype.getParentcategoryxid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Category} returns this
 */
proto.marketplace.Category.prototype.setParentcategoryxid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string iconLink = 3;
 * @return {string}
 */
proto.marketplace.Category.prototype.getIconlink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Category} returns this
 */
proto.marketplace.Category.prototype.setIconlink = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated AdParam adParams = 4;
 * @return {!Array<!proto.marketplace.AdParam>}
 */
proto.marketplace.Category.prototype.getAdparamsList = function() {
  return /** @type{!Array<!proto.marketplace.AdParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, ads_pb.AdParam, 4));
};


/**
 * @param {!Array<!proto.marketplace.AdParam>} value
 * @return {!proto.marketplace.Category} returns this
*/
proto.marketplace.Category.prototype.setAdparamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.marketplace.AdParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marketplace.AdParam}
 */
proto.marketplace.Category.prototype.addAdparams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.marketplace.AdParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.Category} returns this
 */
proto.marketplace.Category.prototype.clearAdparamsList = function() {
  return this.setAdparamsList([]);
};


/**
 * map<string, float> defaultLimits = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.marketplace.Category.prototype.getDefaultlimitsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.marketplace.Category} returns this
 */
proto.marketplace.Category.prototype.clearDefaultlimitsMap = function() {
  this.getDefaultlimitsMap().clear();
  return this;};


/**
 * map<string, float> productPrice = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.marketplace.Category.prototype.getProductpriceMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.marketplace.Category} returns this
 */
proto.marketplace.Category.prototype.clearProductpriceMap = function() {
  this.getProductpriceMap().clear();
  return this;};


/**
 * optional int32 sortedOrder = 7;
 * @return {number}
 */
proto.marketplace.Category.prototype.getSortedorder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.Category} returns this
 */
proto.marketplace.Category.prototype.setSortedorder = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


goog.object.extend(exports, proto.marketplace);
