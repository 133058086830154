// source: marketplace.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var discovery_pb = require('./discovery_pb.js');
goog.object.extend(proto, discovery_pb);
var users_pb = require('./users_pb.js');
goog.object.extend(proto, users_pb);
var ads_pb = require('./ads_pb.js');
goog.object.extend(proto, ads_pb);
var categories_pb = require('./categories_pb.js');
goog.object.extend(proto, categories_pb);
var wallets_pb = require('./wallets_pb.js');
goog.object.extend(proto, wallets_pb);
var scheduled_pb = require('./scheduled_pb.js');
goog.object.extend(proto, scheduled_pb);
