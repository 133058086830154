export const TOKEN = 'token';
export const AUTH_TOKEN = 'auth_token';
export const LIMIT = 30;
export const UNAUTHORISED_MESSAGE = 'you are not authorised';
export const LAST_NEWS = '0';
export const ALL_GOVERNORATE = '0';
export const ALL_DELEGATION = '0';
export const TYPE_SELECT = 'array';
export const TYPE_RANGE = 'number';
export const CUSTOM_CATEGORY = 'Vacances';
export const SHOW_ALL_ADS = 9;
export const SHOP_TYPE = 1;

export type Value<T> = T | null;
