// source: messenger.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.messenger.Ad', null, global);
goog.exportSymbol('proto.messenger.BlockUserRequest', null, global);
goog.exportSymbol('proto.messenger.BlockUserResponse', null, global);
goog.exportSymbol('proto.messenger.DeleteMsgRequest', null, global);
goog.exportSymbol('proto.messenger.DeleteMsgResponse', null, global);
goog.exportSymbol('proto.messenger.Discussion', null, global);
goog.exportSymbol('proto.messenger.DiscussionMember', null, global);
goog.exportSymbol('proto.messenger.DiscussionRequest', null, global);
goog.exportSymbol('proto.messenger.DiscussionResponse', null, global);
goog.exportSymbol('proto.messenger.GetAdRelatedDiscussionByMembersRequest', null, global);
goog.exportSymbol('proto.messenger.GetAdRelatedDiscussionByMembersResponse', null, global);
goog.exportSymbol('proto.messenger.GetDiscussionsResponse', null, global);
goog.exportSymbol('proto.messenger.GetMessagesResponse', null, global);
goog.exportSymbol('proto.messenger.InitiateDiscussionRequest', null, global);
goog.exportSymbol('proto.messenger.InitiateDiscussionResponse', null, global);
goog.exportSymbol('proto.messenger.Message', null, global);
goog.exportSymbol('proto.messenger.NewMessageRequest', null, global);
goog.exportSymbol('proto.messenger.UnreadDiscussionsCountResponse', null, global);
goog.exportSymbol('proto.messenger.UserRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.UserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.UserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.UserRequest.displayName = 'proto.messenger.UserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.DiscussionMember = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.DiscussionMember, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.DiscussionMember.displayName = 'proto.messenger.DiscussionMember';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.Ad = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.Ad, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.Ad.displayName = 'proto.messenger.Ad';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.Discussion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.messenger.Discussion.repeatedFields_, null);
};
goog.inherits(proto.messenger.Discussion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.Discussion.displayName = 'proto.messenger.Discussion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.DiscussionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.DiscussionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.DiscussionResponse.displayName = 'proto.messenger.DiscussionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.DiscussionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.DiscussionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.DiscussionRequest.displayName = 'proto.messenger.DiscussionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.Message = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.Message, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.Message.displayName = 'proto.messenger.Message';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.GetDiscussionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.messenger.GetDiscussionsResponse.repeatedFields_, null);
};
goog.inherits(proto.messenger.GetDiscussionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.GetDiscussionsResponse.displayName = 'proto.messenger.GetDiscussionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.GetMessagesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.messenger.GetMessagesResponse.repeatedFields_, null);
};
goog.inherits(proto.messenger.GetMessagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.GetMessagesResponse.displayName = 'proto.messenger.GetMessagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.GetAdRelatedDiscussionByMembersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.GetAdRelatedDiscussionByMembersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.GetAdRelatedDiscussionByMembersRequest.displayName = 'proto.messenger.GetAdRelatedDiscussionByMembersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.GetAdRelatedDiscussionByMembersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.GetAdRelatedDiscussionByMembersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.GetAdRelatedDiscussionByMembersResponse.displayName = 'proto.messenger.GetAdRelatedDiscussionByMembersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.NewMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.NewMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.NewMessageRequest.displayName = 'proto.messenger.NewMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.InitiateDiscussionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.InitiateDiscussionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.InitiateDiscussionRequest.displayName = 'proto.messenger.InitiateDiscussionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.InitiateDiscussionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.InitiateDiscussionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.InitiateDiscussionResponse.displayName = 'proto.messenger.InitiateDiscussionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.UnreadDiscussionsCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.UnreadDiscussionsCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.UnreadDiscussionsCountResponse.displayName = 'proto.messenger.UnreadDiscussionsCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.DeleteMsgRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.DeleteMsgRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.DeleteMsgRequest.displayName = 'proto.messenger.DeleteMsgRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.DeleteMsgResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.DeleteMsgResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.DeleteMsgResponse.displayName = 'proto.messenger.DeleteMsgResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.BlockUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.BlockUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.BlockUserRequest.displayName = 'proto.messenger.BlockUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.messenger.BlockUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.messenger.BlockUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.messenger.BlockUserResponse.displayName = 'proto.messenger.BlockUserResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.UserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.UserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.UserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.UserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    kcid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    skip: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.UserRequest}
 */
proto.messenger.UserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.UserRequest;
  return proto.messenger.UserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.UserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.UserRequest}
 */
proto.messenger.UserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKcid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSkip(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.UserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.UserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.UserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.UserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKcid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkip();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string kcId = 1;
 * @return {string}
 */
proto.messenger.UserRequest.prototype.getKcid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.UserRequest} returns this
 */
proto.messenger.UserRequest.prototype.setKcid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 Skip = 2;
 * @return {number}
 */
proto.messenger.UserRequest.prototype.getSkip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.messenger.UserRequest} returns this
 */
proto.messenger.UserRequest.prototype.setSkip = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 Limit = 3;
 * @return {number}
 */
proto.messenger.UserRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.messenger.UserRequest} returns this
 */
proto.messenger.UserRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.DiscussionMember.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.DiscussionMember.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.DiscussionMember} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.DiscussionMember.toObject = function(includeInstance, msg) {
  var f, obj = {
    kcid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fullname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilepicture: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.DiscussionMember}
 */
proto.messenger.DiscussionMember.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.DiscussionMember;
  return proto.messenger.DiscussionMember.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.DiscussionMember} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.DiscussionMember}
 */
proto.messenger.DiscussionMember.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKcid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilepicture(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.DiscussionMember.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.DiscussionMember.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.DiscussionMember} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.DiscussionMember.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKcid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFullname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilepicture();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string kcId = 1;
 * @return {string}
 */
proto.messenger.DiscussionMember.prototype.getKcid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.DiscussionMember} returns this
 */
proto.messenger.DiscussionMember.prototype.setKcid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fullname = 2;
 * @return {string}
 */
proto.messenger.DiscussionMember.prototype.getFullname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.DiscussionMember} returns this
 */
proto.messenger.DiscussionMember.prototype.setFullname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profilePicture = 3;
 * @return {string}
 */
proto.messenger.DiscussionMember.prototype.getProfilepicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.DiscussionMember} returns this
 */
proto.messenger.DiscussionMember.prototype.setProfilepicture = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.Ad.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.Ad.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.Ad} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.Ad.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    picture: jspb.Message.getFieldWithDefault(msg, 3, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    userid: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.Ad}
 */
proto.messenger.Ad.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.Ad;
  return proto.messenger.Ad.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.Ad} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.Ad}
 */
proto.messenger.Ad.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPicture(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.Ad.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.Ad.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.Ad} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.Ad.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPicture();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.messenger.Ad.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.Ad} returns this
 */
proto.messenger.Ad.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.messenger.Ad.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.Ad} returns this
 */
proto.messenger.Ad.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string picture = 3;
 * @return {string}
 */
proto.messenger.Ad.prototype.getPicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.Ad} returns this
 */
proto.messenger.Ad.prototype.setPicture = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double price = 4;
 * @return {number}
 */
proto.messenger.Ad.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.messenger.Ad} returns this
 */
proto.messenger.Ad.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string userid = 5;
 * @return {string}
 */
proto.messenger.Ad.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.Ad} returns this
 */
proto.messenger.Ad.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.messenger.Discussion.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.Discussion.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.Discussion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.Discussion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.Discussion.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastmessagedate: (f = msg.getLastmessagedate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastmessagepreview: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    membersList: jspb.Message.toObjectList(msg.getMembersList(),
    proto.messenger.DiscussionMember.toObject, includeInstance),
    ad: (f = msg.getAd()) && proto.messenger.Ad.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.Discussion}
 */
proto.messenger.Discussion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.Discussion;
  return proto.messenger.Discussion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.Discussion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.Discussion}
 */
proto.messenger.Discussion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastmessagedate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastmessagepreview(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 6:
      var value = new proto.messenger.DiscussionMember;
      reader.readMessage(value,proto.messenger.DiscussionMember.deserializeBinaryFromReader);
      msg.addMembers(value);
      break;
    case 7:
      var value = new proto.messenger.Ad;
      reader.readMessage(value,proto.messenger.Ad.deserializeBinaryFromReader);
      msg.setAd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.Discussion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.Discussion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.Discussion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.Discussion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastmessagedate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastmessagepreview();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMembersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.messenger.DiscussionMember.serializeBinaryToWriter
    );
  }
  f = message.getAd();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.messenger.Ad.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp lastMessageDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.messenger.Discussion.prototype.getLastmessagedate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.messenger.Discussion} returns this
*/
proto.messenger.Discussion.prototype.setLastmessagedate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messenger.Discussion} returns this
 */
proto.messenger.Discussion.prototype.clearLastmessagedate = function() {
  return this.setLastmessagedate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messenger.Discussion.prototype.hasLastmessagedate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string lastMessagePreview = 3;
 * @return {string}
 */
proto.messenger.Discussion.prototype.getLastmessagepreview = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.Discussion} returns this
 */
proto.messenger.Discussion.prototype.setLastmessagepreview = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.messenger.Discussion.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.messenger.Discussion} returns this
*/
proto.messenger.Discussion.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messenger.Discussion} returns this
 */
proto.messenger.Discussion.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messenger.Discussion.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp updatedAt = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.messenger.Discussion.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.messenger.Discussion} returns this
*/
proto.messenger.Discussion.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messenger.Discussion} returns this
 */
proto.messenger.Discussion.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messenger.Discussion.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated DiscussionMember members = 6;
 * @return {!Array<!proto.messenger.DiscussionMember>}
 */
proto.messenger.Discussion.prototype.getMembersList = function() {
  return /** @type{!Array<!proto.messenger.DiscussionMember>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.messenger.DiscussionMember, 6));
};


/**
 * @param {!Array<!proto.messenger.DiscussionMember>} value
 * @return {!proto.messenger.Discussion} returns this
*/
proto.messenger.Discussion.prototype.setMembersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.messenger.DiscussionMember=} opt_value
 * @param {number=} opt_index
 * @return {!proto.messenger.DiscussionMember}
 */
proto.messenger.Discussion.prototype.addMembers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.messenger.DiscussionMember, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.messenger.Discussion} returns this
 */
proto.messenger.Discussion.prototype.clearMembersList = function() {
  return this.setMembersList([]);
};


/**
 * optional Ad ad = 7;
 * @return {?proto.messenger.Ad}
 */
proto.messenger.Discussion.prototype.getAd = function() {
  return /** @type{?proto.messenger.Ad} */ (
    jspb.Message.getWrapperField(this, proto.messenger.Ad, 7));
};


/**
 * @param {?proto.messenger.Ad|undefined} value
 * @return {!proto.messenger.Discussion} returns this
*/
proto.messenger.Discussion.prototype.setAd = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messenger.Discussion} returns this
 */
proto.messenger.Discussion.prototype.clearAd = function() {
  return this.setAd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messenger.Discussion.prototype.hasAd = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.DiscussionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.DiscussionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.DiscussionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.DiscussionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    discussion: (f = msg.getDiscussion()) && proto.messenger.Discussion.toObject(includeInstance, f),
    unreadmessages: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.DiscussionResponse}
 */
proto.messenger.DiscussionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.DiscussionResponse;
  return proto.messenger.DiscussionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.DiscussionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.DiscussionResponse}
 */
proto.messenger.DiscussionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.messenger.Discussion;
      reader.readMessage(value,proto.messenger.Discussion.deserializeBinaryFromReader);
      msg.setDiscussion(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnreadmessages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.DiscussionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.DiscussionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.DiscussionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.DiscussionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDiscussion();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.messenger.Discussion.serializeBinaryToWriter
    );
  }
  f = message.getUnreadmessages();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.messenger.DiscussionResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.DiscussionResponse} returns this
 */
proto.messenger.DiscussionResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Discussion discussion = 2;
 * @return {?proto.messenger.Discussion}
 */
proto.messenger.DiscussionResponse.prototype.getDiscussion = function() {
  return /** @type{?proto.messenger.Discussion} */ (
    jspb.Message.getWrapperField(this, proto.messenger.Discussion, 2));
};


/**
 * @param {?proto.messenger.Discussion|undefined} value
 * @return {!proto.messenger.DiscussionResponse} returns this
*/
proto.messenger.DiscussionResponse.prototype.setDiscussion = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messenger.DiscussionResponse} returns this
 */
proto.messenger.DiscussionResponse.prototype.clearDiscussion = function() {
  return this.setDiscussion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messenger.DiscussionResponse.prototype.hasDiscussion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 unreadMessages = 3;
 * @return {number}
 */
proto.messenger.DiscussionResponse.prototype.getUnreadmessages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.messenger.DiscussionResponse} returns this
 */
proto.messenger.DiscussionResponse.prototype.setUnreadmessages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.DiscussionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.DiscussionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.DiscussionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.DiscussionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    discussionid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.DiscussionRequest}
 */
proto.messenger.DiscussionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.DiscussionRequest;
  return proto.messenger.DiscussionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.DiscussionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.DiscussionRequest}
 */
proto.messenger.DiscussionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscussionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.DiscussionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.DiscussionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.DiscussionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.DiscussionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiscussionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string discussionId = 1;
 * @return {string}
 */
proto.messenger.DiscussionRequest.prototype.getDiscussionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.DiscussionRequest} returns this
 */
proto.messenger.DiscussionRequest.prototype.setDiscussionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.Message.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.Message.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.Message} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.Message.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    discussionid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    senderid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    text: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    read: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.Message}
 */
proto.messenger.Message.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.Message;
  return proto.messenger.Message.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.Message}
 */
proto.messenger.Message.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscussionid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRead(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.Message.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.Message.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDiscussionid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSenderid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRead();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.messenger.Message.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.Message} returns this
 */
proto.messenger.Message.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string discussionId = 2;
 * @return {string}
 */
proto.messenger.Message.prototype.getDiscussionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.Message} returns this
 */
proto.messenger.Message.prototype.setDiscussionid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string senderId = 3;
 * @return {string}
 */
proto.messenger.Message.prototype.getSenderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.Message} returns this
 */
proto.messenger.Message.prototype.setSenderid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string text = 4;
 * @return {string}
 */
proto.messenger.Message.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.Message} returns this
 */
proto.messenger.Message.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.messenger.Message.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.messenger.Message} returns this
*/
proto.messenger.Message.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messenger.Message} returns this
 */
proto.messenger.Message.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messenger.Message.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool read = 6;
 * @return {boolean}
 */
proto.messenger.Message.prototype.getRead = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.messenger.Message} returns this
 */
proto.messenger.Message.prototype.setRead = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool deleted = 7;
 * @return {boolean}
 */
proto.messenger.Message.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.messenger.Message} returns this
 */
proto.messenger.Message.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.messenger.GetDiscussionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.GetDiscussionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.GetDiscussionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.GetDiscussionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.GetDiscussionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    discussionsList: jspb.Message.toObjectList(msg.getDiscussionsList(),
    proto.messenger.DiscussionResponse.toObject, includeInstance),
    countdisscussion: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.GetDiscussionsResponse}
 */
proto.messenger.GetDiscussionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.GetDiscussionsResponse;
  return proto.messenger.GetDiscussionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.GetDiscussionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.GetDiscussionsResponse}
 */
proto.messenger.GetDiscussionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.messenger.DiscussionResponse;
      reader.readMessage(value,proto.messenger.DiscussionResponse.deserializeBinaryFromReader);
      msg.addDiscussions(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCountdisscussion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.GetDiscussionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.GetDiscussionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.GetDiscussionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.GetDiscussionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiscussionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.messenger.DiscussionResponse.serializeBinaryToWriter
    );
  }
  f = message.getCountdisscussion();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated DiscussionResponse discussions = 1;
 * @return {!Array<!proto.messenger.DiscussionResponse>}
 */
proto.messenger.GetDiscussionsResponse.prototype.getDiscussionsList = function() {
  return /** @type{!Array<!proto.messenger.DiscussionResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.messenger.DiscussionResponse, 1));
};


/**
 * @param {!Array<!proto.messenger.DiscussionResponse>} value
 * @return {!proto.messenger.GetDiscussionsResponse} returns this
*/
proto.messenger.GetDiscussionsResponse.prototype.setDiscussionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.messenger.DiscussionResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.messenger.DiscussionResponse}
 */
proto.messenger.GetDiscussionsResponse.prototype.addDiscussions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.messenger.DiscussionResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.messenger.GetDiscussionsResponse} returns this
 */
proto.messenger.GetDiscussionsResponse.prototype.clearDiscussionsList = function() {
  return this.setDiscussionsList([]);
};


/**
 * optional int64 CountDisscussion = 2;
 * @return {number}
 */
proto.messenger.GetDiscussionsResponse.prototype.getCountdisscussion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.messenger.GetDiscussionsResponse} returns this
 */
proto.messenger.GetDiscussionsResponse.prototype.setCountdisscussion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.messenger.GetMessagesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.GetMessagesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.GetMessagesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.GetMessagesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.GetMessagesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    messagesList: jspb.Message.toObjectList(msg.getMessagesList(),
    proto.messenger.Message.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.GetMessagesResponse}
 */
proto.messenger.GetMessagesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.GetMessagesResponse;
  return proto.messenger.GetMessagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.GetMessagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.GetMessagesResponse}
 */
proto.messenger.GetMessagesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.messenger.Message;
      reader.readMessage(value,proto.messenger.Message.deserializeBinaryFromReader);
      msg.addMessages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.GetMessagesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.GetMessagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.GetMessagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.GetMessagesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.messenger.Message.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Message messages = 1;
 * @return {!Array<!proto.messenger.Message>}
 */
proto.messenger.GetMessagesResponse.prototype.getMessagesList = function() {
  return /** @type{!Array<!proto.messenger.Message>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.messenger.Message, 1));
};


/**
 * @param {!Array<!proto.messenger.Message>} value
 * @return {!proto.messenger.GetMessagesResponse} returns this
*/
proto.messenger.GetMessagesResponse.prototype.setMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.messenger.Message=} opt_value
 * @param {number=} opt_index
 * @return {!proto.messenger.Message}
 */
proto.messenger.GetMessagesResponse.prototype.addMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.messenger.Message, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.messenger.GetMessagesResponse} returns this
 */
proto.messenger.GetMessagesResponse.prototype.clearMessagesList = function() {
  return this.setMessagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.GetAdRelatedDiscussionByMembersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.GetAdRelatedDiscussionByMembersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.GetAdRelatedDiscussionByMembersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.GetAdRelatedDiscussionByMembersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstmemberkcid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    secondmemberkcid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.GetAdRelatedDiscussionByMembersRequest}
 */
proto.messenger.GetAdRelatedDiscussionByMembersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.GetAdRelatedDiscussionByMembersRequest;
  return proto.messenger.GetAdRelatedDiscussionByMembersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.GetAdRelatedDiscussionByMembersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.GetAdRelatedDiscussionByMembersRequest}
 */
proto.messenger.GetAdRelatedDiscussionByMembersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstmemberkcid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondmemberkcid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.GetAdRelatedDiscussionByMembersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.GetAdRelatedDiscussionByMembersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.GetAdRelatedDiscussionByMembersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.GetAdRelatedDiscussionByMembersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstmemberkcid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSecondmemberkcid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string firstMemberKcid = 1;
 * @return {string}
 */
proto.messenger.GetAdRelatedDiscussionByMembersRequest.prototype.getFirstmemberkcid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.GetAdRelatedDiscussionByMembersRequest} returns this
 */
proto.messenger.GetAdRelatedDiscussionByMembersRequest.prototype.setFirstmemberkcid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secondMemberKcid = 2;
 * @return {string}
 */
proto.messenger.GetAdRelatedDiscussionByMembersRequest.prototype.getSecondmemberkcid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.GetAdRelatedDiscussionByMembersRequest} returns this
 */
proto.messenger.GetAdRelatedDiscussionByMembersRequest.prototype.setSecondmemberkcid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string adId = 3;
 * @return {string}
 */
proto.messenger.GetAdRelatedDiscussionByMembersRequest.prototype.getAdid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.GetAdRelatedDiscussionByMembersRequest} returns this
 */
proto.messenger.GetAdRelatedDiscussionByMembersRequest.prototype.setAdid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.GetAdRelatedDiscussionByMembersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.GetAdRelatedDiscussionByMembersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.GetAdRelatedDiscussionByMembersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.GetAdRelatedDiscussionByMembersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    discussionresponse: (f = msg.getDiscussionresponse()) && proto.messenger.DiscussionResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.GetAdRelatedDiscussionByMembersResponse}
 */
proto.messenger.GetAdRelatedDiscussionByMembersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.GetAdRelatedDiscussionByMembersResponse;
  return proto.messenger.GetAdRelatedDiscussionByMembersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.GetAdRelatedDiscussionByMembersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.GetAdRelatedDiscussionByMembersResponse}
 */
proto.messenger.GetAdRelatedDiscussionByMembersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.messenger.DiscussionResponse;
      reader.readMessage(value,proto.messenger.DiscussionResponse.deserializeBinaryFromReader);
      msg.setDiscussionresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.GetAdRelatedDiscussionByMembersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.GetAdRelatedDiscussionByMembersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.GetAdRelatedDiscussionByMembersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.GetAdRelatedDiscussionByMembersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiscussionresponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.messenger.DiscussionResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional DiscussionResponse discussionResponse = 1;
 * @return {?proto.messenger.DiscussionResponse}
 */
proto.messenger.GetAdRelatedDiscussionByMembersResponse.prototype.getDiscussionresponse = function() {
  return /** @type{?proto.messenger.DiscussionResponse} */ (
    jspb.Message.getWrapperField(this, proto.messenger.DiscussionResponse, 1));
};


/**
 * @param {?proto.messenger.DiscussionResponse|undefined} value
 * @return {!proto.messenger.GetAdRelatedDiscussionByMembersResponse} returns this
*/
proto.messenger.GetAdRelatedDiscussionByMembersResponse.prototype.setDiscussionresponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messenger.GetAdRelatedDiscussionByMembersResponse} returns this
 */
proto.messenger.GetAdRelatedDiscussionByMembersResponse.prototype.clearDiscussionresponse = function() {
  return this.setDiscussionresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messenger.GetAdRelatedDiscussionByMembersResponse.prototype.hasDiscussionresponse = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.NewMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.NewMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.NewMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.NewMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    senderid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    discussionid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    text: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.NewMessageRequest}
 */
proto.messenger.NewMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.NewMessageRequest;
  return proto.messenger.NewMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.NewMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.NewMessageRequest}
 */
proto.messenger.NewMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscussionid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.NewMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.NewMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.NewMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.NewMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSenderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDiscussionid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string senderId = 1;
 * @return {string}
 */
proto.messenger.NewMessageRequest.prototype.getSenderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.NewMessageRequest} returns this
 */
proto.messenger.NewMessageRequest.prototype.setSenderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string discussionId = 2;
 * @return {string}
 */
proto.messenger.NewMessageRequest.prototype.getDiscussionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.NewMessageRequest} returns this
 */
proto.messenger.NewMessageRequest.prototype.setDiscussionid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string text = 4;
 * @return {string}
 */
proto.messenger.NewMessageRequest.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.NewMessageRequest} returns this
 */
proto.messenger.NewMessageRequest.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.InitiateDiscussionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.InitiateDiscussionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.InitiateDiscussionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.InitiateDiscussionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    senderid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partnerid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    text: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.InitiateDiscussionRequest}
 */
proto.messenger.InitiateDiscussionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.InitiateDiscussionRequest;
  return proto.messenger.InitiateDiscussionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.InitiateDiscussionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.InitiateDiscussionRequest}
 */
proto.messenger.InitiateDiscussionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.InitiateDiscussionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.InitiateDiscussionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.InitiateDiscussionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.InitiateDiscussionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSenderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartnerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string senderId = 1;
 * @return {string}
 */
proto.messenger.InitiateDiscussionRequest.prototype.getSenderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.InitiateDiscussionRequest} returns this
 */
proto.messenger.InitiateDiscussionRequest.prototype.setSenderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string partnerId = 2;
 * @return {string}
 */
proto.messenger.InitiateDiscussionRequest.prototype.getPartnerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.InitiateDiscussionRequest} returns this
 */
proto.messenger.InitiateDiscussionRequest.prototype.setPartnerid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string adId = 3;
 * @return {string}
 */
proto.messenger.InitiateDiscussionRequest.prototype.getAdid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.InitiateDiscussionRequest} returns this
 */
proto.messenger.InitiateDiscussionRequest.prototype.setAdid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string text = 4;
 * @return {string}
 */
proto.messenger.InitiateDiscussionRequest.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.InitiateDiscussionRequest} returns this
 */
proto.messenger.InitiateDiscussionRequest.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.InitiateDiscussionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.InitiateDiscussionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.InitiateDiscussionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.InitiateDiscussionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    discussion: (f = msg.getDiscussion()) && proto.messenger.DiscussionResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.InitiateDiscussionResponse}
 */
proto.messenger.InitiateDiscussionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.InitiateDiscussionResponse;
  return proto.messenger.InitiateDiscussionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.InitiateDiscussionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.InitiateDiscussionResponse}
 */
proto.messenger.InitiateDiscussionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.messenger.DiscussionResponse;
      reader.readMessage(value,proto.messenger.DiscussionResponse.deserializeBinaryFromReader);
      msg.setDiscussion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.InitiateDiscussionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.InitiateDiscussionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.InitiateDiscussionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.InitiateDiscussionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiscussion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.messenger.DiscussionResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional DiscussionResponse discussion = 1;
 * @return {?proto.messenger.DiscussionResponse}
 */
proto.messenger.InitiateDiscussionResponse.prototype.getDiscussion = function() {
  return /** @type{?proto.messenger.DiscussionResponse} */ (
    jspb.Message.getWrapperField(this, proto.messenger.DiscussionResponse, 1));
};


/**
 * @param {?proto.messenger.DiscussionResponse|undefined} value
 * @return {!proto.messenger.InitiateDiscussionResponse} returns this
*/
proto.messenger.InitiateDiscussionResponse.prototype.setDiscussion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.messenger.InitiateDiscussionResponse} returns this
 */
proto.messenger.InitiateDiscussionResponse.prototype.clearDiscussion = function() {
  return this.setDiscussion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.messenger.InitiateDiscussionResponse.prototype.hasDiscussion = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.UnreadDiscussionsCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.UnreadDiscussionsCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.UnreadDiscussionsCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.UnreadDiscussionsCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.UnreadDiscussionsCountResponse}
 */
proto.messenger.UnreadDiscussionsCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.UnreadDiscussionsCountResponse;
  return proto.messenger.UnreadDiscussionsCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.UnreadDiscussionsCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.UnreadDiscussionsCountResponse}
 */
proto.messenger.UnreadDiscussionsCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.UnreadDiscussionsCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.UnreadDiscussionsCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.UnreadDiscussionsCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.UnreadDiscussionsCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 count = 1;
 * @return {number}
 */
proto.messenger.UnreadDiscussionsCountResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.messenger.UnreadDiscussionsCountResponse} returns this
 */
proto.messenger.UnreadDiscussionsCountResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.DeleteMsgRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.DeleteMsgRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.DeleteMsgRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.DeleteMsgRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.DeleteMsgRequest}
 */
proto.messenger.DeleteMsgRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.DeleteMsgRequest;
  return proto.messenger.DeleteMsgRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.DeleteMsgRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.DeleteMsgRequest}
 */
proto.messenger.DeleteMsgRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.DeleteMsgRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.DeleteMsgRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.DeleteMsgRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.DeleteMsgRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string MessageId = 1;
 * @return {string}
 */
proto.messenger.DeleteMsgRequest.prototype.getMessageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.DeleteMsgRequest} returns this
 */
proto.messenger.DeleteMsgRequest.prototype.setMessageid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.DeleteMsgResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.DeleteMsgResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.DeleteMsgResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.DeleteMsgResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.DeleteMsgResponse}
 */
proto.messenger.DeleteMsgResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.DeleteMsgResponse;
  return proto.messenger.DeleteMsgResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.DeleteMsgResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.DeleteMsgResponse}
 */
proto.messenger.DeleteMsgResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.DeleteMsgResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.DeleteMsgResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.DeleteMsgResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.DeleteMsgResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool Deleted = 1;
 * @return {boolean}
 */
proto.messenger.DeleteMsgResponse.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.messenger.DeleteMsgResponse} returns this
 */
proto.messenger.DeleteMsgResponse.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.BlockUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.BlockUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.BlockUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.BlockUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.BlockUserRequest}
 */
proto.messenger.BlockUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.BlockUserRequest;
  return proto.messenger.BlockUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.BlockUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.BlockUserRequest}
 */
proto.messenger.BlockUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.BlockUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.BlockUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.BlockUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.BlockUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string UserId = 1;
 * @return {string}
 */
proto.messenger.BlockUserRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.messenger.BlockUserRequest} returns this
 */
proto.messenger.BlockUserRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.messenger.BlockUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.messenger.BlockUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.messenger.BlockUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.BlockUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    blocked: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.messenger.BlockUserResponse}
 */
proto.messenger.BlockUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.messenger.BlockUserResponse;
  return proto.messenger.BlockUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.messenger.BlockUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.messenger.BlockUserResponse}
 */
proto.messenger.BlockUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBlocked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.messenger.BlockUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.messenger.BlockUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.messenger.BlockUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.messenger.BlockUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlocked();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool Blocked = 1;
 * @return {boolean}
 */
proto.messenger.BlockUserResponse.prototype.getBlocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.messenger.BlockUserResponse} returns this
 */
proto.messenger.BlockUserResponse.prototype.setBlocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.messenger);
