// source: users.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.marketplace.Advantages', null, global);
goog.exportSymbol('proto.marketplace.Blacklist', null, global);
goog.exportSymbol('proto.marketplace.BuyTokensRequest', null, global);
goog.exportSymbol('proto.marketplace.BuyTokensResponse', null, global);
goog.exportSymbol('proto.marketplace.ConsumeTokensByUserRequest', null, global);
goog.exportSymbol('proto.marketplace.ConsumeTokensByUserResponse', null, global);
goog.exportSymbol('proto.marketplace.GetAccountTransactionsRequest', null, global);
goog.exportSymbol('proto.marketplace.GetUserBalanceResponse', null, global);
goog.exportSymbol('proto.marketplace.GetUserKeysRequest', null, global);
goog.exportSymbol('proto.marketplace.GetUserKeysResponse', null, global);
goog.exportSymbol('proto.marketplace.GetUserTransactionsBalanceForAdminResponse', null, global);
goog.exportSymbol('proto.marketplace.GetWalletsAccountRequest', null, global);
goog.exportSymbol('proto.marketplace.GetWalletsAccountResponse', null, global);
goog.exportSymbol('proto.marketplace.NewUserByIDRequest', null, global);
goog.exportSymbol('proto.marketplace.NewUserRequest', null, global);
goog.exportSymbol('proto.marketplace.ProductType', null, global);
goog.exportSymbol('proto.marketplace.SendUserDeviceConfigRequest', null, global);
goog.exportSymbol('proto.marketplace.SendUserDeviceConfigResponse', null, global);
goog.exportSymbol('proto.marketplace.ShopCountAdResponse', null, global);
goog.exportSymbol('proto.marketplace.TotalUserCountResponse', null, global);
goog.exportSymbol('proto.marketplace.Transaction', null, global);
goog.exportSymbol('proto.marketplace.Transaction.Status', null, global);
goog.exportSymbol('proto.marketplace.TransferTokensByAdminRequest', null, global);
goog.exportSymbol('proto.marketplace.TransferTokensResponse', null, global);
goog.exportSymbol('proto.marketplace.TransfertTransactionForAdmin', null, global);
goog.exportSymbol('proto.marketplace.User', null, global);
goog.exportSymbol('proto.marketplace.UserByIDRequest', null, global);
goog.exportSymbol('proto.marketplace.UserByPhoneNumberRequest', null, global);
goog.exportSymbol('proto.marketplace.UserGeolocation', null, global);
goog.exportSymbol('proto.marketplace.UserLocation', null, global);
goog.exportSymbol('proto.marketplace.UserResponse', null, global);
goog.exportSymbol('proto.marketplace.UserState', null, global);
goog.exportSymbol('proto.marketplace.UserState.UserStateChangeType', null, global);
goog.exportSymbol('proto.marketplace.UserType', null, global);
goog.exportSymbol('proto.marketplace.WalletsCreateAccountRequest', null, global);
goog.exportSymbol('proto.marketplace.WalletsCreateAccountWithKeysRequest', null, global);
goog.exportSymbol('proto.marketplace.createAccountResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.Blacklist = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.Blacklist, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.Blacklist.displayName = 'proto.marketplace.Blacklist';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketplace.User.repeatedFields_, null);
};
goog.inherits(proto.marketplace.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.User.displayName = 'proto.marketplace.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.UserState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketplace.UserState.repeatedFields_, null);
};
goog.inherits(proto.marketplace.UserState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.UserState.displayName = 'proto.marketplace.UserState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.NewUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketplace.NewUserRequest.repeatedFields_, null);
};
goog.inherits(proto.marketplace.NewUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.NewUserRequest.displayName = 'proto.marketplace.NewUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.UserLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.UserLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.UserLocation.displayName = 'proto.marketplace.UserLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.TotalUserCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.TotalUserCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.TotalUserCountResponse.displayName = 'proto.marketplace.TotalUserCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.UserGeolocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.UserGeolocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.UserGeolocation.displayName = 'proto.marketplace.UserGeolocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.UserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.UserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.UserResponse.displayName = 'proto.marketplace.UserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.ShopCountAdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.ShopCountAdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.ShopCountAdResponse.displayName = 'proto.marketplace.ShopCountAdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.UserByIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.UserByIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.UserByIDRequest.displayName = 'proto.marketplace.UserByIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.UserByPhoneNumberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.UserByPhoneNumberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.UserByPhoneNumberRequest.displayName = 'proto.marketplace.UserByPhoneNumberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.NewUserByIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketplace.NewUserByIDRequest.repeatedFields_, null);
};
goog.inherits(proto.marketplace.NewUserByIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.NewUserByIDRequest.displayName = 'proto.marketplace.NewUserByIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.GetUserKeysRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.GetUserKeysRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.GetUserKeysRequest.displayName = 'proto.marketplace.GetUserKeysRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.GetUserKeysResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.GetUserKeysResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.GetUserKeysResponse.displayName = 'proto.marketplace.GetUserKeysResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.GetAccountTransactionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.GetAccountTransactionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.GetAccountTransactionsRequest.displayName = 'proto.marketplace.GetAccountTransactionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.BuyTokensRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.BuyTokensRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.BuyTokensRequest.displayName = 'proto.marketplace.BuyTokensRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.BuyTokensResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.BuyTokensResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.BuyTokensResponse.displayName = 'proto.marketplace.BuyTokensResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.createAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.createAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.createAccountResponse.displayName = 'proto.marketplace.createAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.ConsumeTokensByUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.ConsumeTokensByUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.ConsumeTokensByUserRequest.displayName = 'proto.marketplace.ConsumeTokensByUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.ConsumeTokensByUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.ConsumeTokensByUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.ConsumeTokensByUserResponse.displayName = 'proto.marketplace.ConsumeTokensByUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.GetUserBalanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.GetUserBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.GetUserBalanceResponse.displayName = 'proto.marketplace.GetUserBalanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.WalletsCreateAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.WalletsCreateAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.WalletsCreateAccountRequest.displayName = 'proto.marketplace.WalletsCreateAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.WalletsCreateAccountWithKeysRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.WalletsCreateAccountWithKeysRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.WalletsCreateAccountWithKeysRequest.displayName = 'proto.marketplace.WalletsCreateAccountWithKeysRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.GetWalletsAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.GetWalletsAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.GetWalletsAccountRequest.displayName = 'proto.marketplace.GetWalletsAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.GetWalletsAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.GetWalletsAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.GetWalletsAccountResponse.displayName = 'proto.marketplace.GetWalletsAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.Transaction.displayName = 'proto.marketplace.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.TransferTokensByAdminRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.TransferTokensByAdminRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.TransferTokensByAdminRequest.displayName = 'proto.marketplace.TransferTokensByAdminRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.TransferTokensResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.TransferTokensResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.TransferTokensResponse.displayName = 'proto.marketplace.TransferTokensResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.TransfertTransactionForAdmin = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.TransfertTransactionForAdmin, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.TransfertTransactionForAdmin.displayName = 'proto.marketplace.TransfertTransactionForAdmin';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.GetUserTransactionsBalanceForAdminResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketplace.GetUserTransactionsBalanceForAdminResponse.repeatedFields_, null);
};
goog.inherits(proto.marketplace.GetUserTransactionsBalanceForAdminResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.GetUserTransactionsBalanceForAdminResponse.displayName = 'proto.marketplace.GetUserTransactionsBalanceForAdminResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.SendUserDeviceConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.SendUserDeviceConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.SendUserDeviceConfigRequest.displayName = 'proto.marketplace.SendUserDeviceConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.SendUserDeviceConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.SendUserDeviceConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.SendUserDeviceConfigResponse.displayName = 'proto.marketplace.SendUserDeviceConfigResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.Blacklist.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.Blacklist.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.Blacklist} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Blacklist.toObject = function(includeInstance, msg) {
  var f, obj = {
    blockerkcid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    blockedkcid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    blockedname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    blockdate: (f = msg.getBlockdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.Blacklist}
 */
proto.marketplace.Blacklist.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.Blacklist;
  return proto.marketplace.Blacklist.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.Blacklist} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.Blacklist}
 */
proto.marketplace.Blacklist.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockerkcid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockedkcid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockedname(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBlockdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.Blacklist.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.Blacklist.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.Blacklist} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Blacklist.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlockerkcid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBlockedkcid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBlockedname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBlockdate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string Blockerkcid = 1;
 * @return {string}
 */
proto.marketplace.Blacklist.prototype.getBlockerkcid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Blacklist} returns this
 */
proto.marketplace.Blacklist.prototype.setBlockerkcid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Blockedkcid = 2;
 * @return {string}
 */
proto.marketplace.Blacklist.prototype.getBlockedkcid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Blacklist} returns this
 */
proto.marketplace.Blacklist.prototype.setBlockedkcid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string BlockedName = 3;
 * @return {string}
 */
proto.marketplace.Blacklist.prototype.getBlockedname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Blacklist} returns this
 */
proto.marketplace.Blacklist.prototype.setBlockedname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp BlockDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marketplace.Blacklist.prototype.getBlockdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marketplace.Blacklist} returns this
*/
proto.marketplace.Blacklist.prototype.setBlockdate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.Blacklist} returns this
 */
proto.marketplace.Blacklist.prototype.clearBlockdate = function() {
  return this.setBlockdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.Blacklist.prototype.hasBlockdate = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketplace.User.repeatedFields_ = [4,7,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.User.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    kcid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creationtimestamp: (f = msg.getCreationtimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    currentstate: (f = msg.getCurrentstate()) && proto.marketplace.UserState.toObject(includeInstance, f),
    statesList: jspb.Message.toObjectList(msg.getStatesList(),
    proto.marketplace.UserState.toObject, includeInstance),
    usertype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    shopbackgroundimage: jspb.Message.getFieldWithDefault(msg, 6, ""),
    favoritesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    publickey: jspb.Message.getFieldWithDefault(msg, 9, ""),
    privatekey: jspb.Message.getFieldWithDefault(msg, 10, ""),
    label: jspb.Message.getFieldWithDefault(msg, 11, ""),
    blacklistList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    producttype: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.User}
 */
proto.marketplace.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.User;
  return proto.marketplace.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.User}
 */
proto.marketplace.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKcid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationtimestamp(value);
      break;
    case 3:
      var value = new proto.marketplace.UserState;
      reader.readMessage(value,proto.marketplace.UserState.deserializeBinaryFromReader);
      msg.setCurrentstate(value);
      break;
    case 4:
      var value = new proto.marketplace.UserState;
      reader.readMessage(value,proto.marketplace.UserState.deserializeBinaryFromReader);
      msg.addStates(value);
      break;
    case 5:
      var value = /** @type {!proto.marketplace.UserType} */ (reader.readEnum());
      msg.setUsertype(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopbackgroundimage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addFavorites(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublickey(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivatekey(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addBlacklist(value);
      break;
    case 13:
      var value = /** @type {!proto.marketplace.ProductType} */ (reader.readEnum());
      msg.setProducttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKcid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreationtimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCurrentstate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.marketplace.UserState.serializeBinaryToWriter
    );
  }
  f = message.getStatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.marketplace.UserState.serializeBinaryToWriter
    );
  }
  f = message.getUsertype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getShopbackgroundimage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFavoritesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getPublickey();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPrivatekey();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getBlacklistList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getProducttype();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
};


/**
 * optional string kcId = 1;
 * @return {string}
 */
proto.marketplace.User.prototype.getKcid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.User} returns this
 */
proto.marketplace.User.prototype.setKcid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp creationTimestamp = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marketplace.User.prototype.getCreationtimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marketplace.User} returns this
*/
proto.marketplace.User.prototype.setCreationtimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.User} returns this
 */
proto.marketplace.User.prototype.clearCreationtimestamp = function() {
  return this.setCreationtimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.User.prototype.hasCreationtimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UserState currentState = 3;
 * @return {?proto.marketplace.UserState}
 */
proto.marketplace.User.prototype.getCurrentstate = function() {
  return /** @type{?proto.marketplace.UserState} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.UserState, 3));
};


/**
 * @param {?proto.marketplace.UserState|undefined} value
 * @return {!proto.marketplace.User} returns this
*/
proto.marketplace.User.prototype.setCurrentstate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.User} returns this
 */
proto.marketplace.User.prototype.clearCurrentstate = function() {
  return this.setCurrentstate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.User.prototype.hasCurrentstate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated UserState states = 4;
 * @return {!Array<!proto.marketplace.UserState>}
 */
proto.marketplace.User.prototype.getStatesList = function() {
  return /** @type{!Array<!proto.marketplace.UserState>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.marketplace.UserState, 4));
};


/**
 * @param {!Array<!proto.marketplace.UserState>} value
 * @return {!proto.marketplace.User} returns this
*/
proto.marketplace.User.prototype.setStatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.marketplace.UserState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marketplace.UserState}
 */
proto.marketplace.User.prototype.addStates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.marketplace.UserState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.User} returns this
 */
proto.marketplace.User.prototype.clearStatesList = function() {
  return this.setStatesList([]);
};


/**
 * optional UserType userType = 5;
 * @return {!proto.marketplace.UserType}
 */
proto.marketplace.User.prototype.getUsertype = function() {
  return /** @type {!proto.marketplace.UserType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.marketplace.UserType} value
 * @return {!proto.marketplace.User} returns this
 */
proto.marketplace.User.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string shopBackgroundImage = 6;
 * @return {string}
 */
proto.marketplace.User.prototype.getShopbackgroundimage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.User} returns this
 */
proto.marketplace.User.prototype.setShopbackgroundimage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string favorites = 7;
 * @return {!Array<string>}
 */
proto.marketplace.User.prototype.getFavoritesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.marketplace.User} returns this
 */
proto.marketplace.User.prototype.setFavoritesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.marketplace.User} returns this
 */
proto.marketplace.User.prototype.addFavorites = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.User} returns this
 */
proto.marketplace.User.prototype.clearFavoritesList = function() {
  return this.setFavoritesList([]);
};


/**
 * optional bool enabled = 8;
 * @return {boolean}
 */
proto.marketplace.User.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marketplace.User} returns this
 */
proto.marketplace.User.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string publicKey = 9;
 * @return {string}
 */
proto.marketplace.User.prototype.getPublickey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.User} returns this
 */
proto.marketplace.User.prototype.setPublickey = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string privateKey = 10;
 * @return {string}
 */
proto.marketplace.User.prototype.getPrivatekey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.User} returns this
 */
proto.marketplace.User.prototype.setPrivatekey = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string label = 11;
 * @return {string}
 */
proto.marketplace.User.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.User} returns this
 */
proto.marketplace.User.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated string blacklist = 12;
 * @return {!Array<string>}
 */
proto.marketplace.User.prototype.getBlacklistList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.marketplace.User} returns this
 */
proto.marketplace.User.prototype.setBlacklistList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.marketplace.User} returns this
 */
proto.marketplace.User.prototype.addBlacklist = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.User} returns this
 */
proto.marketplace.User.prototype.clearBlacklistList = function() {
  return this.setBlacklistList([]);
};


/**
 * optional ProductType productType = 13;
 * @return {!proto.marketplace.ProductType}
 */
proto.marketplace.User.prototype.getProducttype = function() {
  return /** @type {!proto.marketplace.ProductType} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.marketplace.ProductType} value
 * @return {!proto.marketplace.User} returns this
 */
proto.marketplace.User.prototype.setProducttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketplace.UserState.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.UserState.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.UserState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.UserState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.UserState.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    statechangetype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    avatar: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fullname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phonenumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    tokenbalance: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    description: jspb.Message.getFieldWithDefault(msg, 8, ""),
    advantagesList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    address: jspb.Message.getFieldWithDefault(msg, 10, ""),
    categoryid: jspb.Message.getFieldWithDefault(msg, 11, ""),
    location: (f = msg.getLocation()) && proto.marketplace.UserLocation.toObject(includeInstance, f),
    url: jspb.Message.getFieldWithDefault(msg, 13, ""),
    username: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.UserState}
 */
proto.marketplace.UserState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.UserState;
  return proto.marketplace.UserState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.UserState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.UserState}
 */
proto.marketplace.UserState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {!proto.marketplace.UserState.UserStateChangeType} */ (reader.readEnum());
      msg.setStatechangetype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatar(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullname(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhonenumber(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTokenbalance(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 9:
      var value = /** @type {!Array<!proto.marketplace.Advantages>} */ (reader.readPackedEnum());
      msg.setAdvantagesList(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryid(value);
      break;
    case 12:
      var value = new proto.marketplace.UserLocation;
      reader.readMessage(value,proto.marketplace.UserLocation.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.UserState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.UserState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.UserState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.UserState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatechangetype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAvatar();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFullname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTokenbalance();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAdvantagesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      9,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCategoryid();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.marketplace.UserLocation.serializeBinaryToWriter
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.marketplace.UserState.UserStateChangeType = {
  FILL: 0,
  BURN: 1
};

/**
 * optional google.protobuf.Timestamp timestamp = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marketplace.UserState.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marketplace.UserState} returns this
*/
proto.marketplace.UserState.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.UserState} returns this
 */
proto.marketplace.UserState.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.UserState.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserStateChangeType stateChangeType = 2;
 * @return {!proto.marketplace.UserState.UserStateChangeType}
 */
proto.marketplace.UserState.prototype.getStatechangetype = function() {
  return /** @type {!proto.marketplace.UserState.UserStateChangeType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.marketplace.UserState.UserStateChangeType} value
 * @return {!proto.marketplace.UserState} returns this
 */
proto.marketplace.UserState.prototype.setStatechangetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string avatar = 3;
 * @return {string}
 */
proto.marketplace.UserState.prototype.getAvatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.UserState} returns this
 */
proto.marketplace.UserState.prototype.setAvatar = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.marketplace.UserState.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.UserState} returns this
 */
proto.marketplace.UserState.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string fullName = 5;
 * @return {string}
 */
proto.marketplace.UserState.prototype.getFullname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.UserState} returns this
 */
proto.marketplace.UserState.prototype.setFullname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phoneNumber = 6;
 * @return {string}
 */
proto.marketplace.UserState.prototype.getPhonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.UserState} returns this
 */
proto.marketplace.UserState.prototype.setPhonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional float tokenBalance = 7;
 * @return {number}
 */
proto.marketplace.UserState.prototype.getTokenbalance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.UserState} returns this
 */
proto.marketplace.UserState.prototype.setTokenbalance = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.marketplace.UserState.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.UserState} returns this
 */
proto.marketplace.UserState.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated Advantages advantages = 9;
 * @return {!Array<!proto.marketplace.Advantages>}
 */
proto.marketplace.UserState.prototype.getAdvantagesList = function() {
  return /** @type {!Array<!proto.marketplace.Advantages>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<!proto.marketplace.Advantages>} value
 * @return {!proto.marketplace.UserState} returns this
 */
proto.marketplace.UserState.prototype.setAdvantagesList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {!proto.marketplace.Advantages} value
 * @param {number=} opt_index
 * @return {!proto.marketplace.UserState} returns this
 */
proto.marketplace.UserState.prototype.addAdvantages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.UserState} returns this
 */
proto.marketplace.UserState.prototype.clearAdvantagesList = function() {
  return this.setAdvantagesList([]);
};


/**
 * optional string address = 10;
 * @return {string}
 */
proto.marketplace.UserState.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.UserState} returns this
 */
proto.marketplace.UserState.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string categoryId = 11;
 * @return {string}
 */
proto.marketplace.UserState.prototype.getCategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.UserState} returns this
 */
proto.marketplace.UserState.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional UserLocation location = 12;
 * @return {?proto.marketplace.UserLocation}
 */
proto.marketplace.UserState.prototype.getLocation = function() {
  return /** @type{?proto.marketplace.UserLocation} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.UserLocation, 12));
};


/**
 * @param {?proto.marketplace.UserLocation|undefined} value
 * @return {!proto.marketplace.UserState} returns this
*/
proto.marketplace.UserState.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.UserState} returns this
 */
proto.marketplace.UserState.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.UserState.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string url = 13;
 * @return {string}
 */
proto.marketplace.UserState.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.UserState} returns this
 */
proto.marketplace.UserState.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string username = 14;
 * @return {string}
 */
proto.marketplace.UserState.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.UserState} returns this
 */
proto.marketplace.UserState.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketplace.NewUserRequest.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.NewUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.NewUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.NewUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NewUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    kcid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    avatar: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fullname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phonenumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    advantagesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    categoryid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    location: (f = msg.getLocation()) && proto.marketplace.UserLocation.toObject(includeInstance, f),
    url: jspb.Message.getFieldWithDefault(msg, 12, ""),
    address: jspb.Message.getFieldWithDefault(msg, 13, ""),
    shopbackgroundimage: jspb.Message.getFieldWithDefault(msg, 14, ""),
    usertype: jspb.Message.getFieldWithDefault(msg, 15, 0),
    label: jspb.Message.getFieldWithDefault(msg, 16, ""),
    producttype: jspb.Message.getFieldWithDefault(msg, 17, 0),
    username: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.NewUserRequest}
 */
proto.marketplace.NewUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.NewUserRequest;
  return proto.marketplace.NewUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.NewUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.NewUserRequest}
 */
proto.marketplace.NewUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKcid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatar(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullname(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhonenumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = /** @type {!Array<!proto.marketplace.Advantages>} */ (reader.readPackedEnum());
      msg.setAdvantagesList(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryid(value);
      break;
    case 11:
      var value = new proto.marketplace.UserLocation;
      reader.readMessage(value,proto.marketplace.UserLocation.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopbackgroundimage(value);
      break;
    case 15:
      var value = /** @type {!proto.marketplace.UserType} */ (reader.readEnum());
      msg.setUsertype(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 17:
      var value = /** @type {!proto.marketplace.ProductType} */ (reader.readEnum());
      msg.setProducttype(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.NewUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.NewUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.NewUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NewUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKcid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAvatar();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFullname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAdvantagesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      8,
      f
    );
  }
  f = message.getCategoryid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.marketplace.UserLocation.serializeBinaryToWriter
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getShopbackgroundimage();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUsertype();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getProducttype();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string kcId = 1;
 * @return {string}
 */
proto.marketplace.NewUserRequest.prototype.getKcid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewUserRequest} returns this
 */
proto.marketplace.NewUserRequest.prototype.setKcid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string avatar = 3;
 * @return {string}
 */
proto.marketplace.NewUserRequest.prototype.getAvatar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewUserRequest} returns this
 */
proto.marketplace.NewUserRequest.prototype.setAvatar = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.marketplace.NewUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewUserRequest} returns this
 */
proto.marketplace.NewUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string fullName = 5;
 * @return {string}
 */
proto.marketplace.NewUserRequest.prototype.getFullname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewUserRequest} returns this
 */
proto.marketplace.NewUserRequest.prototype.setFullname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phoneNumber = 6;
 * @return {string}
 */
proto.marketplace.NewUserRequest.prototype.getPhonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewUserRequest} returns this
 */
proto.marketplace.NewUserRequest.prototype.setPhonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.marketplace.NewUserRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewUserRequest} returns this
 */
proto.marketplace.NewUserRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated Advantages advantages = 8;
 * @return {!Array<!proto.marketplace.Advantages>}
 */
proto.marketplace.NewUserRequest.prototype.getAdvantagesList = function() {
  return /** @type {!Array<!proto.marketplace.Advantages>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<!proto.marketplace.Advantages>} value
 * @return {!proto.marketplace.NewUserRequest} returns this
 */
proto.marketplace.NewUserRequest.prototype.setAdvantagesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!proto.marketplace.Advantages} value
 * @param {number=} opt_index
 * @return {!proto.marketplace.NewUserRequest} returns this
 */
proto.marketplace.NewUserRequest.prototype.addAdvantages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.NewUserRequest} returns this
 */
proto.marketplace.NewUserRequest.prototype.clearAdvantagesList = function() {
  return this.setAdvantagesList([]);
};


/**
 * optional string categoryId = 10;
 * @return {string}
 */
proto.marketplace.NewUserRequest.prototype.getCategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewUserRequest} returns this
 */
proto.marketplace.NewUserRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional UserLocation location = 11;
 * @return {?proto.marketplace.UserLocation}
 */
proto.marketplace.NewUserRequest.prototype.getLocation = function() {
  return /** @type{?proto.marketplace.UserLocation} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.UserLocation, 11));
};


/**
 * @param {?proto.marketplace.UserLocation|undefined} value
 * @return {!proto.marketplace.NewUserRequest} returns this
*/
proto.marketplace.NewUserRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.NewUserRequest} returns this
 */
proto.marketplace.NewUserRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.NewUserRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string url = 12;
 * @return {string}
 */
proto.marketplace.NewUserRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewUserRequest} returns this
 */
proto.marketplace.NewUserRequest.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string address = 13;
 * @return {string}
 */
proto.marketplace.NewUserRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewUserRequest} returns this
 */
proto.marketplace.NewUserRequest.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string shopBackgroundImage = 14;
 * @return {string}
 */
proto.marketplace.NewUserRequest.prototype.getShopbackgroundimage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewUserRequest} returns this
 */
proto.marketplace.NewUserRequest.prototype.setShopbackgroundimage = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional UserType userType = 15;
 * @return {!proto.marketplace.UserType}
 */
proto.marketplace.NewUserRequest.prototype.getUsertype = function() {
  return /** @type {!proto.marketplace.UserType} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.marketplace.UserType} value
 * @return {!proto.marketplace.NewUserRequest} returns this
 */
proto.marketplace.NewUserRequest.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional string label = 16;
 * @return {string}
 */
proto.marketplace.NewUserRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewUserRequest} returns this
 */
proto.marketplace.NewUserRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional ProductType productType = 17;
 * @return {!proto.marketplace.ProductType}
 */
proto.marketplace.NewUserRequest.prototype.getProducttype = function() {
  return /** @type {!proto.marketplace.ProductType} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.marketplace.ProductType} value
 * @return {!proto.marketplace.NewUserRequest} returns this
 */
proto.marketplace.NewUserRequest.prototype.setProducttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional string username = 18;
 * @return {string}
 */
proto.marketplace.NewUserRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewUserRequest} returns this
 */
proto.marketplace.NewUserRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.UserLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.UserLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.UserLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.UserLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    governorate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    delegation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    coordinates: (f = msg.getCoordinates()) && proto.marketplace.UserGeolocation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.UserLocation}
 */
proto.marketplace.UserLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.UserLocation;
  return proto.marketplace.UserLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.UserLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.UserLocation}
 */
proto.marketplace.UserLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGovernorate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelegation(value);
      break;
    case 3:
      var value = new proto.marketplace.UserGeolocation;
      reader.readMessage(value,proto.marketplace.UserGeolocation.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.UserLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.UserLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.UserLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.UserLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGovernorate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDelegation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.marketplace.UserGeolocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string governorate = 1;
 * @return {string}
 */
proto.marketplace.UserLocation.prototype.getGovernorate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.UserLocation} returns this
 */
proto.marketplace.UserLocation.prototype.setGovernorate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string delegation = 2;
 * @return {string}
 */
proto.marketplace.UserLocation.prototype.getDelegation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.UserLocation} returns this
 */
proto.marketplace.UserLocation.prototype.setDelegation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional UserGeolocation coordinates = 3;
 * @return {?proto.marketplace.UserGeolocation}
 */
proto.marketplace.UserLocation.prototype.getCoordinates = function() {
  return /** @type{?proto.marketplace.UserGeolocation} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.UserGeolocation, 3));
};


/**
 * @param {?proto.marketplace.UserGeolocation|undefined} value
 * @return {!proto.marketplace.UserLocation} returns this
*/
proto.marketplace.UserLocation.prototype.setCoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.UserLocation} returns this
 */
proto.marketplace.UserLocation.prototype.clearCoordinates = function() {
  return this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.UserLocation.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.TotalUserCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.TotalUserCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.TotalUserCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.TotalUserCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.TotalUserCountResponse}
 */
proto.marketplace.TotalUserCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.TotalUserCountResponse;
  return proto.marketplace.TotalUserCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.TotalUserCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.TotalUserCountResponse}
 */
proto.marketplace.TotalUserCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.TotalUserCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.TotalUserCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.TotalUserCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.TotalUserCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 count = 1;
 * @return {number}
 */
proto.marketplace.TotalUserCountResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.TotalUserCountResponse} returns this
 */
proto.marketplace.TotalUserCountResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.UserGeolocation.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.UserGeolocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.UserGeolocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.UserGeolocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.UserGeolocation}
 */
proto.marketplace.UserGeolocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.UserGeolocation;
  return proto.marketplace.UserGeolocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.UserGeolocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.UserGeolocation}
 */
proto.marketplace.UserGeolocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.UserGeolocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.UserGeolocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.UserGeolocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.UserGeolocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double latitude = 1;
 * @return {number}
 */
proto.marketplace.UserGeolocation.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.UserGeolocation} returns this
 */
proto.marketplace.UserGeolocation.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double longitude = 2;
 * @return {number}
 */
proto.marketplace.UserGeolocation.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.UserGeolocation} returns this
 */
proto.marketplace.UserGeolocation.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.UserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.UserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.UserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.UserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && proto.marketplace.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.UserResponse}
 */
proto.marketplace.UserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.UserResponse;
  return proto.marketplace.UserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.UserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.UserResponse}
 */
proto.marketplace.UserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.marketplace.User;
      reader.readMessage(value,proto.marketplace.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.UserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.UserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.UserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.UserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.marketplace.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.UserResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.UserResponse} returns this
 */
proto.marketplace.UserResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user = 2;
 * @return {?proto.marketplace.User}
 */
proto.marketplace.UserResponse.prototype.getUser = function() {
  return /** @type{?proto.marketplace.User} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.User, 2));
};


/**
 * @param {?proto.marketplace.User|undefined} value
 * @return {!proto.marketplace.UserResponse} returns this
*/
proto.marketplace.UserResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.UserResponse} returns this
 */
proto.marketplace.UserResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.UserResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.ShopCountAdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.ShopCountAdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.ShopCountAdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ShopCountAdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    total: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.ShopCountAdResponse}
 */
proto.marketplace.ShopCountAdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.ShopCountAdResponse;
  return proto.marketplace.ShopCountAdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.ShopCountAdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.ShopCountAdResponse}
 */
proto.marketplace.ShopCountAdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryname(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.ShopCountAdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.ShopCountAdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.ShopCountAdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ShopCountAdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional string _ID = 1;
 * @return {string}
 */
proto.marketplace.ShopCountAdResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ShopCountAdResponse} returns this
 */
proto.marketplace.ShopCountAdResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string categoryName = 2;
 * @return {string}
 */
proto.marketplace.ShopCountAdResponse.prototype.getCategoryname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ShopCountAdResponse} returns this
 */
proto.marketplace.ShopCountAdResponse.prototype.setCategoryname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float total = 3;
 * @return {number}
 */
proto.marketplace.ShopCountAdResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.ShopCountAdResponse} returns this
 */
proto.marketplace.ShopCountAdResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.UserByIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.UserByIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.UserByIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.UserByIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    kcid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.UserByIDRequest}
 */
proto.marketplace.UserByIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.UserByIDRequest;
  return proto.marketplace.UserByIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.UserByIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.UserByIDRequest}
 */
proto.marketplace.UserByIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKcid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.UserByIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.UserByIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.UserByIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.UserByIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKcid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string kcId = 1;
 * @return {string}
 */
proto.marketplace.UserByIDRequest.prototype.getKcid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.UserByIDRequest} returns this
 */
proto.marketplace.UserByIDRequest.prototype.setKcid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.UserByPhoneNumberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.UserByPhoneNumberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.UserByPhoneNumberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.UserByPhoneNumberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    phonenumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.UserByPhoneNumberRequest}
 */
proto.marketplace.UserByPhoneNumberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.UserByPhoneNumberRequest;
  return proto.marketplace.UserByPhoneNumberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.UserByPhoneNumberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.UserByPhoneNumberRequest}
 */
proto.marketplace.UserByPhoneNumberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhonenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.UserByPhoneNumberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.UserByPhoneNumberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.UserByPhoneNumberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.UserByPhoneNumberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhonenumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string phoneNumber = 1;
 * @return {string}
 */
proto.marketplace.UserByPhoneNumberRequest.prototype.getPhonenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.UserByPhoneNumberRequest} returns this
 */
proto.marketplace.UserByPhoneNumberRequest.prototype.setPhonenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketplace.NewUserByIDRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.NewUserByIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.NewUserByIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.NewUserByIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NewUserByIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    kcid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newuserstate: (f = msg.getNewuserstate()) && proto.marketplace.UserState.toObject(includeInstance, f),
    usertype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    shopbackgroundimage: jspb.Message.getFieldWithDefault(msg, 4, ""),
    favoritesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    label: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.NewUserByIDRequest}
 */
proto.marketplace.NewUserByIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.NewUserByIDRequest;
  return proto.marketplace.NewUserByIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.NewUserByIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.NewUserByIDRequest}
 */
proto.marketplace.NewUserByIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKcid(value);
      break;
    case 2:
      var value = new proto.marketplace.UserState;
      reader.readMessage(value,proto.marketplace.UserState.deserializeBinaryFromReader);
      msg.setNewuserstate(value);
      break;
    case 3:
      var value = /** @type {!proto.marketplace.UserType} */ (reader.readEnum());
      msg.setUsertype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopbackgroundimage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addFavorites(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.NewUserByIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.NewUserByIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.NewUserByIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.NewUserByIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKcid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewuserstate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.marketplace.UserState.serializeBinaryToWriter
    );
  }
  f = message.getUsertype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getShopbackgroundimage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFavoritesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string kcId = 1;
 * @return {string}
 */
proto.marketplace.NewUserByIDRequest.prototype.getKcid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewUserByIDRequest} returns this
 */
proto.marketplace.NewUserByIDRequest.prototype.setKcid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UserState newUserState = 2;
 * @return {?proto.marketplace.UserState}
 */
proto.marketplace.NewUserByIDRequest.prototype.getNewuserstate = function() {
  return /** @type{?proto.marketplace.UserState} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.UserState, 2));
};


/**
 * @param {?proto.marketplace.UserState|undefined} value
 * @return {!proto.marketplace.NewUserByIDRequest} returns this
*/
proto.marketplace.NewUserByIDRequest.prototype.setNewuserstate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.NewUserByIDRequest} returns this
 */
proto.marketplace.NewUserByIDRequest.prototype.clearNewuserstate = function() {
  return this.setNewuserstate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.NewUserByIDRequest.prototype.hasNewuserstate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UserType userType = 3;
 * @return {!proto.marketplace.UserType}
 */
proto.marketplace.NewUserByIDRequest.prototype.getUsertype = function() {
  return /** @type {!proto.marketplace.UserType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.marketplace.UserType} value
 * @return {!proto.marketplace.NewUserByIDRequest} returns this
 */
proto.marketplace.NewUserByIDRequest.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string shopBackgroundImage = 4;
 * @return {string}
 */
proto.marketplace.NewUserByIDRequest.prototype.getShopbackgroundimage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewUserByIDRequest} returns this
 */
proto.marketplace.NewUserByIDRequest.prototype.setShopbackgroundimage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string favorites = 5;
 * @return {!Array<string>}
 */
proto.marketplace.NewUserByIDRequest.prototype.getFavoritesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.marketplace.NewUserByIDRequest} returns this
 */
proto.marketplace.NewUserByIDRequest.prototype.setFavoritesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.marketplace.NewUserByIDRequest} returns this
 */
proto.marketplace.NewUserByIDRequest.prototype.addFavorites = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.NewUserByIDRequest} returns this
 */
proto.marketplace.NewUserByIDRequest.prototype.clearFavoritesList = function() {
  return this.setFavoritesList([]);
};


/**
 * optional bool enabled = 6;
 * @return {boolean}
 */
proto.marketplace.NewUserByIDRequest.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marketplace.NewUserByIDRequest} returns this
 */
proto.marketplace.NewUserByIDRequest.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string label = 7;
 * @return {string}
 */
proto.marketplace.NewUserByIDRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.NewUserByIDRequest} returns this
 */
proto.marketplace.NewUserByIDRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.GetUserKeysRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.GetUserKeysRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.GetUserKeysRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GetUserKeysRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    kcid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.GetUserKeysRequest}
 */
proto.marketplace.GetUserKeysRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.GetUserKeysRequest;
  return proto.marketplace.GetUserKeysRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.GetUserKeysRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.GetUserKeysRequest}
 */
proto.marketplace.GetUserKeysRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKcid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.GetUserKeysRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.GetUserKeysRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.GetUserKeysRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GetUserKeysRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKcid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string kcId = 1;
 * @return {string}
 */
proto.marketplace.GetUserKeysRequest.prototype.getKcid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GetUserKeysRequest} returns this
 */
proto.marketplace.GetUserKeysRequest.prototype.setKcid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.GetUserKeysResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.GetUserKeysResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.GetUserKeysResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GetUserKeysResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    publickey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    privatekey: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.GetUserKeysResponse}
 */
proto.marketplace.GetUserKeysResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.GetUserKeysResponse;
  return proto.marketplace.GetUserKeysResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.GetUserKeysResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.GetUserKeysResponse}
 */
proto.marketplace.GetUserKeysResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublickey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivatekey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.GetUserKeysResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.GetUserKeysResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.GetUserKeysResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GetUserKeysResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublickey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrivatekey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string publicKey = 1;
 * @return {string}
 */
proto.marketplace.GetUserKeysResponse.prototype.getPublickey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GetUserKeysResponse} returns this
 */
proto.marketplace.GetUserKeysResponse.prototype.setPublickey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string privateKey = 2;
 * @return {string}
 */
proto.marketplace.GetUserKeysResponse.prototype.getPrivatekey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GetUserKeysResponse} returns this
 */
proto.marketplace.GetUserKeysResponse.prototype.setPrivatekey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.GetAccountTransactionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.GetAccountTransactionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.GetAccountTransactionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GetAccountTransactionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.GetAccountTransactionsRequest}
 */
proto.marketplace.GetAccountTransactionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.GetAccountTransactionsRequest;
  return proto.marketplace.GetAccountTransactionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.GetAccountTransactionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.GetAccountTransactionsRequest}
 */
proto.marketplace.GetAccountTransactionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.GetAccountTransactionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.GetAccountTransactionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.GetAccountTransactionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GetAccountTransactionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.marketplace.GetAccountTransactionsRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GetAccountTransactionsRequest} returns this
 */
proto.marketplace.GetAccountTransactionsRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.BuyTokensRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.BuyTokensRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.BuyTokensRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.BuyTokensRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.BuyTokensRequest}
 */
proto.marketplace.BuyTokensRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.BuyTokensRequest;
  return proto.marketplace.BuyTokensRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.BuyTokensRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.BuyTokensRequest}
 */
proto.marketplace.BuyTokensRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.BuyTokensRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.BuyTokensRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.BuyTokensRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.BuyTokensRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.marketplace.BuyTokensRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.BuyTokensRequest} returns this
 */
proto.marketplace.BuyTokensRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.marketplace.BuyTokensRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.BuyTokensRequest} returns this
 */
proto.marketplace.BuyTokensRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string amount = 3;
 * @return {string}
 */
proto.marketplace.BuyTokensRequest.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.BuyTokensRequest} returns this
 */
proto.marketplace.BuyTokensRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.BuyTokensResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.BuyTokensResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.BuyTokensResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.BuyTokensResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.BuyTokensResponse}
 */
proto.marketplace.BuyTokensResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.BuyTokensResponse;
  return proto.marketplace.BuyTokensResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.BuyTokensResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.BuyTokensResponse}
 */
proto.marketplace.BuyTokensResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.BuyTokensResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.BuyTokensResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.BuyTokensResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.BuyTokensResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.marketplace.BuyTokensResponse.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.BuyTokensResponse} returns this
 */
proto.marketplace.BuyTokensResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.marketplace.BuyTokensResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.BuyTokensResponse} returns this
 */
proto.marketplace.BuyTokensResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string amount = 3;
 * @return {string}
 */
proto.marketplace.BuyTokensResponse.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.BuyTokensResponse} returns this
 */
proto.marketplace.BuyTokensResponse.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.createAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.createAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.createAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.createAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountname: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.createAccountResponse}
 */
proto.marketplace.createAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.createAccountResponse;
  return proto.marketplace.createAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.createAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.createAccountResponse}
 */
proto.marketplace.createAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.createAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.createAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.createAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.createAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string accountName = 1;
 * @return {string}
 */
proto.marketplace.createAccountResponse.prototype.getAccountname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.createAccountResponse} returns this
 */
proto.marketplace.createAccountResponse.prototype.setAccountname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.ConsumeTokensByUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.ConsumeTokensByUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.ConsumeTokensByUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ConsumeTokensByUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.ConsumeTokensByUserRequest}
 */
proto.marketplace.ConsumeTokensByUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.ConsumeTokensByUserRequest;
  return proto.marketplace.ConsumeTokensByUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.ConsumeTokensByUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.ConsumeTokensByUserRequest}
 */
proto.marketplace.ConsumeTokensByUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.ConsumeTokensByUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.ConsumeTokensByUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.ConsumeTokensByUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ConsumeTokensByUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.marketplace.ConsumeTokensByUserRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ConsumeTokensByUserRequest} returns this
 */
proto.marketplace.ConsumeTokensByUserRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.marketplace.ConsumeTokensByUserRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ConsumeTokensByUserRequest} returns this
 */
proto.marketplace.ConsumeTokensByUserRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string amount = 3;
 * @return {string}
 */
proto.marketplace.ConsumeTokensByUserRequest.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ConsumeTokensByUserRequest} returns this
 */
proto.marketplace.ConsumeTokensByUserRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.ConsumeTokensByUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.ConsumeTokensByUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.ConsumeTokensByUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ConsumeTokensByUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.ConsumeTokensByUserResponse}
 */
proto.marketplace.ConsumeTokensByUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.ConsumeTokensByUserResponse;
  return proto.marketplace.ConsumeTokensByUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.ConsumeTokensByUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.ConsumeTokensByUserResponse}
 */
proto.marketplace.ConsumeTokensByUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.ConsumeTokensByUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.ConsumeTokensByUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.ConsumeTokensByUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.ConsumeTokensByUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.marketplace.ConsumeTokensByUserResponse.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ConsumeTokensByUserResponse} returns this
 */
proto.marketplace.ConsumeTokensByUserResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.marketplace.ConsumeTokensByUserResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ConsumeTokensByUserResponse} returns this
 */
proto.marketplace.ConsumeTokensByUserResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string amount = 3;
 * @return {string}
 */
proto.marketplace.ConsumeTokensByUserResponse.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.ConsumeTokensByUserResponse} returns this
 */
proto.marketplace.ConsumeTokensByUserResponse.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.GetUserBalanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.GetUserBalanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.GetUserBalanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GetUserBalanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    balance: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.GetUserBalanceResponse}
 */
proto.marketplace.GetUserBalanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.GetUserBalanceResponse;
  return proto.marketplace.GetUserBalanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.GetUserBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.GetUserBalanceResponse}
 */
proto.marketplace.GetUserBalanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.GetUserBalanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.GetUserBalanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.GetUserBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GetUserBalanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBalance();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.marketplace.GetUserBalanceResponse.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GetUserBalanceResponse} returns this
 */
proto.marketplace.GetUserBalanceResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string balance = 2;
 * @return {string}
 */
proto.marketplace.GetUserBalanceResponse.prototype.getBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GetUserBalanceResponse} returns this
 */
proto.marketplace.GetUserBalanceResponse.prototype.setBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.WalletsCreateAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.WalletsCreateAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.WalletsCreateAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.WalletsCreateAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.WalletsCreateAccountRequest}
 */
proto.marketplace.WalletsCreateAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.WalletsCreateAccountRequest;
  return proto.marketplace.WalletsCreateAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.WalletsCreateAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.WalletsCreateAccountRequest}
 */
proto.marketplace.WalletsCreateAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.WalletsCreateAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.WalletsCreateAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.WalletsCreateAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.WalletsCreateAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string userName = 1;
 * @return {string}
 */
proto.marketplace.WalletsCreateAccountRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.WalletsCreateAccountRequest} returns this
 */
proto.marketplace.WalletsCreateAccountRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.WalletsCreateAccountWithKeysRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.WalletsCreateAccountWithKeysRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.WalletsCreateAccountWithKeysRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.WalletsCreateAccountWithKeysRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    privatekey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    publickey: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.WalletsCreateAccountWithKeysRequest}
 */
proto.marketplace.WalletsCreateAccountWithKeysRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.WalletsCreateAccountWithKeysRequest;
  return proto.marketplace.WalletsCreateAccountWithKeysRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.WalletsCreateAccountWithKeysRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.WalletsCreateAccountWithKeysRequest}
 */
proto.marketplace.WalletsCreateAccountWithKeysRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivatekey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublickey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.WalletsCreateAccountWithKeysRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.WalletsCreateAccountWithKeysRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.WalletsCreateAccountWithKeysRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.WalletsCreateAccountWithKeysRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrivatekey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPublickey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string userName = 1;
 * @return {string}
 */
proto.marketplace.WalletsCreateAccountWithKeysRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.WalletsCreateAccountWithKeysRequest} returns this
 */
proto.marketplace.WalletsCreateAccountWithKeysRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string privateKey = 2;
 * @return {string}
 */
proto.marketplace.WalletsCreateAccountWithKeysRequest.prototype.getPrivatekey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.WalletsCreateAccountWithKeysRequest} returns this
 */
proto.marketplace.WalletsCreateAccountWithKeysRequest.prototype.setPrivatekey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string publicKey = 3;
 * @return {string}
 */
proto.marketplace.WalletsCreateAccountWithKeysRequest.prototype.getPublickey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.WalletsCreateAccountWithKeysRequest} returns this
 */
proto.marketplace.WalletsCreateAccountWithKeysRequest.prototype.setPublickey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.GetWalletsAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.GetWalletsAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.GetWalletsAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GetWalletsAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.GetWalletsAccountRequest}
 */
proto.marketplace.GetWalletsAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.GetWalletsAccountRequest;
  return proto.marketplace.GetWalletsAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.GetWalletsAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.GetWalletsAccountRequest}
 */
proto.marketplace.GetWalletsAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.GetWalletsAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.GetWalletsAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.GetWalletsAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GetWalletsAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.marketplace.GetWalletsAccountRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GetWalletsAccountRequest} returns this
 */
proto.marketplace.GetWalletsAccountRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.GetWalletsAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.GetWalletsAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.GetWalletsAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GetWalletsAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    domainid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.GetWalletsAccountResponse}
 */
proto.marketplace.GetWalletsAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.GetWalletsAccountResponse;
  return proto.marketplace.GetWalletsAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.GetWalletsAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.GetWalletsAccountResponse}
 */
proto.marketplace.GetWalletsAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomainid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.GetWalletsAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.GetWalletsAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.GetWalletsAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GetWalletsAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDomainid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string accountID = 1;
 * @return {string}
 */
proto.marketplace.GetWalletsAccountResponse.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GetWalletsAccountResponse} returns this
 */
proto.marketplace.GetWalletsAccountResponse.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string domainID = 2;
 * @return {string}
 */
proto.marketplace.GetWalletsAccountResponse.prototype.getDomainid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GetWalletsAccountResponse} returns this
 */
proto.marketplace.GetWalletsAccountResponse.prototype.setDomainid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    srcAccount: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dstAccount: jspb.Message.getFieldWithDefault(msg, 2, ""),
    creatorId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    creationtimestamp: (f = msg.getCreationtimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    validuntiltimestamp: (f = msg.getValiduntiltimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.Transaction}
 */
proto.marketplace.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.Transaction;
  return proto.marketplace.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.Transaction}
 */
proto.marketplace.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrcAccount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDstAccount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatorId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationtimestamp(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setValiduntiltimestamp(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAmount(value);
      break;
    case 8:
      var value = /** @type {!proto.marketplace.Transaction.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSrcAccount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDstAccount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatorId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreationtimestamp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getValiduntiltimestamp();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.marketplace.Transaction.Status = {
  OK: 0,
  NOT_ENOUGH_BALANCE: 1,
  SRC_ACCOUNT_NOT_FOUND: 2,
  DST_ACCOUNT_NOT_FOUND: 3,
  CTR_ACCOUNT_NOT_FOUND: 4,
  INTERNAL_ERROR: 5,
  INVALID_PRIVATE_KEY: 6,
  INVALID_PUB_KEY: 7,
  SIGNATURE_ERROR: 8,
  UNAUTHORIZED_TRANSACTION_CREATOR: 9
};

/**
 * optional string src_account = 1;
 * @return {string}
 */
proto.marketplace.Transaction.prototype.getSrcAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Transaction} returns this
 */
proto.marketplace.Transaction.prototype.setSrcAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dst_account = 2;
 * @return {string}
 */
proto.marketplace.Transaction.prototype.getDstAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Transaction} returns this
 */
proto.marketplace.Transaction.prototype.setDstAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string creator_id = 3;
 * @return {string}
 */
proto.marketplace.Transaction.prototype.getCreatorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Transaction} returns this
 */
proto.marketplace.Transaction.prototype.setCreatorId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.marketplace.Transaction.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Transaction} returns this
 */
proto.marketplace.Transaction.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp creationTimestamp = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marketplace.Transaction.prototype.getCreationtimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marketplace.Transaction} returns this
*/
proto.marketplace.Transaction.prototype.setCreationtimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.Transaction} returns this
 */
proto.marketplace.Transaction.prototype.clearCreationtimestamp = function() {
  return this.setCreationtimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.Transaction.prototype.hasCreationtimestamp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp validUntilTimestamp = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marketplace.Transaction.prototype.getValiduntiltimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marketplace.Transaction} returns this
*/
proto.marketplace.Transaction.prototype.setValiduntiltimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.Transaction} returns this
 */
proto.marketplace.Transaction.prototype.clearValiduntiltimestamp = function() {
  return this.setValiduntiltimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.Transaction.prototype.hasValiduntiltimestamp = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional float amount = 7;
 * @return {number}
 */
proto.marketplace.Transaction.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.Transaction} returns this
 */
proto.marketplace.Transaction.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional Status status = 8;
 * @return {!proto.marketplace.Transaction.Status}
 */
proto.marketplace.Transaction.prototype.getStatus = function() {
  return /** @type {!proto.marketplace.Transaction.Status} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.marketplace.Transaction.Status} value
 * @return {!proto.marketplace.Transaction} returns this
 */
proto.marketplace.Transaction.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.TransferTokensByAdminRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.TransferTokensByAdminRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.TransferTokensByAdminRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.TransferTokensByAdminRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dstAccount: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    validuntiltimestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.TransferTokensByAdminRequest}
 */
proto.marketplace.TransferTokensByAdminRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.TransferTokensByAdminRequest;
  return proto.marketplace.TransferTokensByAdminRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.TransferTokensByAdminRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.TransferTokensByAdminRequest}
 */
proto.marketplace.TransferTokensByAdminRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDstAccount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValiduntiltimestamp(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.TransferTokensByAdminRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.TransferTokensByAdminRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.TransferTokensByAdminRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.TransferTokensByAdminRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDstAccount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValiduntiltimestamp();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional string dst_account = 1;
 * @return {string}
 */
proto.marketplace.TransferTokensByAdminRequest.prototype.getDstAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.TransferTokensByAdminRequest} returns this
 */
proto.marketplace.TransferTokensByAdminRequest.prototype.setDstAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.marketplace.TransferTokensByAdminRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.TransferTokensByAdminRequest} returns this
 */
proto.marketplace.TransferTokensByAdminRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 validUntilTimestamp = 3;
 * @return {number}
 */
proto.marketplace.TransferTokensByAdminRequest.prototype.getValiduntiltimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.TransferTokensByAdminRequest} returns this
 */
proto.marketplace.TransferTokensByAdminRequest.prototype.setValiduntiltimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional float amount = 4;
 * @return {number}
 */
proto.marketplace.TransferTokensByAdminRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.TransferTokensByAdminRequest} returns this
 */
proto.marketplace.TransferTokensByAdminRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.TransferTokensResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.TransferTokensResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.TransferTokensResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.TransferTokensResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.TransferTokensResponse}
 */
proto.marketplace.TransferTokensResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.TransferTokensResponse;
  return proto.marketplace.TransferTokensResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.TransferTokensResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.TransferTokensResponse}
 */
proto.marketplace.TransferTokensResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.marketplace.Transaction.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.TransferTokensResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.TransferTokensResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.TransferTokensResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.TransferTokensResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional Transaction.Status status = 1;
 * @return {!proto.marketplace.Transaction.Status}
 */
proto.marketplace.TransferTokensResponse.prototype.getStatus = function() {
  return /** @type {!proto.marketplace.Transaction.Status} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.marketplace.Transaction.Status} value
 * @return {!proto.marketplace.TransferTokensResponse} returns this
 */
proto.marketplace.TransferTokensResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.TransfertTransactionForAdmin.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.TransfertTransactionForAdmin.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.TransfertTransactionForAdmin} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.TransfertTransactionForAdmin.toObject = function(includeInstance, msg) {
  var f, obj = {
    srcAccountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    destAccountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    creatorAccountId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    validuntiltimestamp: (f = msg.getValiduntiltimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.TransfertTransactionForAdmin}
 */
proto.marketplace.TransfertTransactionForAdmin.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.TransfertTransactionForAdmin;
  return proto.marketplace.TransfertTransactionForAdmin.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.TransfertTransactionForAdmin} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.TransfertTransactionForAdmin}
 */
proto.marketplace.TransfertTransactionForAdmin.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrcAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatorAccountId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedTime(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setValiduntiltimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.TransfertTransactionForAdmin.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.TransfertTransactionForAdmin.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.TransfertTransactionForAdmin} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.TransfertTransactionForAdmin.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSrcAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDestAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatorAccountId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedTime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getValiduntiltimestamp();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string src_account_id = 1;
 * @return {string}
 */
proto.marketplace.TransfertTransactionForAdmin.prototype.getSrcAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.TransfertTransactionForAdmin} returns this
 */
proto.marketplace.TransfertTransactionForAdmin.prototype.setSrcAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dest_account_id = 2;
 * @return {string}
 */
proto.marketplace.TransfertTransactionForAdmin.prototype.getDestAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.TransfertTransactionForAdmin} returns this
 */
proto.marketplace.TransfertTransactionForAdmin.prototype.setDestAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string amount = 3;
 * @return {string}
 */
proto.marketplace.TransfertTransactionForAdmin.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.TransfertTransactionForAdmin} returns this
 */
proto.marketplace.TransfertTransactionForAdmin.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.marketplace.TransfertTransactionForAdmin.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.TransfertTransactionForAdmin} returns this
 */
proto.marketplace.TransfertTransactionForAdmin.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string creator_account_id = 5;
 * @return {string}
 */
proto.marketplace.TransfertTransactionForAdmin.prototype.getCreatorAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.TransfertTransactionForAdmin} returns this
 */
proto.marketplace.TransfertTransactionForAdmin.prototype.setCreatorAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 created_time = 6;
 * @return {number}
 */
proto.marketplace.TransfertTransactionForAdmin.prototype.getCreatedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.TransfertTransactionForAdmin} returns this
 */
proto.marketplace.TransfertTransactionForAdmin.prototype.setCreatedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp validUntilTimestamp = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marketplace.TransfertTransactionForAdmin.prototype.getValiduntiltimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marketplace.TransfertTransactionForAdmin} returns this
*/
proto.marketplace.TransfertTransactionForAdmin.prototype.setValiduntiltimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.TransfertTransactionForAdmin} returns this
 */
proto.marketplace.TransfertTransactionForAdmin.prototype.clearValiduntiltimestamp = function() {
  return this.setValiduntiltimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.TransfertTransactionForAdmin.prototype.hasValiduntiltimestamp = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketplace.GetUserTransactionsBalanceForAdminResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.GetUserTransactionsBalanceForAdminResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.GetUserTransactionsBalanceForAdminResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.GetUserTransactionsBalanceForAdminResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GetUserTransactionsBalanceForAdminResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    balance: jspb.Message.getFieldWithDefault(msg, 2, ""),
    allTransactionsSize: jspb.Message.getFieldWithDefault(msg, 3, 0),
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.marketplace.TransfertTransactionForAdmin.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.GetUserTransactionsBalanceForAdminResponse}
 */
proto.marketplace.GetUserTransactionsBalanceForAdminResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.GetUserTransactionsBalanceForAdminResponse;
  return proto.marketplace.GetUserTransactionsBalanceForAdminResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.GetUserTransactionsBalanceForAdminResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.GetUserTransactionsBalanceForAdminResponse}
 */
proto.marketplace.GetUserTransactionsBalanceForAdminResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalance(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAllTransactionsSize(value);
      break;
    case 4:
      var value = new proto.marketplace.TransfertTransactionForAdmin;
      reader.readMessage(value,proto.marketplace.TransfertTransactionForAdmin.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.GetUserTransactionsBalanceForAdminResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.GetUserTransactionsBalanceForAdminResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.GetUserTransactionsBalanceForAdminResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.GetUserTransactionsBalanceForAdminResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBalance();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAllTransactionsSize();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.marketplace.TransfertTransactionForAdmin.serializeBinaryToWriter
    );
  }
};


/**
 * optional string userID = 1;
 * @return {string}
 */
proto.marketplace.GetUserTransactionsBalanceForAdminResponse.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GetUserTransactionsBalanceForAdminResponse} returns this
 */
proto.marketplace.GetUserTransactionsBalanceForAdminResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string balance = 2;
 * @return {string}
 */
proto.marketplace.GetUserTransactionsBalanceForAdminResponse.prototype.getBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.GetUserTransactionsBalanceForAdminResponse} returns this
 */
proto.marketplace.GetUserTransactionsBalanceForAdminResponse.prototype.setBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 all_transactions_size = 3;
 * @return {number}
 */
proto.marketplace.GetUserTransactionsBalanceForAdminResponse.prototype.getAllTransactionsSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.GetUserTransactionsBalanceForAdminResponse} returns this
 */
proto.marketplace.GetUserTransactionsBalanceForAdminResponse.prototype.setAllTransactionsSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated TransfertTransactionForAdmin transactions = 4;
 * @return {!Array<!proto.marketplace.TransfertTransactionForAdmin>}
 */
proto.marketplace.GetUserTransactionsBalanceForAdminResponse.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.marketplace.TransfertTransactionForAdmin>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.marketplace.TransfertTransactionForAdmin, 4));
};


/**
 * @param {!Array<!proto.marketplace.TransfertTransactionForAdmin>} value
 * @return {!proto.marketplace.GetUserTransactionsBalanceForAdminResponse} returns this
*/
proto.marketplace.GetUserTransactionsBalanceForAdminResponse.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.marketplace.TransfertTransactionForAdmin=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marketplace.TransfertTransactionForAdmin}
 */
proto.marketplace.GetUserTransactionsBalanceForAdminResponse.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.marketplace.TransfertTransactionForAdmin, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.GetUserTransactionsBalanceForAdminResponse} returns this
 */
proto.marketplace.GetUserTransactionsBalanceForAdminResponse.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.SendUserDeviceConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.SendUserDeviceConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.SendUserDeviceConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.SendUserDeviceConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    devicetoken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceplatform: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.SendUserDeviceConfigRequest}
 */
proto.marketplace.SendUserDeviceConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.SendUserDeviceConfigRequest;
  return proto.marketplace.SendUserDeviceConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.SendUserDeviceConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.SendUserDeviceConfigRequest}
 */
proto.marketplace.SendUserDeviceConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicetoken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceplatform(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.SendUserDeviceConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.SendUserDeviceConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.SendUserDeviceConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.SendUserDeviceConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevicetoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceplatform();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string DeviceId = 1;
 * @return {string}
 */
proto.marketplace.SendUserDeviceConfigRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.SendUserDeviceConfigRequest} returns this
 */
proto.marketplace.SendUserDeviceConfigRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string DeviceToken = 2;
 * @return {string}
 */
proto.marketplace.SendUserDeviceConfigRequest.prototype.getDevicetoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.SendUserDeviceConfigRequest} returns this
 */
proto.marketplace.SendUserDeviceConfigRequest.prototype.setDevicetoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string DevicePlatform = 3;
 * @return {string}
 */
proto.marketplace.SendUserDeviceConfigRequest.prototype.getDeviceplatform = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.SendUserDeviceConfigRequest} returns this
 */
proto.marketplace.SendUserDeviceConfigRequest.prototype.setDeviceplatform = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string UserId = 4;
 * @return {string}
 */
proto.marketplace.SendUserDeviceConfigRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.SendUserDeviceConfigRequest} returns this
 */
proto.marketplace.SendUserDeviceConfigRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.SendUserDeviceConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.SendUserDeviceConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.SendUserDeviceConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.SendUserDeviceConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    devicetoken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceplatform: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.SendUserDeviceConfigResponse}
 */
proto.marketplace.SendUserDeviceConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.SendUserDeviceConfigResponse;
  return proto.marketplace.SendUserDeviceConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.SendUserDeviceConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.SendUserDeviceConfigResponse}
 */
proto.marketplace.SendUserDeviceConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicetoken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceplatform(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.SendUserDeviceConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.SendUserDeviceConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.SendUserDeviceConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.SendUserDeviceConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevicetoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceplatform();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string DeviceId = 1;
 * @return {string}
 */
proto.marketplace.SendUserDeviceConfigResponse.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.SendUserDeviceConfigResponse} returns this
 */
proto.marketplace.SendUserDeviceConfigResponse.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string DeviceToken = 2;
 * @return {string}
 */
proto.marketplace.SendUserDeviceConfigResponse.prototype.getDevicetoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.SendUserDeviceConfigResponse} returns this
 */
proto.marketplace.SendUserDeviceConfigResponse.prototype.setDevicetoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string DevicePlatform = 3;
 * @return {string}
 */
proto.marketplace.SendUserDeviceConfigResponse.prototype.getDeviceplatform = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.SendUserDeviceConfigResponse} returns this
 */
proto.marketplace.SendUserDeviceConfigResponse.prototype.setDeviceplatform = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string UserId = 4;
 * @return {string}
 */
proto.marketplace.SendUserDeviceConfigResponse.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.SendUserDeviceConfigResponse} returns this
 */
proto.marketplace.SendUserDeviceConfigResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.marketplace.Advantages = {
  HAS_A_PHYSICAL_LOCATION: 0,
  SCHEDULE_A_VISIT: 1,
  INVOICE: 2,
  DELIVERY_SERVICE: 3,
  ACCEPTS_CREDIT_CARDS: 4,
  GUARANTEE: 5,
  TEST_DRIVE: 6,
  MAINTENANCE: 7,
  MONTHLY_INSTALLMENTS: 8,
  INSURANCE: 9,
  LOAN: 10,
  FINANCING: 11
};

/**
 * @enum {number}
 */
proto.marketplace.UserType = {
  STANDARD: 0,
  SHOP: 1
};

/**
 * @enum {number}
 */
proto.marketplace.ProductType = {
  DEFAULT: 0,
  NEWS: 1
};

goog.object.extend(exports, proto.marketplace);
