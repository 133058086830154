// package: messenger
// file: messenger.proto

var messenger_pb = require("./messenger_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var Messenger = (function () {
  function Messenger() {}
  Messenger.serviceName = "messenger.Messenger";
  return Messenger;
}());

Messenger.GetDiscussionsByUser = {
  methodName: "GetDiscussionsByUser",
  service: Messenger,
  requestStream: false,
  responseStream: false,
  requestType: messenger_pb.UserRequest,
  responseType: messenger_pb.GetDiscussionsResponse
};

Messenger.GetMessages = {
  methodName: "GetMessages",
  service: Messenger,
  requestStream: false,
  responseStream: false,
  requestType: messenger_pb.DiscussionRequest,
  responseType: messenger_pb.GetMessagesResponse
};

Messenger.SendMessage = {
  methodName: "SendMessage",
  service: Messenger,
  requestStream: false,
  responseStream: false,
  requestType: messenger_pb.NewMessageRequest,
  responseType: google_protobuf_empty_pb.Empty
};

Messenger.GetAdRelatedDiscussionByMembers = {
  methodName: "GetAdRelatedDiscussionByMembers",
  service: Messenger,
  requestStream: false,
  responseStream: false,
  requestType: messenger_pb.GetAdRelatedDiscussionByMembersRequest,
  responseType: messenger_pb.GetAdRelatedDiscussionByMembersResponse
};

Messenger.InitiateDiscussion = {
  methodName: "InitiateDiscussion",
  service: Messenger,
  requestStream: false,
  responseStream: false,
  requestType: messenger_pb.InitiateDiscussionRequest,
  responseType: messenger_pb.InitiateDiscussionResponse
};

Messenger.MarkDiscussionMessagesAsRead = {
  methodName: "MarkDiscussionMessagesAsRead",
  service: Messenger,
  requestStream: false,
  responseStream: false,
  requestType: messenger_pb.DiscussionRequest,
  responseType: google_protobuf_empty_pb.Empty
};

Messenger.GetUnreadDiscussionsCount = {
  methodName: "GetUnreadDiscussionsCount",
  service: Messenger,
  requestStream: false,
  responseStream: false,
  requestType: messenger_pb.UserRequest,
  responseType: messenger_pb.UnreadDiscussionsCountResponse
};

Messenger.DeleteMsg = {
  methodName: "DeleteMsg",
  service: Messenger,
  requestStream: false,
  responseStream: false,
  requestType: messenger_pb.DeleteMsgRequest,
  responseType: messenger_pb.DeleteMsgResponse
};

Messenger.BlockUser = {
  methodName: "BlockUser",
  service: Messenger,
  requestStream: false,
  responseStream: false,
  requestType: messenger_pb.BlockUserRequest,
  responseType: messenger_pb.BlockUserResponse
};

Messenger.UnblockUser = {
  methodName: "UnblockUser",
  service: Messenger,
  requestStream: false,
  responseStream: false,
  requestType: messenger_pb.BlockUserRequest,
  responseType: messenger_pb.BlockUserResponse
};

exports.Messenger = Messenger;

function MessengerClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

MessengerClient.prototype.getDiscussionsByUser = function getDiscussionsByUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Messenger.GetDiscussionsByUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MessengerClient.prototype.getMessages = function getMessages(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Messenger.GetMessages, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MessengerClient.prototype.sendMessage = function sendMessage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Messenger.SendMessage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MessengerClient.prototype.getAdRelatedDiscussionByMembers = function getAdRelatedDiscussionByMembers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Messenger.GetAdRelatedDiscussionByMembers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MessengerClient.prototype.initiateDiscussion = function initiateDiscussion(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Messenger.InitiateDiscussion, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MessengerClient.prototype.markDiscussionMessagesAsRead = function markDiscussionMessagesAsRead(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Messenger.MarkDiscussionMessagesAsRead, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MessengerClient.prototype.getUnreadDiscussionsCount = function getUnreadDiscussionsCount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Messenger.GetUnreadDiscussionsCount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MessengerClient.prototype.deleteMsg = function deleteMsg(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Messenger.DeleteMsg, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MessengerClient.prototype.blockUser = function blockUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Messenger.BlockUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MessengerClient.prototype.unblockUser = function unblockUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Messenger.UnblockUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.MessengerClient = MessengerClient;

