// source: discovery.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.marketplace.CategoryRequest', null, global);
goog.exportSymbol('proto.marketplace.Facet', null, global);
goog.exportSymbol('proto.marketplace.FacetedSearchResponse', null, global);
goog.exportSymbol('proto.marketplace.FilterAndDatesSearch', null, global);
goog.exportSymbol('proto.marketplace.FilterAndDatesSearch.SortMechanism', null, global);
goog.exportSymbol('proto.marketplace.FilterWithAdParamsByRangeRequest', null, global);
goog.exportSymbol('proto.marketplace.FilterWithAdParamsByTextRequest', null, global);
goog.exportSymbol('proto.marketplace.FilterWithLocationRequest', null, global);
goog.exportSymbol('proto.marketplace.LocationRequest', null, global);
goog.exportSymbol('proto.marketplace.MatchParams', null, global);
goog.exportSymbol('proto.marketplace.PaginationParams', null, global);
goog.exportSymbol('proto.marketplace.PendingAndManualModerationAdsRequest', null, global);
goog.exportSymbol('proto.marketplace.PendingAndManualModerationAdsRequest.SortMechanism', null, global);
goog.exportSymbol('proto.marketplace.PriceRange', null, global);
goog.exportSymbol('proto.marketplace.Range', null, global);
goog.exportSymbol('proto.marketplace.RangeFilter', null, global);
goog.exportSymbol('proto.marketplace.SearchAdsByTitleRequest', null, global);
goog.exportSymbol('proto.marketplace.SearchAdsByTitleRequest.SortMechanism', null, global);
goog.exportSymbol('proto.marketplace.SearchUsersByTitleRequest', null, global);
goog.exportSymbol('proto.marketplace.TextFilter', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.FacetedSearchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.FacetedSearchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.FacetedSearchResponse.displayName = 'proto.marketplace.FacetedSearchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.Facet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketplace.Facet.repeatedFields_, null);
};
goog.inherits(proto.marketplace.Facet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.Facet.displayName = 'proto.marketplace.Facet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.Range = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.Range, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.Range.displayName = 'proto.marketplace.Range';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.CategoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.CategoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.CategoryRequest.displayName = 'proto.marketplace.CategoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.FilterWithAdParamsByRangeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.FilterWithAdParamsByRangeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.FilterWithAdParamsByRangeRequest.displayName = 'proto.marketplace.FilterWithAdParamsByRangeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.FilterWithAdParamsByTextRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.FilterWithAdParamsByTextRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.FilterWithAdParamsByTextRequest.displayName = 'proto.marketplace.FilterWithAdParamsByTextRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.SearchAdsByTitleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.SearchAdsByTitleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.SearchAdsByTitleRequest.displayName = 'proto.marketplace.SearchAdsByTitleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.SearchUsersByTitleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.SearchUsersByTitleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.SearchUsersByTitleRequest.displayName = 'proto.marketplace.SearchUsersByTitleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.FilterWithLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.FilterWithLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.FilterWithLocationRequest.displayName = 'proto.marketplace.FilterWithLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.MatchParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.MatchParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.MatchParams.displayName = 'proto.marketplace.MatchParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.LocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.LocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.LocationRequest.displayName = 'proto.marketplace.LocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.FilterAndDatesSearch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.FilterAndDatesSearch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.FilterAndDatesSearch.displayName = 'proto.marketplace.FilterAndDatesSearch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.PaginationParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.PaginationParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.PaginationParams.displayName = 'proto.marketplace.PaginationParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.PriceRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.PriceRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.PriceRange.displayName = 'proto.marketplace.PriceRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.RangeFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.RangeFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.RangeFilter.displayName = 'proto.marketplace.RangeFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.TextFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketplace.TextFilter.repeatedFields_, null);
};
goog.inherits(proto.marketplace.TextFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.TextFilter.displayName = 'proto.marketplace.TextFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketplace.PendingAndManualModerationAdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketplace.PendingAndManualModerationAdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketplace.PendingAndManualModerationAdsRequest.displayName = 'proto.marketplace.PendingAndManualModerationAdsRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.FacetedSearchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.FacetedSearchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.FacetedSearchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.FacetedSearchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    facetsMap: (f = msg.getFacetsMap()) ? f.toObject(includeInstance, proto.marketplace.Facet.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.FacetedSearchResponse}
 */
proto.marketplace.FacetedSearchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.FacetedSearchResponse;
  return proto.marketplace.FacetedSearchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.FacetedSearchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.FacetedSearchResponse}
 */
proto.marketplace.FacetedSearchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getFacetsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.marketplace.Facet.deserializeBinaryFromReader, "", new proto.marketplace.Facet());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.FacetedSearchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.FacetedSearchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.FacetedSearchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.FacetedSearchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFacetsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.marketplace.Facet.serializeBinaryToWriter);
  }
};


/**
 * map<string, Facet> facets = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.marketplace.Facet>}
 */
proto.marketplace.FacetedSearchResponse.prototype.getFacetsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.marketplace.Facet>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.marketplace.Facet));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.marketplace.FacetedSearchResponse} returns this
 */
proto.marketplace.FacetedSearchResponse.prototype.clearFacetsMap = function() {
  this.getFacetsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketplace.Facet.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.Facet.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.Facet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.Facet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Facet.toObject = function(includeInstance, msg) {
  var f, obj = {
    rangelistList: jspb.Message.toObjectList(msg.getRangelistList(),
    proto.marketplace.Range.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.Facet}
 */
proto.marketplace.Facet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.Facet;
  return proto.marketplace.Facet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.Facet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.Facet}
 */
proto.marketplace.Facet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.marketplace.Range;
      reader.readMessage(value,proto.marketplace.Range.deserializeBinaryFromReader);
      msg.addRangelist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.Facet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.Facet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.Facet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Facet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRangelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.marketplace.Range.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Range rangeList = 1;
 * @return {!Array<!proto.marketplace.Range>}
 */
proto.marketplace.Facet.prototype.getRangelistList = function() {
  return /** @type{!Array<!proto.marketplace.Range>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.marketplace.Range, 1));
};


/**
 * @param {!Array<!proto.marketplace.Range>} value
 * @return {!proto.marketplace.Facet} returns this
*/
proto.marketplace.Facet.prototype.setRangelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.marketplace.Range=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marketplace.Range}
 */
proto.marketplace.Facet.prototype.addRangelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.marketplace.Range, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.Facet} returns this
 */
proto.marketplace.Facet.prototype.clearRangelistList = function() {
  return this.setRangelistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.Range.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.Range.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.Range} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Range.toObject = function(includeInstance, msg) {
  var f, obj = {
    min: jspb.Message.getFieldWithDefault(msg, 1, ""),
    max: jspb.Message.getFieldWithDefault(msg, 2, ""),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.Range}
 */
proto.marketplace.Range.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.Range;
  return proto.marketplace.Range.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.Range} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.Range}
 */
proto.marketplace.Range.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMin(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMax(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.Range.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.Range.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.Range} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.Range.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMax();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string min = 1;
 * @return {string}
 */
proto.marketplace.Range.prototype.getMin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Range} returns this
 */
proto.marketplace.Range.prototype.setMin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string max = 2;
 * @return {string}
 */
proto.marketplace.Range.prototype.getMax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.Range} returns this
 */
proto.marketplace.Range.prototype.setMax = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 count = 3;
 * @return {number}
 */
proto.marketplace.Range.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.Range} returns this
 */
proto.marketplace.Range.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.CategoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.CategoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.CategoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.CategoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subcategoryid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    buckets: jspb.Message.getFieldWithDefault(msg, 2, 0),
    matchparams: (f = msg.getMatchparams()) && proto.marketplace.MatchParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.CategoryRequest}
 */
proto.marketplace.CategoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.CategoryRequest;
  return proto.marketplace.CategoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.CategoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.CategoryRequest}
 */
proto.marketplace.CategoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubcategoryid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBuckets(value);
      break;
    case 3:
      var value = new proto.marketplace.MatchParams;
      reader.readMessage(value,proto.marketplace.MatchParams.deserializeBinaryFromReader);
      msg.setMatchparams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.CategoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.CategoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.CategoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.CategoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubcategoryid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBuckets();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMatchparams();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.marketplace.MatchParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subcategoryId = 1;
 * @return {string}
 */
proto.marketplace.CategoryRequest.prototype.getSubcategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.CategoryRequest} returns this
 */
proto.marketplace.CategoryRequest.prototype.setSubcategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 buckets = 2;
 * @return {number}
 */
proto.marketplace.CategoryRequest.prototype.getBuckets = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.CategoryRequest} returns this
 */
proto.marketplace.CategoryRequest.prototype.setBuckets = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional MatchParams matchParams = 3;
 * @return {?proto.marketplace.MatchParams}
 */
proto.marketplace.CategoryRequest.prototype.getMatchparams = function() {
  return /** @type{?proto.marketplace.MatchParams} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.MatchParams, 3));
};


/**
 * @param {?proto.marketplace.MatchParams|undefined} value
 * @return {!proto.marketplace.CategoryRequest} returns this
*/
proto.marketplace.CategoryRequest.prototype.setMatchparams = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.CategoryRequest} returns this
 */
proto.marketplace.CategoryRequest.prototype.clearMatchparams = function() {
  return this.setMatchparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.CategoryRequest.prototype.hasMatchparams = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.FilterWithAdParamsByRangeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.FilterWithAdParamsByRangeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.FilterWithAdParamsByRangeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.FilterWithAdParamsByRangeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subcategoryid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rangefilterMap: (f = msg.getRangefilterMap()) ? f.toObject(includeInstance, proto.marketplace.RangeFilter.toObject) : [],
    matchparams: (f = msg.getMatchparams()) && proto.marketplace.MatchParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.FilterWithAdParamsByRangeRequest}
 */
proto.marketplace.FilterWithAdParamsByRangeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.FilterWithAdParamsByRangeRequest;
  return proto.marketplace.FilterWithAdParamsByRangeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.FilterWithAdParamsByRangeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.FilterWithAdParamsByRangeRequest}
 */
proto.marketplace.FilterWithAdParamsByRangeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubcategoryid(value);
      break;
    case 2:
      var value = msg.getRangefilterMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.marketplace.RangeFilter.deserializeBinaryFromReader, "", new proto.marketplace.RangeFilter());
         });
      break;
    case 3:
      var value = new proto.marketplace.MatchParams;
      reader.readMessage(value,proto.marketplace.MatchParams.deserializeBinaryFromReader);
      msg.setMatchparams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.FilterWithAdParamsByRangeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.FilterWithAdParamsByRangeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.FilterWithAdParamsByRangeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.FilterWithAdParamsByRangeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubcategoryid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRangefilterMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.marketplace.RangeFilter.serializeBinaryToWriter);
  }
  f = message.getMatchparams();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.marketplace.MatchParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subcategoryId = 1;
 * @return {string}
 */
proto.marketplace.FilterWithAdParamsByRangeRequest.prototype.getSubcategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.FilterWithAdParamsByRangeRequest} returns this
 */
proto.marketplace.FilterWithAdParamsByRangeRequest.prototype.setSubcategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, RangeFilter> rangeFilter = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.marketplace.RangeFilter>}
 */
proto.marketplace.FilterWithAdParamsByRangeRequest.prototype.getRangefilterMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.marketplace.RangeFilter>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.marketplace.RangeFilter));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.marketplace.FilterWithAdParamsByRangeRequest} returns this
 */
proto.marketplace.FilterWithAdParamsByRangeRequest.prototype.clearRangefilterMap = function() {
  this.getRangefilterMap().clear();
  return this;};


/**
 * optional MatchParams matchParams = 3;
 * @return {?proto.marketplace.MatchParams}
 */
proto.marketplace.FilterWithAdParamsByRangeRequest.prototype.getMatchparams = function() {
  return /** @type{?proto.marketplace.MatchParams} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.MatchParams, 3));
};


/**
 * @param {?proto.marketplace.MatchParams|undefined} value
 * @return {!proto.marketplace.FilterWithAdParamsByRangeRequest} returns this
*/
proto.marketplace.FilterWithAdParamsByRangeRequest.prototype.setMatchparams = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.FilterWithAdParamsByRangeRequest} returns this
 */
proto.marketplace.FilterWithAdParamsByRangeRequest.prototype.clearMatchparams = function() {
  return this.setMatchparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.FilterWithAdParamsByRangeRequest.prototype.hasMatchparams = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.FilterWithAdParamsByTextRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.FilterWithAdParamsByTextRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.FilterWithAdParamsByTextRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.FilterWithAdParamsByTextRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subcategoryid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    textfilterMap: (f = msg.getTextfilterMap()) ? f.toObject(includeInstance, proto.marketplace.TextFilter.toObject) : [],
    matchparams: (f = msg.getMatchparams()) && proto.marketplace.MatchParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.FilterWithAdParamsByTextRequest}
 */
proto.marketplace.FilterWithAdParamsByTextRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.FilterWithAdParamsByTextRequest;
  return proto.marketplace.FilterWithAdParamsByTextRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.FilterWithAdParamsByTextRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.FilterWithAdParamsByTextRequest}
 */
proto.marketplace.FilterWithAdParamsByTextRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubcategoryid(value);
      break;
    case 2:
      var value = msg.getTextfilterMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.marketplace.TextFilter.deserializeBinaryFromReader, "", new proto.marketplace.TextFilter());
         });
      break;
    case 3:
      var value = new proto.marketplace.MatchParams;
      reader.readMessage(value,proto.marketplace.MatchParams.deserializeBinaryFromReader);
      msg.setMatchparams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.FilterWithAdParamsByTextRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.FilterWithAdParamsByTextRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.FilterWithAdParamsByTextRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.FilterWithAdParamsByTextRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubcategoryid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTextfilterMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.marketplace.TextFilter.serializeBinaryToWriter);
  }
  f = message.getMatchparams();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.marketplace.MatchParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subcategoryId = 1;
 * @return {string}
 */
proto.marketplace.FilterWithAdParamsByTextRequest.prototype.getSubcategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.FilterWithAdParamsByTextRequest} returns this
 */
proto.marketplace.FilterWithAdParamsByTextRequest.prototype.setSubcategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, TextFilter> textFilter = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.marketplace.TextFilter>}
 */
proto.marketplace.FilterWithAdParamsByTextRequest.prototype.getTextfilterMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.marketplace.TextFilter>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.marketplace.TextFilter));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.marketplace.FilterWithAdParamsByTextRequest} returns this
 */
proto.marketplace.FilterWithAdParamsByTextRequest.prototype.clearTextfilterMap = function() {
  this.getTextfilterMap().clear();
  return this;};


/**
 * optional MatchParams matchParams = 3;
 * @return {?proto.marketplace.MatchParams}
 */
proto.marketplace.FilterWithAdParamsByTextRequest.prototype.getMatchparams = function() {
  return /** @type{?proto.marketplace.MatchParams} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.MatchParams, 3));
};


/**
 * @param {?proto.marketplace.MatchParams|undefined} value
 * @return {!proto.marketplace.FilterWithAdParamsByTextRequest} returns this
*/
proto.marketplace.FilterWithAdParamsByTextRequest.prototype.setMatchparams = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.FilterWithAdParamsByTextRequest} returns this
 */
proto.marketplace.FilterWithAdParamsByTextRequest.prototype.clearMatchparams = function() {
  return this.setMatchparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.FilterWithAdParamsByTextRequest.prototype.hasMatchparams = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.SearchAdsByTitleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.SearchAdsByTitleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.SearchAdsByTitleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subcategoryid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: (f = msg.getLocation()) && proto.marketplace.LocationRequest.toObject(includeInstance, f),
    matchparams: (f = msg.getMatchparams()) && proto.marketplace.MatchParams.toObject(includeInstance, f),
    userid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    price: (f = msg.getPrice()) && proto.marketplace.PriceRange.toObject(includeInstance, f),
    categoryid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sortmechanism: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.SearchAdsByTitleRequest}
 */
proto.marketplace.SearchAdsByTitleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.SearchAdsByTitleRequest;
  return proto.marketplace.SearchAdsByTitleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.SearchAdsByTitleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.SearchAdsByTitleRequest}
 */
proto.marketplace.SearchAdsByTitleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubcategoryid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = new proto.marketplace.LocationRequest;
      reader.readMessage(value,proto.marketplace.LocationRequest.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 4:
      var value = new proto.marketplace.MatchParams;
      reader.readMessage(value,proto.marketplace.MatchParams.deserializeBinaryFromReader);
      msg.setMatchparams(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 6:
      var value = new proto.marketplace.PriceRange;
      reader.readMessage(value,proto.marketplace.PriceRange.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryid(value);
      break;
    case 8:
      var value = /** @type {!proto.marketplace.SearchAdsByTitleRequest.SortMechanism} */ (reader.readEnum());
      msg.setSortmechanism(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.SearchAdsByTitleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.SearchAdsByTitleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.SearchAdsByTitleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubcategoryid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.marketplace.LocationRequest.serializeBinaryToWriter
    );
  }
  f = message.getMatchparams();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.marketplace.MatchParams.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.marketplace.PriceRange.serializeBinaryToWriter
    );
  }
  f = message.getCategoryid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSortmechanism();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.marketplace.SearchAdsByTitleRequest.SortMechanism = {
  TIMESTAMP: 0,
  RELEVANCE: 1,
  ASCPRICE: 2,
  DESCPRICE: 3
};

/**
 * optional string subcategoryId = 1;
 * @return {string}
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.getSubcategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.SearchAdsByTitleRequest} returns this
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.setSubcategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.SearchAdsByTitleRequest} returns this
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional LocationRequest location = 3;
 * @return {?proto.marketplace.LocationRequest}
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.getLocation = function() {
  return /** @type{?proto.marketplace.LocationRequest} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.LocationRequest, 3));
};


/**
 * @param {?proto.marketplace.LocationRequest|undefined} value
 * @return {!proto.marketplace.SearchAdsByTitleRequest} returns this
*/
proto.marketplace.SearchAdsByTitleRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.SearchAdsByTitleRequest} returns this
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MatchParams matchParams = 4;
 * @return {?proto.marketplace.MatchParams}
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.getMatchparams = function() {
  return /** @type{?proto.marketplace.MatchParams} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.MatchParams, 4));
};


/**
 * @param {?proto.marketplace.MatchParams|undefined} value
 * @return {!proto.marketplace.SearchAdsByTitleRequest} returns this
*/
proto.marketplace.SearchAdsByTitleRequest.prototype.setMatchparams = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.SearchAdsByTitleRequest} returns this
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.clearMatchparams = function() {
  return this.setMatchparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.hasMatchparams = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string userID = 5;
 * @return {string}
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.SearchAdsByTitleRequest} returns this
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional PriceRange price = 6;
 * @return {?proto.marketplace.PriceRange}
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.getPrice = function() {
  return /** @type{?proto.marketplace.PriceRange} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.PriceRange, 6));
};


/**
 * @param {?proto.marketplace.PriceRange|undefined} value
 * @return {!proto.marketplace.SearchAdsByTitleRequest} returns this
*/
proto.marketplace.SearchAdsByTitleRequest.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.SearchAdsByTitleRequest} returns this
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string categoryId = 7;
 * @return {string}
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.getCategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.SearchAdsByTitleRequest} returns this
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional SortMechanism sortMechanism = 8;
 * @return {!proto.marketplace.SearchAdsByTitleRequest.SortMechanism}
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.getSortmechanism = function() {
  return /** @type {!proto.marketplace.SearchAdsByTitleRequest.SortMechanism} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.marketplace.SearchAdsByTitleRequest.SortMechanism} value
 * @return {!proto.marketplace.SearchAdsByTitleRequest} returns this
 */
proto.marketplace.SearchAdsByTitleRequest.prototype.setSortmechanism = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.SearchUsersByTitleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.SearchUsersByTitleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.SearchUsersByTitleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.SearchUsersByTitleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subcategoryid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    location: (f = msg.getLocation()) && proto.marketplace.LocationRequest.toObject(includeInstance, f),
    matchparams: (f = msg.getMatchparams()) && proto.marketplace.MatchParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.SearchUsersByTitleRequest}
 */
proto.marketplace.SearchUsersByTitleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.SearchUsersByTitleRequest;
  return proto.marketplace.SearchUsersByTitleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.SearchUsersByTitleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.SearchUsersByTitleRequest}
 */
proto.marketplace.SearchUsersByTitleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubcategoryid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = new proto.marketplace.LocationRequest;
      reader.readMessage(value,proto.marketplace.LocationRequest.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 4:
      var value = new proto.marketplace.MatchParams;
      reader.readMessage(value,proto.marketplace.MatchParams.deserializeBinaryFromReader);
      msg.setMatchparams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.SearchUsersByTitleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.SearchUsersByTitleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.SearchUsersByTitleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.SearchUsersByTitleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubcategoryid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.marketplace.LocationRequest.serializeBinaryToWriter
    );
  }
  f = message.getMatchparams();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.marketplace.MatchParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subcategoryId = 1;
 * @return {string}
 */
proto.marketplace.SearchUsersByTitleRequest.prototype.getSubcategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.SearchUsersByTitleRequest} returns this
 */
proto.marketplace.SearchUsersByTitleRequest.prototype.setSubcategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.marketplace.SearchUsersByTitleRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.SearchUsersByTitleRequest} returns this
 */
proto.marketplace.SearchUsersByTitleRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional LocationRequest location = 3;
 * @return {?proto.marketplace.LocationRequest}
 */
proto.marketplace.SearchUsersByTitleRequest.prototype.getLocation = function() {
  return /** @type{?proto.marketplace.LocationRequest} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.LocationRequest, 3));
};


/**
 * @param {?proto.marketplace.LocationRequest|undefined} value
 * @return {!proto.marketplace.SearchUsersByTitleRequest} returns this
*/
proto.marketplace.SearchUsersByTitleRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.SearchUsersByTitleRequest} returns this
 */
proto.marketplace.SearchUsersByTitleRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.SearchUsersByTitleRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MatchParams matchParams = 4;
 * @return {?proto.marketplace.MatchParams}
 */
proto.marketplace.SearchUsersByTitleRequest.prototype.getMatchparams = function() {
  return /** @type{?proto.marketplace.MatchParams} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.MatchParams, 4));
};


/**
 * @param {?proto.marketplace.MatchParams|undefined} value
 * @return {!proto.marketplace.SearchUsersByTitleRequest} returns this
*/
proto.marketplace.SearchUsersByTitleRequest.prototype.setMatchparams = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.SearchUsersByTitleRequest} returns this
 */
proto.marketplace.SearchUsersByTitleRequest.prototype.clearMatchparams = function() {
  return this.setMatchparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.SearchUsersByTitleRequest.prototype.hasMatchparams = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.FilterWithLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.FilterWithLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.FilterWithLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.FilterWithLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subcategoryid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    governorate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    delegation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    matchparams: (f = msg.getMatchparams()) && proto.marketplace.MatchParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.FilterWithLocationRequest}
 */
proto.marketplace.FilterWithLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.FilterWithLocationRequest;
  return proto.marketplace.FilterWithLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.FilterWithLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.FilterWithLocationRequest}
 */
proto.marketplace.FilterWithLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubcategoryid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGovernorate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelegation(value);
      break;
    case 4:
      var value = new proto.marketplace.MatchParams;
      reader.readMessage(value,proto.marketplace.MatchParams.deserializeBinaryFromReader);
      msg.setMatchparams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.FilterWithLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.FilterWithLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.FilterWithLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.FilterWithLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubcategoryid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGovernorate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDelegation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMatchparams();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.marketplace.MatchParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subcategoryId = 1;
 * @return {string}
 */
proto.marketplace.FilterWithLocationRequest.prototype.getSubcategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.FilterWithLocationRequest} returns this
 */
proto.marketplace.FilterWithLocationRequest.prototype.setSubcategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string governorate = 2;
 * @return {string}
 */
proto.marketplace.FilterWithLocationRequest.prototype.getGovernorate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.FilterWithLocationRequest} returns this
 */
proto.marketplace.FilterWithLocationRequest.prototype.setGovernorate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string delegation = 3;
 * @return {string}
 */
proto.marketplace.FilterWithLocationRequest.prototype.getDelegation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.FilterWithLocationRequest} returns this
 */
proto.marketplace.FilterWithLocationRequest.prototype.setDelegation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MatchParams matchParams = 4;
 * @return {?proto.marketplace.MatchParams}
 */
proto.marketplace.FilterWithLocationRequest.prototype.getMatchparams = function() {
  return /** @type{?proto.marketplace.MatchParams} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.MatchParams, 4));
};


/**
 * @param {?proto.marketplace.MatchParams|undefined} value
 * @return {!proto.marketplace.FilterWithLocationRequest} returns this
*/
proto.marketplace.FilterWithLocationRequest.prototype.setMatchparams = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.FilterWithLocationRequest} returns this
 */
proto.marketplace.FilterWithLocationRequest.prototype.clearMatchparams = function() {
  return this.setMatchparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.FilterWithLocationRequest.prototype.hasMatchparams = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.MatchParams.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.MatchParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.MatchParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.MatchParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, 0),
    to: jspb.Message.getFieldWithDefault(msg, 2, 0),
    paginationparams: (f = msg.getPaginationparams()) && proto.marketplace.PaginationParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.MatchParams}
 */
proto.marketplace.MatchParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.MatchParams;
  return proto.marketplace.MatchParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.MatchParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.MatchParams}
 */
proto.marketplace.MatchParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTo(value);
      break;
    case 3:
      var value = new proto.marketplace.PaginationParams;
      reader.readMessage(value,proto.marketplace.PaginationParams.deserializeBinaryFromReader);
      msg.setPaginationparams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.MatchParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.MatchParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.MatchParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.MatchParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPaginationparams();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.marketplace.PaginationParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 from = 1;
 * @return {number}
 */
proto.marketplace.MatchParams.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.MatchParams} returns this
 */
proto.marketplace.MatchParams.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 to = 2;
 * @return {number}
 */
proto.marketplace.MatchParams.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.MatchParams} returns this
 */
proto.marketplace.MatchParams.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional PaginationParams paginationParams = 3;
 * @return {?proto.marketplace.PaginationParams}
 */
proto.marketplace.MatchParams.prototype.getPaginationparams = function() {
  return /** @type{?proto.marketplace.PaginationParams} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.PaginationParams, 3));
};


/**
 * @param {?proto.marketplace.PaginationParams|undefined} value
 * @return {!proto.marketplace.MatchParams} returns this
*/
proto.marketplace.MatchParams.prototype.setPaginationparams = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.MatchParams} returns this
 */
proto.marketplace.MatchParams.prototype.clearPaginationparams = function() {
  return this.setPaginationparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.MatchParams.prototype.hasPaginationparams = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.LocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.LocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.LocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.LocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    governorate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    delegation: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.LocationRequest}
 */
proto.marketplace.LocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.LocationRequest;
  return proto.marketplace.LocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.LocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.LocationRequest}
 */
proto.marketplace.LocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGovernorate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelegation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.LocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.LocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.LocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.LocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGovernorate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDelegation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string governorate = 1;
 * @return {string}
 */
proto.marketplace.LocationRequest.prototype.getGovernorate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.LocationRequest} returns this
 */
proto.marketplace.LocationRequest.prototype.setGovernorate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string delegation = 2;
 * @return {string}
 */
proto.marketplace.LocationRequest.prototype.getDelegation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.LocationRequest} returns this
 */
proto.marketplace.LocationRequest.prototype.setDelegation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.FilterAndDatesSearch.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.FilterAndDatesSearch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.FilterAndDatesSearch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.FilterAndDatesSearch.toObject = function(includeInstance, msg) {
  var f, obj = {
    sortmechanism: jspb.Message.getFieldWithDefault(msg, 1, 0),
    filterparamsMap: (f = msg.getFilterparamsMap()) ? f.toObject(includeInstance, undefined) : [],
    rangefilterMap: (f = msg.getRangefilterMap()) ? f.toObject(includeInstance, proto.marketplace.RangeFilter.toObject) : [],
    textfilterMap: (f = msg.getTextfilterMap()) ? f.toObject(includeInstance, proto.marketplace.TextFilter.toObject) : [],
    from: jspb.Message.getFieldWithDefault(msg, 5, 0),
    to: jspb.Message.getFieldWithDefault(msg, 6, 0),
    paginationparams: (f = msg.getPaginationparams()) && proto.marketplace.PaginationParams.toObject(includeInstance, f),
    subcategoryid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    location: (f = msg.getLocation()) && proto.marketplace.LocationRequest.toObject(includeInstance, f),
    price: (f = msg.getPrice()) && proto.marketplace.PriceRange.toObject(includeInstance, f),
    userid: jspb.Message.getFieldWithDefault(msg, 11, ""),
    categoryid: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.FilterAndDatesSearch}
 */
proto.marketplace.FilterAndDatesSearch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.FilterAndDatesSearch;
  return proto.marketplace.FilterAndDatesSearch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.FilterAndDatesSearch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.FilterAndDatesSearch}
 */
proto.marketplace.FilterAndDatesSearch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.marketplace.FilterAndDatesSearch.SortMechanism} */ (reader.readEnum());
      msg.setSortmechanism(value);
      break;
    case 2:
      var value = msg.getFilterparamsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 3:
      var value = msg.getRangefilterMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.marketplace.RangeFilter.deserializeBinaryFromReader, "", new proto.marketplace.RangeFilter());
         });
      break;
    case 4:
      var value = msg.getTextfilterMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.marketplace.TextFilter.deserializeBinaryFromReader, "", new proto.marketplace.TextFilter());
         });
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFrom(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTo(value);
      break;
    case 7:
      var value = new proto.marketplace.PaginationParams;
      reader.readMessage(value,proto.marketplace.PaginationParams.deserializeBinaryFromReader);
      msg.setPaginationparams(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubcategoryid(value);
      break;
    case 9:
      var value = new proto.marketplace.LocationRequest;
      reader.readMessage(value,proto.marketplace.LocationRequest.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 10:
      var value = new proto.marketplace.PriceRange;
      reader.readMessage(value,proto.marketplace.PriceRange.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.FilterAndDatesSearch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.FilterAndDatesSearch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.FilterAndDatesSearch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.FilterAndDatesSearch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSortmechanism();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getFilterparamsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getRangefilterMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.marketplace.RangeFilter.serializeBinaryToWriter);
  }
  f = message.getTextfilterMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.marketplace.TextFilter.serializeBinaryToWriter);
  }
  f = message.getFrom();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPaginationparams();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.marketplace.PaginationParams.serializeBinaryToWriter
    );
  }
  f = message.getSubcategoryid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.marketplace.LocationRequest.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.marketplace.PriceRange.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCategoryid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.marketplace.FilterAndDatesSearch.SortMechanism = {
  TIMESTAMP: 0,
  RELEVANCE: 1,
  ASCPRICE: 2,
  DESCPRICE: 3
};

/**
 * optional SortMechanism sortMechanism = 1;
 * @return {!proto.marketplace.FilterAndDatesSearch.SortMechanism}
 */
proto.marketplace.FilterAndDatesSearch.prototype.getSortmechanism = function() {
  return /** @type {!proto.marketplace.FilterAndDatesSearch.SortMechanism} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.marketplace.FilterAndDatesSearch.SortMechanism} value
 * @return {!proto.marketplace.FilterAndDatesSearch} returns this
 */
proto.marketplace.FilterAndDatesSearch.prototype.setSortmechanism = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * map<string, string> filterParams = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.marketplace.FilterAndDatesSearch.prototype.getFilterparamsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.marketplace.FilterAndDatesSearch} returns this
 */
proto.marketplace.FilterAndDatesSearch.prototype.clearFilterparamsMap = function() {
  this.getFilterparamsMap().clear();
  return this;};


/**
 * map<string, RangeFilter> rangeFilter = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.marketplace.RangeFilter>}
 */
proto.marketplace.FilterAndDatesSearch.prototype.getRangefilterMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.marketplace.RangeFilter>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.marketplace.RangeFilter));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.marketplace.FilterAndDatesSearch} returns this
 */
proto.marketplace.FilterAndDatesSearch.prototype.clearRangefilterMap = function() {
  this.getRangefilterMap().clear();
  return this;};


/**
 * map<string, TextFilter> textFilter = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.marketplace.TextFilter>}
 */
proto.marketplace.FilterAndDatesSearch.prototype.getTextfilterMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.marketplace.TextFilter>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.marketplace.TextFilter));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.marketplace.FilterAndDatesSearch} returns this
 */
proto.marketplace.FilterAndDatesSearch.prototype.clearTextfilterMap = function() {
  this.getTextfilterMap().clear();
  return this;};


/**
 * optional int32 from = 5;
 * @return {number}
 */
proto.marketplace.FilterAndDatesSearch.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.FilterAndDatesSearch} returns this
 */
proto.marketplace.FilterAndDatesSearch.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 to = 6;
 * @return {number}
 */
proto.marketplace.FilterAndDatesSearch.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.FilterAndDatesSearch} returns this
 */
proto.marketplace.FilterAndDatesSearch.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional PaginationParams paginationParams = 7;
 * @return {?proto.marketplace.PaginationParams}
 */
proto.marketplace.FilterAndDatesSearch.prototype.getPaginationparams = function() {
  return /** @type{?proto.marketplace.PaginationParams} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.PaginationParams, 7));
};


/**
 * @param {?proto.marketplace.PaginationParams|undefined} value
 * @return {!proto.marketplace.FilterAndDatesSearch} returns this
*/
proto.marketplace.FilterAndDatesSearch.prototype.setPaginationparams = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.FilterAndDatesSearch} returns this
 */
proto.marketplace.FilterAndDatesSearch.prototype.clearPaginationparams = function() {
  return this.setPaginationparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.FilterAndDatesSearch.prototype.hasPaginationparams = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string subcategoryId = 8;
 * @return {string}
 */
proto.marketplace.FilterAndDatesSearch.prototype.getSubcategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.FilterAndDatesSearch} returns this
 */
proto.marketplace.FilterAndDatesSearch.prototype.setSubcategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional LocationRequest location = 9;
 * @return {?proto.marketplace.LocationRequest}
 */
proto.marketplace.FilterAndDatesSearch.prototype.getLocation = function() {
  return /** @type{?proto.marketplace.LocationRequest} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.LocationRequest, 9));
};


/**
 * @param {?proto.marketplace.LocationRequest|undefined} value
 * @return {!proto.marketplace.FilterAndDatesSearch} returns this
*/
proto.marketplace.FilterAndDatesSearch.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.FilterAndDatesSearch} returns this
 */
proto.marketplace.FilterAndDatesSearch.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.FilterAndDatesSearch.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PriceRange price = 10;
 * @return {?proto.marketplace.PriceRange}
 */
proto.marketplace.FilterAndDatesSearch.prototype.getPrice = function() {
  return /** @type{?proto.marketplace.PriceRange} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.PriceRange, 10));
};


/**
 * @param {?proto.marketplace.PriceRange|undefined} value
 * @return {!proto.marketplace.FilterAndDatesSearch} returns this
*/
proto.marketplace.FilterAndDatesSearch.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.FilterAndDatesSearch} returns this
 */
proto.marketplace.FilterAndDatesSearch.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.FilterAndDatesSearch.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string userID = 11;
 * @return {string}
 */
proto.marketplace.FilterAndDatesSearch.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.FilterAndDatesSearch} returns this
 */
proto.marketplace.FilterAndDatesSearch.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string categoryId = 12;
 * @return {string}
 */
proto.marketplace.FilterAndDatesSearch.prototype.getCategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.FilterAndDatesSearch} returns this
 */
proto.marketplace.FilterAndDatesSearch.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.PaginationParams.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.PaginationParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.PaginationParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.PaginationParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    skip: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.PaginationParams}
 */
proto.marketplace.PaginationParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.PaginationParams;
  return proto.marketplace.PaginationParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.PaginationParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.PaginationParams}
 */
proto.marketplace.PaginationParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSkip(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.PaginationParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.PaginationParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.PaginationParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.PaginationParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSkip();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 limit = 1;
 * @return {number}
 */
proto.marketplace.PaginationParams.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.PaginationParams} returns this
 */
proto.marketplace.PaginationParams.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 skip = 2;
 * @return {number}
 */
proto.marketplace.PaginationParams.prototype.getSkip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.PaginationParams} returns this
 */
proto.marketplace.PaginationParams.prototype.setSkip = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.PriceRange.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.PriceRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.PriceRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.PriceRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    minprice: jspb.Message.getFieldWithDefault(msg, 1, 0),
    maxprice: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.PriceRange}
 */
proto.marketplace.PriceRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.PriceRange;
  return proto.marketplace.PriceRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.PriceRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.PriceRange}
 */
proto.marketplace.PriceRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinprice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.PriceRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.PriceRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.PriceRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.PriceRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinprice();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMaxprice();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 minPrice = 1;
 * @return {number}
 */
proto.marketplace.PriceRange.prototype.getMinprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.PriceRange} returns this
 */
proto.marketplace.PriceRange.prototype.setMinprice = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 maxPrice = 2;
 * @return {number}
 */
proto.marketplace.PriceRange.prototype.getMaxprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.PriceRange} returns this
 */
proto.marketplace.PriceRange.prototype.setMaxprice = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.RangeFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.RangeFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.RangeFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.RangeFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    min: jspb.Message.getFieldWithDefault(msg, 1, 0),
    max: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.RangeFilter}
 */
proto.marketplace.RangeFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.RangeFilter;
  return proto.marketplace.RangeFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.RangeFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.RangeFilter}
 */
proto.marketplace.RangeFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.RangeFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.RangeFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.RangeFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.RangeFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMin();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMax();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 min = 1;
 * @return {number}
 */
proto.marketplace.RangeFilter.prototype.getMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.RangeFilter} returns this
 */
proto.marketplace.RangeFilter.prototype.setMin = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 max = 2;
 * @return {number}
 */
proto.marketplace.RangeFilter.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.marketplace.RangeFilter} returns this
 */
proto.marketplace.RangeFilter.prototype.setMax = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketplace.TextFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.TextFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.TextFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.TextFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.TextFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    filtervalueList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.TextFilter}
 */
proto.marketplace.TextFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.TextFilter;
  return proto.marketplace.TextFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.TextFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.TextFilter}
 */
proto.marketplace.TextFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addFiltervalue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.TextFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.TextFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.TextFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.TextFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFiltervalueList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string filterValue = 1;
 * @return {!Array<string>}
 */
proto.marketplace.TextFilter.prototype.getFiltervalueList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.marketplace.TextFilter} returns this
 */
proto.marketplace.TextFilter.prototype.setFiltervalueList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.marketplace.TextFilter} returns this
 */
proto.marketplace.TextFilter.prototype.addFiltervalue = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketplace.TextFilter} returns this
 */
proto.marketplace.TextFilter.prototype.clearFiltervalueList = function() {
  return this.setFiltervalueList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketplace.PendingAndManualModerationAdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketplace.PendingAndManualModerationAdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketplace.PendingAndManualModerationAdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.PendingAndManualModerationAdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paginationparams: (f = msg.getPaginationparams()) && proto.marketplace.PaginationParams.toObject(includeInstance, f),
    filterparamsMap: (f = msg.getFilterparamsMap()) ? f.toObject(includeInstance, undefined) : [],
    sortmechanism: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketplace.PendingAndManualModerationAdsRequest}
 */
proto.marketplace.PendingAndManualModerationAdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketplace.PendingAndManualModerationAdsRequest;
  return proto.marketplace.PendingAndManualModerationAdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketplace.PendingAndManualModerationAdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketplace.PendingAndManualModerationAdsRequest}
 */
proto.marketplace.PendingAndManualModerationAdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = new proto.marketplace.PaginationParams;
      reader.readMessage(value,proto.marketplace.PaginationParams.deserializeBinaryFromReader);
      msg.setPaginationparams(value);
      break;
    case 3:
      var value = msg.getFilterparamsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 4:
      var value = /** @type {!proto.marketplace.PendingAndManualModerationAdsRequest.SortMechanism} */ (reader.readEnum());
      msg.setSortmechanism(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketplace.PendingAndManualModerationAdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketplace.PendingAndManualModerationAdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketplace.PendingAndManualModerationAdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketplace.PendingAndManualModerationAdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaginationparams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.marketplace.PaginationParams.serializeBinaryToWriter
    );
  }
  f = message.getFilterparamsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getSortmechanism();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.marketplace.PendingAndManualModerationAdsRequest.SortMechanism = {
  TIMESTAMP: 0,
  RELEVANCE: 1,
  ASCPRICE: 2,
  DESCPRICE: 3
};

/**
 * optional string userID = 1;
 * @return {string}
 */
proto.marketplace.PendingAndManualModerationAdsRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketplace.PendingAndManualModerationAdsRequest} returns this
 */
proto.marketplace.PendingAndManualModerationAdsRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PaginationParams paginationParams = 2;
 * @return {?proto.marketplace.PaginationParams}
 */
proto.marketplace.PendingAndManualModerationAdsRequest.prototype.getPaginationparams = function() {
  return /** @type{?proto.marketplace.PaginationParams} */ (
    jspb.Message.getWrapperField(this, proto.marketplace.PaginationParams, 2));
};


/**
 * @param {?proto.marketplace.PaginationParams|undefined} value
 * @return {!proto.marketplace.PendingAndManualModerationAdsRequest} returns this
*/
proto.marketplace.PendingAndManualModerationAdsRequest.prototype.setPaginationparams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketplace.PendingAndManualModerationAdsRequest} returns this
 */
proto.marketplace.PendingAndManualModerationAdsRequest.prototype.clearPaginationparams = function() {
  return this.setPaginationparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketplace.PendingAndManualModerationAdsRequest.prototype.hasPaginationparams = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * map<string, string> filterParams = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.marketplace.PendingAndManualModerationAdsRequest.prototype.getFilterparamsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.marketplace.PendingAndManualModerationAdsRequest} returns this
 */
proto.marketplace.PendingAndManualModerationAdsRequest.prototype.clearFilterparamsMap = function() {
  this.getFilterparamsMap().clear();
  return this;};


/**
 * optional SortMechanism sortMechanism = 4;
 * @return {!proto.marketplace.PendingAndManualModerationAdsRequest.SortMechanism}
 */
proto.marketplace.PendingAndManualModerationAdsRequest.prototype.getSortmechanism = function() {
  return /** @type {!proto.marketplace.PendingAndManualModerationAdsRequest.SortMechanism} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.marketplace.PendingAndManualModerationAdsRequest.SortMechanism} value
 * @return {!proto.marketplace.PendingAndManualModerationAdsRequest} returns this
 */
proto.marketplace.PendingAndManualModerationAdsRequest.prototype.setSortmechanism = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


goog.object.extend(exports, proto.marketplace);
