 import { grpc } from '@improbable-eng/grpc-web';
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';

import {
  BlockUserRequest,
  BlockUserResponse,
  DeleteMsgRequest,
  DeleteMsgResponse,
  DiscussionRequest,
  GetAdRelatedDiscussionByMembersRequest,
  GetAdRelatedDiscussionByMembersResponse,
  GetDiscussionsResponse,
  GetMessagesResponse,
  InitiateDiscussionRequest,
  InitiateDiscussionResponse,
  NewMessageRequest,
  UnreadDiscussionsCountResponse,
  UserRequest,
} from '@/api/gen-protos/messenger_pb';
import { Messenger } from '@/api/gen-protos/messenger_pb_service';
// @ts-ignore
import { LIMIT, SHOW_ALL_ADS, Value } from '@/lib/constants';
// eslint-disable-next-line import/no-duplicates
import { mapAdCurrentVersionResponseToIListingItem } from '@/modules/core/mappings';
import { environment, environment as envLocal } from 'environment';

// @ts-ignore
import { FilterPrivateAdsType, IListingItem } from '../../modules/core/types';
import {
  AdByIDRequest,
  AdCurrentVersionResponse,
  AdResponse,
  DelegationResponse,
  DelegationsByGovernorateRequest,
  GetAdsByUserRequest,
  GoldAdIdResponse,
  LocationByGovernorateAndDelegation,
  LocationResponse,
  NbAdsByUserRequest,
  NbAdsByUserResponse,
  NewAdRequest,
  TotalAdsCountResponse,
  Version,
  AdViewRequest,
  ViewResponse,
  ResponseView,
  VersionInAdByIDRequest,
  GetPaymentResponse,
  TransactionStatusRequest,
  TransactionStatusResponse,
  ReportAdByUserRequest,
  ReportAdByUserResponse,
   AnalyticsResponse,
} from '../gen-protos/ads_pb';
import {
  CategoryResponse,
  CategoryByIDRequest,
} from '../gen-protos/categories_pb';
import {
  FilterAndDatesSearch,
  PaginationParams,
  SearchUsersByTitleRequest,
} from '../gen-protos/discovery_pb';
import { MarketPlace } from '../gen-protos/marketplace_pb_service';
import {
  AutoBoostAdByIDRequest,
  AddAutomaticBoostResponse,
} from '../gen-protos/scheduled_pb';
import {
  GetAccountTransactionsRequest,
  NewUserByIDRequest,
  SendUserDeviceConfigRequest,
  SendUserDeviceConfigResponse,
  TotalUserCountResponse,
    TransferTokensByAdminRequest,
    TransferTokensResponse,
  UserByIDRequest,
  UserByPhoneNumberRequest,
  UserResponse,
} from '../gen-protos/users_pb';
import { GetUserTransactionsBalanceResponse } from '../gen-protos/wallets_pb';

/**
 *get all parents categories  for  both server and client side and map them to
  
 * @returns CategoryResponse[]
 */

export const getParentCategories = (): Promise<CategoryResponse[]> => {
  // tslint:disable-next-line:no-shadowed-variable
  const categoryResponse: CategoryResponse[] = [];
  const empty = new Empty();
  return new Promise<CategoryResponse[]>((resolve, reject) => {
    const metadata = new grpc.Metadata({ Authorization: `Bearer rrgferfrf` });
    grpc.invoke(MarketPlace.ListCategories, {
      metadata,
      request: empty,
      host: environment.marketPlaceHostClient,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),

      onMessage: (response: CategoryResponse) => {
        categoryResponse.push(response);
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(categoryResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
};

/**
 * get filtred list ads from server and map them to IListingItem
 * @param token string
 * @param filterAndDatesSearch FilterAndDatesSearch
 * @returns IListingItem
 */

export const listAdsCurrentVersion = (
  filterAndDatesSearch: FilterAndDatesSearch,
  token: string
): Promise<IListingItem[]> => {
  const iListingItem: IListingItem[] = [];
  return new Promise<IListingItem[]>((resolve, reject) => {
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    grpc.invoke(MarketPlace.FilterAdsWithAdParams, {
      metadata,
      request: filterAndDatesSearch,
      host: environment.marketPlaceHostClient,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: AdCurrentVersionResponse) => {
        if (response)
          iListingItem.push(
            mapAdCurrentVersionResponseToIListingItem(response)
          );
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(iListingItem);
        } else {
          reject(new Error(`Error fetching ads with current version: ${msg}`));
        }
      },
    });
  });
};

/**
 * get fimtred category by id from server and map it to ICategoryParamList
 * @param token
 * @param categoryByIDRequest CategoryByIDRequest
 * @returns CategoryResponse
 */

export const getCategory = (
  categoryByIDRequest: CategoryByIDRequest
): Promise<CategoryResponse> =>
  new Promise<CategoryResponse>((resolve, reject) => {
    let categoryByIDResult: CategoryResponse;
    grpc.invoke(MarketPlace.GetCategory, {
      request: categoryByIDRequest,
      host: environment.marketPlaceHostClient,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),

      onMessage: (response: CategoryResponse) => {
        categoryByIDResult = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(categoryByIDResult as CategoryResponse);
        } else {
          reject(msg);
        }
      },
    });
  });

/**
 *  get count filtred Ads list
 * @param token
 * @param filterData FilterAndDatesSearch
 * @returns Promise<TotalAdsCountResponse>
 */

export const GetTotalAdsCount = (
  filterData: FilterAndDatesSearch
): Promise<TotalAdsCountResponse> =>
  new Promise<TotalAdsCountResponse>((resolve, reject) => {
    let response: TotalAdsCountResponse;

    grpc.invoke(MarketPlace.GetTotalAdsCount, {
      request: filterData,
      host: environment.marketPlaceHostClient,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),

      onMessage: (responseMessage: TotalAdsCountResponse) => {
        response = responseMessage;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(response);
        } else {
          reject(msg);
        }
      },
    });
  });

/**
 *  get gold list
 * @param token
 * @returns Promise<GoldAdIdResponse[]>
 */

export const getGoldAd = (token: string): Promise<GoldAdIdResponse[]> => {
  const empty = new Empty();
  const goldAdIdResponse: GoldAdIdResponse[] = [];
  return new Promise<GoldAdIdResponse[]>((resolve, reject) => {
    const metadata = new grpc.Metadata({
      Authorization: `Bearer ${token}`,
    });
    grpc.invoke(MarketPlace.GetGoldenAd, {
      metadata,
      request: empty,
      host: environment.marketPlaceHostClient,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: GoldAdIdResponse) => {
        goldAdIdResponse.push(response);
        // mapAdCurrentVersionResponseToGoldAdIdResponse(
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(goldAdIdResponse);
        } else if (msg === '' || msg === 'Response closed without headers') {
          // Apparantly core is giving these message when there exists no
          // golden ad
          resolve([]);
        } else {
          reject(msg);
        }
      },
    });
  });
};

export const GetAdsByUser = async (
  filterParams: FilterPrivateAdsType
): Promise<IListingItem[]> => {
  const { limit, skip, isPublic, userId, status } = filterParams;

  const getAdsByUserRequest = new GetAdsByUserRequest();
  getAdsByUserRequest.setUserid(userId);
  const paginationParams = new PaginationParams();
  paginationParams.setLimit(limit || LIMIT);
  paginationParams.setSkip(skip || 0);
  getAdsByUserRequest.setPaginationparams(paginationParams);
  const filterWithAdParamsByRangeReponse: IListingItem[] = [];
  if (isPublic) {
    getAdsByUserRequest
      .getFilterparamsMap()
      .set('currentversion.state', Version.State.ACTIVE.toString());
  } else if (status !== SHOW_ALL_ADS)
    getAdsByUserRequest
      .getFilterparamsMap()
      .set('currentversion.state', status.toString());

  // Object.keys(otherParams).forEach(
  //   (keyParam: string) =>
  //     otherParams[keyParam] &&
  //     getAdsByUserRequest
  //       .getFilterparamsMap()
  //       .set(keyParam, otherParams[keyParam] || '')
  // );

  return new Promise<IListingItem[]>((resolve, reject) => {
    grpc.invoke(MarketPlace.GetAdsByUser, {
      request: getAdsByUserRequest,
      host: environment.marketPlaceHostClient,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: AdCurrentVersionResponse) => {
        filterWithAdParamsByRangeReponse.push(
          mapAdCurrentVersionResponseToIListingItem(response)
        );
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(filterWithAdParamsByRangeReponse);
        } else {
          reject(msg);
        }
      },
    });
  });
};

export const getUserBalanceTransactions = (
  token: Value<string>,
  getAccountTransactionsRequest: GetAccountTransactionsRequest
): Promise<GetUserTransactionsBalanceResponse> =>
  new Promise<GetUserTransactionsBalanceResponse>((resolve, reject) => {
    let getUserTransactionsBalanceResponse =
      new GetUserTransactionsBalanceResponse();
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    grpc.invoke(MarketPlace.GetUserBalanceAndTransactions, {
      metadata,
      request: getAccountTransactionsRequest,
      host: environment.marketPlaceHostClient,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: GetUserTransactionsBalanceResponse) => {
        getUserTransactionsBalanceResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(getUserTransactionsBalanceResponse);
        } else {
          reject(msg);
        }
      },
    });
  });

export const getUserbyphone = async (
  userByPhoneNumberRequest: UserByPhoneNumberRequest
): Promise<UserResponse> =>
  new Promise<UserResponse>((resolve, reject) => {
    // const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    let userResponse = new UserResponse();
    grpc.invoke(MarketPlace.GetUserByPhoneNumber, {
      request: userByPhoneNumberRequest,
      host: environment.marketPlaceHostClient,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: UserResponse) => {
        userResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(userResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
export const GetUserById = async (
  userByIDRequest: UserByIDRequest
): Promise<UserResponse> =>
  new Promise<UserResponse>((resolve, reject) => {
    // const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    let userResponse = new UserResponse();
    grpc.invoke(MarketPlace.GetUser, {
      request: userByIDRequest,
      host: environment.marketPlaceHostClient,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: UserResponse) => {
        userResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(userResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
export const getAd = (adByIDRequest: AdByIDRequest): Promise<AdResponse> =>
  new Promise<AdResponse>((resolve, reject) => {
    // const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });

    let adResponse = new AdResponse();
    grpc.invoke(MarketPlace.GetAd, {
      request: adByIDRequest,
      host: environment.marketPlaceHostClient,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: AdResponse) => {
        adResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(adResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
export const GetDiscussionsByUser = (
  token: Value<string>,
  userRequest: UserRequest
): Promise<GetDiscussionsResponse> =>
  new Promise<GetDiscussionsResponse>((resolve, reject) => {
    let getDiscussionsResponse = new GetDiscussionsResponse();
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    grpc.invoke(Messenger.GetDiscussionsByUser, {
      metadata,
      request: userRequest,
      host: environment.messengerHost,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),

      onMessage: (response: GetDiscussionsResponse) => {
        getDiscussionsResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(getDiscussionsResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
export const GetMessages = (
  token: Value<string>,
  discussionRequest: DiscussionRequest
): Promise<GetMessagesResponse> =>
  new Promise<GetMessagesResponse>((resolve, reject) => {
    let getMessagesResponse = new GetMessagesResponse();
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    grpc.invoke(Messenger.GetMessages, {
      metadata,
      request: discussionRequest,
      host: environment.messengerHost,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),

      onMessage: (response: GetMessagesResponse) => {
        getMessagesResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(getMessagesResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
export const SendMessage = (
  token: Value<string>,
  newMessageRequest: NewMessageRequest
): Promise<boolean> =>
  new Promise<boolean>((resolve, reject) => {
    let response = false;
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    grpc.invoke(Messenger.SendMessage, {
      metadata,
      request: newMessageRequest,
      host:
        envLocal
          .messengerHost,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),

      onMessage: () => {
        response = true;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(response);
        } else {
          reject(msg);
        }
      },
    });
  });
export const DeleteMessage = (
  token: Value<string>,
  newDeleteMsgResquest: DeleteMsgRequest
): Promise<DeleteMsgResponse> =>
  new Promise<DeleteMsgResponse>((resolve, reject) => {
    let deleteMsgResponse = new DeleteMsgResponse();
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    grpc.invoke(Messenger.DeleteMsg, {
      metadata,
      request: newDeleteMsgResquest,
      host: environment.messengerHost,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),

      onMessage: (response: DeleteMsgResponse) => {
        deleteMsgResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(deleteMsgResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
export const BlockUser = (
  token: Value<string>,
  blockUserRequest: BlockUserRequest
): Promise<BlockUserResponse> =>
  new Promise<BlockUserResponse>((resolve, reject) => {
    let blockUserResponse = new BlockUserResponse();
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    grpc.invoke(Messenger.BlockUser, {
      metadata,
      request: blockUserRequest,
      host: environment.messengerHost,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),

      onMessage: (response: BlockUserResponse) => {
        blockUserResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(blockUserResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
export const UnBlockUser = (
  token: Value<string>,
  blockUserRequest: BlockUserRequest
): Promise<BlockUserResponse> =>
  new Promise<BlockUserResponse>((resolve, reject) => {
    let blockUserResponse = new BlockUserResponse();
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    grpc.invoke(Messenger.UnblockUser, {
      metadata,
      request: blockUserRequest,
      host: environment.messengerHost,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),

      onMessage: (response: BlockUserResponse) => {
        blockUserResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(blockUserResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
export const GetAdRelatedDiscussionByMembers = (
  token: Value<string>,
  getAdRelatedDiscussionByMembersRequest: GetAdRelatedDiscussionByMembersRequest
): Promise<GetAdRelatedDiscussionByMembersResponse> =>
  // eslint-disable-next-line @typescript-eslint/no-shadow
  new Promise<GetAdRelatedDiscussionByMembersResponse>((resolve, reject) => {
    let response = new GetAdRelatedDiscussionByMembersResponse();
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    grpc.invoke(Messenger.GetAdRelatedDiscussionByMembers, {
      metadata,
      request: getAdRelatedDiscussionByMembersRequest,
      host: environment.messengerHost,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),

      onMessage: (
        getAdRelatedDiscussionByMembersResponse: GetAdRelatedDiscussionByMembersResponse
      ) => {
        response = getAdRelatedDiscussionByMembersResponse;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(response);
        } else {
          reject(msg);
        }
      },
    });
  });
export const InitiateDiscussion = (
  token: Value<string>,
  initiateDiscussionRequest: InitiateDiscussionRequest
): Promise<InitiateDiscussionResponse> =>
  new Promise<InitiateDiscussionResponse>((resolve, reject) => {
    let response = new InitiateDiscussionResponse();
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    grpc.invoke(Messenger.InitiateDiscussion, {
      metadata,
      request: initiateDiscussionRequest,
      host: environment.messengerHost,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),

      onMessage: (initiateDiscussionResponse: InitiateDiscussionResponse) => {
        response = initiateDiscussionResponse;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(response);
        } else {
          reject(msg);
        }
      },
    });
  });
export const MarkDiscussionMessagesAsRead = (
  token: Value<string>,
  discussionRequest: DiscussionRequest
): Promise<boolean> =>
  new Promise<boolean>((resolve, reject) => {
    let response = false;
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    grpc.invoke(Messenger.MarkDiscussionMessagesAsRead, {
      metadata,
      request: discussionRequest,
      host: environment.messengerHost,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),

      onMessage: () => {
        response = true;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(response);
        } else {
          reject(msg);
        }
      },
    });
  });
export const GetUnreadDiscussionsCount = (
  token: Value<string>,
  userRequest: UserRequest
): Promise<UnreadDiscussionsCountResponse> =>
  new Promise<UnreadDiscussionsCountResponse>((resolve, reject) => {
    let unreadDiscussionsCountResponse = new UnreadDiscussionsCountResponse();
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    grpc.invoke(Messenger.GetUnreadDiscussionsCount, {
      metadata,
      request: userRequest,
      host: environment.messengerHost,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),

      onMessage: (response: UnreadDiscussionsCountResponse) => {
        unreadDiscussionsCountResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(unreadDiscussionsCountResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
export const listUsers = (
  token: Value<string>,
  filterAndDatesSearch: FilterAndDatesSearch
): Promise<UserResponse[]> => {
  const AdCurrentVersionResponseList: UserResponse[] = [];
  return new Promise<UserResponse[]>((resolve, reject) => {
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    grpc.invoke(MarketPlace.ListUsers, {
      metadata,
      request: filterAndDatesSearch,
      host: environment.marketPlaceHostClient,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: UserResponse) => {
        AdCurrentVersionResponseList.push(response);
        // this.adsOnChanged = AdCurrentVersionResponseList;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(AdCurrentVersionResponseList);
        } else {
          reject(msg);
        }
      },
    });
  });
};

export const searchShopsByText = (
  token: Value<string>,
  searchShopByTitle: SearchUsersByTitleRequest
): Promise<UserResponse.AsObject[]> => {
  // tslint:disable-next-line:no-shadowed-variable

  const shopsResponse: UserResponse.AsObject[] = [];
  return new Promise<UserResponse.AsObject[]>((resolve, reject) => {
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    grpc.invoke(MarketPlace.SearchShopsByText, {
      metadata,
      request: searchShopByTitle,
      host: environment.marketPlaceHostClient,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: UserResponse) => {
        shopsResponse.push(response.toObject());
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(shopsResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
};

export const getCountAds = (
  filterParams: FilterPrivateAdsType
): Promise<TotalAdsCountResponse> => {
  const { isPublic, userId, status } = filterParams;

  const userByIDRequest: GetAdsByUserRequest = new GetAdsByUserRequest();
  userByIDRequest.setUserid(userId);
  if (isPublic) {
    userByIDRequest
      .getFilterparamsMap()
      .set('currentversion.state', Version.State.ACTIVE.toString());
  } else if (status !== SHOW_ALL_ADS)
    userByIDRequest
      .getFilterparamsMap()
      .set('currentversion.state', status.toString());

  return new Promise<TotalAdsCountResponse>((resolve, reject) => {
    let shopCountAdResponse = new TotalAdsCountResponse();
    grpc.invoke(MarketPlace.GetAdsByUserCount, {
      request: userByIDRequest,
      host: environment.marketPlaceHostClient,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: TotalAdsCountResponse) => {
        shopCountAdResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(shopCountAdResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
};

export const countShopsByText = (
  searchShopByTitle: SearchUsersByTitleRequest
): Promise<TotalUserCountResponse> =>
  new Promise<TotalUserCountResponse>((resolve, reject) => {
    let listUsersCount = new TotalUserCountResponse();
    grpc.invoke(MarketPlace.SearchShopsByTextCount, {
      request: searchShopByTitle,
      host: environment.marketPlaceHostClient,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: TotalUserCountResponse) => {
        listUsersCount = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(listUsersCount);
        } else {
          reject(msg);
        }
      },
    });
  });

export const getListDelegationByGovernorate = (
  delegationsByGovernorateRequest: DelegationsByGovernorateRequest
): Promise<DelegationResponse[]> => {
  // tslint:disable-next-line:no-shadowed-variable
  const DelegationListResponse: DelegationResponse[] = [];
  return new Promise<DelegationResponse[]>((resolve, reject) => {
    grpc.invoke(MarketPlace.GetDelegationList, {
      request: delegationsByGovernorateRequest,
      host: environment.marketPlaceHostClient,
      transport: grpc.FetchReadableStreamTransport({
        keepalive: true,
        credentials: 'include',
        mode: 'cors',
      }),
      onMessage: (response: DelegationResponse) => {
        DelegationListResponse.push(response);
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(DelegationListResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
};

export const createAd = (
  token: Value<string>,
  newAdRequest: NewAdRequest
): Promise<AdResponse> =>
  new Promise<AdResponse>((resolve, reject) => {
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    grpc.invoke(MarketPlace.CreateAd, {
      request: newAdRequest,
      host: environment.marketPlaceHostClient,
      metadata,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: AdResponse) => {
        resolve(response);
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code !== grpc.Code.OK) {
          reject(msg);
        }
      },
    });
  });

export const GetDefaultLocationByGovernorateAndDelegation = (
  locationByGovernorateAndDelegation: LocationByGovernorateAndDelegation
): Promise<LocationResponse> =>
  new Promise<LocationResponse>((resolve, reject) => {
    let locationResponse = new LocationResponse();
    grpc.invoke(MarketPlace.GetDefaultLocationByGovernorateAndDelegation, {
      request: locationByGovernorateAndDelegation,
      host: environment.marketPlaceHostClient,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: LocationResponse) => {
        locationResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(locationResponse);
        } else {
          reject(msg);
        }
      },
    });
  });

export const getNumberAdsByUser = (
  nbAdsByUserRequest: NbAdsByUserRequest,
  token: string
): Promise<NbAdsByUserResponse> =>
  new Promise<NbAdsByUserResponse>((resolve, reject) => {
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });

    let nbAdsByUserResponse = new NbAdsByUserResponse();
    grpc.invoke(MarketPlace.GetNumberAdsByUser, {
      request: nbAdsByUserRequest,
      host: environment.marketPlaceHostClient,
      metadata,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: NbAdsByUserResponse) => {
        nbAdsByUserResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(nbAdsByUserResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
export const ViewAd = (viewRequest: AdViewRequest): Promise<ViewResponse> =>
  new Promise<ViewResponse>((resolve, reject) => {
    const metadata = new grpc.Metadata({ Authorization: `Bearer` });

    let viewResponse = new ViewResponse();
    grpc.invoke(MarketPlace.ViewAd, {
      request: viewRequest,
      host: environment.marketPlaceHostClient,
      metadata,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: ViewResponse) => {
        viewResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(viewResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
export const GetAdLeads = (adbyidrequest: AdByIDRequest): Promise<ResponseView> =>
  new Promise<ResponseView>((resolve, reject) => {
    const metadata = new grpc.Metadata({ Authorization: `Bearer` });

    let view = new ResponseView();
    grpc.invoke(MarketPlace.GetViewPerAd, {
      request: adbyidrequest,
      host: environment.marketPlaceHostClient,
      metadata,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: ResponseView) => {
        view = response;
        console.log(view.toObject())
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(view);
        } else {
          console.log(msg)
          reject(msg);
        }
      },
    });
  });

export const markAdSold = (
  token: Value<string>,
  id: string
): Promise<AdCurrentVersionResponse> => {
  const adByIDRequest = new AdByIDRequest();
  adByIDRequest.setId(id);

  return new Promise<AdCurrentVersionResponse>((resolve, reject) => {
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    let adCurrentVersionResponse = new AdCurrentVersionResponse();

    grpc.invoke(MarketPlace.SoldAd, {
      request: adByIDRequest,
      host: environment.marketPlaceHostClient,
      metadata,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: AdCurrentVersionResponse) => {
        adCurrentVersionResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(adCurrentVersionResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
};

export const updateUser = (
  token: Value<string>,
  newUserByIDRequest: NewUserByIDRequest
): Promise<UserResponse> =>
  new Promise<UserResponse>((resolve, reject) => {
    let userResponse = new UserResponse();
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    grpc.invoke(MarketPlace.UpdateUser, {
      request: newUserByIDRequest,
      host: environment.marketPlaceHostClient,
      metadata,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: UserResponse) => {
        userResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(userResponse);
        } else {
          reject(msg);
        }
      },
    });
  });

export const updateAd = (
  token: Value<string>,
  stateInAdByIDRequest: VersionInAdByIDRequest
): Promise<AdCurrentVersionResponse> =>
  new Promise<AdCurrentVersionResponse>((resolve, reject) => {
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });

    let adResponse = new AdCurrentVersionResponse();

    grpc.invoke(MarketPlace.UpdateAdData, {
      request: stateInAdByIDRequest,
      host: environment.marketPlaceHostClient,
      metadata,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: AdCurrentVersionResponse) => {
        adResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(adResponse);
        } else {
          reject(msg);
        }
      },
    });
  });

export const deleteAd = (
  token: Value<string>,
  id: string
): Promise<AdCurrentVersionResponse> => {
  const adByIDRequest = new AdByIDRequest();
  adByIDRequest.setId(id);

  return new Promise<AdCurrentVersionResponse>((resolve, reject) => {
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });

    let adResponse = new AdCurrentVersionResponse();

    grpc.invoke(MarketPlace.DeleteAd, {
      request: adByIDRequest,
      host: environment.marketPlaceHostClient,
      metadata,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: AdCurrentVersionResponse) => {
        adResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(adResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
};

export const boostAd = (
  token: string,
  adByIDRequest: AdByIDRequest
): Promise<AdCurrentVersionResponse> =>
  new Promise<AdCurrentVersionResponse>((resolve, reject) => {
    let adBoostResoponse = new AdCurrentVersionResponse();
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });

    grpc.invoke(MarketPlace.BoostAd, {
      request: adByIDRequest,
      host: environment.marketPlaceHostClient,
      metadata,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: AdCurrentVersionResponse) => {
        adBoostResoponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(adBoostResoponse);
        } else {
          reject(msg);
        }
      },
    });
  });

export const autoBoostAd = (
  token: string,
  adByIDRequest: AutoBoostAdByIDRequest
): Promise<AddAutomaticBoostResponse> =>
  new Promise<AddAutomaticBoostResponse>((resolve, reject) => {
    let adBoostResoponse = new AddAutomaticBoostResponse();
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });

    grpc.invoke(MarketPlace.AddAutomaticBoost, {
      request: adByIDRequest,
      host: environment.marketPlaceHostClient,
      metadata,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: AddAutomaticBoostResponse) => {
        adBoostResoponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(adBoostResoponse);
        } else {
          reject(msg);
        }
      },
    });
  });

export const markAdAsFeatured = (
  token: string,
  adByIDRequest: AdByIDRequest
): Promise<AdCurrentVersionResponse> =>
  new Promise<AdCurrentVersionResponse>((resolve, reject) => {
    let markAdAsFeaturedResoponse = new AdCurrentVersionResponse();
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });

    grpc.invoke(MarketPlace.MarkAdAsFeatured, {
      request: adByIDRequest,
      host: environment.marketPlaceHostClient,
      metadata,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: AdCurrentVersionResponse) => {
        markAdAsFeaturedResoponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(markAdAsFeaturedResoponse);
        } else {
          reject(msg);
        }
      },
    });
  });

export const getPaymentVars = (token: string): Promise<GetPaymentResponse> => {
  const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });

  const empty: Empty = new Empty();
  return new Promise<GetPaymentResponse>((resolve, reject) => {
    let paymentData = new GetPaymentResponse();
    grpc.invoke(MarketPlace.GetPaymentInformation, {
      request: empty,
      host: environment.marketPlaceHostClient,
      metadata,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: GetPaymentResponse) => {
        paymentData = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(paymentData);
        } else {
          reject(msg);
        }
      },
    });
  });
};

export const choosePaymentType = (
  token: string,
  transactionStatusRequest: TransactionStatusRequest
): Promise<TransactionStatusResponse> =>
  new Promise<TransactionStatusResponse>((resolve, reject) => {
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
    let transactionStatusResponse = new TransactionStatusResponse();
    grpc.invoke(MarketPlace.AddTransactionStatus, {
      request: transactionStatusRequest,
      host: environment.marketPlaceHostClient,
      metadata,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: TransactionStatusResponse) => {
        transactionStatusResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(transactionStatusResponse);
        } else {
          reject(msg);
        }
      },
    });
  });

export const ReportAdByUser = (
  token: string,
  reportAdByUserRequest: ReportAdByUserRequest
): Promise<ReportAdByUserResponse> =>
  new Promise<ReportAdByUserResponse>((resolve, reject) => {
    const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });

    let reportAdByUserResponse = new ReportAdByUserResponse();
    grpc.invoke(MarketPlace.ReportAdByUser, {
      request: reportAdByUserRequest,
      host: environment.marketPlaceHostClient,
      metadata,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: ReportAdByUserResponse) => {
        reportAdByUserResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(reportAdByUserResponse);
        } else {
          reject(msg);
        }
      },
    });
  });

export const persistFireBaseToken = (
  sendUserDeviceConfigRequest: SendUserDeviceConfigRequest
): Promise<SendUserDeviceConfigResponse> =>
  new Promise<SendUserDeviceConfigResponse>((resolve, reject) => {
    let sendUserDeviceConfigResponse = new SendUserDeviceConfigResponse();
    const metadata = new grpc.Metadata({ Authorization: `` });

    grpc.invoke(MarketPlace.PersistDeviceConfig, {
      request: sendUserDeviceConfigRequest,
      host: environment.marketPlaceHostClient,
      metadata,
      transport:
        typeof window === 'undefined'
          ? NodeHttpTransport()
          : grpc.FetchReadableStreamTransport({
            keepalive: true,
            credentials: 'include',
            mode: 'cors',
          }),
      onMessage: (response: SendUserDeviceConfigResponse) => {
        sendUserDeviceConfigResponse = response;
      },
      onEnd: (code: grpc.Code, msg: string | undefined) => {
        if (code === grpc.Code.OK) {
          resolve(sendUserDeviceConfigResponse);
        } else {
          reject(msg);
        }
      },
    });
  });
  export const republishAd = (
    token: Value<string>,
    id: string
  ): Promise<AdCurrentVersionResponse> => {
    const adByIDRequest = new AdByIDRequest();
    adByIDRequest.setId(id);
    return new Promise<AdCurrentVersionResponse>((resolve, reject) => {
      const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
      let adCurrentVersionResponse = new AdCurrentVersionResponse();
  
      grpc.invoke(MarketPlace.RepublishAd, {
        request: adByIDRequest,
        host: environment.marketPlaceHostClient,
        metadata,
        transport:
          typeof window === 'undefined'
            ? NodeHttpTransport()
            : grpc.FetchReadableStreamTransport({
              keepalive: true,
              credentials: 'include',
              mode: 'cors',
            }),
        onMessage: (response: AdCurrentVersionResponse) => {
          adCurrentVersionResponse = response;
        },
        onEnd: (code: grpc.Code, msg: string | undefined) => {
          if (code === grpc.Code.OK) {
            resolve(adCurrentVersionResponse);
          } else {
            reject(msg);
          }
        },
      });
    });
  };
 export const AddTokenByAdmin = (
     token: string,
     buyTokensRequest: TransferTokensByAdminRequest
 ): Promise<TransferTokensResponse> =>
     new Promise<TransferTokensResponse>((resolve, reject) => {
         const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
         let transferTokensResponse = new TransferTokensResponse();
         grpc.invoke(MarketPlace.AddTokensByAdmin, {
             request: buyTokensRequest,
             host: environment.marketPlaceHostClient,
             metadata,
             transport:
                 typeof window === 'undefined'
                     ? NodeHttpTransport()
                     : grpc.FetchReadableStreamTransport({
                         keepalive: true,
                         credentials: 'include',
                         mode: 'cors',
                     }),
             onMessage: (response: TransferTokensResponse) => {
                 transferTokensResponse = response;
             },
             onEnd: (code: grpc.Code, msg: string | undefined) => {
                 if (code === grpc.Code.OK) {
                     resolve(transferTokensResponse);
                 } else {
                     reject(msg);
                 }
             },
         });
     });
     
      export const getUserAnalytics = (
        token: Value<string>,
        getAccountTransactionsRequest: UserByIDRequest
      ): Promise<AnalyticsResponse> =>
        new Promise<AnalyticsResponse>((resolve, reject) => {
          let getUserTransactionsBalanceResponse =
            new AnalyticsResponse();
          const metadata = new grpc.Metadata({ Authorization: `Bearer ${token}` });
          grpc.invoke(MarketPlace.GetUserAnalytics, {
            metadata,
            request: getAccountTransactionsRequest,
            host: environment.marketPlaceHostClient,
            transport:
              typeof window === 'undefined'
                ? NodeHttpTransport()
                : grpc.FetchReadableStreamTransport({
                  keepalive: true,
                  credentials: 'include',
                  mode: 'cors',
                }),
            onMessage: (response: AnalyticsResponse) => {
              getUserTransactionsBalanceResponse = response;
            },
            onEnd: (code: grpc.Code, msg: string | undefined) => {
              if (code === grpc.Code.OK) {
                resolve(getUserTransactionsBalanceResponse);
              } else {
                reject(msg);
              }
            },
          });
        });
      
