/**
 * Real-world map coordinates
 */
export interface ICoordinates {
  latitude?: number;
  longitude?: number;
}

/**
 * Tunisian cities, delegations and governorates with coordinates
 */
export interface ILocation {
  coordinates?: ICoordinates;
  delegation?: string;
  governorate?: string;
}

/**
 * The user who published the item, whether it's a shop or a casual user.
 */
export interface IPublisher {
  id: string;
  avatar: string;
  isShop?: boolean;
  number?: string;
  address?: string;
  name?: string;
  isApproved?: boolean;
}

/**
 * Listing item's state
 * @enum {number}
 */
export enum ListingState {
  PENDING_MOD,
  REJECTED,
  ACTIVE,
  EXPIRED,
  DELETED,
  SOLD,
  PENDING_MANUAL_MOD,
  PENDING_INSERTION,
}

/**
 * Listing item's product type
 * @enum {number}
 */
export enum ListingProductType {
  Used = 0,
  New = 1,
}

/**
 * Listing item's metadata
 */
export interface IListingItemMetadata {
  publishedOn?: Date;
  isActive?: boolean;
  isModified?: boolean;
  category?: string;
  subCategory?: string;
  isFeatured?: boolean;
  isGold?: boolean;
  state: ListingState;
  publisher?: IPublisher;
  meiliSynced?: boolean;
  producttype?: ListingProductType;
}

export interface IListingItem {
  id: string;
  title: string;
  images: string[];
  imgLoad?: string;
  description: string;
  price: number;
  location?: ILocation;
  phone?: string;
  metadata?: IListingItemMetadata;
}

export interface ICategoryParam {
  name: string;
  type: string;
  label: string;
  possiblevaluesList: string[];
  dependantname: string;
  single: boolean;
}

export type ICategoryParamList = ICategoryParam[];

export type QueryType = {
  sort: string;
  category: string;
  subCategory: string;
  governorate: string;
  delegation: string;
  price: string;
  [key: string]: any;
  page?: number;
};

export type IUserData = {
  id?: string;
  creationtimestamp?: Date;
  avatar?: string;
  email?: string;
  fullname?: string;
  location?: undefined;
  phonenumber?: string;

  tokenbalance?: number;
  url?: string;
  enabled?: boolean;
  favoritesList?: string[];
  kcid?: string;
  address?: string;
  [key: string]: any;
  shopBackgroundIMage?: string;

  isShop?: boolean;
  description?: string;
  categoryId?: string;
};

export type Itransaction = {
  createdTime?: number;
  description?: string;
  amount?: string;
  isVirement?: boolean;
};

export interface IAdAnalytics {
  id?: string;
  title: string;
  value: number | string;
  description: string;
}

export interface AdParam {
  label?: string;

  value?: string;
}

export interface IAdDetailItem {
  id: string;
  title: string;
  images: string[];
  description: string;
  price: number;
  category: string;
  subCategoryId: string;
  publishedOn?: Date;

  location?: ILocation;
  phone?: string;
  adParams?: AdParam[];
  publisher: IPublisher;
  sold?: boolean;
  deleted?: boolean;
  state: ListingState;
}

export interface IParams {
  min?: number;
  max?: number;
  name?: string;
  type?: string;
  singleSelect?: boolean;
  possiblesValues?: string[];
  fixedPossiblesValues?: string[];
  dependant?: string;
  active?: boolean;
  disabled?: boolean;
}

export interface SelectedIParams extends IParams {
  value: string;
}

export interface SelectedIParamsList {
  [key: string]: SelectedIParams;
}

// private ads  related to private pages (shops / profiles ...)

export interface FilterPrivateAdsType {
  userId: string;
  limit?: number;
  skip?: number;
  isPublic?: boolean;
  category?: string;
  status: number;
}

export interface ICategory {
  name?: string;

  defaultlimits?: string;

  boost?: number;
  featured?: number;
  golden?: number;

  boost24?: number;
  boost7?: number;
}

export enum ProductTypeEnum {
  'NEW' = 1,
  'OCCASION' = 0,
}

// hot deals
export interface CategoryAttributes {
  title: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
}

export interface Category {
  id: number;
  attributes: CategoryAttributes;
}

export interface CategoriesResponse {
  data: Category[];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
}

export interface HotDealAttributes {
  title: string;
  description: string;
  link: string;
  active: boolean;
  endDate: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  startDate: string;
  images: {
    data: {
      id: number;
      attributes: {
        url: string;
      };
    }[];
  };
  desktopImage: {
    data: {
      id: number;
      attributes: {
        url: string;
      };
    };
  };
  handsetImage: {
    data: {
      id: number;
      attributes: {
        url: string;
      };
    };
  };
}

export interface HotDeal {
  id: number;
  attributes: HotDealAttributes;
}

export interface HotDealsResponse {
  [x: string]: any;
  data: HotDeal[];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
}

export interface HotDealsPageProps {
  initialCategories: Category[];
  initialHotDeals: HotDeal[];
}
